import React, { useState, useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'

import './User.css'

import BaseRegisterForm from '../Authentication/BaseRegisterForm';
import SelectionDropdown from '../Common/SelectionDropdown';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { useNavigate } from 'react-router-dom';
import { BsCloudUpload } from 'react-icons/bs'
import { ADMIN_USER_TYPE, securedRegisterUser } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';


const TeacherRegisterForm = ({ onTeacherCreate = null, setIsSaving, user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const navigate = useNavigate();

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const subjectsResponse = await fetchAndSetSubjects();
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse);
        })();
    }, []);

    const onSelectedSubjectChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
    };

    const resetState = () => {
        setEmail("");
        setContactNumber("");
        setFullName("");
    }

    const handleRegister = async () => {
        setIsSaving(true);
        const data = {
            user_data: {
                first_name: fullName,
                last_name: "",
                email: email,
                phone_number: contactNumber,
                password: 'password'
            },
            subjects: selectedSubjects.map(selectedSubject => ({ subject_id: parseInt(selectedSubject.id) }))
        }
        const response = await securedRegisterUser('teacher', data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        resetState();
        setIsSaving(false);
        if (onTeacherCreate) {
            await onTeacherCreate();
        }
    };

    return (

        <>

            <div className="register-container flex">

                <h3 data-aos="fade-down" className="registerTitle">
                    Register New Teacher
                </h3>

                <form className="registerInfoInput flex" onSubmit={(event) => (event.preventDefault())}>

                    <BaseRegisterForm email={email} setEmail={setEmail} contactNumber={contactNumber} setContactNumber={setContactNumber} fullName={fullName} setFullName={setFullName} />

                    <SelectionDropdownMultiple className="subjectSelectionDropdown" name='Subjects' onSelectionToggle={onSelectedSubjectChange} selectedItems={selectedSubjects} itemList={subjects} nameField='subject_name' valueField='id' required={true} />

                </form>

                <button className='btn flex' onClick={handleRegister}>
                    SUBMIT <BsCloudUpload className="icon" />
                </button>

            </div>

        </>
    )
};

export default TeacherRegisterForm;
