import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Problems.png'
import Questions from '../../Components/Questions/Questions';
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';

const StudentQuestionsPage = () => {
    return (
        <>
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            <Hero
                cName="hero"
                img={bkgImage}
                title="Questions"
                description="Build Your Strength with JEETA!"
            />
            <Questions user={STUDENT_USER_TYPE} />
            <Footer user={STUDENT_USER_TYPE} />
        </>
    )
}

export default StudentQuestionsPage;