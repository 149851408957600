import React from 'react'

import image from '../../Assets/LiveClass.png'
import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';

import { StudentNavItems } from './StudentNavItems';
import { STUDENT_USER_TYPE } from '../../services/UserService';

import BatchView from '../../Components/Batches/BatchView';
import SlotView from '../../Components/Slots/SlotView';
import { useParams } from 'react-router-dom';

const StudentSlots = () => {
    const { slotId } = useParams();
    return (
        <>
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={image}
                title="Live Class"
                description="Engage and Motivate Yourself with JEETA!"
            /> */}
            <SlotView slotId={parseInt(slotId)} user={STUDENT_USER_TYPE} />
            <Footer user={STUDENT_USER_TYPE} />
        </>
    )
};

export default StudentSlots;