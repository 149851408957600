import React, { useState } from 'react';

import './testSection.css'

import TestSectionMarkingSchemeUpsert from './TestSectionMarkingSchemeUpsert';
import { UnauthorizedError } from '../../../../services/Errors';
import { useNavigate } from 'react-router';
import { securedCreateTestSections, securedUpdateTestSections } from '../../../../services/TestService';
import { ADMIN_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

import { AiOutlineSave } from 'react-icons/ai';
import { RiArrowGoBackFill } from 'react-icons/ri';

const TestSectionUpsert = ({ testId, testSubjectId, testSectionData = null, getOrder = null, onUpsertSection = null, handleCancel = null, user = ADMIN_USER_TYPE }) => {
    const [fullMarks, setFullMarks] = useState(testSectionData ? testSectionData.full_marks || 0 : 0);
    const [negativeMarks, setNegativeMarks] = useState(testSectionData ? testSectionData.negative_marks || 0 : 0);
    const [partialMarks, setPartialMarks] = useState(testSectionData ? testSectionData.partial_marks || 0 : 0);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const data = {
            full_marks: fullMarks ? parseInt(fullMarks) : 0,
            negative_marks: negativeMarks ? parseInt(negativeMarks) : 0,
            partial_marks: partialMarks ? parseInt(partialMarks) : 0
        }
        let response = null;
        if (!testSectionData) {
            data['section_order'] = getOrder();
            response = await securedCreateTestSections(testId, testSubjectId, [data], navigateCallbackOptions(navigate));
        } else {
            data['section_order'] = testSectionData.section_order;
            data['id'] = testSectionData.id;
            response = await securedUpdateTestSections(testId, testSubjectId, [data], navigateCallbackOptions(navigate));

        }
        if (response !== null && onUpsertSection !== null) {
            await onUpsertSection(response[0].test_section_id);
            // setResponseSaved(true);
        }
    }

    return (
        <div className="testSection flex">

            <TestSectionMarkingSchemeUpsert fullMarks={fullMarks} setFullMarks={setFullMarks} negativeMarks={negativeMarks} setNegativeMarks={setNegativeMarks} partialMarks={partialMarks} setPartialMarks={setPartialMarks} user={user} />

            <div className="btns flex">

                <button className="btn flex" onClick={handleSubmit}>
                    Save <AiOutlineSave className="icon" />
                </button>

                <button className="btn flex" onClick={() => handleCancel(false)}>
                    Cancel <RiArrowGoBackFill className="icon" />
                </button>

            </div>

        </div>
    );
}

export default TestSectionUpsert;
