import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import '../Test.css'
import './studentTest.css'

import { TEST_CREATE_TYPE_PRACTICE, securedFetchAttemptedTests, securedFetchTestStudents, securedFetchTests, securedStartTest } from '../../../services/TestService';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import PracticeTestCreate from '../TestCreate/PracticeTestCreate';

const StudentPracticeTestPage = ({ user = STUDENT_USER_TYPE }) => {
    const [allTests, setAllTests] = useState([]);
    const [isCreatePracticeTestModalOpen, setCreatePracticeTestModalOpen] = useState(false);

    const navigate = useNavigate();

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_PRACTICE, {}, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const allTests = await fetchAllTests();
            if (allTests === null) {
                return;
            }
            setAllTests(allTests);

        })();
    }, []);

    const onTestSelectionChange = (testId) => {
        navigate(`${testId}`);
    };


    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs < 10 ? "0" : "") + hrs.toString();
        const mins_str = (mins < 10 ? "0" : "") + mins.toString();

        return hrs_str + ":" + mins_str;
    }

    const onStartTest = async (event, test) => {
        event.stopPropagation();
        const response = await securedStartTest(TEST_CREATE_TYPE_PRACTICE, test.id, { test_duration_minutes: test.test_duration_minutes });
        if (response === null) {
            return;
        }
        navigate(`${test.id}`)
    }

    const onPracticeTestCreate = async () => {
        const allTests = await fetchAllTests();
        if (allTests === null) {
            return;
        }
        setAllTests(allTests);
    }


    return (


        <div className="student-tests-glass flex">

            <div>
                <button className='btn' onClick={() => setCreatePracticeTestModalOpen(true)}>Create New Practice Test</button>
            </div>
            <PracticeTestCreate isOpen={isCreatePracticeTestModalOpen} onPracticeTestCreate={onPracticeTestCreate} onRequestClose={() => setCreatePracticeTestModalOpen(false)} user={user} />
            <div className="testsList flex">
                <div className="testsTable flex" >

                    <table className="tests-table-style">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Duration</th>
                                {/* <th>Remaining Time</th> */}
                                {/* <th>...</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {allTests.length > 0 && allTests.map(test => (
                                <tr key={test.id} onClick={() => onTestSelectionChange(test.id)}>
                                    {test.test_start_time === null ? (
                                        <td onClick={(event) => onStartTest(event, test)} id={test.id}>
                                            <p className={'bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                START
                                            </p>
                                        </td>) : (
                                        <td>
                                            <p className={'flex justify-center items-center gap-1 bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                COMPLETED
                                            </p>
                                        </td>
                                    )}
                                    <td>
                                        {test.test_name}
                                    </td>
                                    <td>
                                        {test.test_start_time ? moment(test.test_start_time).format('DD/MM/YYYY') : null}
                                    </td>
                                    <td>
                                        {test.test_start_time ? moment(test.test_start_time).format('hh:mm A') : null}
                                    </td>
                                    <td>
                                        {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                    </td>
                                    {/* <td>
                                            </td> */}
                                    {/* <td>

                                    </td> */}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default StudentPracticeTestPage;
