import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

import './batches.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import SelectionDropdown from "../Common/SelectionDropdown";
import { UnauthorizedError } from "../../services/Errors";
import { securedCreateBatch } from "../../services/BatchService";
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import LoadingPage from '../Common/LoadingPage';

Modal.setAppElement('#root');

const GradeBatchCreate = ({ isOpen = false, onRequestClose = null, gradesInput, gradeInput, setBatch, onBatchCreate = null, user = ADMIN_USER_TYPE }) => {

    const [batchName, setBatchName] = useState("");
    const [batchProgramName, setBatchProgramName] = useState("");
    const [batchRank, setBatchRank] = useState(1);
    const [batchStartYear, setBatchStartYear] = useState(new Date().getFullYear());
    const [batchEndYear, setBatchEndYear] = useState(new Date().getFullYear() + 1);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (() => {
            setGrades(gradesInput);
            setSelectedGrade(gradeInput);
        })();
    }, [gradesInput]);

    const onTextChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const onNumberChange = (setter, minValue) => (event) => {
        if (event.target.value < minValue) {
            setter(minValue);
        } else {
            setter(event.target.value);
        }
    }

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            setSelectedGrade({ id: gradeId, grade_name: selection.label });
        }
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        const data = {
            batch_name: batchName,
            batch_program: batchProgramName,
            batch_rank: batchRank,
            batch_start_year: batchStartYear,
            batch_end_year: batchEndYear,
            batch_grade: selectedGrade.id
        }
        const response = await securedCreateBatch(data, navigateCallbackOptions(navigate));
        if (onBatchCreate) {
            await onBatchCreate({ id: response.batch_id, ...data });
        }
        setIsSaving(false);
        onRequestClose();
    }

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const renderBatchCreate = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>
                <div className="batchCreate flex">

                    <h3 className="modalTitle"> Create New Batch </h3>

                    {/* Grade Selection */}
                    <form className="batchInfoInput flex">

                        <div className="textInput flex">
                            <label className="title" htmlFor="batchName">Name</label>
                            <input type="text" value={batchName} onChange={onTextChange(setBatchName)} placeholder="Enter..." required />
                        </div>

                        <div className="numberInput flex">
                            <label className="title" htmlFor="batchRank">Rank</label>
                            <input type="number" value={batchRank} onChange={onNumberChange(setBatchRank, 1)} />
                        </div>

                        <div className="numberInput flex">
                            <label className="title" htmlFor="batchYear">Start Year</label>
                            <input type="number" value={batchStartYear} onChange={onNumberChange(setBatchStartYear, 2020)} />
                        </div>

                        <SelectionDropdown className="gradeSelectionDropdown" name='Grade' onSelectionChange={onSelectedGradeChange} selectedItem={selectedGrade} itemList={grades} nameField='grade_name' valueField='id' required={true} />

                    </form>

                    <button className='btn flex' onClick={handleSubmit}>
                        CREATE <MdAddCircleOutline className="icon" />
                    </button>

                </div>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (

        <Modal overlayClassName="customModal" className="batchModal" isOpen={isOpen} onRequestClose={onRequestClose}>
            {renderBatchCreate()}

        </Modal>
    )
}

export default GradeBatchCreate;