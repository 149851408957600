import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { TEACHER_USER_TYPE } from '../../services/UserService';
import { TeacherNavItems } from './TeacherNavItems';
import Syllabus from '../../Components/Syllabus/Syllabus';

const TeacherSyllabus = () => {
    return (
        <>
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Syllabus"
                description="Master entire syllabus with JEETA"
            /> */}
            <Syllabus user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </>
    )
}

export default TeacherSyllabus;