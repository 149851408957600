import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './ReadingMaterialView.css';
import { AiOutlineDelete, AiOutlineLeft, AiOutlineRight, AiOutlineSelect, AiOutlineUndo, AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';

import { pdfjs } from 'react-pdf';
import { MdCropFree } from 'react-icons/md';
import { securedCreateReadingMaterialMarkings, securedDeleteReadingMaterial, securedDeleteReadingMaterialMarking, securedFetchReadingMaterialMarkingsByFilter } from '../../../services/ReadingMaterialService';
import { useNavigate } from 'react-router-dom';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import LoadingPage from '../../Common/LoadingPage';
import Spinner from '../../Common/Tailwind/Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;


const useNonPassiveTouchListener = (ref, handleTouchMove, handleTouchStart, handleTouchEnd) => {
    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('touchmove', handleTouchMove, { passive: false });
            node.addEventListener('touchstart', handleTouchStart, { passive: false });
            node.addEventListener('touchend', handleTouchEnd, { passive: false });
        }
        return () => {
            if (node) {
                node.removeEventListener('touchmove', handleTouchMove, { passive: false });
                node.removeEventListener('touchstart', handleTouchStart, { passive: false });
                node.removeEventListener('touchend', handleTouchEnd, { passive: false });
            }
        };
    }, [ref, handleTouchMove]);
};

const ReadingMaterialPdfViewer = ({ readingMaterialData, user }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const [annotations, setAnnotations] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [currentRect, setCurrentRect] = useState(null);
    const [canDraw, setCanDraw] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const canvasRef = useRef(null);
    const pageCanvasRef = useRef(null);
    const containerRef = useRef(null);
    const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
    const [pdfWidth, setPdfWidth] = useState(null);
    const [actions, setActions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchReadingMaterialMarkings = async () => {
        const filter = { reading_material_ids: [readingMaterialData.id], page_numbers: [pageNumber] };
        const response = await securedFetchReadingMaterialMarkingsByFilter(filter, navigateCallbackOptions(navigate));
        if (response === null) return null;
        return response.data;
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const readingMaterialMarkings = await fetchReadingMaterialMarkings();
            if (readingMaterialMarkings === null) {
                setLoading(false);
                return;
            }
            setAnnotations(readingMaterialMarkings);
            setActions([]);
            setLoading(false);
        })();

    }, [readingMaterialData, pageNumber]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const goToPrevPage = () => {
        setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
    };

    const goToNextPage = () => {
        setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
    };

    const zoomIn = () => {
        setScale(prevScale => prevScale + 0.2);
    };

    const zoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.2, 0.5));
    };

    const handleMouseClick = async (e) => {
        if (!canDelete) {
            return;
        }
        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) / scale;
        const y = (e.clientY - rect.top) / scale;
        const deletedAnnotation = await clearSelectedAnnotation(x, y);
        setCanDelete(false);
        if (deletedAnnotation !== null) {
            setActions(prevActions => [...prevActions, { action: 'DELETE', data: deletedAnnotation }])
        }

    }

    const handleMouseDown = (e) => {
        if (!canDraw) {
            return;
        }
        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) / scale;
        const y = (e.clientY - rect.top) / scale;

        if (!isDrawing) {
            setCurrentRect({ top_left_x: x, top_left_y: y, width: 0, height: 0 });
            setIsDrawing(true);
        }
    };

    const handleMouseMove = (e) => {
        if (!canDraw) {
            return;
        }
        if (isDrawing && currentRect) {
            const rect = e.target.getBoundingClientRect();
            const width = (e.clientX - rect.left) / scale - currentRect.top_left_x;
            const height = (e.clientY - rect.top) / scale - currentRect.top_left_y;
            setCurrentRect(prevRect => ({ ...prevRect, width, height }));
        }
    };

    const handleMouseUp = async () => {
        if (!canDraw) {
            return;
        }
        if (isDrawing) {
            setIsDrawing(false);
            setCurrentRect(null);
            setCanDraw(false);
            const currentAnnotation = { ...currentRect, page_number: pageNumber };
            const updatedAnnotation = await handleSaveAnnotation(currentAnnotation);
            if (updatedAnnotation !== null) {
                setAnnotations(prevAnnotations => [...prevAnnotations, updatedAnnotation]);
                setActions(prevActions => [...prevActions, { action: 'DRAW', data: updatedAnnotation }]);
            }
        }
    };

    const handleSaveAnnotation = async (annotation) => {
        if (annotation.id) {
            delete annotation.id;
        }
        const data = { reading_material_markings: [{ ...annotation, reading_material_id: readingMaterialData.id }] };
        const response = await securedCreateReadingMaterialMarkings(data, navigateCallbackOptions(navigate));
        if (response === null) {
            return null;
        }

        annotation.id = response.reading_material_markings[0].reading_material_marking_id;
        return annotation;
    };

    const deleteAnnotation = async (annotationId) => {
        const annotation = annotations.find(annotation => annotation.id === annotationId);
        setAnnotations(annotations.filter(annotation => annotation.id !== annotationId));
        if (!annotation) {
            return;
        }
        return await handleDeleteAnnotation(annotationId);
    };

    const handleDeleteAnnotation = async (annotationId) => {
        const response = await securedDeleteReadingMaterialMarking(annotationId, navigateCallbackOptions(navigate));
        return response.ok;
    };

    const clearSelectedAnnotation = async (x, y) => {
        const selectedAnnotation = annotations.reverse().find(
            (annotation) =>
                annotation.page_number === pageNumber &&
                // annotation.type === 'rectangle' &&
                x >= annotation.top_left_x &&
                x <= annotation.top_left_x + annotation.width &&
                y >= annotation.top_left_y &&
                y <= annotation.top_left_y + annotation.height
        );
        if (!selectedAnnotation) {
            return null;
        }


        await deleteAnnotation(selectedAnnotation.id);
        return selectedAnnotation;
    };

    const undoLastAction = async () => {
        if (actions.length === 0) {
            return;
        }
        const lastAction = actions[actions.length - 1];
        if (lastAction.action === 'DRAW') {
            await deleteAnnotation(lastAction.data.id);
            setActions(prevActions => prevActions.slice(0, prevActions.length - 1));
        }
        if (lastAction.action === 'DELETE') {
            const previousDrawId = lastAction.data.id;
            const newAnnotation = await handleSaveAnnotation(lastAction.data);
            setAnnotations(prevAnnotations => [...prevAnnotations, newAnnotation]);
            const newActions = actions.slice(0, actions.length - 1).map(action => {
                if (action.data.id === previousDrawId) {
                    action.data.id = newAnnotation.id;
                }
                return action;
            });
            setActions(newActions);
        }


    }

    const loadAnnotations = (ctx, page, scale) => {
        annotations
            .filter(annotation => annotation.page_number === page)
            .forEach(annotation => {
                // if (annotation.type === 'rectangle') {
                // ctx.strokeStyle = 'red';
                // ctx.lineWidth = 2;
                // ctx.strokeRect(
                //     annotation.top_left_x * scale,
                //     annotation.top_left_y * scale,
                //     annotation.width * scale,
                //     annotation.height * scale
                // );
                ctx.fillStyle = 'rgba(255, 255, 0, 0.25)'; // Yellow with 50% opacity
                ctx.fillRect(
                    annotation.top_left_x * scale,
                    annotation.top_left_y * scale,
                    annotation.width * scale,
                    annotation.height * scale
                );

                // }
            });
    };

    const clearAnnotations = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    };

    const redrawAnnotations = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            clearAnnotations(ctx);
            loadAnnotations(ctx, pageNumber, scale);
        }
    };

    useEffect(() => {
        redrawAnnotations();
    }, [pageNumber, annotations, scale]);

    const onPageLoadSuccess = () => {
        if (pageCanvasRef.current) {
            const { width, height } = pageCanvasRef.current.getBoundingClientRect();
            if (pdfWidth === null) {
                setPdfWidth(width);
            }
            setPageDimensions({ width, height });
            if (canvasRef.current) {
                canvasRef.current.width = width;
                canvasRef.current.height = height;
            }
            if (pdfWidth === null) {
                setPdfWidth(width);
                setScale(containerRef.current.clientWidth / width);
            }
        }
        redrawAnnotations();
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas && currentRect) {
            const ctx = canvas.getContext('2d');
            clearAnnotations(ctx);
            loadAnnotations(ctx, pageNumber, scale);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.strokeRect(
                currentRect.top_left_x * scale,
                currentRect.top_left_y * scale,
                currentRect.width * scale,
                currentRect.height * scale
            );
        }
    }, [currentRect]);


    const handleTouchMove = (e) => {
        if (!canDraw) {
            return;
        }
        e.preventDefault();
        handleMouseMove(e.touches[0]);
    }

    const handleTouchStart = (e) => {
        if (!canDraw) {
            return;
        }
        e.preventDefault();
        handleMouseDown(e.touches[0]);
    }

    const handleTouchEnd = (e) => {
        if (!canDraw) {
            return;
        }
        e.preventDefault();
        handleMouseUp(e.changedTouches[0]);
    }

    useNonPassiveTouchListener(canvasRef, handleTouchMove, handleTouchStart, handleTouchEnd);

    const renderLoadingPage = () => {
        return <div className="loadingPageWrapper"><Spinner extraClassName='page-loading' /></div>;
    }

    return (
        <div className="pdf-viewer">
            <div className="pdf-controls">
                <div className="zoom-controls flex">
                    <button className="left-btn flex" onClick={zoomOut} hidden={scale <= 0.5}>
                        <AiOutlineZoomOut className="icon" />
                    </button>
                    <span>{(scale * 100).toFixed(0)}%</span>
                    <button className="right-btn flex" onClick={zoomIn} hidden={scale >= 2.0}>
                        <AiOutlineZoomIn className="icon" />
                    </button>
                </div>
                <div className="change-page-btns flex">
                    <button className="left-btn flex" onClick={goToPrevPage} hidden={pageNumber === 1}>
                        <AiOutlineLeft className="icon" />
                    </button>
                    <span>{pageNumber}/{numPages}</span>
                    <button className="right-btn flex" onClick={goToNextPage} hidden={pageNumber >= numPages}>
                        <AiOutlineRight className="icon" />
                    </button>
                </div>
                <div className="annotation-controls flex">
                    {actions.length > 0 && (<button className="undo-mark-btn flex" onClick={undoLastAction}>
                        <p>Undo {actions[actions.length - 1].action === "DRAW" ? "Highlight" : "Delete"}</p>
                        <AiOutlineUndo className="icon" />
                    </button>)}
                    <button className={`create-mark-btn ${canDraw ? 'enabled' : ''} flex`} onClick={() => setCanDraw(prevCanDraw => !prevCanDraw)}>
                        <p>Highlight</p>
                        <MdCropFree className="icon" />
                    </button>
                    {!canDraw && (<button className={`delete-mark-btn ${canDelete ? 'enabled' : ''} flex`} onClick={() => setCanDelete(prevCanDelete => !prevCanDelete)}>
                        <p>Delete</p>
                        <AiOutlineDelete className="icon" />
                    </button>)}
                </div>
            </div>
            <div className="pdf-container" style={{ position: 'relative' }} ref={containerRef}>
                <Document
                    file={readingMaterialData.pdf.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={renderLoadingPage}
                >
                    {isLoading ?
                        renderLoadingPage() :
                        <Page
                            className='pdf-page'
                            pageNumber={pageNumber}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            scale={scale}
                            onLoadSuccess={onPageLoadSuccess}
                            devicePixelRatio={2}
                            canvasRef={pageCanvasRef}
                        >
                            <canvas
                                ref={canvasRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: pageDimensions.width,
                                    height: pageDimensions.height,
                                    pointerEvents: 'auto'
                                }}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onClick={handleMouseClick}
                            />
                        </Page>}
                </Document>
            </div>
        </div>
    );
};

export default ReadingMaterialPdfViewer;
