import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import './questionModal.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import TopicCreate from '../../Syllabus/TopicCreate';
import QuestionCreateTypeFactory from './QuestionCreateTypeFactory';
import { QUESTION_UPLOAD_TYPES } from '../../../services/QuestionService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedFetchGrades } from '../../../services/BatchService';

import { AiFillCloseCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import QuestionEditTypeFactory from './QuestionEditTypeFactory';
import { securedFetchTagsByFilter } from '../../../services/TagService';
import TagCreate from '../../Tags/TagCreate';
import Spinner from '../../Common/Tailwind/Spinner';
import LoadingPage from '../../Common/LoadingPage';

Modal.setAppElement('#root'); // This is important for accessibility reasons

const QuestionEdit = ({ isOpen = true, inputQuestionData = null, onRequestClose = null, onEditQuestion = null, user = ADMIN_USER_TYPE }) => {

    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isImageBased, setImageBased] = useState(false);
    const [isTopicCreateModalOpen, setTopicCreateModalOpen] = useState(false);
    const [isTagCreateModalOpen, setTagCreateModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const setInitialIsImageBased = () => {
        if (inputQuestionData.paragraph_text === undefined) {
            setImageBased(inputQuestionData.question_text.length === 0);
        }
        else {
            setImageBased(inputQuestionData.paragraph_text.length === 0);
        }
    }

    const makeTopicFilter = (initialFilter) => {
        const filter = {};
        if (selectedSubject && selectedSubject.id) {
            filter['subject_ids'] = [selectedSubject.id];
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        if (selectedGrade && selectedGrade.id) {
            filter['grades'] = [selectedGrade.id];
        } else {
            filter['grades'] = grades.map(subject => subject.id);
        }
        return filter;
    }

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        setSubjects(subjectsResponse.data);
        return subjectsResponse.data;
    };

    const fetchAndSetTopics = async (filter = null) => {
        let topicFilter = filter;
        if (!topicFilter) {
            topicFilter = makeTopicFilter();
        }
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return null;
        }
        setTopics(topicsResponse.data);
        return topicsResponse.data;
    };

    const onTopicCreate = async (newTopicId) => {
        const topicsResponse = await fetchAndSetTopics();
        if (topicsResponse === null) {
            return;
        }
        const topicToAdd = topicsResponse.find(topic => (topic.id === newTopicId));
        if (topicToAdd) {
            setSelectedTopics([...selectedTopics, topicToAdd]);
        }
    }

    const onTagCreate = async (newTagId) => {
        const tagsResponse = await securedFetchTagsByFilter(null);
        const tagsResponseData = tagsResponse.data;
        setTags(tagsResponseData);
        const tagsToAdd = tagsResponseData.find(tag => (tag.id === newTagId));
        if (tagsToAdd) {
            setSelectedTags([...selectedTags, tagsToAdd]);
        }
    }


    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
        return gradesResponse.data;
    };

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            setIsLoading(true);
            setInitialIsImageBased();
            const subjectsResponse = await fetchAndSetSubjects();
            if (subjectsResponse === null) {
                return;
            }
            const questionSubject = subjectsResponse.find(subject => subject.id === inputQuestionData.topics[0].topic.subject.id);
            setSelectedSubject(questionSubject);
            const gradesResponse = await fetchAndSetGrades();
            if (gradesResponse === null) {
                return;
            }
            const questionGrade = gradesResponse.find(grade => grade.id === inputQuestionData.topics[0].topic.grade);
            setSelectedGrade(questionGrade);
            const topicsResponse = await fetchAndSetTopics({ subject_ids: [questionSubject.id], grades: [questionGrade.id] });
            if (topicsResponse === null) {
                return;
            }
            const questionTopicIds = inputQuestionData.topics.map(topic => topic.topic.id);
            setSelectedTopics(topicsResponse.filter(topic => questionTopicIds.includes(topic.id)));
            const tagsResponse = await securedFetchTagsByFilter(null);
            const tagsResponseData = tagsResponse.data;
            setTags(tagsResponseData);
            const questionTagIds = inputQuestionData.tags.map(tag => tag.tag.id);
            setSelectedTags(tagsResponseData.filter(tag => questionTagIds.includes(tag.id)));
            setIsLoading(false);
        })();
    }, [isOpen]);

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            const topicFilter = makeTopicFilter();
            topicFilter["subject_ids"] = [subjectId]
            const topicsResponseData = await fetchAndSetTopics(topicFilter);
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
        setSelectedTopics([]);
    };

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            const topicFilter = makeTopicFilter();
            topicFilter["grades"] = [gradeId]
            const topicsResponse = await fetchAndSetTopics(topicFilter);
            setSelectedGrade({ id: gradeId, grade: gradeId.toString() });
        }
        setSelectedTopics([]);
    };

    const onSelectedTopicToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(selectedTopicsFromSelect);
    };

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const isQuestionDataValid = () => {
        return selectedTopics.length > 0;
    }

    const renderQuestionEdit = () => {
        if (isLoading) {
            return <Spinner />;
        }
        if (isSaving) {
            return <LoadingPage type='save' />
        }
        return (
            <>
                <h3 className="modalTitle"> Edit Question </h3>

                <form className="questionContent flex" onSubmit={onSubmit} >
                    <div className="questionCheck flex">
                        <label className="title">Image Based Only</label>
                        <input
                            type="checkbox"
                            checked={isImageBased}
                            onChange={() => setImageBased(!isImageBased)}
                        />
                    </div>
                    <div className="questionInfoInput flex">
                        <SelectionDropdown className="questionSelectionDropdown" name='Subject' onSelectionChange={onSelectedSubjectChange} itemList={subjects} selectedItem={selectedSubject} nameField='subject_name' valueField='id' />
                        <SelectionDropdown className="questionSelectionDropdown" name='Grade' onSelectionChange={onSelectedGradeChange} itemList={grades} selectedItem={selectedGrade} nameField='grade' valueField='id' />
                    </div>

                    <div className="questionTopicInput flex">
                        <SelectionDropdownMultiple className="questionSelectionDropdownMultiple" name='Topics' itemList={topics} selectedItems={selectedTopics} onSelectionToggle={onSelectedTopicToggle} nameField='topic_name' valueField='id' required isSearchable={true} />
                        <button id="add-btn" onClick={() => setTopicCreateModalOpen(true)}><MdAdd className="icon" /> </button>
                    </div>
                    <div className="questionTagInput flex">
                        <SelectionDropdownMultiple className="questionSelectionDropdownMultiple" name='Tags' itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagToggle} nameField='tag_name' valueField='id' isSearchable={true} />
                        <button id="add-btn" onClick={() => setTagCreateModalOpen(true)}><MdAdd className="icon" /> </button>
                    </div>

                    <QuestionEditTypeFactory inputQuestionData={inputQuestionData} isImageBased={isImageBased} selectedTopics={selectedTopics} selectedTags={selectedTags} onRequestClose={onRequestClose} onAddQuestion={onEditQuestion} isQuestionDataValid={isQuestionDataValid} user={user} setIsSaving={setIsSaving} />
                </form>

                <TopicCreate isOpen={isTopicCreateModalOpen} onRequestClose={() => setTopicCreateModalOpen(false)} initialSubjects={subjects} initialGrades={grades} initialSubject={selectedSubject} initialGrade={selectedGrade} onTopicCreate={onTopicCreate} user={user} />
                <TagCreate isOpen={isTagCreateModalOpen} onRequestClose={() => setTagCreateModalOpen(false)} allTags={tags} onTagCreate={onTagCreate} user={user} />

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (

        <Modal overlayClassName="customModal" className="questionModal flex" isOpen={isOpen} onRequestClose={onRequestClose} >
            {renderQuestionEdit()}
        </Modal>

    );
}



export default QuestionEdit