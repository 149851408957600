import React, { useEffect, useState } from "react";

import "./studentTest.css";

import StudentTestPage from "./StudentTestPage";
import StudentPracticeTestPage from "./StudentPracticeTestPage";
import StudentVirtualTestPage from "./StudentVirtualTestPage";
import StudentSelectTestPageSidebar from "./Sidebar/StudentSelectTestPageSidebar";
import { STUDENT_USER_TYPE } from "../../../services/UserService";
import HeaderSection from "../../Common/Tailwind/PageSections/HeaderSection";

const StudentSelectTestPage = ({ pathTab = null, user = STUDENT_USER_TYPE }) => {

    const [selectedTab, setSelectedTab] = useState(pathTab === null ? "Common" : pathTab);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 760);

    const renderTestPageContent = () => {
        return (
            <div className="studentSelectTestPage-main flex">

                {isTabletView ? (
                    <>
                        {(selectedTab === 'Common') ? (
                            <StudentTestPage />
                        ) : ((selectedTab === 'Virtual') ? (
                            <StudentVirtualTestPage />
                        ) : (
                            <StudentPracticeTestPage />
                        ))}
                    </>
                ) : (
                    <h3>Please open test in PC or Tablet.</h3>
                )}

            </div>
        )
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className="page">

            <div className="tests flex">

                <div className="tests-header">
                    <HeaderSection header={"Perfect Your Techniques by Practicing with JEETA!"} />

                    {/* <div className="title">
                        <h2>Perfect Your Techniques by Practicing with JEETA!</h2>
                    </div> */}
                </div>
                {/* Sidebar */}

                <div className="studentSelectTestPage">
                    <StudentSelectTestPageSidebar
                        user={user}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    {renderTestPageContent()}
                </div>


            </div>

        </div>
    )
};

export default StudentSelectTestPage;