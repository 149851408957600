import React, { useState, useEffect } from "react";

import './User.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import StudentList from "./StudentList";
import TeacherList from "./TeacherList";
import { ADMIN_USER_TYPE, MEMBER_TYPES } from "../../services/UserService";
import HeaderSection from "../Common/Tailwind/PageSections/HeaderSection";

const MemberPage = ({ user = ADMIN_USER_TYPE }) => {

    const [type, setType] = useState(MEMBER_TYPES[0]);

    const onTypeChange = (selection) => {
        setType({ id: selection.value, name: selection.label });
    };

    const renderList = () => {

        switch (type.id) {
            case 'student':
                return <StudentList />;
            default:
                return <TeacherList />
        }
    }

    return (

        <div className="page">

            <div className="members flex">

                <div className="members-header">
                    <HeaderSection header={"Upgrade Member Management to the Next Level with JEETA!"} />

                    {/* <div className="title">
                        <h2>Upgrade Member Management to the Next Level with JEETA!</h2>
                    </div> */}
                </div>

                {/* Type Selection */}
                <div className="members-glass flex">

                    <div className="title">
                        <SelectionDropdown className="memberTypeSelectionDropdown" name="" itemList={MEMBER_TYPES} selectedItem={type} onSelectionChange={onTypeChange} nameField='name' valueField='id' />
                        <h3> in Your Coaching </h3>
                    </div>

                    {renderList()}

                </div>
            </div>

        </div>

    )
};

export default MemberPage;
