export const AdminNavItems = [
    {
        title: "Dashboard",
        url: "/admin/dashboard",
        cName: "navLink",
    },
    {
        title: "Syllabus",
        url: "/admin/syllabus",
        cName: "navLink",
    },
    // {
    //     title: "Questions",
    //     url: "/admin/questions",
    //     cName: "navLink",
    // },
    {
        title: "Batches",
        url: "/admin/liveclass",
        cName: "navLink",
    },
    {
        title: "Members",
        url: "/admin/members",
        cName: "navLink",
    },
    {
        title: "Tests",
        url: "/admin/tests",
        cName: "navLink",
    },
    // {
    //     title: "Analytics",
    //     url: "/admin/analytics",
    //     cName: "navLink",
    // },
    {
        title: "Profile",
        url: "/admin/profile",
        cName: "navLink",
    }
]