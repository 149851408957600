import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './Tags.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BsCloudUpload } from 'react-icons/bs'
import { securedCreateTag } from '../../services/TagService';
import SelectionDropdown from '../Common/SelectionDropdown';

Modal.setAppElement('#root');

const TagCreate = ({ isOpen, onRequestClose, allTags = [], onTagCreate = null, user = ADMIN_USER_TYPE }) => {

    const [tagName, setTagName] = useState("");
    const [tagType, setTagType] = useState("");
    const [isHidden, setIsHidden] = useState(false);
    const [tagTypes, setTagTypes] = useState([]);
    const [selectedTag, setSelectedTag] = useState({});
    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (tagName.length === 0 || tagType.length === 0) {
            return;
        }
        const data = {
            tag_name: tagName,
            tag_type: tagType,
            is_hidden: isHidden
        }

        const response = await securedCreateTag(data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        if (onTagCreate) {
            await onTagCreate(response.tag_id);
        }
        onRequestClose();
    }

    const setUniqueTagTypes = () => {
        const uniqueTagTypes = [];
        allTags.map((item) => {
            var findItem = uniqueTagTypes.find((x) => x.name === item.tag_type);
            if (!findItem) uniqueTagTypes.push({ id: item.tag_type, name: item.tag_type });
        });
        setTagTypes(uniqueTagTypes);
    }

    const onTagTypeChange = (selection) => {
        setSelectedTag({ id: selection.value, name: selection.label });
        setTagType(selection.value);
    }


    useEffect(() => {
        Aos.init({ duration: 1000 })
        if (isOpen) {
            setUniqueTagTypes();
        }
    }, [isOpen])

    return (
        <Modal overlayClassName="customModal" className="tagModal" isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="tagCreate flex">

                <h3 className="tagTitle"> Create New Tag </h3>

                <form className="tagInfoInput flex">
                    <SelectionDropdown className="tagTypeSelectionDropdown" name="Existing Types" itemList={tagTypes} selectedItem={selectedTag} onSelectionChange={onTagTypeChange} nameField='name' valueField='id' isSearchable={true} />
                    <div className="textInput flex">
                        <label className="title">Type</label>
                        <input type="text" value={tagType} onChange={e => setTagType(e.target.value)} placeholder="Enter..." required />
                    </div>

                    <div className="textInput flex">
                        <label className="title">Name</label>
                        <input type="text" value={tagName} onChange={e => setTagName(e.target.value)} placeholder="Enter..." required />
                    </div>

                    <div className="checkBoxInput flex">
                        <label className="title">Keep Hidden</label>
                        <input
                            type="checkbox"
                            className='checkBox'
                            checked={isHidden}
                            onChange={() => setIsHidden(prev => !prev)}
                        />
                    </div>


                </form>

                <button className='btn flex' onClick={handleSubmit}>
                    SUBMIT <BsCloudUpload className="icon" />
                </button>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />

            </div>
        </Modal>
    );
}

export default TagCreate;
