import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import TestPage from '../../Components/Test/TestPage';

const AdminTests = () => {
    return (
        <>
            <Navbar navItems={AdminNavItems} />
            <TestPage user={ADMIN_USER_TYPE} />
            <Footer />
        </>
    )
}

export default AdminTests;