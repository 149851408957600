import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { motion } from "framer-motion";

import './Syllabus.css'

import SyllabusQuestions from './SyllabusQuestions';
import SyllabusSidebar from './Sidebar/SyllabusSidebar';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import SyllabusSlots from './SyllabusSlots';
import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { securedFetchGrades } from '../../services/BatchService';
import SyllabusFilter from './SyllabusFilter';
import SyllabusReadingMaterials from './SyllabusReadingMaterial';
import SyllabusRecordedVideos from './SyllabusRecordedVideos';
import HeaderSection from '../Common/Tailwind/PageSections/HeaderSection';
import { useAuth } from '../Authentication/AuthContext';
import Spinner from '../Common/Tailwind/Spinner';


const Syllabus = ({ user = ADMIN_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Questions");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopics, setSelectedMainTopics] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [syllabusFilter, setSyllabusFilter] = useState(null);
    const navigate = useNavigate();

    const {
        config
    } = useAuth();


    const handleSubmit = async () => {
        setSyllabusFilter(makeSyllabusFilter);
    }

    useEffect(() => {
        (async () => {
            const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
            if (fetchedUserInfo === null) {
                return;
            }
            setUserInfo(fetchedUserInfo);
            setSyllabusFilter({ subjects: [], selectedSubjects: [], mainTopics: [], selectedMainTopics: [], topics: [], selectedTopics: [] });
        })();
    }, []);

    useEffect(() => {
        setSyllabusFilter(makeSyllabusFilter());
    }, [topics, selectedTopics, subjects, selectedSubjects, mainTopics, selectedMainTopics]);

    const makeSyllabusFilter = () => {
        return {
            subjects: subjects,
            selectedSubjects: selectedSubjects,
            grades: grades,
            selectedGrades: selectedGrades,
            mainTopics: mainTopics,
            selectedMainTopics: selectedMainTopics,
            topics: topics,
            selectedTopics: selectedTopics
        }
    }

    return (
        <div className="page">

            <div className="syllabus flex">

                {/* header & title */}
                <div className="syllabus-header">
                    <HeaderSection header={"Master the Entire Topics in Syllabus with JEETA!"} />
                    {/* <div className="title">
                        <h2>Master the Entire Topics in Syllabus with JEETA!</h2>
                    </div> */}
                </div>

                <div className="syllabus-glass">
                    {/* Sidebar */}
                    <SyllabusSidebar user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                    <div className="syllabus-main flex">

                        {/* <h3 className="title"> Dive Deep into Learning:</h3> */}
                        {/* Topic Filter */}
                        <SyllabusFilter subjects={subjects} setSubjects={setSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects}
                            grades={grades} setGrades={setGrades} selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                            mainTopics={mainTopics} setMainTopics={setMainTopics} selectedMainTopics={selectedMainTopics} setSelectedMainTopics={setSelectedMainTopics}
                            topics={topics} setTopics={setTopics} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} user={user} />

                        {/* Question */}
                        {syllabusFilter ? (
                            <>
                                {userInfo && (selectedTab === "Questions") && (
                                    <>

                                        <SyllabusQuestions syllabusFilter={syllabusFilter} user={user} />

                                    </>
                                )}

                                {/* Video */}
                                {userInfo && (selectedTab === "Lectures") && (
                                    <>
                                        <SyllabusSlots syllabusFilter={syllabusFilter} user={user} />
                                        {/* <Details /> */}
                                    </>
                                )}

                                {/* Doubts */}
                                {userInfo && (selectedTab === "Doubts") && (
                                    <>
                                        {/* <Details /> */}
                                    </>
                                )}

                                {/* Texts */}
                                {userInfo && (selectedTab === "Readings") && (
                                    <>
                                        <SyllabusReadingMaterials syllabusFilter={syllabusFilter} user={user} />
                                        {/* <Details /> */}
                                    </>
                                )}

                                {userInfo && (selectedTab === "Videos") && (
                                    <>
                                        <SyllabusRecordedVideos syllabusFilter={syllabusFilter} user={user} />
                                        {/* <Details /> */}
                                    </>
                                )}

                                {/* Quizzes */}
                                {userInfo && (selectedTab === "Quizzes") && (
                                    <>
                                        {/* <Details /> */}
                                    </>
                                )}
                            </>)
                            : (
                                <Spinner />
                            )}

                    </div>
                </div>

            </div>
        </div>
    );
};

export default Syllabus;