import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'chart.js';

Chart.register(ChartDataLabels);

const QuestionChart = ({ inputData, dataType }) => {

    const titleText = dataType === 'question' ? `Question ${inputData[1]}` : `Student ${inputData[1]}`;

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: titleText,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                formatter: (value) => value,
            },
        },
    };

    const barOptions = {
        // responsive: false,
        // maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: titleText,
                padding: {
                    bottom: 20,
                },
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bold',
                    size: 12,
                },
                formatter: (value) => value,
            },
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                },
                ticks: {
                    display: false // This will remove the labels on the x-axis
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: false,
                    // text: 'Number of Students',
                },
                ticks: {
                    stepSize: 1,
                    precision: 0
                },
            },
        },
    };

    const questionData = {
        labels: ['Correct', 'Incorrect', 'Partial Correct', 'No Response'],
        datasets: [
            {
                label: 'Number of Students',
                data: [
                    inputData[2],
                    inputData[3],
                    inputData[4],
                    inputData[5],
                ],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const studentData = {
        labels: ['Correct', 'Incorrect', 'Partial Correct', 'No Response'],
        datasets: [
            {
                label: 'Number of Students',
                data: [
                    inputData[2],
                    inputData[3],
                    inputData[4],
                    inputData[5],
                ],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="chart-container">

            {dataType === "question" ? (
                <Bar data={questionData} options={barOptions} />
            ) : (
                <Bar data={studentData} options={barOptions} />
            )}
            {/* <Pie data={data} options={options} /> */}
        </div>
    );
};

export default QuestionChart;