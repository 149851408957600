import React from 'react';

import { RiDeleteBin5Line } from 'react-icons/ri'

import './Image.css'

const ImagePreview = ({ images, selectImages, imageFileNames }) => {

    const onDelete = (image) => (event) => {
        selectImages(images.filter((e) => e !== image));
    }

    return (
        <div className="previewImagesContainer grid" value={images} >
            {images &&
                images.map((Img, Idx) => {
                    return (
                        <div key={Img} className="previewImages flex">
                            <img className="previewImage" src={Img} alt="upload" />
                            <button className="delete-btn" onClick={onDelete(Img)}> <RiDeleteBin5Line className="icon" /> </button>
                            {imageFileNames.length > 0 ? (
                                <p>{Idx + 1} {imageFileNames[Idx].name}</p>
                            ) : (
                                <p>{Idx + 1}</p>
                            )}

                        </div>
                    );
                })}
        </div>
    )
}

export default ImagePreview;
