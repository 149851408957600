import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import "./SlotViewSidebar.css";

import Logo from "../../../Assets/LiveClass.png";

import { SidebarItems } from "./SidebarItems";
import { motion } from "framer-motion";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../../services/UserService";


const SlotViewSidebar = ({ user = STUDENT_USER_TYPE, lectureData = null, selectedTab = null, setSelectedTab = null }) => {
    const [expanded, setExpaned] = useState(true);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    return (
        <>
            <motion.div className='slotView-sidebar'
                variants={sidebarVariants}
                animate={window.innerWidth <= 768 ? `${expanded}` : ''}
            >
                {/* logo */}
                <div className="slotView-logo">
                    <img src={Logo} alt="logo" />

                    {user === ADMIN_USER_TYPE ? (<span>
                        B<span>a</span>t<span>ch</span>es
                    </span>) : (
                        <span>
                            L<span>i</span>v<span>e</span> Cl<span>a</span>ss
                        </span>
                    )}
                </div>

                <div className="slotView-menu">
                    {SidebarItems.map((item, index) => {
                        return (
                            <div
                                className={selectedTab === item.heading ? "menuItem active" : "menuItem"}
                                key={index}
                                onClick={() => {
                                    setSearchParams({ selectedTab: item.heading });
                                    setSelectedTab(item.heading);
                                }}
                            >
                                <item.icon />
                                <span>{item.heading}</span>
                            </div>
                        );
                    })}
                </div>
            </motion.div >
        </>
    );
};

export default SlotViewSidebar;