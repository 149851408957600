import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';

import './Pdf.css';

const PdfUpload = ({ title = 'Pdfs', pdfs, setPdfs, label, maxSize = 1, setPdfFileNames }) => {

    const [addEnabled, setAddEnabled] = useState(true);

    useEffect(() => {
        if (maxSize && pdfs.length === maxSize) {
            setAddEnabled(false);
        } else {
            setAddEnabled(true);
        }
    }, [pdfs]);

    const onDrop = useCallback((selectedFiles) => {
        const selectedFilesArray = Array.from(selectedFiles);
        const pdfsArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        setPdfs((previousPdfArray) => previousPdfArray.concat(pdfsArray));
        setPdfFileNames(selectedFiles);
    }, [setPdfs]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        multiple: false,
        maxFiles: 1,
    });

    return (
        <div className="pdfInputContainer flex">

            <label className="title">{title}</label>

            <div className="pdfInput flex">
                {addEnabled && (
                    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} flex`}>
                        <input {...getInputProps()} id={`${label.toLowerCase()}-pdf-upload`} name="pdfs" />
                        {isDragActive ? (
                            <span className="drag-n-drop-msg">Drop the files here ...</span>
                        ) : (
                            <span className="drag-n-drop-msg">Drag 'n' drop files here <br />
                                or <br />
                                Click to select files
                            </span>
                        )}
                    </div>
                )}

                {pdfs.length > 0 &&
                    (pdfs.length > maxSize + 1 ? (
                        <div className="upload-pdf-number" id="error">
                            <p className="warning-msg">
                                Can't upload more than {maxSize} pdfs! <br />
                                <span>
                                    Please delete <b>{pdfs.length - maxSize}</b> of them.
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="upload-pdf-number">
                            TOTAL {pdfs.length} PDFS{pdfs.length === 1 ? '' : 'S'}
                        </div>
                    ))
                }
            </div>

        </div >
    )
}

export default PdfUpload;
