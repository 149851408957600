import React, { useState, useEffect } from "react";
import Modal from 'react-modal'

import './User.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import TeacherRegisterForm from './TeacherRegisterForm';
import StudentRegisterForm from './StudentRegisterForm';

import SelectionDropdown from '../Common/SelectionDropdown';
import { ADMIN_USER_TYPE } from "../../services/UserService";
import { AiFillCloseCircle } from 'react-icons/ai';
import LoadingPage from "../Common/LoadingPage";

Modal.setAppElement('#root');

const MemberRegisterForm = ({ isOpen = false, onRequestClose = null, onMemberCreate = null, type, user = ADMIN_USER_TYPE }) => {

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const renderType = () => {
        switch (type.id) {
            case 'teacher':
                return <TeacherRegisterForm onTeacherCreate={onMemberCreate} setIsSaving={setIsSaving} />;

            default:
                return <StudentRegisterForm onStudentCreate={onMemberCreate} setIsSaving={setIsSaving} />;
        }
    };

    const renderForm = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>
                {renderType(type)}

                < AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (

        <Modal overlayClassName="customModal" className='memberRegisterModal' isOpen={isOpen} onRequestClose={onRequestClose} >
            {renderForm()}
        </Modal >
    )
};

export default MemberRegisterForm;
