import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import moment from 'moment';

import './testModal.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import MultiBatchGradeSelection from '../../Batches/MultiBatchGradeSelection';
import SelectionDropdown from '../../Common/SelectionDropdown';
import { FULL_TEST_TYPES, TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_PRACTICE, securedCreateTest, securedCreateTestSeries, securedCreateTestUsers, securedDeleteTestUsers, securedFetchAllTestSeries, securedUpdateTest } from '../../../services/TestService';
import { UnauthorizedError } from '../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilePlus } from "react-icons/fi";
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import { checkArraysEqual, getUniqueValues } from '../../../services/CommonService';
import LoadingPage from '../../Common/LoadingPage';
import { securedFetchTagsByFilter } from '../../../services/TagService';
import SyllabusFilter from '../../Syllabus/SyllabusFilter';

Modal.setAppElement('#root');

const PracticeTestCreate = ({ isOpen, onRequestClose, onPracticeTestCreate = null, user = STUDENT_USER_TYPE }) => {
    const [testName, setTestName] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopics, setSelectedMainTopics] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [queryString, setQueryString] = useState('');
    const [testDurationMinutes, setTestDurationMinutes] = useState(10);
    const [numOfQuestions, setNumOfQuestions] = useState(10);
    const [testDifficulty, setTestDifficulty] = useState(0.5);
    const [incorrectOnly, setIncorrectOnly] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [minTimeTakenMinutes, setMinTimeTakenMinutes] = useState(null);
    const navigate = useNavigate();

    const onTestDurationChange = (event) => {
        if (event.target.value < 0) {
            setTestDurationMinutes(0);
        } else {
            setTestDurationMinutes(parseInt(event.target.value));
        }
    }

    const onMinTimeTakenMinutesChange = (event) => {
        if (event.target.value < 0) {
            setMinTimeTakenMinutes(null);
        } else {
            setMinTimeTakenMinutes(parseInt(event.target.value));
        }
    }

    const makeTopicsList = () => {
        if (selectedSubjects.length === 0 && selectedGrades.length === 0 && selectedMainTopics.length === 0 && selectedTopics.length === 0) {
            return null;
        }
        let allSelectedTopics = [];
        if (!topics || !mainTopics) {
            return [];
        }
        if (selectedTopics.length > 0) {
            allSelectedTopics = allSelectedTopics.concat(selectedTopics);
        } else if (selectedMainTopics.length > 0) {
            allSelectedTopics = allSelectedTopics.concat(selectedMainTopics.concat(topics));
        }
        return allSelectedTopics.length === 0 ? topics.concat(mainTopics) : allSelectedTopics;
    }

    const handleSubmit = async () => {
        setIsSaving(true);
        const data = {
            test_name: testName,
            duration_in_minutes: testDurationMinutes,
            difficulty: testDifficulty
        }

        const topicsToTakeFrom = makeTopicsList();
        const tagsToTakeFrom = selectedTags.length > 0 ? selectedTags : null;
        if (topicsToTakeFrom !== null) {
            data['topic_ids'] = topicsToTakeFrom.map(topic => topic.id);
        }
        if (tagsToTakeFrom !== null) {
            data['tag_ids'] = tagsToTakeFrom.map(tag => tag.id);
        }

        data['query_string'] = queryString;
        data['number_of_questions'] = numOfQuestions;
        if (incorrectOnly) {
            data['include_only_incorrect'] = true;
        }
        if (minTimeTakenMinutes != null) {
            data['minimum_time_spent_minutes'] = minTimeTakenMinutes;
        }

        let response = null;
        response = await securedCreateTest(TEST_CREATE_TYPE_PRACTICE, data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            onRequestClose();
            return;
        }
        setIsSaving(false);
        onRequestClose();
        if (onPracticeTestCreate) {
            await onPracticeTestCreate(response.test_id);
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const onSelectedTagChange = (selection) => {
        // const { value, checked } = event.target;
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedTags = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(newSelectedTags);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            const tagsResponse = await securedFetchTagsByFilter({}, navigateCallbackOptions(navigate));
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
        })();
    }, [isOpen]);

    const renderTestCreate = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>

                <h3 className="modalTitle"> {'Create New'} Practice Test </h3>

                <form className="testContent flex" onSubmit={onSubmit} >

                    <div className="testInfoInput">
                        <label className="title">Name</label>
                        <input type="text" className="text" value={testName} onChange={e => setTestName(e.target.value)} placeholder="Enter..." required />
                    </div>

                    <div style={{ zIndex: '1001', minWidth: '800px', backgroundColor: 'rgba(65, 137, 230, 0.3)', padding: '.5rem 1rem', borderRadius: '.5rem', transform: 'scale(0.9)' }}>
                        <SyllabusFilter subjects={subjects} setSubjects={setSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects}
                            grades={grades} setGrades={setGrades} selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                            mainTopics={mainTopics} setMainTopics={setMainTopics} selectedMainTopics={selectedMainTopics} setSelectedMainTopics={setSelectedMainTopics}
                            topics={topics} setTopics={setTopics} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} user={user} />
                    </div>

                    <SelectionDropdownMultiple className="testSelectionDropdownMultiple" name="Tags" itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagChange} nameField={'tag_name'} valueField={'id'} isSearchable={true} />

                    <div className="testInfoInput flex">
                        <label className="title">Text</label>
                        <input type="text" className="text" value={queryString} onChange={e => setQueryString(e.target.value)} placeholder="Question Text..." />
                    </div>

                    <div className="testInfoInput flex">
                        <label className="title">Duration (minutes)</label>
                        <input className="number" type="number" value={testDurationMinutes} onChange={onTestDurationChange} placeholder="Enter..." required />
                    </div>

                    <div className="testInfoInput flex">
                        <label className="title">Number of Questions</label>
                        <input className="number" type="number" value={numOfQuestions} onChange={(event) => setNumOfQuestions(event.target.value)} placeholder="Enter..." required />
                    </div>

                    <div className="testInfoInput flex">
                        <label className="title">Difficulty</label>
                        <span className="sliderValue">{testDifficulty}</span>
                        <span className="sliderRange">Easy(0)</span>
                        <input className="number" type="range" id="slider" min={0} max={1} step="0.01" value={testDifficulty} onChange={(event) => setTestDifficulty(event.target.value)} required />
                        <span className="sliderRange">Hard(1)</span>
                        {/* <input className="number" type="number" min={0} max={1} value={testDifficulty} onChange={(event) => setTestDifficulty(event.target.value)} placeholder="Enter..." required /> */}
                    </div>

                    <div className="testInfoInput flex">
                        <div className="checkBox flex">
                            <label className="title">Choose incorrect questions only</label>
                            <input
                                type="checkbox"
                                checked={incorrectOnly}
                                onChange={() => setIncorrectOnly(prev => !prev)}
                            />
                        </div>
                    </div>
                    <div className="testInfoInput flex">
                        <label className="title">{'Time Taken > '}</label>
                        <input className="number" type="number" value={minTimeTakenMinutes} onChange={onMinTimeTakenMinutesChange} placeholder="Enter in minutes..." />
                    </div>


                </form >

                <button className='btn flex' id='submit' onClick={handleSubmit}>
                    {'Create'} <FiFilePlus className="icon" />
                </button>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (
        <Modal overlayClassName="customModal" className="testModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderTestCreate()}

        </Modal >
    );
}

export default PracticeTestCreate;
