import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './NTA.css'

import 'aos/dist/aos.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { AiOutlineRight } from 'react-icons/ai';


Modal.setAppElement('#root');

const NTASubmitWarning = ({ isOpen, onRequestClose, handleSubmitTest, user = STUDENT_USER_TYPE, parentId }) => {

    const handleSubmit = async () => {
        await handleSubmitTest();
        onRequestClose();
    }
    return (
        <Modal overlayClassName="customModal" className="testModal flex" isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <span>Once you submit, you won't be able to attempt the test again. <br />
                Are you sure you want to submit?
            </span>

            <div className="btns flex">
                <button className="btn" onClick={handleSubmit}>YES</button>
                <button className="btn" onClick={onRequestClose}>NO</button>
            </div>
        </Modal >
    );
}

export default NTASubmitWarning;
