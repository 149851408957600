import React, { useEffect, useState } from 'react';

import 'aos/dist/aos.css'

import "./Doubt.css"

import DoubtCard from './DoubtCard';
import { securedFetchDoubtsByFilter } from '../../services/DoubtService';
import { useNavigate } from 'react-router-dom';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Disclosure, DisclosureButton } from '@headlessui/react';
import Spinner from '../Common/Tailwind/Spinner';

const DoubtClusterCard = ({ doubtClusterData, user = ADMIN_USER_TYPE }) => {

    const [showDetailed, setShowDetailed] = useState(false);
    const [relatedDoubts, setRelatedDoubts] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleDoubtClusterCardClick = async () => {

        setShowDetailed(!showDetailed);
        if (!showDetailed && relatedDoubts === null) {
            setLoading(true);
            const doubtsResponse = await securedFetchDoubtsByFilter({ doubt_cluster_ids: [doubtClusterData.id] }, navigateCallbackOptions(navigate));
            if (doubtsResponse === null) {
                setRelatedDoubts([]);
                setShowDetailed(true);
                setLoading(false);
                return;
            }
            setRelatedDoubts(doubtsResponse.data);
            setLoading(false);
        }
    }

    const renderRelatedDoubts = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <>
                {relatedDoubts !== null && (
                    relatedDoubts.map((doubt, index) => {
                        return <DoubtCard doubtData={doubt} user={user} numberPrefix={`${index + 1}.`} />;
                    })
                )}
            </>)
    }

    return (
        <Disclosure key={doubtClusterData.doubt_cluster_text} as="div" className="pt-6">
            <dt>
                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900" onClick={handleDoubtClusterCardClick}>
                    <span className="text-base font-semibold leading-7">{doubtClusterData.doubt_cluster_text}</span>
                    <span className="ml-6 flex h-7 items-center">
                        <ChevronDownIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                        <ChevronUpIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                </DisclosureButton>
            </dt>
            <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${showDetailed ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
                {renderRelatedDoubts()}
            </div>

        </Disclosure>
    );
};

export default DoubtClusterCard;
