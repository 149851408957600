import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { createParams } from "./CommonService";

export const fetchStudentRecommendationQuestions = async (type, filter) => {
    const response = await fetch(`${BACKEND_URL}/student-recommendation/questions/${type}${createParams(filter)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchStudentRecommendationQuestions = withAuth(fetchStudentRecommendationQuestions);
