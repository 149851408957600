import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { TEACHER_USER_TYPE } from '../../services/UserService';
import { TeacherNavItems } from './TeacherNavItems';
import TeacherTestPage from '../../Components/Test/TeacherTest/TeacherTestPage';

const TeacherTests = () => {
    return (
        <>
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            <TeacherTestPage user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </>
    )
}

export default TeacherTests;