import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Chart } from "react-google-charts";
import Modal from 'react-modal';

import './testReport.css';

import GeneralStatistics from './Statistics/GeneralStatistics';
import ResponseStatistics from './Statistics/ResponseStatistics';
import QuestionwiseStatistics from './Statistics/QuestionwiseStatistics';
import StudentwiseStatistics from './Statistics/StudentwiseStatistics';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedCreateTestReport, securedFetchTestReport } from '../../../services/TestService';

import { AiFillCloseCircle } from "react-icons/ai";
import DetailResponses from './Statistics/DetailResponses';


const formatPercentage = (percentage) => percentage !== null ? `${Math.floor(Number(percentage) * 100) / 100}%` : '0%';

const ShowGeneralStatistics = ({ data }) => {
    return (
        <div className="statistics-section total flex">
            <h3>Number of Questions: {data.total_questions_in_test}</h3>
            <h3>Number of Students: {data.total_students_appeared_in_test}</h3>
        </div>
    );
};

const TestReport = ({ testId, testName, isOpen, onRequestClose, user = ADMIN_USER_TYPE }) => {

    const [testReportData, setTestReportData] = useState(null);
    const [selected, setSelected] = useState("QUESTIONS");
    const [selectedMenu, setSelectedMenu] = useState("SUMMARY");

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!isOpen || (testReportData !== null && testReportData.test_id === testId)) {
                return;
            }

            const secondResponse = await securedFetchTestReport(testId, navigateCallbackOptions(navigate));
            if (secondResponse === null) {
                onRequestClose();
                return;
            }
            if (secondResponse.status === 404) {
                alert('Test Report has not been generated yet!');
                onRequestClose();
                return;
            }
            setTestReportData(secondResponse);
        })();
    }, [testId, isOpen]);

    if (testReportData === null) {
        return;
    }

    return (
        <Modal overlayClassName="customModal" className="testReportModal" isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="testReport flex">

                <h2 className="testReportTitle">Test Report</h2>

                <div className="testReportButtons flex">
                    <button className="btn flex" onClick={() => setSelectedMenu("SUMMARY")}>SUMMARY</button>
                    <button className="btn flex" onClick={() => setSelectedMenu("DETAIL")}>DETAIL</button>
                </div>

                {testReportData && (
                    <div className="report-container flex">

                        <div className="report-title flex">
                            <h3>Number of Questions: {testReportData.report_data.general_statistics.total_questions_in_test}</h3>
                            {user !== STUDENT_USER_TYPE &&
                                <h3>Number of Students: {testReportData.report_data.general_statistics.total_students_appeared_in_test}</h3>}
                        </div>

                        {selectedMenu === "SUMMARY" ? (
                            <>
                                <ResponseStatistics data={testReportData.report_data.response_statistics} />

                                <GeneralStatistics data={testReportData.report_data.student_wise_report}
                                    avg={testReportData.report_data.general_statistics.mean_score}
                                    totalMarks={testReportData.report_data.general_statistics.total_test_marks} />

                                <div className="report-items flex">
                                    <button className={`report-item ${selected === "QUESTIONS" ? "selected" : ""}`}
                                        onClick={() => { setSelected("QUESTIONS") }}> Questions </button>
                                    <button className={`report-item ${selected === "STUDENTS" ? "selected" : ""}`}
                                        onClick={() => { setSelected("STUDENTS") }}> Students </button>
                                </div>

                                {selected === "QUESTIONS" && (
                                    <QuestionwiseStatistics data={testReportData.report_data.question_wise_report}
                                        num_students={testReportData.report_data.general_statistics.total_students_appeared_in_test} />
                                )}

                                {selected === "STUDENTS" && (
                                    <StudentwiseStatistics data={testReportData.report_data.student_wise_report}
                                        num_questions={testReportData.report_data.general_statistics.total_questions_in_test} />
                                )}
                            </>
                        ) : (
                            <div className="report-table-wrapper">
                                <DetailResponses
                                    testId={testId}
                                    testName={testName}
                                    data={testReportData.report_data.student_question_wise_report}
                                    student_wise_data={testReportData.report_data.student_wise_report}
                                    num_questions={testReportData.report_data.general_statistics.total_questions_in_test}
                                    num_students={testReportData.report_data.general_statistics.total_students_appeared_in_test} />
                            </div>
                        )}

                    </div>

                )}

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />


            </div>
        </Modal >
    );
}

export default TestReport;
