import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import Modal from 'react-modal';

import './SlotTest.css'

import { securedCreateTest, securedCreateTestSubjects, securedCreateTestSections, TEST_CREATE_TYPE_BASIC } from '../../../services/TestService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { FiFilePlus } from 'react-icons/fi';
import { AiFillCloseCircle } from "react-icons/ai";

Modal.setAppElement('#root');

const SlotTestCreate = ({ isOpen, onRequestClose, lectureData, onTestCreate = null, user = ADMIN_USER_TYPE, testType }) => {

    const [testName, setTestName] = useState(testType + ' quiz');
    const [testDurationMinutes, setTestDurationMinutes] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await setTestName(
                "Live Quiz " + moment(lectureData.start_time).format('YYMMDD')
            );
        })();
    }, [testType]);

    const onTestDurationChange = (event) => {
        setTestDurationMinutes(parseInt(event.target.value));
    }

    const createTestSection = async (testId, testSubjectId) => {
        const data = {
            section_description: `${testName} Section 1`,
            full_marks: 1,
            negative_marks: 0,
            partial_marks: 0,
            section_order: 0
        }

        const response = await securedCreateTestSections(testId, testSubjectId, [data]);
    }

    const createTestSubject = async (testId) => {
        const data = {
            subject_id: lectureData.subject_id,
            subject_order: 0
        }
        const response = await securedCreateTestSubjects(testId, [data]);
        if (response === null) {
            return;
        }
        await createTestSection(testId, response[0].test_subject_id);
    }

    const handleSubmit = async () => {
        const data = {
            test_name: testName,
            test_batches: lectureData.timetable_schedule.batches.map(batch => ({ batch_id: batch.batch_id })),
            test_type: testType,
            test_date: moment(lectureData.start_time).format('YYYY-MM-DD'),
            test_duration_minutes: testDurationMinutes,
            timetable_slot_id: lectureData.id
        }

        const response = await securedCreateTest(TEST_CREATE_TYPE_BASIC, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        await createTestSubject(response.test_id);
        if (onTestCreate) {
            await onTestCreate(response.test_id);
        }
        onRequestClose();
    }

    return (
        <Modal overlayClassName="customModal" className="slotTestCreateModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            <h2 className="modalTitle" >Create New Live Quiz</h2>

            <div className="testInfoInput flex">
                <label className="infoLabel">Name</label>
                <input className="textInput"
                    type="text"
                    value={testName}
                    onChange={e => setTestName(e.target.value)}
                    placeholder="Enter..." />
            </div>

            <div className="testInfoInput flex">
                <label className="infoLabel" >Duration <br />(in minutes)</label>
                <input className="numberInput"
                    type="number"
                    value={testDurationMinutes}
                    onChange={onTestDurationChange}
                    placeholder="Enter..."
                />
            </div>

            <button className='btn flex' id="create" onClick={handleSubmit}>
                CREATE <FiFilePlus className="icon" />
            </button>

            <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />

        </Modal >
    );
}

export default SlotTestCreate;
