import React, { useMemo, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";

// const MARGIN = { top: 50, right: 30, bottom: 100, left: 50 }; // Increased bottom margin
// const LEGEND_HEIGHT = 30;
// const LEGEND_ITEM_WIDTH = 120;

// const StackedBarplot = ({ width, height, data }) => {
//     const axesRef = useRef(null);
//     const boundsWidth = width - MARGIN.right - MARGIN.left;
//     const boundsHeight = height - MARGIN.top - MARGIN.bottom - LEGEND_HEIGHT;

//     const allDates = data.map((d) => d.date);
//     const allSubjects = ['Mathematics', 'Physics', 'Chemistry'];

//     const subjectColors = {
//         'Physics': '#003366',
//         'Chemistry': '#4CAF50',
//         'Mathematics': '#800000'
//     };

//     const stackSeries = d3.stack().keys(allSubjects).order(d3.stackOrderNone);
//     const series = stackSeries(data);

//     const yMax = d3.max(series, layer => d3.max(layer, sequence => sequence[1])) || 0;
//     const yScale = useMemo(() => {
//         return d3.scaleLinear()
//             .domain([0, yMax])
//             .range([boundsHeight, 0]);
//     }, [yMax, boundsHeight]);

//     const xScale = useMemo(() => {
//         return d3.scaleBand()
//             .domain(allDates)
//             .range([0, boundsWidth])
//             .padding(0.05);
//     }, [allDates, boundsWidth]);

//     const colorScale = d3.scaleOrdinal()
//         .domain(allSubjects)
//         .range(allSubjects.map(subject => subjectColors[subject]));

//     useEffect(() => {
//         if (!data || data.length === 0) return;

//         const svgElement = d3.select(axesRef.current);
//         svgElement.selectAll('*').remove();

//         const xAxisGenerator = d3.axisBottom(xScale);
//         svgElement
//             .append('g')
//             .attr('transform', `translate(0,${boundsHeight})`)
//             .call(xAxisGenerator)
//             .selectAll('text')
//             .attr('transform', 'rotate(-45)')
//             .style('text-anchor', 'end')
//             .attr('dx', '-0.8em')
//             .attr('dy', '0.15em');

//         const yAxisGenerator = d3.axisLeft(yScale);
//         svgElement.append('g').call(yAxisGenerator);
//     }, [xScale, yScale, boundsHeight]);

//     const rectangles = series.map((subgroup, i) => (
//         <g key={i}>
//             {subgroup.map((group, j) => {
//                 const barHeight = yScale(group[0]) - yScale(group[1]);
//                 const shouldShowLabel = barHeight > 20;

//                 return (
//                     <g key={`${i}-${j}`}>
//                         <rect
//                             x={xScale(group.data.date)}
//                             y={yScale(group[1])}
//                             height={barHeight}
//                             width={xScale.bandwidth()}
//                             fill={colorScale(subgroup.key)}
//                             opacity={0.9}
//                         >
//                             <title>{`${subgroup.key}: ${group.data[subgroup.key]} questions`}</title>
//                         </rect>
//                         {shouldShowLabel && (
//                             <text
//                                 x={xScale(group.data.date) + xScale.bandwidth() / 2}
//                                 y={yScale(group[1]) + barHeight / 2}
//                                 textAnchor="middle"
//                                 dominantBaseline="middle"
//                                 fill="white"
//                                 fontSize="12px"
//                             >
//                                 {group.data[subgroup.key]}
//                             </text>
//                         )}
//                     </g>
//                 );
//             })}
//         </g>
//     ));

//     const Legend = () => {
//         const legendWidth = allSubjects.length * LEGEND_ITEM_WIDTH;
//         const startX = (width - legendWidth) / 2;

//         return (
//             <g transform={`translate(${startX}, 10)`}>
//                 {allSubjects.map((subject, index) => (
//                     <g key={subject} transform={`translate(${index * LEGEND_ITEM_WIDTH}, 0)`}>
//                         <rect width="15" height="15" fill={colorScale(subject)} />
//                         <text x="20" y="12" fontSize="12px">{subject}</text>
//                     </g>
//                 ))}
//             </g>
//         );
//     };

//     return (
//         <div>
//             <svg width={width} height={height}>
//                 <Legend />
//                 <g
//                     width={boundsWidth}
//                     height={boundsHeight}
//                     transform={`translate(${[MARGIN.left, MARGIN.top + LEGEND_HEIGHT].join(',')})`}
//                 >
//                     {rectangles}
//                 </g>
//                 <g
//                     width={boundsWidth}
//                     height={boundsHeight}
//                     ref={axesRef}
//                     transform={`translate(${[MARGIN.left, MARGIN.top + LEGEND_HEIGHT].join(',')})`}
//                 />
//             </svg>

//             <Bar
//                 pointStyle="star"
//                 data={{
//                     // labels: data.labels,
//                     responsive: true,
//                     offset: true,
//                     datasets: [
//                         {
//                             label: "Mobile",
//                             pointStyle: "rectRounded",
//                             backgroundColor: "#6ED3FF",
//                             barThickness: 40,
//                             categoryPercentage: 1,
//                             data: data.previousDate.dataSet //From API
//                         },
//                         {
//                             label: "Desktop",
//                             backgroundColor: "#1497FF",
//                             barThickness: 40,
//                             categoryPercentage: 1,
//                             pointStyle: "triangle",
//                             data: data.currentDate.dataSet //From API
//                         }
//                     ]
//                 }}
//                 height={height}
//                 options={{
//                     offsetGridLines: true,
//                     drawTicks: true,
//                     layout: {
//                         padding: {
//                             top: 30,
//                             right: 40,
//                             bottom: 40
//                         }
//                     },
//                     legend: {
//                         display: true,
//                         position: "right",
//                         align: "start",
//                         labels: {
//                             usePointStyle: true
//                         }
//                     },
//                     responsive: true,
//                     maintainAspectRatio: false,
//                     scales: {
//                         xAxes: [
//                             {
//                                 stacked: true,
//                                 ticks: {
//                                     padding: 5
//                                 },
//                                 gridLines: {
//                                     display: false
//                                 }
//                             }
//                         ],
//                         yAxes: [
//                             {
//                                 stacked: false,
//                                 gridLines: {
//                                     drawBorder: false
//                                 },
//                                 ticks: {
//                                     beginAtZero: true,
//                                     maxTicksLimit: 6,
//                                     padding: 20,
//                                     callback(n) {
//                                         if (n < 1e3) return n;
//                                         if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
//                                     }
//                                 }
//                             }
//                         ]
//                     }
//                 }}
//             />
//         </div>
//     );
// };

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels, zoomPlugin);

// const StackedBarplot = ({ width, height, data }) => {

//     const chartRef = useRef(null);

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: false,
//             },
//             datalabels: {
//                 color: 'white',
//                 anchor: 'center',
//                 align: 'center',
//                 font: {
//                     weight: 'bold'
//                 },
//                 formatter: (value) => value || ''
//             },
//             zoom: {
//                 pan: {
//                     enabled: true,
//                     mode: 'x',
//                 },
//                 zoom: {
//                     wheel: { enabled: false },
//                     pinch: { enabled: false },
//                     mode: 'x',
//                 }
//             }
//         },
//         scales: {
//             x: {
//                 stacked: true,
//                 ticks: {
//                     maxRotation: 45,
//                     minRotation: 45,
//                 },
//             },
//             y: {
//                 stacked: true,
//                 beginAtZero: true,
//                 ticks: {
//                     callback: (value) => value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value,
//                 },
//             },
//         },
//     };

//     useEffect(() => {
//         const chart = chartRef.current;
//         if (chart) {
//             const totalBars = data.labels.length;
//             const visibleBars = 30;
//             if (totalBars > visibleBars) {
//                 const scrollPercent = visibleBars / totalBars;
//                 chart.canvas.parentNode.style.height = `${height}px`;
//                 chart.canvas.parentNode.style.width = `${width}px`;
//                 chart.canvas.style.width = `${width / scrollPercent}px`;
//                 chart.canvas.style.height = `${height}px`;
//             }
//         }
//     }, [data.labels.length, width, height]);

//     return (
//         <div style={{ width, height, overflowX: 'auto' }}>
//             <Bar ref={chartRef} data={data} options={options} />
//         </div>
//     );
// };

// export default StackedBarplot;

const StackedBarplot = ({ width, height, data }) => {

    console.log(data);

    const chartConfig = {
        type: 'bar',
        series: data.datasets,
        options: {
            chart: {
                stacked: true,
                scrollable: true,
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleXaxis: true,
                    autoScaleYaxis: true,
                    resetIcon: {
                        offsetX: -10,
                        offsetY: 0,
                        fillColor: '#fff',
                        strokeColor: '#37474F'
                    },
                    selection: {
                        background: '#90CAF9',
                        border: '#0D47A1'
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: data.labels,
                tickPlacement: 'on',
                tickAmount: data.labels.length < 30 ? data.labels.length - 1 : 30,
                range: data.labels.length < 30 ? data.labels.length - 1 : 30,
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val >= 1000 ? `${(val / 1000).toFixed(1)}K` : val;
                        // return val + "K"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                // offsetX: 40
            },
            // zoom: {
            //     enabled: true,
            //     type: 'x',
            //     resetIcon: {
            //         offsetX: -10,
            //         offsetY: 0,
            //         fillColor: '#fff',
            //         strokeColor: '#37474F'
            //     },
            //     selection: {
            //         background: '#90CAF9',
            //         border: '#0D47A1'
            //     }
            // }
        },


    };

    return (
        <div style={{ width: width, height: height, overflowX: 'auto' }}>
            <Chart {...chartConfig} />
        </div>
    );
}

export default StackedBarplot;