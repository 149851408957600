import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import './batches.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import { UnauthorizedError } from '../../services/Errors';
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade, securedFetchGrades } from '../../services/BatchService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { getUniqueValues } from "../../services/CommonService";

const MultiBatchGradeSelection = ({ grades, selectedGrades, batches, selectedBatches, setGrades, setSelectedGrades, setBatches, setSelectedBatches, onSelectedBatchesToggle, prefillGrades = false, user = ADMIN_USER_TYPE }) => {

    const navigate = useNavigate();

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };

    useEffect(() => {
        (async () => {
            const fetchedGrades = await fetchAndSetGrades();
            if (fetchedGrades === null) {
                return;
            }
            const parsedGrades = fetchedGrades.map((item) => ({ id: item.grade, grade_name: String(item.grade) }))
            setGrades(parsedGrades);
            const batchFilter = {};
            if (selectedGrades.length > 0) {
                batchFilter['grades'] = selectedGrades.map(grade => grade.id);
                setSelectedGrades(parsedGrades.filter(grade => batchFilter['grades'].includes(grade.id)));
            }
            const allBatches = await securedFetchBatchesByFilter(batchFilter, navigateCallbackOptions(navigate));
            if (allBatches === null) {
                return;
            }
            setBatches(allBatches.data);
            const selectedBatchIds = selectedBatches.map(batch => batch.id);
            const currentSelectedBatches = allBatches.data.filter(batch => selectedBatchIds.includes(batch.id))
            setSelectedBatches(currentSelectedBatches);
            if (selectedGrades.length === 0 && prefillGrades) {
                const currentBatchGradeIds = getUniqueValues(currentSelectedBatches.map(batch => batch.batch_grade));
                setSelectedGrades(parsedGrades.filter(grade => currentBatchGradeIds.includes(grade.id)));
                const batchesResponse = await securedFetchBatchesByFilter({ grades: currentBatchGradeIds }, navigateCallbackOptions(navigate));
                if (batchesResponse === null) {
                    return;
                }
                setBatches(batchesResponse.data);
            }
        })();
    }, []);

    const onSelectedGradesToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedGradesFromSelect = grades.filter(grade => selectedIds.includes(grade.id));
        const batchesResponse = await securedFetchBatchesByFilter({ grades: selectedGradesFromSelect.map(grade => grade.id) }, navigateCallbackOptions(navigate));
        if (batchesResponse === null) {
            return;
        }
        const previousSelectedBatchIds = selectedBatches.map(batch => batch.id);
        setBatches(batchesResponse.data);
        setSelectedBatches(batchesResponse.data.filter(batch => previousSelectedBatchIds.includes(batch.id)));
        setSelectedGrades(selectedGradesFromSelect);
    };

    return (
        <div className="batchGradeSelectDropdown flex">
            <SelectionDropdownMultiple className="gradeSelectionDropdown" name='Grade' onSelectionToggle={onSelectedGradesToggle} selectedItems={selectedGrades} itemList={grades} nameField='grade_name' valueField='id' required={true} />
            <SelectionDropdownMultiple className="batchSelectionDropdown" name='Batch' onSelectionToggle={onSelectedBatchesToggle} selectedItems={selectedBatches} itemList={batches} nameField='batch_name' valueField='id' required={true} />
        </div>
    )
};

export default MultiBatchGradeSelection;