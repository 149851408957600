export const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // JS months are 0-based, hence we add one
    let day = date.getDate();

    // if month is less than 10, prepend a '0' to maintain the YYYY-MM-DD format
    if (month < 10) month = '0' + month;

    // Same for day
    if (day < 10) day = '0' + day;

    return `${year}-${month}-${day} 00:00:00`;
};

export const getStartDateOfLastMonth = (date) => {
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    return date;
};

export const getEndDateOfNextMonth = (date) => {
    date.setMonth(date.getMonth() + 2);
    date.setDate(0);
    return date;
};

export const durationStringToSeconds = (duration) => {
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const secondsAndMilliseconds = parts[2].split('.');
    const seconds = parseInt(secondsAndMilliseconds[0], 10);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds; // + milliseconds;
    return totalSeconds;
}

export const getCurrentTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}