'use client'

import React, { useState, useEffect } from 'react';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './LoginForm.css'

import { AiFillCloseCircle } from 'react-icons/ai'
import { BsCloudUpload } from 'react-icons/bs'
import { RiLockPasswordLine } from "react-icons/ri";
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'


import { securedUserForgotPassword } from '../../services/UserService';

const ForgotPassword = ({ isOpen = false, onRequestClose = null, initEmail = null }) => {

    const [email, setEmail] = useState(null);
    const [isEmailSubmitted, setEmailSubmitted] = useState(false);

    useEffect(() => {
        setEmailSubmitted(false);
        setEmail(initEmail);
    }, [])

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    const onSubmit = async () => {
        const response = await securedUserForgotPassword(email);
        if (response !== null) {
            setEmailSubmitted(true);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>



                        {/* <Modal overlayClassName="customModal" className="resetPasswordModal flex" isOpen={isOpen} onRequestClose={onRequestClose}> */}


                        {isEmailSubmitted ?
                            (
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            New Password
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                has been sent to your E-mail
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            : (
                                // <div className="input-box flex">
                                //     <div className="email-input flex">
                                //         <label> E-mail</label>
                                //         <input type="email" name="email" value={email} onChange={onTextChange(setEmail)} placeholder='E-mail Address' required />
                                //     </div>


                                // </div>

                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <RiLockPasswordLine aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Change Password
                                        </DialogTitle>

                                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                            <formn className="space-y-4">
                                                <div>
                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Email Address
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            value={email}
                                                            placeholder='E-mail Address'
                                                            onChange={onTextChange(setEmail)}
                                                            required
                                                            autoComplete="email"
                                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        onClick={onSubmit}
                                                        className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                                    >
                                                        <span>Submit</span>
                                                        <BsCloudUpload className="icon" />
                                                    </button>
                                                </div>

                                            </formn>

                                        </div>

                                    </div>
                                </div>
                            )}

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    )
};

export default ForgotPassword;