import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE, TEACHER_USER_TYPE } from "./UserService";

const backgroundColorSubjectMapping = {
    Physics: 'rgba(173, 216, 230, 0.7)',
    Mathematics: 'rgba(239, 187, 204, 0.7)',
    Chemistry: 'rgba(162, 210, 182, 0.7)'
}

export const getEventsListFromTimetable = (timetables) => {

    const timetableEvents = timetables.map((timetable) => {

        const subject_name = timetable.subject ? timetable.subject.subject_name : 'Unknown';
        const teacher_name = timetable.teacher.user_data.first_name;
        const teacher_full_name = teacher_name + ' ' + timetable.teacher.user_data.last_name;
        const teacher_name_parts = teacher_full_name.split(' ');
        const name_initials = teacher_name_parts.map(part => part[0]).join('');

        // const firstPartOfName = teacher_name_parts[0];
        // const lastPartOfName = teacher_name_parts.length > 1 ? teacher_name_parts[teacher_name_parts.length - 1] : ''; // Last part or empty if only one part

        const title_initial = subject_name.charAt(0) + name_initials;

        return {
            id: timetable.id,
            start: new Date(timetable.start_time), // Assuming timetable.start_time is a DateTime string
            end: new Date(timetable.end_time), // Assuming timetable.end_time is a DateTime string
            subject_id: timetable.subject_id,
            subject_name: subject_name,
            backgroundColor: backgroundColorSubjectMapping[subject_name],
            teacher_id: timetable.teacher.id,
            teacher_name: teacher_full_name,
            lecture_hall_id: timetable.lecture_hall?.id || null,
            batch_id: timetable.batch_id,
            title: `${title_initial}`,
            topics: [],
            display: 'normal',
            duration: (new Date(timetable.end_time) - new Date(timetable.start_time)) / 1000
        };
    });
    timetableEvents.sort((a, b) => (a.duration < b.duration));
    return timetableEvents;
};


export const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.subject_id === 1 ? '#BFB8DA' : (event.subject_id === 2 ? '#7FACD6' : '#E887D4')
    // '#' + Math.floor((event.subject_id)*16777215).toString(16); // Random color generator
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: '#031B88',
        border: '0px',
        display: 'block',
    };
    return {
        style: style
    };
}

export const fetchTimetableSchedulesByFilter = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    let urlString = `${BACKEND_URL}/timetable/schedules${params}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchTimetableSlotById = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchTimetableSlotStatusById = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}/status`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const securedFetchTimetableSlotById = withAuth(fetchTimetableSlotById);
export const securedFetchTimetableSlotStatusById = withAuth(fetchTimetableSlotStatusById);

export const getLectureVideoById = async (id) => {
    let urlString = `${BACKEND_URL}/lecture-videos/${id}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchLectureTranscriptById = async (id) => {
    let urlString = `${BACKEND_URL}/lecture-transcripts/${id}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchLectureSummaryById = async (id) => {
    let urlString = `${BACKEND_URL}/lecture-summaries/${id}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchLectureHalls = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    let urlString = `${BACKEND_URL}/lecture-halls${params}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createSchedule = async (data) => {
    let urlString = `${BACKEND_URL}/timetable/schedules`
    const response = await fetch(urlString, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteScheduleById = async (id, data) => {
    let urlString = `${BACKEND_URL}/timetable/schedules/${id}`
    const response = await fetch(urlString, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deleteSlotById = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}`
    const response = await fetch(urlString, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateSlotById = async (id, newData) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}`
    const response = await fetch(urlString, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateSlotByIdStartTime = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}/lecture-video-start-time`
    const response = await fetch(urlString, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateSlotByIdEndTime = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}/lecture-video-end-time`
    const response = await fetch(urlString, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateScheduleById = async (id, newData) => {
    let urlString = `${BACKEND_URL}/timetable/schedules/${id}`
    const response = await fetch(urlString, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const lecturePinAuthenticate = async (id, data) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}/students`
    const response = await fetch(urlString, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchSlotStatus = async (id) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${id}/status`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchNextSlot = async (filter) => {
    let urlString = `${BACKEND_URL}/timetable/slots/next${createParams(filter)}`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteSlotUsers = async (slotId, data) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${slotId}/users/bulk${createParams(data)}`
    const response = await fetch(urlString, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const createSlotUsers = async (slotId, data) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${slotId}/users/bulk`
    const response = await fetch(urlString, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchTimetableStudentAttendance = async (slotId) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${slotId}/students`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTimetableSlotQuestionsByFilter = async (slotId, type, filter) => {
    let urlString = `${BACKEND_URL}/timetable/slots/${slotId}/questions/${type}${createParams(filter)}`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}
export const securedFetchTimetableSchedulesByFilter = withAuth(fetchTimetableSchedulesByFilter);
export const securedFetchLectureVideoById = withAuth(getLectureVideoById);
export const securedFetchLectureTranscriptById = withAuth(fetchLectureTranscriptById);
export const securedFetchLectureSummaryById = withAuth(fetchLectureSummaryById);
export const securedFetchLectureHalls = withAuth(fetchLectureHalls);
export const securedCreateSchedule = withAuth(createSchedule);
export const securedDeleteScheduleById = withAuth(deleteScheduleById);
export const securedDeleteSlotById = withAuth(deleteSlotById);
export const securedUpdateSlotByIdStartTime = withAuth(updateSlotByIdStartTime);
export const securedUpdateSlotByIdEndTime = withAuth(updateSlotByIdEndTime);
export const securedUpdateScheduleById = withAuth(updateScheduleById);
export const securedUpdateSlotById = withAuth(updateSlotById);
export const securedLecturePinAuthenticate = withAuth(lecturePinAuthenticate);
export const securedFetchSlotStatus = withAuth(fetchSlotStatus);
export const securedFetchNextSlot = withAuth(fetchNextSlot);
export const securedDeleteSlotUsers = withAuth(deleteSlotUsers);
export const securedCreateSlotUsers = withAuth(createSlotUsers);
export const securedFetchTimetableStudentAttendance = withAuth(fetchTimetableStudentAttendance);
export const securedFetchTImetableSlotQuestionsByFilter = withAuth(fetchTimetableSlotQuestionsByFilter);


export const hasTimetableScheduleCreateAuthority = (user) => {
    return user === ADMIN_USER_TYPE;
}

export const needsSlotPinAuthentication = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const hasSlotPinViewAuthority = (user) => {
    return user === TEACHER_USER_TYPE;
}

export const REPEAT_TYPES = [{ id: 'single', name: 'Single' }, { id: 'daily', name: 'Daily' },
{ id: 'weekly', name: 'Weekly' }, { id: 'monthly', name: 'Monthly' }]
export const CLASS_TYPES = [{ id: 'offline', name: 'Offline' }, { id: 'hybrid', name: 'Hybrid' }, { id: 'online', name: 'Online' }];


export const ONLINE_CLASS_TYPE = 'online';