import { RiLogoutCircleRLine, RiHome4Line, RiFileChartLine } from "react-icons/ri";
import { BsQuestionCircleFill, BsQuestionCircle } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlineQuiz, MdOutlineVideoCameraFront } from "react-icons/md";
import { TbTargetArrow } from "react-icons/tb";

import { FiUsers } from "react-icons/fi";


// Sidebar Items
export const SidebarItems = [
    {
        icon: RiHome4Line,
        heading: "Overall",
    },
    // {
    //     icon: RiFileChartLine,
    //     heading: 'Reports'
    // },
    {
        icon: TbTargetArrow,
        heading: 'Topics'
    },
    {
        icon: MdOutlineQuiz,
        heading: 'Questions'
    },
    {
        icon: MdOutlineVideoCameraFront,
        heading: 'Lectures'
    },
    {
        icon: HiOutlineClipboardList,
        heading: "Tests",
    },

    // {
    //     icon: BsQuestionCircle,
    //     heading: 'Doubts'
    // },
    // {
    //     icon: FiUsers,
    //     heading: "Personal",
    // },
];

// Analytics Cards Data
export const cardsData = [
    {
        title: "Questions",
        color: {
            backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
            boxShadow: "0px 10px 20px 0px #e0c6f5",
        },
        barValue: 70,
        value: "25,970",
        png: MdOutlineQuiz,
        series: [
            {
                name: "Sales",
                data: [31, 40, 28, 51, 42, 109, 100],
            },
        ],
    },
    {
        title: "Lectures",
        color: {
            backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
            boxShadow: "0px 10px 20px 0px #FDC0C7",
        },
        barValue: 80,
        value: "14,270",
        png: SiGoogleclassroom,
        series: [
            {
                name: "Revenue",
                data: [10, 100, 50, 70, 80, 30, 40],
            },
        ],
    },
    {
        title: "Doubts",
        color: {
            backGround:
                "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
            boxShadow: "0px 10px 20px 0px #F9D59B",
        },
        barValue: 60,
        value: "4,270",
        png: BsQuestionCircleFill,
        series: [
            {
                name: "Expenses",
                data: [10, 25, 15, 30, 12, 15, 20],
            },
        ],
    },
];

// Recent Update Card Data
export const UpdatesData = [
    {
        // img: img1,
        name: "Andrew Thomas",
        noti: "has ordered Apple smart watch 2500mh battery.",
        time: "25 seconds ago",
    },
    {
        // img: img2,
        name: "James Bond",
        noti: "has received Samsung gadget for charging battery.",
        time: "30 minutes ago",
    },
    {
        // img: img3,
        name: "Iron Man",
        noti: "has ordered Apple smart watch, samsung Gear 2500mh battery.",
        time: "2 hours ago",
    },
];