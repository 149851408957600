import React from 'react';

const withEnablePrivacy = (WrappedComponent) => {
    class withEnablePrivacy extends React.Component {
        componentDidMount() {
            document.addEventListener('cut', this.preventAction);
            document.addEventListener('copy', this.preventAction);
            document.addEventListener('contextmenu', this.preventAction);
        }

        componentWillUnmount() {
            document.removeEventListener('cut', this.preventAction);
            document.removeEventListener('copy', this.preventAction);
            document.addEventListener('contextmenu', this.preventAction);
        }

        preventAction = (e) => {
            e.preventDefault();
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return withEnablePrivacy;
};

export default withEnablePrivacy;