import React, { Component, useEffect, useRef, useState } from 'react';
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Transition } from 'semantic-ui-react'


const Canvas = () => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [curWidth, setCurWidth] = useState(3);
    const [curColor, setCurColor] = useState('#000000');
    const [paths, setPaths] = useState([]);
    const canvasRef = useRef(null);

    /////////////////////////// DRAWING FUNCTIONS //////////////////////////////////

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "grey";
        ctx.fillRect(0, 0, 800, 500);
    }, [canvasRef.current]);

    const handleMouseDown = () => {
        setIsDrawing(true);
        const newPath = makePath();
        setPaths(currentPaths => [...currentPaths, newPath]);
    };


    const handleMouseMove = (ev) => {
        if (!isDrawing) return;

        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');

        setPaths(currentPaths => {
            const pathsCopy = [...currentPaths];
            const lastPath = pathsCopy[pathsCopy.length - 1];
            let x = ev.clientX - ctx.canvas.offsetLeft
            let y = ev.clientY - ctx.canvas.offsetTop
            lastPath.coordinates.push([x, y]);
            pathsCopy[pathsCopy.length - 1] = lastPath;
            drawPartialLine(lastPath);
            return pathsCopy;
        });
    };

    const handleMouseUpOrLeave = () => {
        setIsDrawing(false);

    };

    const drawPartialLine = (p) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.save();
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = curWidth;
        ctx.strokeStyle = curColor;
        ctx.beginPath();
        const c = p.coordinates;
        ctx.moveTo(c[0], c[1]);
        for (let i = 2; i < c.length; i += 2) {
            ctx.lineTo(c[i], c[i + 1]);
        }
        ctx.stroke();
        ctx.restore();
    }

    const drawFullLine = (data) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.save();
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = data.strokeWidth;
        ctx.strokeStyle = data.color;
        ctx.beginPath();
        const c = data.coordinates;
        ctx.moveTo(c[0], c[1]);
        for (let i = 2; i < c.length; i += 2) {
            ctx.lineTo(c[i], c[i + 1]);
        }
        ctx.stroke();
        ctx.restore();
    };

    const clearArea = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    };

    const iterateOverPaths = () => {
        paths.forEach(path => drawFullLine(path));
    };

    const makePath = () => {
        return {
            color: curColor,
            strokeWidth: curWidth,
            coordinates: []
        }
    }

    const undo = () => {
        setPaths(currentPaths => currentPaths.slice(0, -1));
        setTimeout(() => { // Ensure state is set before redrawing
            clearArea();
            iterateOverPaths();
        }, 0);
    };


    return (
        <Transition duration={1000}>
            <div className="ui small scale visible transition">
                <canvas
                    ref={canvasRef}
                    width="600"
                    height="500"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUpOrLeave}
                ></canvas>
                <br />
                <div className="controls">
                    <button className="ui button" onClick={clearArea}>
                        Clear Area
                    </button>
                    <button className="ui button" onClick={undo}>
                        Undo
                    </button>
                    <div className="controls">
                        <div className="label">Line Width:</div>
                        <input
                            id="slider"
                            type="range"
                            min="1"
                            max="50"
                            value={curWidth}
                            onChange={(e) => setCurWidth(e.target.value)}
                        ></input>
                    </div>
                    <div className="controls">
                        <div className="label">Color:</div>
                        <input
                            id="selColor"
                            type="color"
                            value={curColor}
                            onChange={(e) => setCurColor(e.target.value)}
                        ></input>
                    </div>
                </div>
            </div>
        </Transition>
    );

}

export default Canvas;