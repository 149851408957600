import { useNavigate } from 'react-router-dom';

import './ReadingMaterialCard.css'

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import { useState } from 'react';
import ReadingMaterialView from '../ReadingMaterialView/ReadingMaterialView';


const ReadingMaterialCard = ({ readingMaterialData, onCardClick, user = STUDENT_USER_TYPE }) => {

    const subjectName = readingMaterialData.topics.length > 0 ? readingMaterialData.topics[0].topic.subject.subject_name : '';
    const materialType = readingMaterialData.material_type;

    return (

        <div className={`reading-material-card ${materialType}`} onClick={() => onCardClick(readingMaterialData)}>
            <div className={`bkg-cover`}></div>

            <span className="material"> {readingMaterialData.material_name}</span>
            <span className={`subject ${subjectName}`}>{subjectName}</span>
        </div >
    )

}

export default ReadingMaterialCard;