import Hex from 'crypto-js/enc-hex';
import { checkAuthorized, returnJsonResponse, withAuth } from './AuthenticationService';
import { createParams } from './CommonService';
import { BACKEND_URL } from './BackendConfig';


export const renewHmacUrl = async (url) => {
    const params = createParams({ url: url });
    let urlString = `${BACKEND_URL}/objects/hmac${params}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const securedRenewHmacUrl = withAuth(renewHmacUrl);
