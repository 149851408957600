import React, { useMemo, useState } from 'react';
import * as d3 from 'd3';

const Treemap = ({ width, height, data, themeColor, valueAccessor, nameAccessor }) => {
    const [hoveredLeaf, setHoveredLeaf] = useState(null);
    const [clickedLeaf, setClickedLeaf] = useState(null);

    const hierarchy = useMemo(() => {
        return d3.hierarchy(data).sum(valueAccessor);
    }, [data, valueAccessor]);

    const root = useMemo(() => {
        const treeGenerator = d3.treemap().size([width, height]).padding(4);
        return treeGenerator(hierarchy);
    }, [hierarchy, width, height]);

    const colorScale = useMemo(() => {
        const values = root.leaves().map(leaf => valueAccessor(leaf.data));
        return d3.scaleLinear()
            .domain([0, Math.max(...values)])
            .range(['#FFFFFF', themeColor]);
    }, [root, valueAccessor, themeColor]);

    const handleMouseEnter = (leaf) => {
        setHoveredLeaf(leaf);
    };

    const handleMouseLeave = () => {
        setHoveredLeaf(null);
    };

    const handleClick = (leaf) => {
        setClickedLeaf(leaf === clickedLeaf ? null : leaf);
    };

    const allShapes = root.leaves().map((leaf, i) => {
        const color = colorScale(valueAccessor(leaf.data));
        const isHovered = leaf === hoveredLeaf;
        const isClicked = leaf === clickedLeaf;

        return (
            <g
                key={i}
                onMouseEnter={() => handleMouseEnter(leaf)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(leaf)}
            >
                <rect
                    x={leaf.x0}
                    y={leaf.y0}
                    width={leaf.x1 - leaf.x0}
                    height={leaf.y1 - leaf.y0}
                    stroke={isClicked ? "black" : "transparent"}
                    strokeWidth={isClicked ? 2 : 0}
                    fill={color}
                    opacity={isHovered || isClicked ? 1 : (hoveredLeaf ? 0.3 : 0.8)}
                    className="transition-all duration-200"
                />
                <text
                    x={leaf.x0 + 3}
                    y={leaf.y0 + 3}
                    fontSize={12}
                    textAnchor="start"
                    alignmentBaseline="hanging"
                    fill="white"
                    className="font-bold pointer-events-none"
                >
                    {nameAccessor(leaf.data)}
                </text>
                <text
                    x={leaf.x0 + 3}
                    y={leaf.y0 + 18}
                    fontSize={12}
                    textAnchor="start"
                    alignmentBaseline="hanging"
                    fill="white"
                    className="font-light pointer-events-none"
                >
                    {valueAccessor(leaf.data)}
                </text>
            </g>
        );
    });

    return (
        <svg width={width} height={height} className="treemap-leaf">
            {allShapes}
        </svg>
    );
};

export default Treemap;