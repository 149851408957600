import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './Syllabus.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedCreateTopic, securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { securedFetchGrades } from '../../services/BatchService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BsCloudUpload } from 'react-icons/bs'

Modal.setAppElement('#root');

const TopicCreate = ({ isOpen, onRequestClose, initialSubjects = [], initialGrades = [], initialSubject = {}, initialGrade = {}, onTopicCreate = null, user = ADMIN_USER_TYPE }) => {

    const [topicName, setTopicName] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopic, setSelectedMainTopic] = useState({});
    const navigate = useNavigate();

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        setSubjects(subjectsResponse.data);
        return subjectsResponse.data;
    };

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
        return gradesResponse.data;
    };

    const makeMainTopicFilter = (initSubjects = null, initGrades = null) => {
        const filter = {};
        if (selectedGrade.id) {
            filter['grades'] = [selectedGrade.id];
        } else if (initialGrade.id) {
            filter['grades'] = [initialGrade.id];
        } else {
            if (initialGrades) {
                filter['grades'] = initialGrades.map(grade => grade.id)
            } else {
                filter['grades'] = grades.map(grade => grade.id)
            }
        }

        if (selectedSubject.id) {
            filter['subject_ids'] = [selectedSubject.id];
        } else if (initialSubject.id) {
            filter['subject_ids'] = [initialSubject.id];
        } else {
            if (initSubjects) {
                filter['subject_ids'] = initSubjects.map(subject => subject.id)
            } else {
                filter['subject_ids'] = subjects.map(subject => subject.id)
            }
        }
        filter['parent_ids'] = [-1];
        return filter;
    }

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            let subjectsResponse, gradesResponse;
            if (!initialSubjects) {
                setSubjects(initialSubjects);
                subjectsResponse = initialSubjects;
            } else {
                subjectsResponse = await fetchAndSetSubjects();
            }
            if (subjectsResponse === null) {
                return;
            }

            if (!initialGrades) {
                setGrades(initialGrades);
                gradesResponse = initialGrades;
            } else {
                gradesResponse = await fetchAndSetGrades();
            }
            if (gradesResponse === null) {
                return;
            }
            setSelectedGrade(initialGrade);
            setSelectedSubject(initialSubject);
            setSelectedMainTopic({});
            const mainTopicFilter = makeMainTopicFilter(subjectsResponse, gradesResponse);
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
        })();
    }, [isOpen]);

    const onSelectedMainTopicChange = (selection) => {
        const mainTopicId = selection.value ? selection.value : null;
        if (mainTopicId) {
            setSelectedMainTopic({ id: selection.value, topic_name: selection.label });
        }
    }

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection.value ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: selection.value, subject_name: selection.label });
            const mainTopicFilter = makeMainTopicFilter();
            mainTopicFilter['subject_ids'] = [subjectId];
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
            setSelectedMainTopic({});
        }
    }

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection.value ? selection.value : null;
        if (gradeId) {
            setSelectedGrade({ id: selection.value, grade: selection.label });
            const mainTopicFilter = makeMainTopicFilter();
            mainTopicFilter['grades'] = [gradeId];
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
            setSelectedMainTopic({});
        }
    }

    const handleSubmit = async () => {
        if (!selectedSubject.id || !selectedGrade.id) {
            alert("Subject and Grade must be chosen!");
        }
        const data = {
            topic_name: topicName,
            grade: parseInt(selectedGrade.id),
        }

        if (selectedMainTopic.id) {
            data['parent_id'] = selectedMainTopic.id;
        }
        const response = await securedCreateTopic(selectedSubject.id, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const mainTopicsResponse = await securedFetchTopicsByFilter(makeMainTopicFilter(), navigateCallbackOptions(navigate));
        if (mainTopicsResponse === null) {
            return;
        }
        setMainTopics(mainTopicsResponse.data);
        if (onTopicCreate) {
            await onTopicCreate(response.topic_id);
        }
        onRequestClose();
    }


    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return (
        <Modal overlayClassName="customModal" className="topicModal" isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="topicCreate flex">

                <h3 className="topicTitle"> Create New Topic </h3>

                <form className="topicInfoInput flex">

                    <div className="textInput flex">
                        <label className="title">Name</label>
                        <input type="text" value={topicName} onChange={e => setTopicName(e.target.value)} placeholder="Enter..." required />
                    </div>
                    <SelectionDropdown className="topicSelectionDropdown" name='Subject' onSelectionChange={onSelectedSubjectChange} itemList={subjects} selectedItem={selectedSubject} nameField='subject_name' valueField='id' />
                    <SelectionDropdown className="topicSelectionDropdown" name='Grade' onSelectionChange={onSelectedGradeChange} itemList={grades} selectedItem={selectedGrade} nameField='grade' valueField='id' />
                    <SelectionDropdown className="topicSelectionDropdown" name='Main Topic' onSelectionChange={onSelectedMainTopicChange} itemList={mainTopics} selectedItem={selectedMainTopic} nameField='topic_name' valueField='id' isSearchable={true} />
                </form>

                <button className='btn flex' onClick={handleSubmit}>
                    SUBMIT <BsCloudUpload className="icon" />
                </button>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />

            </div>
        </Modal>
    );
}

export default TopicCreate;
