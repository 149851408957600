import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'

import './SlotTest.css';

import SelectionDropdown from '../../Common/SelectionDropdown';
import TestEdit from '../../Test/TestEdit';
import TestHeader from '../../Test/TestHeader';
import SlotTestCreate from './SlotTestCreate';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { TEST_CREATE_TYPE_BASIC, securedFetchTests } from '../../../services/TestService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { UnauthorizedError } from '../../../services/Errors';

import { FiFilePlus } from "react-icons/fi";

const SlotTestEdit = ({ lectureData, user = ADMIN_USER_TYPE, testType }) => {

    const [isTestModalOpen, setTestModalOpen] = useState(false);
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [testStatus, setTestStatus] = useState(null);
    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);
    const navigate = useNavigate();

    const makeFilter = () => {
        const filter = {
            timetable_slot_ids: [lectureData.id],
            test_types: [testType]
        };
        return filter;
    };

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return [];
        }
        return allTestsResponse.data;
    };

    const fetchAndSetAllTests = async (selectedTestId = null) => {
        const response = await fetchAllTests();
        setTests(response);
        if (!selectedTestId) {
            setSelectedTest({});
            return;
        }
        const test = response.find((singleTest) => (singleTest.id === selectedTestId));
        await onTestSelectionChange({ value: test.id, label: test.test_name });
    };

    useEffect(() => {
        (async () => {
            await fetchAndSetAllTests();
        })();
    }, [testType]);

    const onTestSelectionChange = async (selection) => {
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedTest({ id: selection.value, test_name: selection.label });
        }
    };

    const onTestCreate = async (testId) => {
        await fetchAndSetAllTests(testId);
    }

    return (
        <div className="slotTestEdit flex">

            <div className="quizSelect flex">
                <SelectionDropdown
                    className="quizSelectionDropdown"
                    name=""
                    itemList={tests}
                    selectedItem={selectedTest}
                    onSelectionChange={onTestSelectionChange}
                    nameField='test_name'
                    valueField='id'
                    placeHolderText={"Select Live Quiz"} />
                {lectureData && lectureData.status !== 'finished' && (
                    <>
                        <FiFilePlus className="createNewQuiz"
                            onClick={() => setTestModalOpen(true)}
                            data-tooltip-id={`tooltip-${lectureData.id}`}
                            data-tooltip-content="Create New Quiz"
                            data-tooltip-place="right" />
                        <SlotTestCreate isOpen={isTestModalOpen} onRequestClose={() => (setTestModalOpen(false))} lectureData={lectureData} onTestCreate={onTestCreate} user={user} testType={testType} />
                    </>
                )}

                <Tooltip id={`tooltip-${lectureData.id}`} />
            </div>

            {/* Show Live Quiz ... */}
            <div className="quizDiv flex">
                {selectedTest.id && (
                    <>
                        <TestHeader testData={tests.find(test => test.id === selectedTest.id)} testStatus={testStatus} setTestStatus={setTestStatus} user={user} />
                        {/* Test Form */}
                        <TestEdit id={selectedTest.id} testStatus={testStatus} user={user} testType={testType} />
                    </>
                )}
            </div>

        </div >
    );
}

export default SlotTestEdit;
