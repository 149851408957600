import React from 'react'

import bkgImage from '../../Assets/AdminProfile.png'

import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import Dashboard from '../../Components/Dashboard/Dashboard';

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';

const AdminDashboard = () => {
    return (
        <>
            <Navbar navItems={AdminNavItems} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title={"Hi! Khandelwal Classes"}
                description={"Boost Your Productivity and Success with JEETA!"}
            /> */}
            <Dashboard />
            <Footer />
        </>
    )
};

export default AdminDashboard;