import React, { useState } from 'react';
import './LoginForm.css'

import ForgotPassword from './ForgotPassword';

import { SlLogin } from 'react-icons/sl'
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { MdLockReset } from "react-icons/md";

const LoginForm = ({ handleLogin, handleRegister, showLogIn = false }) => {

    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    });

    const handleCredentialsChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

    return (
        <>
            <form className="loginForm flex">
                {showLogIn && (
                    <>
                        <div className="loginInfoInput flex">
                            <label className="inputTitle" htmlFor="ID">ID</label>
                            <div className="idInput flex">
                                <input type="email" name="username" value={credentials.username} onChange={handleCredentialsChange} placeholder='E-mail Address' required />
                                <AiOutlineMail className="icon" />
                            </div>
                        </div>

                        <div className="loginInfoInput flex" >
                            <label className="inputTitle" htmlFor="PW">PW</label>
                            <div className="pwInput flex">
                                <input type="password" name="password" value={credentials.password} onChange={handleCredentialsChange} placeholder='Password' required />
                                <RiLockPasswordLine className="icon" />
                            </div>
                        </div>

                        <div className="remember-forgot flex">
                            <label className="forgot-btn flex" onClick={() => setForgotPasswordModalOpen(true)}>Forgot password?</label>
                            <ForgotPassword
                                isOpen={isForgotPasswordModalOpen}
                                onRequestClose={() => { setForgotPasswordModalOpen(false) }}
                                initEmail={credentials.username} />
                        </div>

                        <button className="btn flex" onClick={(event) => handleLogin(event, credentials)}>
                            <SlLogin className="icon" />
                            <span>LOG IN</span>
                        </button>
                    </>
                )
                }

            </form >
        </>
    )
};

export default LoginForm;
