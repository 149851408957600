import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';

import { ChevronLeft, ChevronRight } from "react-feather";

import "./QuestionSlider.css";

import QuestionSlide from './QuestionSlide';
import ParagraphSlide from './ParagraphSlide';

import { securedFetchQuestionById } from '../../../../services/QuestionService';
import { UnauthorizedError } from '../../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

const QuestionSlider = ({ type, questionTypes = null, questionIds, questionIdIndex = 0, setQuestionIdIndex, onQuestionSlideEnd, showImages = false, showAnswers = false, user = ADMIN_USER_TYPE, onQuestionDelete = null }) => {

    const [curr, setCurr] = useState(questionIdIndex);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Minimum distance (in pixels) to consider a swipe gesture
    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null on every new touch start
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () =>
        setCurr((curr) => (curr === 0 ? questionIds.length - 1 : curr - 1));
    const next = () =>
        setCurr((curr) => (curr === questionIds.length - 1 ? 0 : curr + 1));

    const renderQuestionSlide = (id, index) => {
        const currentType = questionTypes ? questionTypes[index] : type;
        if (currentType === 'standalone') {
            return (<QuestionSlide questionId={id} showImages={showImages} showAnswers={showAnswers} user={user} onQuestionDelete={onQuestionDelete} />);
        }
        return (<ParagraphSlide paragraphId={id} showImages={showImages} showAnswers={showAnswers} user={user} onParagraphDelete={onQuestionDelete} />);
    }

    return (

        <div className="questionSlider flex">

            {questionIds.map((id, index) => (
                curr === index && (
                    <>
                        <div key={index} className='slide-wrapper' onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

                            <div className='slider-controls'>
                                <div className="slider-btns flex">
                                    <button className="prev-btn flex"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            prev();
                                        }}
                                    >
                                        <ChevronLeft className="icon" />
                                    </button>
                                    <button className="next-tbn flex"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            next();
                                        }}
                                    >
                                        <ChevronRight className="icon" />
                                    </button>
                                </div>

                                <div className="dots-wrapper">
                                    <div className="dots-container flex">
                                        {questionIds.map((_, i) => (
                                            <div key={i}
                                                className={`dot ${curr === i ? "dot-active" : ""}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {renderQuestionSlide(id, index)}
                        </div>
                    </>
                )
            ))
            }

        </div >
    );
};

export default QuestionSlider;
