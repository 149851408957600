import React, { useState } from 'react';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip'

import './testSection.css'

import { UnauthorizedError } from '../../../../services/Errors';
import { useNavigate } from 'react-router';
import { securedCreateTestSections, securedFetchTestSectionById } from '../../../../services/TestService';
import { ADMIN_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

import { AiFillCloseCircle, AiOutlineSave } from 'react-icons/ai';

Modal.setAppElement('#root');  // This is important for accessibility reasons

const TestSectionMarkingSchemeUpsert = ({ fullMarks, setFullMarks, negativeMarks, setNegativeMarks, partialMarks, setPartialMarks, enablePartialMarks = true, user = ADMIN_USER_TYPE }) => {
    return (
        <div className="testSectionNumbers flex">
            <label className="numbersTitle">Marks per Question:</label>
            <div className="numbersInput flex">
                <label htmlFor="fullMarks">Full Marks</label>
                <input
                    type="number"
                    id="fullMarks"
                    className="testMarks"
                    value={fullMarks}
                    onChange={(e) =>
                        setFullMarks(e.target.value < 0 ? 0 : e.target.value)
                    }
                />
            </div>

            <div className="numbersInput flex">
                <label htmlFor="negativeMarks">Negative Marks</label>
                <input
                    type="number"
                    id="negativeMarks"
                    className="testMarks"
                    value={negativeMarks}
                    onChange={(e) =>
                        setNegativeMarks(
                            e.target.value > 0 ? 0 : e.target.value
                        )
                    }
                />
            </div>
            {enablePartialMarks && (
                <div className="numbersInput flex">
                    <label htmlFor="partialMarks">Partial Marks</label>
                    <input
                        type="number"
                        id="partialMarks"
                        className="testMarks"
                        value={partialMarks}
                        onChange={(e) =>
                            setPartialMarks(e.target.value < 0 ? 0 : e.target.value)
                        }
                        max={fullMarks / 3}
                    />
                </div>)}
        </div>
    );
}

export default TestSectionMarkingSchemeUpsert;
