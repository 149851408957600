import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './testModal.css'


import { ADMIN_USER_TYPE, STUDENT_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { TEST_CREATE_TYPE_VIRTUAL, securedCreateTest } from '../../../services/TestService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { BellAlertIcon } from '@heroicons/react/20/solid';

const VirtualTestCreatePopup = ({ isOpen = false, onRequestClose = null, testId, onCreateVirtualTest = null, user = STUDENT_USER_TYPE, parentId }) => {

    const navigate = useNavigate();

    const handleSubmit = async () => {
        const response = await securedCreateTest(TEST_CREATE_TYPE_VIRTUAL, { test_id: testId }, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        onRequestClose();
        if (onCreateVirtualTest) {
            onCreateVirtualTest(response.virtual_test_id);
        }
    }

    return (

        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10" shouldCloseOnOverlayClick={false} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>


                        <div className="text-center" >

                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                <BellAlertIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />
                            </div>

                            <h3 className="text-base font-semibold leading-6 pt-1 pb-4 text-[var(--PrimaryColor)]">
                                Are you sure you want to
                                <br />
                                create a virtual test from this test ?
                            </h3>

                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    YES
                                </button>
                                <button
                                    type="button"
                                    onClick={onRequestClose}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    NO
                                </button>
                            </div>
                        </div>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

        // <Modal overlayClassName="customModal" className="testModal flex" isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
        //     <span>Are you sure you want to create a virtual test from this test ? </span>

        //     <div className="btns flex">
        //         <button className="btn" onClick={handleSubmit}>YES</button>
        //         <button className="btn" onClick={onRequestClose}>NO</button>
        //     </div>
        // </Modal >

    );
}

export default VirtualTestCreatePopup;