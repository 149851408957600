import React, { useState, useEffect } from 'react';
import produce from 'immer';

const CheckboxAnswerInputList = ({ answerInputs, setAnswerInputs, selectedAnswers, setSelectedAnswers, required = null, editable = true }) => {
    if (answerInputs.length !== 4) {
        throw new Error("Exactly four options are required.");
    }

    const onAnswerTextChange = (index) => (event) => {
        const newValue = event.target.value;
        setAnswerInputs(produce(draft => {
            draft[index].value = newValue;
        }));
    }

    const onSelectedAnswersChange = (index) => (event) => {
        const newSelectedAnswers = [...selectedAnswers];
        newSelectedAnswers[index] = event.target.checked;
        setSelectedAnswers(newSelectedAnswers);
    }

    return (
        <div className="checkboxInput">
            {answerInputs.map((answerDict, index) => {
                const answer = String.fromCharCode(65 + index);
                return (
                    <div className="checkboxOption" key={answer}>
                        <input type="checkbox" name='answer' value={answer} id={answer} checked={selectedAnswers[index]} onChange={onSelectedAnswersChange(index)} />
                        <label htmlFor={answer}>
                            ({answer})
                            {editable ?
                                <textarea className="optionInput" value={answerDict.value} placeholder={`Answer ${answer}...`} onChange={onAnswerTextChange(index)} rows="1" required={required} /> :
                                <p className="optionInput"></p>
                            }
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default CheckboxAnswerInputList