import React from 'react'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import { StudentNavItems } from './StudentNavItems';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';

import { useParams } from 'react-router-dom';
import ZoomView from '../../Components/Zoom/ZoomView';
import ZoomIframeView from '../../Components/Zoom/ZoomIframeView';

const StudentZoomOnly = () => {
    const { slotId, zoomMeetingId } = useParams();
    return (
        <>
            {/* <Hero
                cName="hero"
                img={image}
                title="Live Class"
                description="Engage and Motivate Yourself with JEETA!"
            /> */}
            <ZoomView slotId={parseInt(slotId)} zoomMeetingId={parseInt(zoomMeetingId)} user={STUDENT_USER_TYPE} />
        </>
    )
};

export default StudentZoomOnly;