import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import * as bootstrap from "bootstrap";

//FullCalendar
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { formatDate } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import '../Common/CalendarStyles.css';
import '../Slots/slotview.css';
import './batches.css';

import Logo from "../../Assets/LiveClass.png";

import BatchScheduleCreate from './BatchScheduleCreate';
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { hasTimetableScheduleCreateAuthority, needsSlotPinAuthentication, securedDeleteSlotById, securedFetchSlotStatus } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { updateSearchParams } from '../../services/CommonService';

const BatchTimetableFullCalendar = ({ eventsList, batch = null, onScheduleCreate = null, onSlotDelete = null, onSlotEdit = null, onViewTimetable = null, user = ADMIN_USER_TYPE, dateRange, setDateRange, replaceSearchParams = true }) => {

    const calendarRef = useRef(null);
    const [currentEvents, setCurrentEvents] = useState(eventsList);
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [isAddEventModalOpen, setAddEventModalOpen] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState({});
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 860);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentView, setCurrentView] = useState(searchParams.get('viewType') || (isMobileView ? 'timeGridDay' : 'timeGridWeek'));

    const navigate = useNavigate();

    useEffect(() => {
        if (!calendarRef.current || !dateRange.startDate || !dateRange.endDate) {
            return;
        }
        const calendarApi = calendarRef.current.getApi();
        const view = calendarApi.view;
        const calendarStartDate = moment(view.currentStart).format('YYYY-MM-DD');
        const calendarEndDate = moment(view.currentEnd).subtract(1, 'seconds').format('YYYY-MM-DD');
        changeSearchParams();
        const calculatedCurrentView = calculateCurrentView();
        setCurrentView(calculatedCurrentView);
        if (!dateRange.startDate || !dateRange.endDate ||
            (calendarStartDate === dateRange.startDate && calendarEndDate === dateRange.endDate)) {
            return;
        }

        setTimeout(() => {
            calendarApi.changeView(calculatedCurrentView, dateRange.startDate);
        }, 0);
    }, [dateRange]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 860) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }

            if (window.innerWidth < 600) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        // Set the sidebar state based on the initial window width
        handleResize();
        if (!searchParams.get('viewType')) {
            setCurrentView(calculateCurrentView());
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeSearchParams = () => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }
        if (searchParams.get('startDate') !== dateRange.startDate || searchParams.get('endDate') !== dateRange.endDate) {
            updateSearchParams(setSearchParams, searchParams, { startDate: dateRange.startDate, endDate: dateRange.endDate, viewType: calculateCurrentView() }, replaceSearchParams);
        }
    };

    const handleEvents = async (events) => {
        const currentEventIds = events.map(event => parseInt(event.id));
        setCurrentEvents(eventsList.filter(event => currentEventIds.includes(event.id)));
    }

    const handleEventClick = async (clickInfo) => {
        await handleEventClickById(clickInfo.event.id);
    }

    const handleEventClickById = async (eventId) => {
        navigate(`slots/${eventId}`)
    }

    const handleSlotSelect = (selectInfo) => {
        setAddEventModalOpen(true);
        setSelectedEventData(
            {
                start: selectInfo.start,
                end: selectInfo.end,
                allDay: selectInfo.allDay
            }
        )
    }

    const calculateCurrentView = () => {
        if (!dateRange.startDate) {

            if (isMobileView) {
                return 'timeGridDay';
            } else {
                return 'timeGridWeek';
            }
        }

        const duration = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
        if (duration === 0) {
            return 'timeGridDay';
        }
        if (duration <= 7) {
            return 'timeGridWeek';
        }
        return 'dayGridMonth';
    }

    const getInitialView = () => {
        if (!dateRange.startDate) {

            if (isMobileView) {
                setCurrentView('timeGridDay');
                return;
            } else {
                setCurrentView('timeGridWeek');
                return;
            }
        }

        const duration = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
        if (duration === 0) {
            setCurrentView('timeGridDay');
            return;
        }
        if (duration <= 7) {
            setCurrentView('timeGridWeek');
            return;
        }
        setCurrentView('dayGridMonth');
        return;
    };

    function Sidebar({ currentEvents }) {
        return (
            <div className="full-calendar-sidebar">
                {/* logo */}
                <div className="full-calendar-logo">
                    <img src={Logo} alt="logo" />
                    <span>
                        L<span>i</span>v<span>e</span> C<span>l</span>a<span>ss</span>
                    </span>
                </div>

                <div className="total-events">
                    {currentEvents.length} {currentEvents.length > 1 ? "Lectures" : "Lecture"}
                </div>

                <div className='events-list flex'>
                    <ul className='event-items flex'>
                        {currentEvents.map(renderSidebarEvent)}
                    </ul>
                </div>

            </div >
        ); if (window.innerWidth < 860) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }

    // a custom render function
    function renderEventContent(eventInfo) {
        return (
            <div className="event-info-calendar">
                {!(currentView === 'timeGridWeek' && isTabletView) &&
                    <b className="event-time">{moment(eventInfo.event.start).format('h:mmA')}</b>
                }
                <span className="event-subject" id={eventInfo.event.title[0]}>
                    {eventInfo.event.title[0]}
                </span>
                {!(currentView === 'dayGridMonth' && isTabletView) && <i className="event-teacher">{eventInfo.event.title.slice(1)}</i>}
            </div>
        )
    }

    function renderSidebarEvent(event) {
        return (
            <li key={event.id} className="event-info" onClick={() => handleEventClickById(event.id)}>
                {isTabletView ?
                    (<b className="event-date">{moment(event.start).format('h:mmA DD/MM/YY')}</b>)
                    : (<>
                        <b className="event-date">{moment(event.start).format('h:mmA DD MMMM YYYY')}</b>
                        <br />
                    </>)}

                <span>
                    <p className="event-subject" id={event.subject_name}>
                        {event.subject_name[0]}
                    </p>
                    <i className="event-teacher">{event.teacher_name}</i>
                </span>
            </li>
        );
    }

    const onDatesSet = async (dateInfo) => {
        const newStartDate = moment(dateInfo.view.currentStart).format('YYYY-MM-DD');
        const newEndDate = moment(dateInfo.view.currentEnd).subtract(1, 'seconds').format('YYYY-MM-DD');
        if (newStartDate !== dateRange.startDate || newEndDate !== dateRange.endDate) {
            setDateRange({
                startDate: newStartDate,
                endDate: newEndDate
            })
        }
    }

    if (user === ADMIN_USER_TYPE && !batch.id) {
        return;
    }

    return (

        <div className="timeTable" id="full-calendar-wrapper">

            <div className='full-calendar'>
                <Sidebar currentEvents={currentEvents} />

                <div className="full-calendar-container">

                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        headerToolbar={{
                            left: 'today,prev,next',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        // view={['dayGridMonth,timeGridWeek,timeGridDay']}
                        height="auto" // This makes the calendar height responsive
                        initialDate={dateRange.startDate || moment().format('YYYY-MM-DD')}
                        initialView={currentView}
                        events={eventsList}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        eventOverlap={false}
                        editable={user === ADMIN_USER_TYPE ? true : false}
                        selectable={true}
                        selectMirror={true}
                        selectMinDistance={2}
                        weekends={weekendsVisible}
                        nowIndicator={true}
                        allDaySlot={false}
                        slotMinTime="6:00:00"
                        slotMaxTime="23:00:00"
                        select={handleSlotSelect}
                        duration="00:30:00"

                        dayMaxEventRows={true}
                        dayHeaderContent={(args) => {
                            const day = moment(args.date).format('ddd');
                            const date = moment(args.date).format('D/M');

                            if (args.view.type === 'timeGridWeek') {
                                return (
                                    <span>
                                        {day}
                                        <br />
                                        {date}
                                    </span>
                                );
                            }
                            else { return (<span>{day}</span>) };
                        }}
                        titleFormat={(args) => {
                            // Format the start and end dates
                            const startDate = moment(args.start);
                            const endDate = moment(args.end);
                            const diffDays = endDate.diff(startDate, 'days');

                            if (startDate.format('ddd D/M') === endDate.format('ddd D/M')) {
                                if (isMobileView) {
                                    return startDate.format('DD/MM/YY');
                                } else {
                                    return startDate.format('D MMMM YYYY'); // Return only one date if they are the same
                                }
                            } else if (diffDays > 7) {
                                return startDate.format('MMMM YYYY');
                            } else {
                                if (startDate.format('YYYY') !== endDate.format('YYYY')) {
                                    if (isMobileView) {
                                        return `${startDate.format('D/M/YY')}-${endDate.format('D/M/YY')}`; // Return the date range if they are different
                                    } else {
                                        return `${startDate.format('D/M/YYYY')}-${endDate.format('D/M/YYYY')}`; // Return the date range if they are different
                                    }
                                } else if (startDate.format('MM') !== endDate.format('MM')) {
                                    if (isMobileView) {
                                        return `${startDate.format('D/M')}-${endDate.format('D/M')} ${endDate.format('YY')}`; // Return the date range if they are different
                                    } else {
                                        return `${startDate.format('D/M')}-${endDate.format('D/M')} ${endDate.format('YYYY')}`; // Return the date range if they are different

                                    }
                                } else {
                                    if (isMobileView) {
                                        return `${startDate.format('D')}-${endDate.format('D')}/${endDate.format('MM/YY')}`;
                                    } else {
                                        return `${startDate.format('D')}-${endDate.format('D')} ${endDate.format('MMMM YYYY')}`; // Return the date range if they are different
                                    }
                                }
                            }
                        }}
                        datesSet={onDatesSet}
                    />

                    {hasTimetableScheduleCreateAuthority(user) && batch.id && (
                        <BatchScheduleCreate
                            isOpen={isAddEventModalOpen}
                            onRequestClose={() => (setAddEventModalOpen(false))}
                            startInput={selectedEventData.start}
                            endInput={selectedEventData.end}
                            batchId={batch.id}
                            grade={batch.batch_grade}
                            onScheduleCreate={onScheduleCreate}
                        />
                    )}
                </div>
            </div>
        </div>
    )
};

export default BatchTimetableFullCalendar;