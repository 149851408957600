import { checkAuthorized, returnJsonResponse, withAdminAuth, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { createParams } from "./CommonService";
import { ServerError, UnauthorizedError } from "./Errors";

export const fetchTopicsByFilter = async (data) => {
    data['topic_types'] = ['user']
    const response = await fetch(`${BACKEND_URL}/syllabus/topics${createParams(data)}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createTopic = async (subjectId, data) => {
    const response = await fetch(`${BACKEND_URL}/syllabus/subjects/${subjectId}/topics`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchTopicsByFilter = withAuth(fetchTopicsByFilter);
export const securedCreateTopic = withAuth(createTopic);

export const fetchSubjects = async () => {
    const response = await fetch(`${BACKEND_URL}/syllabus/subjects`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchSubjects = withAuth(fetchSubjects);

export const subjectAbbreviationMap = {
    'Physics': 'PHY',
    'Mathematics': 'MATH',
    'Chemistry': 'CHEM'
};

export const subjectBackgroundColorMap = {
    'Physics': 'PHY',
    'Mathematics': 'MATH',
    'Chemistry': 'CHEM'
}