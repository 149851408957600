import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import './SlotTest.css';

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import MultiBatchGradeSelection from '../../Batches/MultiBatchGradeSelection';
import TestRemainingTime from '../../Test/TestRemainingTime';
import { SLOT_TEST_TYPES, TEST_CREATE_TYPE_BASIC, securedFetchTests } from '../../../services/TestService';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { UnauthorizedError } from '../../../services/Errors';

import { PiClockClockwiseBold } from 'react-icons/pi';
import { FiRefreshCcw } from "react-icons/fi";
import { BsCloudUpload } from 'react-icons/bs';
import TestHeader from '../../Test/TestHeader';
import NTATestView from '../../Test/NTA/NTATestView';
import StudentTestView from '../../Test/StudentTest/StudentTestView';


const SlotTestView = ({ lectureData, user = STUDENT_USER_TYPE, testType }) => {
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [testStatus, setTestStatus] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const navigate = useNavigate();

    const makeFilter = () => {
        const filter = {
            timetable_slot_ids: [lectureData.id],
            test_types: [testType]
        };
        return filter;
    };

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    const fetchAndSetAllTests = async () => {
        const response = await fetchAllTests();
        if (response === null) {
            return;
        }
        setTests(response);
    };

    const onTestEnd = () => {
        setSelectedTest(null);
    }

    const onRefreshTest = () => {
        setRefreshKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        (async () => {
            fetchAndSetAllTests();
            setSelectedTest({});
        })();
    }, [testType]);

    const onTestSelectionChange = (selection) => {
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedTest({ id: selection.value, test_name: selection.label });
        }
    };

    const renderTest = () => {
        if (!selectedTest || !selectedTest.id) {
            return (
                <></>
            );
        }
        return (
            <>
                {/* Test Status  */}
                <TestHeader testData={tests.find(test => test.id === selectedTest.id)} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} onRefreshClick={onRefreshTest} />
                {testStatus !== null && testStatus !== 'NOT STARTED' && (
                    <StudentTestView id={selectedTest.id} user={user} onTestEnd={onTestEnd} testStatus={testStatus} refreshKey={refreshKey} />
                )}
            </>
        );
    }

    return (
        <div className="slotTestView flex">

            <div className="testFilter flex">
                <SelectionDropdown
                    className="slotTestSelectionDropdown"
                    name=""
                    itemList={tests}
                    selectedItem={selectedTest}
                    onSelectionChange={onTestSelectionChange}
                    nameField='test_name'
                    valueField='id'
                    placeHolderText={"Select Live Quiz"} />
            </div>

            {/* Show Live Quiz ... */}
            <div className="quizDiv flex" id={testType}>
                {selectedTest.id && renderTest()}
            </div>

        </div>
    );
}

export default SlotTestView;
