import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import './RecordedVideoCard.css'

import LoadingPage from '../../Common/LoadingPage';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import RecordedVideoCard from './RecordedVideoCard';
import RecordedVideoCardListFilter from './RecordedVideoCardListFilter';
import { needsVideoRequestApproval, securedFetchRecordedVideoByFilter, securedFetchVideoAccessRequestsByFilter } from '../../../services/VideoService';
import VideoViewer from '../../Files/Video/VideoViewer';
import VideoViewerPopup from '../../Files/Video/VideoViewerPopup';
import Spinner from '../../Common/Tailwind/Spinner';


const RecordedVideoCardList = ({ syllabusFilter = null, user = ADMIN_USER_TYPE, page = 1, setPage }) => {

    const [recordedVideos, setRecordedVideos] = useState([]);
    const [totalVideoCount, setTotalVideoCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [videoViewData, setVideoViewData] = useState(null);
    const [isVideoViewOpen, setVideoViewOpen] = useState(false);
    const navigate = useNavigate();

    const makeFilter = (currentPage, externalFilter = null) => {
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const filter = { skip: (currentPage - 1) * 12, limit: 12 }
        if (!currentSearchFilter) {
            return filter;
        }
        if (currentSearchFilter.selectedTopics && currentSearchFilter.selectedTopics.length > 0) {
            filter['topic_ids'] = currentSearchFilter.selectedTopics.map((item) => (item.id));
        }
        else if ((currentSearchFilter.selectedSubjects && currentSearchFilter.selectedSubjects.length > 0) ||
            (currentSearchFilter.selectedGrades && currentSearchFilter.selectedGrades.length > 0)) {
            filter['topic_ids'] = currentSearchFilter.topics.map((item) => (item.id));
        }
        if (currentSearchFilter.queryString && currentSearchFilter.queryString.length > 0) {
            filter['query_string'] = currentSearchFilter.queryString;
        }
        if (currentSearchFilter.nameSubstring && currentSearchFilter.nameSubstring.length > 0) {
            filter['title_substring'] = currentSearchFilter.nameSubstring;
        }
        return filter;
    }

    const onCardClick = (videoData) => {
        setVideoViewData(videoData);
        setVideoViewOpen(true);
    }

    const renderVideoCard = (recordedVideoData) => {
        return <RecordedVideoCard recordedVideoData={recordedVideoData} onCardClick={onCardClick} user={user} />;
    }

    const renderVideoCards = () => {
        return (
            <>
                {
                    recordedVideos.map(videoData => (
                        renderVideoCard(videoData)
                    ))
                }
            </>
        )
    }

    const fetchData = async (hasFilterChanged = false, externalFilter = null, externalPage = null) => {
        let currentPage = externalPage == null ? page : externalPage;
        if (hasFilterChanged) {
            setPage(1);
            currentPage = 1;
        }
        setLoading(true);
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const response = await securedFetchRecordedVideoByFilter(makeFilter(currentPage, currentSearchFilter), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }

        if (!needsVideoRequestApproval(user)) {
            setTotalVideoCount(response.count);
            setRecordedVideos(response.data.map(r => ({ ...r, approval_status: 'approve' })));
            setLoading(false);
            return;
        }
        const approvalsResponse = await securedFetchVideoAccessRequestsByFilter({ video_ids: response.data.map(r => r.id) }, navigateCallbackOptions(navigate));
        if (approvalsResponse === null) {
            return;
        }
        setTotalVideoCount(response.count);
        setRecordedVideos(response.data.map(r => {
            const matchedApproval = approvalsResponse.data.find(a => (a.video_id === r.id));
            return { ...r, approval_status: matchedApproval ? matchedApproval.latest_request_status : ((needsVideoRequestApproval(user) && r.is_access_restricted) ? 'none' : 'approve') };
        }));
        setLoading(false);
    };

    const onPageChange = async (changeUnit) => {
        const numberOfPages = Math.ceil(totalVideoCount / 12);
        if (page + changeUnit < 1 || page + changeUnit > numberOfPages || changeUnit === 0) {
            return;
        }
        setPage(page + changeUnit);
        await fetchData(false, null, page + changeUnit)
    }

    const displayPageButtons = () => {
        const numberOfButtonsAfterPage = Math.max(0, Math.ceil((totalVideoCount - page * 12) / 12));
        const startIndex = Math.max(page - 5, 1);
        const endIndex = Math.min(startIndex + 10, page + numberOfButtonsAfterPage);

        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (

            <button button key={startIndex + index} className={startIndex + index === page ? "page-btns current-page-btn" : "page-btns not-current-page-btn"} onClick={() => onPageChange(startIndex + index - page)} >
                {startIndex + index}
            </button>

        ));
    }

    const onSubmitFilter = async (externalFilter = null) => {
        await fetchData(true, externalFilter);
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    const renderCardList = () => {
        return (
            <>
                <RecordedVideoCardListFilter onSubmitFilter={onSubmitFilter} syllabusFilter={syllabusFilter} user={user} />

                {loading ? <Spinner /> : (

                    <div className="recordedVideoCardList flex">

                        <div className="recordedVideoCards">
                            {recordedVideos && renderVideoCards()}
                        </div>

                        <div className="change-page-btns flex">
                            <button className="left-btn flex" onClick={async () => await onPageChange(-1)} hidden={page === 1}>
                                <AiOutlineLeft className="icon" />
                            </button>

                            {displayPageButtons(totalVideoCount)}

                            <button className="right-btn flex" onClick={async () => await onPageChange(1)}>
                                <AiOutlineRight className="icon" />
                            </button>
                        </div>

                    </div>)}
                <VideoViewerPopup isOpen={isVideoViewOpen} onRequestClose={() => setVideoViewOpen(false)} videoId={videoViewData?.id || null}
                    defaultApproverId={videoViewData?.upserted_user_id || null} videoTitle={`${videoViewData?.title || ''}`} user={user} />

            </>)
    }

    return (
        <>

            {renderCardList()}
        </>
    );
};

export default RecordedVideoCardList;