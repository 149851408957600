import React, { useState } from 'react';

import './Test.css'

import TestRemainingTime from './TestRemainingTime';
import TestReport from './TestReport/TestReport';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { TbReport } from 'react-icons/tb';
import { FiRefreshCcw } from 'react-icons/fi';

const TestHeader = ({ testData, testStatus, setTestStatus, user = ADMIN_USER_TYPE, refreshKey = 0, onRefreshClick = null }) => {

    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);

    const onTestReportFetch = async () => {
        setTestReportModalOpen(true);
    }

    const showTestStatusInProgress = () => {
        return testStatus === 'STARTED';
    }

    const showTestStatusCompleted = () => {
        return testStatus === 'FINISHED';
    }

    const showTestStatusReady = () => {
        return user !== STUDENT_USER_TYPE && testStatus === 'NOT STARTED';
    }

    const showTestReportComponent = () => {
        return user !== STUDENT_USER_TYPE && testStatus === 'FINISHED';
    }

    const canRefreshTest = () => {
        return testStatus !== 'STARTED' && testStatus !== null && user === STUDENT_USER_TYPE;
    }


    return (
        <>
            {/* Test Status  */}
            {showTestStatusInProgress() ? (
                <div className="test-status flex">
                    <p className="msg" id="started">IN PROGRESS</p>
                </div>
            ) : showTestStatusCompleted() ? (
                <div className="test-status flex" >
                    <p className="msg" id="finished">COMPLETED</p>
                </div>
            ) : showTestStatusReady() ? (
                <div className="test-status flex" >
                    <p className="msg" id="not-started">READY</p>
                </div>
            ) : (<></>)}

            {/* Test Report */}
            {showTestReportComponent() && (
                <>
                    <div className="testReport-btn">
                        <button className="report-btn flex" onClick={onTestReportFetch} >
                            Test Report <TbReport className="icon" />
                        </button>
                    </div>

                    <TestReport isOpen={isTestReportModalOpen} onRequestClose={() => (setTestReportModalOpen(false))} testId={testData.id} testName={testData.test_name} user={user} />
                </>

            )}

            {/* Test Refresh */}
            {canRefreshTest() && (
                <div className="test-refresh flex" onClick={onRefreshClick}>
                    <p className="msg" id="started">Refresh <FiRefreshCcw className="icon" /> </p>
                </div>
            )}

            {/* Test Timer */}
            <div className="test-timer flex">
                <TestRemainingTime id={testData.id} testData={testData} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} />
            </div>
        </>
    );
}


export default TestHeader;
