import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import moment from 'moment';

import './batches.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import { ADMIN_USER_TYPE, securedFetchUsers } from '../../services/UserService';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { CLASS_TYPES, ONLINE_CLASS_TYPE, REPEAT_TYPES, securedCreateSchedule, securedFetchLectureHalls } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import LoadingPage from "../Common/LoadingPage";
import Aos from "aos";
import { useAuth } from "../Authentication/AuthContext";
import { securedFetchBatchesByFilter } from "../../services/BatchService";
import SelectionDropdownMultiple from "../Common/SelectionDropdownMultiple";

const BatchScheduleCreate = ({ isOpen = false, onRequestClose = null, startInput, endInput, batchId, grade, onScheduleCreate = null, user = ADMIN_USER_TYPE }) => {

    const { config } = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState({});
    const [halls, setHalls] = useState([]);
    const [selectedHall, setSelectedHall] = useState({});
    const [selectedRepeatType, setSelectedRepeatType] = useState(null);
    const [selectedClassType, setSelectedClassType] = useState(config.DEFAULT_TIMETABLE_CLASS_TYPE ? CLASS_TYPES.find(type => type.id === config.DEFAULT_TIMETABLE_CLASS_TYPE) || CLASS_TYPES[0] : CLASS_TYPES[0]);
    const [selectedScheduleId, setSelectedScheduleId] = useState();
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const makeFilter = (batchId) => {
        const filter = {
            batch_id: batchId
        }
        return filter;
    }

    const onChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    const fetchTeachers = async (filter) => {
        const teachersResponse = await securedFetchUsers('teachers', filter, navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            return null;
        }
        return teachersResponse.data.map(teacher => ({ id: teacher.id, teacher_name: teacher.user_data.first_name + ' ' + teacher.user_data.last_name }));
    }

    const fetchHalls = async (filter) => {
        const hallsResponse = await securedFetchLectureHalls(filter, navigateCallbackOptions(navigate))
        if (hallsResponse === null) {
            return null;
        }
        return hallsResponse.data;
    }

    const fetchBatches = async (filter) => {
        const batchesResponse = await securedFetchBatchesByFilter(filter, navigateCallbackOptions(navigate))
        if (batchesResponse === null) {
            return null;
        }

        return batchesResponse.data.filter(batch => batch.id !== batchId);
    }

    useEffect(() => {
        (async () => {
            const subjectsResponse = await fetchSubjects();
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse);
            const teachersResponse = await fetchTeachers({ subject_ids: subjectsResponse.map(subject => parseInt(subject.id)) });
            if (teachersResponse === null) {
                return;
            }
            setTeachers(teachersResponse);
            const hallsResponse = await fetchHalls(null);
            if (hallsResponse === null) {
                return;
            }
            setHalls(hallsResponse);
            const batchesResponse = await fetchBatches({ grades: [grade] });
            if (batchesResponse === null) {
                return;
            }
            setBatches(batchesResponse);
        })();
    }, []);

    useEffect(() => {
        setSelectedTeacher({});
        setSelectedSubject({});
        setSelectedHall({});
        setSelectedRepeatType({});
        setSelectedBatches([]);
        setStartDate(moment(startInput).format('YYYY-MM-DD'));
        setStartTime(moment(startInput).format('HH:mm:ss'));
        setEndDate(moment(endInput).format('YYYY-MM-DD'));
        setEndTime(moment(endInput).format('HH:mm:ss'));
    }, [startInput, endInput]);

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
            const teachersResponse = await fetchTeachers({ subject_ids: [subjectId] });
            if (teachersResponse === null) {
                return;
            }
            setTeachers(teachersResponse);
            if (teachersResponse.length > 0) {
                setSelectedTeacher(teachersResponse[0]);
            }
        }
    };

    const onSelectedTeacherChange = async (selection) => {
        const teacherId = selection ? selection.value : null;
        if (teacherId) {
            setSelectedTeacher({ id: teacherId, teacher_name: selection.label });
        }
    };

    const onSelectedHallChange = async (selection) => {
        const hallId = selection ? selection.value : null;
        if (hallId) {
            setSelectedHall({ id: hallId, lecture_hall_name: selection.label });
        }
    };

    const onSelectedRepeatTypeChange = async (selection) => {
        const repeatTypeId = selection ? selection.value : null;
        if (repeatTypeId) {
            setSelectedRepeatType({ id: repeatTypeId, name: selection.label });
        }
    };

    const onSelectedClassTypeChange = async (selection) => {
        const classTypeId = selection ? selection.value : null;
        if (classTypeId) {
            setSelectedClassType({ id: classTypeId, name: selection.label });
        }
    };

    const onSelectedBatchesChange = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newBatches = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(newBatches);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const onSubmit = async () => {
        setIsSaving(true);
        const data = {
            start_time: startTime,
            end_time: endTime,
            start_date: startDate,
            end_date: endDate,
            batches: [{ batch_id: batchId }, ...selectedBatches.map(batch => ({ batch_id: batch.id }))],
            subject_id: selectedSubject.id,
            teacher_id: selectedTeacher.id,
            repeat_type: selectedRepeatType.id,
            class_type: selectedClassType.id,
            lecture_hall_id: selectedHall.id,
        }
        const response = await securedCreateSchedule(data, navigateCallbackOptions(navigate));
        onRequestClose();
        if (response !== null && onScheduleCreate) {
            await onScheduleCreate();
        }
        setIsSaving(false);
    };

    const renderScheduleCreate = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>
                <div className="batchScheduleCreate flex">

                    <h3 className="modalTitle" data-aos="fade-down">
                        Create New Schedule
                    </h3>

                    <div className="batchInfoInput flex">


                        <SelectionDropdown className="batchSelectionDropdown" name="Subject" onSelectionChange={onSelectedSubjectChange} selectedItem={selectedSubject} itemList={subjects} nameField='subject_name' valueField='id' required={true} />
                        <SelectionDropdown className="batchSelectionDropdown" name="Teacher" onSelectionChange={onSelectedTeacherChange} selectedItem={selectedTeacher} itemList={teachers} nameField='teacher_name' valueField='id' required={true} />
                        <SelectionDropdown className="batchSelectionDropdown" name="Repeat Type" onSelectionChange={onSelectedRepeatTypeChange} selectedItem={selectedRepeatType} itemList={REPEAT_TYPES} nameField='name' valueField='id' required={true} />
                        <SelectionDropdown className="batchSelectionDropdown" name="Class Type" onSelectionChange={onSelectedClassTypeChange} selectedItem={selectedClassType} itemList={CLASS_TYPES} nameField='name' valueField='id' required={true} />
                        <SelectionDropdownMultiple className="batchSelectionDropdown" name="Other Batches" onSelectionToggle={onSelectedBatchesChange} selectedItems={selectedBatches} itemList={batches} nameField='batch_name' valueField='id' />

                        <div className="timeDateInput flex">
                            <div className="timeInput flex">
                                <label className="title">Start Time</label>
                                <input
                                    className="inputTime"
                                    type="time"
                                    id="startTime"
                                    name="startTime"
                                    value={startTime}
                                    min="09:00"
                                    max="24:00"
                                    onChange={onChange(setStartTime)}
                                    required
                                />
                            </div>

                            <div className="timeInput flex">
                                <label className="title">End Time</label>
                                <input
                                    className="inputTime"
                                    type="time"
                                    id="endTime"
                                    name="endTime"
                                    value={endTime}
                                    min="09:00"
                                    max="24:00"
                                    onChange={onChange(setEndTime)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="timeDateInput flex">
                            <div className="dateInput flex">
                                <label className="title">Start Date</label>
                                <input
                                    className="inputDate"
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    value={startDate}
                                    onChange={onChange(setStartDate)}
                                    required
                                />
                            </div>

                            <div className="dateInput flex">
                                <label className="title">End Date</label>
                                <input
                                    className="inputDate"
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    value={endDate}
                                    onChange={onChange(setEndDate)}
                                    required
                                />
                            </div>
                        </div>

                        {selectedClassType.id !== ONLINE_CLASS_TYPE && (<SelectionDropdown className="batchSelectionDropdown" name="Lecture Hall" onSelectionChange={onSelectedHallChange} selectedItem={selectedHall} itemList={halls} nameField='lecture_hall_name' valueField='id' />)}
                    </div>

                    <button className='btn flex' onClick={onSubmit}>
                        CREATE <MdAddCircleOutline className="icon" />
                    </button>

                </div>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }
    return (

        <Modal overlayClassName='customModal' className='batchModal' isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderScheduleCreate()}

        </Modal >
    )
}

export default BatchScheduleCreate;