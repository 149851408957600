import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import '../../questions.css'

import ImagePreview from '../../../Common/ImagePreview'
import ImageUpload from '../../../Common/ImageUpload'
import CheckboxAnswerInputList from '../CheckboxAnswerInputList'
import QuestionFormContext from '../QuestionFormContext';
import SelectionDropdown from '../../../Common/SelectionDropdown';
import { UnauthorizedError } from '../../../../services/Errors';
import { mapToMultiChoiceQuestionCreateFormat, securedPostQuestion, QUESTION_DIFFICULTIES } from '../../../../services/QuestionService';

import { BsCloudUpload } from 'react-icons/bs'

const QuestionTypeMatchTheColumn = () => {
    return (
        <div className="mtc">
            {/* <h3>The Problem Type is "Match the Column".</h3> */}
        </div>
    );
};

export default QuestionTypeMatchTheColumn;