import React, { useState } from 'react';

import Aos from 'aos'
import 'aos/dist/aos.css'

import { BsPersonCircle } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { FaMobileAlt } from 'react-icons/fa'

const BaseRegisterForm = ({ email, setEmail, contactNumber, setContactNumber, fullName, setFullName }) => {

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    return (

        <>
            <div className="contactInput flex">
                {/* <label className="title" htmlFor="FULL NAME">Full Name</label> */}
                <div className="input flex">
                    <BsPersonCircle className="icon" />
                    <input type="fullName" name="fullName" value={fullName} onChange={onTextChange(setFullName)} placeholder='Full Name' required />
                </div>
            </div>


            <div className="contactInput flex">
                {/* <label className="title" htmlFor="EMAIL">Email</label> */}
                <div className="input flex">
                    <AiOutlineMail className="icon" />
                    <input type="email" name="email" value={email} onChange={onTextChange(setEmail)} placeholder='E-mail Address' required />
                </div>
            </div>

            <div className="contactInput flex">
                {/* <label className="title" htmlFor="CONTACT NUMBER">Contact Number</label> */}
                <div className="input flex">
                    <FaMobileAlt className="icon" />
                    <input type="contactNumber" name="contactNumber" value={contactNumber} onChange={onTextChange(setContactNumber)} placeholder='Contact Number' required />
                </div>
            </div>
        </>

    )
};

export default BaseRegisterForm;
