import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './slotview.css'

import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { IoEnterOutline } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import { securedLecturePinAuthenticate } from '../../services/TimetableService';
import Spinner from '../Common/Tailwind/Spinner';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'


const SlotAttendanceCheck = ({ isOpen, onRequestClose, slotId, user = ADMIN_USER_TYPE, onLecturePinSuccess = null, onLecturePinFail = null }) => {

    const [lecturePin, setLecturePin] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleSubmitWithLocationCheck = async (event) => {
        event.preventDefault();
        navigator.geolocation.getCurrentPosition(handleLocationWithSubmit, handleLocationWithSubmit);
    }

    const handleLocationWithSubmit = async (position) => {
        setLoading(true);
        let latitude = null, longitude = null;
        if (position.coords) {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
        }
        if (lecturePin.length === 0) {
            alert('PIN cannot be empty!');
            setLoading(false);
            return;
        }
        const response = await securedLecturePinAuthenticate(slotId, { pin: lecturePin, latitude: latitude, longitude: longitude }, navigateCallbackOptions(navigate));
        if (response !== null && onLecturePinSuccess) {
            await onLecturePinSuccess();
        }

        if (response === null && onLecturePinFail) {
            onLecturePinFail();
        }
        setLoading(false);
        onRequestClose();
    }

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">


                    <DialogPanel
                        transition
                        className="relative transform min-h-[300px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        {isLoading ? (<Spinner />) : (
                            <>
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        onClick={onRequestClose}
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                    </button>
                                </div>


                                <div>

                                    <div className="mt-3 pt-8 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Enter the Lecture
                                        </DialogTitle>

                                        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                                            <form className="flex flex-col gap-2 justify-center items-center space-y-4">
                                                <div className="flex justify-center items-center gap-2">
                                                    <label htmlFor="pin" className="block text-sm font-medium leading-6 text-gray-900">
                                                        PIN
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={lecturePin}
                                                            onChange={e => setLecturePin(e.target.value)}
                                                            placeholder="Enter..."
                                                            required
                                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        onClick={async (event) => { await handleSubmitWithLocationCheck(event) }}
                                                        className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        ENTER
                                                        <IoEnterOutline className="icon" />
                                                    </button>
                                                </div>

                                            </form>

                                        </div>

                                    </div>
                                </div>
                            </>
                        )}
                    </DialogPanel>




                </div>
            </div>
        </Dialog >
    );
}

export default SlotAttendanceCheck;
