import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";
import { STUDENT_USER_TYPE } from "./UserService";

export const createStudentQuestionResponse = async (data) => {
    const response = await fetch(`${BACKEND_URL}/student-responses/question`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createBulkStudentQuestionResponses = async (data) => {
    const response = await fetch(`${BACKEND_URL}/student-responses/question/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchStudentQuestionResponsesByFilter = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/student-responses/question${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteStudentQuestionResponsesByFilter = async (filter) => {
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/student-responses/question${params}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchQuestionMetadatasByFilter = async (user, type, filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/${user}-question-metadatas/${type}${params}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};


export const updateQuestionMetadatas = async (user, type, data) => {
    const response = await fetch(`${BACKEND_URL}/${user}-question-metadatas/${type}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
};


export const securedCreateStudentQuestionResponse = withAuth(createStudentQuestionResponse);
export const securedCreateBulkStudentQuestionResponses = withAuth(createBulkStudentQuestionResponses);
export const securedFetchStudentQuestionResponsesByFilter = withAuth(fetchStudentQuestionResponsesByFilter);
export const securedDeleteStudentResponsesByFilter = withAuth(deleteStudentQuestionResponsesByFilter);
export const securedFetchQuestionMetadatasByFilter = withAuth(fetchQuestionMetadatasByFilter);
export const securedUpdateQuestionMetadatas = withAuth(updateQuestionMetadatas);

export const checkResponseCorrectness = (studentResponse, noResponseAsNull = false) => {
    if (noResponseAsNull && (studentResponse.response_answers.length === 0 || (studentResponse.student_correct === 0 && studentResponse.student_incorrect === 0))) {
        return null;
    }
    if (studentResponse.student_correct !== studentResponse.total_correct || studentResponse.student_incorrect > 0) {
        return false;
    }
    return true;
}

export const hasQuestionMetadata = (user) => {
    return user === STUDENT_USER_TYPE;
}