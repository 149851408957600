import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'

import './testSection.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import numberToWords from 'number-to-words';

import { ADMIN_USER_TYPE } from '../../../../services/UserService';



const TestSectionMarkingSchemeRender = ({ testSection, questionsForSection, paragraphsForSection, user = ADMIN_USER_TYPE }) => {

    const [subType, setSubType] = useState('single choice');
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [numberOfParagraphs, setNumberOfParagraphs] = useState(0);


    useEffect(() => {
        setSubType(getQuestionSubtype());
        setNumberOfQuestions(getNumberOfQuestions());
        setNumberOfParagraphs(paragraphsForSection.length);
    }, [questionsForSection, paragraphsForSection])

    const getQuestionSubtype = () => {
        if (questionsForSection.length > 0) {
            return questionsForSection[0].question.question_subtype;
        }
        if (paragraphsForSection.length > 0) {
            return paragraphsForSection[0].paragraph.questions[0].question.question_subtype;
        }
        return null;
    }

    const getNumberOfQuestions = () => {
        if (paragraphsForSection.length > 0) {
            const questionCount = paragraphsForSection.map(testParagraph => testParagraph.paragraph.questions.length);
            return questionCount.reduce((a, v) => a + v, 0);
        }
        if (questionsForSection.length > 0) {
            return questionsForSection.length;
        }
        return 0;
    }

    const renderSubtypeLines = () => {
        if (subType === 'single choice') {
            return (
                <>
                    <li> Each question has <strong>FOUR</strong> options (A), (B), (C) and (D). <strong>ONLY ONE</strong> of these four option(s) is the correct answer.</li>
                    <li> For each question, choose the option corresponding to  the correct answer.</li>
                </>)
        }
        if (subType === 'multi choice') {
            return (
                <>
                    <li> Each question has <strong>FOUR</strong> options (A), (B), (C) and (D). <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is(are) correct answer(s).</li>
                    <li> For each question, choose the option(s) corresponding to (all) the correct answer(s).</li>
                </>)
        }
        return (
            <>
                <li> The answer to each question is a <strong>NUMERICAL VALUE</strong>.</li>
                <li> For each question, enter the correct numerical value of the answer. If the numerical answer has more than two decimal places, <strong>truncate/round-off</strong> the value to <strong>TWO</strong> decimal places.</li>
            </>)
    }

    const renderFullmarksIfLine = () => {
        if (subType === 'single choice') {
            return 'if the correct option is chosen;'
        }
        if (subType === 'multi choice') {
            return 'if (all) the correct option(s) is(are) chosen;'
        }
        return 'if the correct numerical value is entered;'
    }

    const renderZeroMarksLine = () => {
        if (testSection.negative_marks === 0) {
            return 'in all other cases.'
        }
        if (subType === 'single choice' || subType === 'multi choice') {
            return 'If none of the options is chosen (i.e. the question is unanswered);'
        }
        return 'If no numerical value is entered (i.e. the question is unanswered);'
    }

    if (paragraphsForSection.length === 0 && questionsForSection.length === 0) {
        return;
    }

    return (

        <div className="testInfo flex">
            <ul className="instructions">
                {numberOfParagraphs === 0 ? (
                    <li> This section contains <strong> {numberToWords.toWords(numberOfQuestions).toUpperCase()} ({numberOfQuestions})</strong> questions.</li>) :
                    (<li> This section contains <strong> {numberToWords.toWords(numberOfParagraphs).toUpperCase()} ({numberOfParagraphs})</strong> paragraph(s) consisting of <strong> {numberToWords.toWords(numberOfQuestions).toUpperCase()} ({numberOfQuestions})</strong> question(s) in total.</li>)
                }
                {renderSubtypeLines()}
                <li> Answer to each question will be evaluated according to folllowing marking scheme:</li>
            </ul>

            <div className="testMarksList">
                <div className="numbersInput flex">
                    <label htmlFor="fullMarks">Full Marks</label>
                    : {testSection.full_marks} ONLY {renderFullmarksIfLine()}
                </div>
                {subType === 'multi choice' && testSection.partial_marks !== 0 && (
                    <>
                        <div className="numbersInput flex">
                            <label htmlFor="partialMarks1">Partial Marks</label>
                            : {testSection.partial_marks * 3} If all the four options are correct but <strong>ONLY</strong> three options are chosen
                        </div>

                        <div className="numbersInput flex">
                            <label htmlFor="partialMarks2">Partial Marks</label>
                            : {testSection.partial_marks * 2} If three or more options are correct but <strong>ONLY</strong> two options are chosen, both of which are correct
                        </div>

                        <div className="numbersInput flex">
                            <label htmlFor="partialMarks3">Partial Marks</label>
                            : {testSection.partial_marks} If two ore more options are correct but <b>ONLY</b> one option is chosen and it is a correct option
                        </div>
                    </>
                )}

                < div className="numbersInput flex">
                    <label htmlFor="zeroMarks">Zero Marks</label>
                    : 0 {renderZeroMarksLine()}
                </div>
                {testSection.negative_marks !== 0 && (
                    <div className="numbersInput flex">
                        <label htmlFor="negativeMarks">Negative Marks</label>
                        : {testSection.negative_marks} in all other cases.
                    </div>)}

            </div>
        </div >
    )
}

export default TestSectionMarkingSchemeRender