import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import '../../questions.css'

import ImagePreview from '../../../Common/ImagePreview'
import ImageUpload from '../../../Common/ImageUpload'
import { QuestionFormContext } from '../QuestionFormContext';
import SelectionDropdown from '../../../Common/SelectionDropdown';
import { securedPostQuestion, QUESTION_DIFFICULTIES, mapToAnswerTextQuestionCreateFormat, mapToNumericalQuestionCreateFormat, securedUpdateQuestion } from '../../../../services/QuestionService';
import { ADMIN_USER_TYPE } from "../../../../services/UserService";
import { navigateCallbackOptions } from "../../../../services/AuthenticationService";

import { BsCloudUpload } from 'react-icons/bs'
import LoadingPage from "../../../Common/LoadingPage";
import { replaceImagesInObject, roundFloat } from "../../../../services/CommonService";

const QuestionTypeSubjective = ({ id, type = 'subjective', inputQuestionData = { id: null }, isImageBased = false, hasSubmit, topics, tags, overallDifficulty, onRequestClose = null, onAddQuestion = null, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {
    const { dispatch } = useContext(QuestionFormContext);
    const [questionImages, setQuestionImages] = useState([]);
    const [solutionImages, setSolutionImages] = useState([]);
    const [answer, setAnswer] = useState(type === 'subjective' ? { value: '' } : { min_value: '', max_value: '' });
    const [question, setQuestion] = useState("");
    const [difficulty, setDifficulty] = useState(overallDifficulty ? overallDifficulty : QUESTION_DIFFICULTIES[0]);
    const [solution, setSolution] = useState("");
    const [questionImageFileNames, setQuestionImageFileNames] = useState([]);
    const [solutionImageFileNames, setSolutionImageFileNames] = useState([]);
    const navigate = useNavigate();

    const resetState = () => {
        setQuestionImages([]);
        setSolutionImages([]);
        setAnswer({ value: '' });
        setQuestion("");
        setSolution("");
    }

    const makeQuestionData = () => {
        return {
            id: inputQuestionData.id,
            question: !isImageBased ? question : '',
            questionImages,
            answer: type === 'subjective' ? answer : { ...answer, min_value: roundFloat(parseFloat(answer.min_value), 2), max_value: (answer.max_value != null && answer.max_value.length > 0) ? roundFloat(parseFloat(answer.max_value), 2) : roundFloat(parseFloat(answer.min_value), 2) },
            solution: !isImageBased ? solution : '',
            solutionImages,
            topics,
            tags,
            difficulty: difficulty ? difficulty.id : QUESTION_DIFFICULTIES[0].id
        }
    }
    useEffect(() => {
        if (!difficulty) {
            setDifficulty(QUESTION_DIFFICULTIES[0]);
        }
        dispatch({
            type: 'UPDATE_QUESTION',
            payload: {
                id,
                type: type,
                data: makeQuestionData()
            }
        });
    }, [question, questionImages, answer, solution, solutionImages, topics, tags, difficulty, isImageBased]);

    useEffect(() => {
        if (inputQuestionData.id === null) {
            return;
        }
        setQuestion(inputQuestionData.question_text);
        if (inputQuestionData.question_subtype === 'subjective') {
            setAnswer({ id: inputQuestionData.answers[0].answer_id, question_answer_id: inputQuestionData.answers[0].id, value: inputQuestionData.answers[0].answer.answer_text });
        }
        else {
            setAnswer({ id: inputQuestionData.answers[0].answer_id, question_answer_id: inputQuestionData.answers[0].id, min_value: inputQuestionData.answers[0].answer.min_value, max_value: inputQuestionData.answers[0].answer.max_value });
        }

        setSolution(inputQuestionData.solution ? inputQuestionData.solution.solution_text : '');
        (async () => {
            setQuestionImages((await replaceImagesInObject(inputQuestionData.images)).map(image => image.image.url), navigate);
            setSolutionImages(inputQuestionData.solution ? (await replaceImagesInObject(inputQuestionData.solution.images, navigate)).map(image => image.image.url) : []);
        })();
        setDifficulty(QUESTION_DIFFICULTIES.find(questionDifficulty => questionDifficulty.id === inputQuestionData.question_difficulty));
    }, [inputQuestionData.id]);


    const onTextChange = (inputText) => (event) => {
        const { value } = event.target;
        inputText(value);
    };

    const onAnswerTextChange = (event) => {
        const answerCopy = { ...answer };
        answerCopy.value = event.target.value;
        setAnswer(answerCopy);
    }

    const onMinAnswerChange = (event) => {
        setAnswer(answer => ({ ...answer, min_value: event.target.value }));
    }

    const onMaxAnswerChange = (event) => {
        setAnswer(answer => ({ ...answer, max_value: event.target.value }));
    }

    // const onDifficultyChange = (selection) => {
    //     setDifficulty({ id: selection.value, name: selection.label });
    // }

    const handleDifficultyClick = (selection) => {
        setDifficulty({ id: selection.id, name: selection.name });
    }

    const isInternalDataInvalid = () => {
        if (!isImageBased) {
            return !isQuestionDataValid() || question.length === 0 || (type !== 'subjective' && (answer.min_value.length === 0));
        }
        return !isQuestionDataValid() || questionImages.length === 0 || (type !== 'subjective' && (answer.min_value.length === 0));
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);

        const data = makeQuestionData();
        let response;
        if (inputQuestionData.id === null) {
            response = await securedPostQuestion(data, 'standalone', mapToAnswerTextQuestionCreateFormat(type), navigateCallbackOptions(navigate));
        } else {
            response = await securedUpdateQuestion(inputQuestionData.id, data, 'standalone', mapToAnswerTextQuestionCreateFormat(type), navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddQuestion) {
            await onAddQuestion('standalone', inputQuestionData.id !== null ? inputQuestionData.id : response.question_id);
        }
        resetState();
        setIsSaving(false);
        if (onRequestClose != null) {
            onRequestClose();
        }
    }


    return (
        <div className="numerical flex">

            {/* Question Input */}
            {!isImageBased && (
                <div className="questionTextInput">
                    <label className="title">Question</label>
                    <textarea className="text" placeholder="Enter..." onChange={onTextChange(setQuestion)} value={question} rows="4" required />
                </div>)}

            {/* Question Image Input */}
            <ImageUpload title='Question Image(s)' images={questionImages} selectImages={setQuestionImages} label={`question-${id}`} maxSize={isImageBased ? 1 : null} setImageFileNames={setQuestionImageFileNames} />
            <ImagePreview images={questionImages} selectImages={setQuestionImages} imageFileNames={questionImageFileNames} />

            {/* Difficulty Selection */}
            <div className="questionDifficultyInput flex">
                <label className="title">Difficulty</label>

                <div className="difficulty-btns flex">
                    {QUESTION_DIFFICULTIES.map((difficulty_option) => (
                        <button
                            key={difficulty_option}
                            className={`difficulty-btn ${difficulty.id === difficulty_option.id ? 'selected' : ''} flex`}
                            onClick={() => handleDifficultyClick(difficulty_option)}
                        >
                            {difficulty_option.name}
                        </button>
                    ))}
                </div>

            </div>

            {/* <SelectionDropdown className="questionSelectionDropdown" name="Difficulty" itemList={QUESTION_DIFFICULTIES} selectedItem={difficulty} onSelectionChange={onDifficultyChange} nameField='name' valueField='id' required={true} /> */}

            {/* Answer Input */}
            <div className="questionTextInput">
                <label className="title">Answer</label>
                {type !== 'subjective' ?
                    (
                        <>
                            < input className="text" type="number" step="0.01" placeholder="Enter Min Value..." onChange={onMinAnswerChange} value={answer.min_value} rows="1" required />
                            < input className="text" type="number" step="0.01" placeholder="Enter Max Value..." onChange={onMaxAnswerChange} value={answer.max_value} rows="1" />
                        </>
                    ) :
                    <input className="text" type="text" placeholder="Enter..." onChange={onAnswerTextChange} value={answer.value} rows="1" />}

            </div>


            {/* Solution Input */}
            {!isImageBased && (
                <div className="questionTextInput">
                    <label className="title">Solution</label>
                    <textarea className="text" placeholder="Enter..." value={solution} onChange={onTextChange(setSolution)} rows="4" />
                </div>)}

            {/* Solution Image Input */}
            <ImageUpload title='Solution Image(s)' images={solutionImages} selectImages={setSolutionImages} label={`solution-${id}`} maxSize={isImageBased ? 1 : null} setImageFileNames={setSolutionImageFileNames} />
            <ImagePreview images={solutionImages} selectImages={setSolutionImages} imageFileNames={solutionImageFileNames} />

            {hasSubmit && (
                <button className='btn flex' id='submit' onClick={handleSubmit}>
                    SUBMIT <BsCloudUpload className="icon" />
                </button>
            )}
        </div>
    );
};

export default QuestionTypeSubjective;