import React, { useEffect, useState } from 'react';

import "../TestQuestion/testQuestion.css";

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE } from '../../../services/UserService';

import QuestionSlide from '../../Questions/QuestionView/QuestionSlider/QuestionSlide';
import ParagraphSlide from '../../Questions/QuestionView/QuestionSlider/ParagraphSlide';

const TeacherTestQuestionView = ({ questions, testId, testStatus = 'NOT STARTED', showImages = false, showAnswers = false, user = TEACHER_USER_TYPE, testType = null }) => {

    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionData, setQuestionData] = useState(null);

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Minimum distance (in pixels) to consider a swipe gesture
    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null on every new touch start
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () => {
        let index = (questionIndex === 0 ? questions.length - 1 : questionIndex - 1);

        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    }

    const next = () => {
        let index = (questionIndex === questions.length - 1 ? 0 : questionIndex + 1);

        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    }

    useEffect(() => {
        (async () => {
            if (questions.length > 0) {
                setQuestionIndex(0);
                setQuestionData(questions[0].question);
            }
        })();
    }, [questions, testStatus]);

    const renderQuestionSlide = () => {

        if (!questionData) {
            return <></>;
        }

        return <QuestionSlide
            basicInfo={questionData.basicInfo ? questionData.basicInfo : null}
            basicImages={questionData.basicImages ? questionData.basicImages : null}
            questionId={questionData.id}
            inputQuestionData={questionData}
            showImages={true}
            showAnswers={true}
            user={user}
            testId={testId}
            testStatus={testStatus}
            testType={testType}
        />;
    }

    const handleClickId = async (index) => {
        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    };

    return (
        <div className="testQuestionView flex" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

            {renderQuestionSlide()}

            <div className="questionNumber">
                <p>Question {questionIndex + 1}</p>
            </div>

            <div className="questionButtons flex">
                <div className="questionIdxBtn grid">
                    {
                        questions.map((question, index) => (
                            <button className="idxBtn flex"
                                id={(index === questionIndex) ? 'clicked-btn' : 'default-btn'}
                                draggable
                                key={index}
                                onClick={() => handleClickId(index)}
                            >
                                {index + 1}
                            </button>))
                    }
                </div>
            </div>

        </div >
    )
};

export default TeacherTestQuestionView;
