import React from 'react';
import produce from 'immer';

const RadioAnswerInputList = ({ namePrefix = "", answerInputs, setAnswerInputs, selectedAnswer, setSelectedAnswer, required = null, editable = true }) => {
    if (answerInputs.length !== 4) {
        throw new Error("Exactly four answers are required.");
    }

    const onSelectedAnswerChange = (index) => (event) => {
        setSelectedAnswer(index);
    }

    const onAnswerTextChange = (index) => (event) => {
        const newValue = event.target.value;
        setAnswerInputs(produce(draft => {
            draft[index].value = newValue;
        }));
    }

    return (
        <div className="radioInput">
            {answerInputs.map((answerDict, index) => {
                const answer = String.fromCharCode(65 + index);
                return (
                    <div className="radioOption" key={answer}>
                        <input type="radio" name={`${namePrefix}-answer`} value={answer} id={answer} checked={selectedAnswer === index} onChange={onSelectedAnswerChange(index)} required />
                        <label htmlFor={answer}>
                            ({answer})
                            {editable ?
                                <textarea className="optionInput" value={answerDict.value} placeholder={`Answer ${answer}...`} rows="1" onChange={onAnswerTextChange(index)} required={required} /> :
                                <p className="optionInput"></p>
                            }
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default RadioAnswerInputList