import React from 'react'

import 'aos/dist/aos.css'

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import BatchManage from '../../Components/Batches/BatchManage';
import bkgImage from '../../Assets/Batch.png'

import '../../Components/Batches/batches.css'

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';

const AdminBatchesManage = () => {
    return (
        <>
            <Navbar navItems={AdminNavItems} />
            <BatchManage user={ADMIN_USER_TYPE} />
            <Footer />

        </>
    )
}

export default AdminBatchesManage;
