import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

import './User.css'

import BaseRegisterForm from '../Authentication/BaseRegisterForm';
import GradeBatchSelection from '../Batches/GradeBatchSelection';
import SelectionDropdown from '../Common/SelectionDropdown';
import MultiBatchGradeSelection from "../Batches/MultiBatchGradeSelection";
import MemberRegisterForm from "./MemberRegisterForm";
import SelectionDropdownMultiple from "../Common/SelectionDropdownMultiple";

import { ADMIN_USER_TYPE, securedFetchUsers, securedUpdateUserDataById, securedDeactivateUserById } from "../../services/UserService";
import { UnauthorizedError } from "../../services/Errors";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade } from "../../services/BatchService";

import { BiFilterAlt } from 'react-icons/bi'
import { AiOutlineSave, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { MdAddCircleOutline } from "react-icons/md";
import Spinner from "../Common/Tailwind/Spinner";


const StudentList = ({ user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [deletePopupStudentId, setDeletePopupStudentId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [selectedBatchesToEdit, setSelectedBatchesToEdit] = useState([]);
    const [batchesToEdit, setBatchesToEdit] = useState([]);
    const [students, setStudents] = useState(null);
    const [selectedStudentId, setSelectedStudentId] = useState();
    const [isCreateUserOpen, setCreateUserOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const closeDeletePopup = () => {
        setDeletePopupStudentId(null);
    };

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    const makeFilter = () => {
        const filter = {};
        if (selectedBatches.length > 0) {
            filter['batch_ids'] = selectedBatches.map(batch => (batch.id));
        }
        else {
            filter['batch_ids'] = batches.map(batch => (batch.id));
        }
        return filter;
    }

    const toggleEdit = async (student) => {
        const probableBatches = await securedFetchBatchesByFilter({}, navigateCallbackOptions(navigate));
        const currentBatchIds = student.batches.map(batch => batch.batch_id);
        setEditMode(!editMode);
        setBatchesToEdit(probableBatches.data);
        setSelectedBatchesToEdit(probableBatches.data.filter(batch => currentBatchIds.includes(batch.id)));
        setSelectedStudentId(student.id);
        setFullName(student.user_data.first_name);
        setEmail(student.user_data.email);
        setContactNumber(student.user_data.phone_number);
    }

    const handleEdit = async (studentId) => {
        const user_data = {
            email: email,
            first_name: fullName,
            phone_number: contactNumber
        }
        const newBatches = selectedBatchesToEdit.map(batch => ({ batch_id: batch.id }));
        const data = {
            batches: newBatches,
            user_data: user_data
        };
        const response = await securedUpdateUserDataById('student', studentId, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const studentsResponse = await securedFetchUsers('students', makeFilter(), navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            return;
        }
        const studentsWithCurrentBatches = studentsResponse.data.map(student => ({ ...student, batches: student.batches.filter(batch => batch.end_time === null) }));
        setStudents(studentsWithCurrentBatches);
        setEditMode(!editMode);
        setSelectedStudentId(null);
    }

    const handleDelete = async (studentId) => {
        const response = await securedDeactivateUserById('student', studentId, navigateCallbackOptions(navigate));
        const newStudentList = students.map(student => {
            if (student.id === studentId) {
                return { ...student, user_data: { ...student.user_data, is_active: false } };
            }
            return student;
        })
        setStudents(newStudentList);
        closeDeletePopup();
    }

    const onSelectedBatchToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedBatchesFromSelect = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(selectedBatchesFromSelect);
    };

    const onSelectedBatchToEditToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newBatchesToEdit = batchesToEdit.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatchesToEdit(newBatchesToEdit);
    };

    const onSubmitFilter = async (externalFilter = null) => {
        setIsLoading(true);
        const filter = externalFilter ? externalFilter : makeFilter();
        const studentsResponse = await securedFetchUsers('students', filter, navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            setIsLoading(false);
            return false;
        }
        const studentsWithCurrentBatches = studentsResponse.data.map(student => ({ ...student, batches: student.batches.filter(batch => batch.end_time === null) }));
        setStudents(studentsWithCurrentBatches);
        setIsLoading(false);
        return true;
    }


    const onStudentCreate = async () => {
        const success = await onSubmitFilter();
        if (!success) {
            return;
        }
        const studentsResponse = await securedFetchUsers('students', makeFilter(), navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            return;
        }
        setStudents(studentsResponse.data);
        setCreateUserOpen(false);
    }

    useEffect(() => {
        (async () => {
            await onSubmitFilter({});
        })();
    }, []);


    const renderHeader = () => {
        if (students === null) {
            return;
        }
        return (
            <div className="membersFilter">
                <MultiBatchGradeSelection
                    grades={grades} batches={batches}
                    setGrades={setGrades} setBatches={setBatches}
                    selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                    selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches}
                    onSelectedBatchesToggle={onSelectedBatchToggle} />

                <button className="btn flex" onClick={async () => { await onSubmitFilter() }}>Filter <BiFilterAlt className="icon" /> </button>

                <button className="create-btn flex"
                    onClick={() => setCreateUserOpen(true)}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Create New Student"
                    data-tooltip-place="right">
                    <MdAddCircleOutline className="icon" />
                </button>

                <MemberRegisterForm isOpen={isCreateUserOpen} onRequestClose={() => (setCreateUserOpen(false))} onMemberCreate={onStudentCreate} type={{ id: 'student', name: 'Student' }} />

                <Tooltip id="tooltip" />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="memberList flex">
                {renderHeader()}
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <div className="memberList flex">
                {renderHeader()}
                {students !== null && (<div className="membersTable flex">
                    <table className="table-style">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Batch</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>

                        <tbody>
                            {students.length > 0 && students.map(student => (
                                <tr key={student.id}>
                                    <td>
                                        {editMode && student.id === selectedStudentId ?
                                            (<input type="fullName" name="fullName" value={fullName} onChange={onTextChange(setFullName)} placeholder={student.user_data.first_name} />)
                                            :
                                            (<> {student.user_data.first_name} </>)
                                        }
                                    </td>
                                    <td>
                                        {editMode && student.id === selectedStudentId ?
                                            (<SelectionDropdownMultiple className="batchSelectionDropdown" name='' onSelectionToggle={onSelectedBatchToEditToggle} selectedItems={selectedBatchesToEdit} itemList={batchesToEdit} nameField='batch_name' valueField='id' />)
                                            :
                                            (<> {student.batches.map(batch => batch.batch.batch_name).join(', ')} </>)
                                        }
                                    </td>
                                    <td>
                                        {editMode && student.id === selectedStudentId ?
                                            (<input type="email" name="email" value={email} onChange={onTextChange(setEmail)} placeholder={student.user_data.email} />)
                                            :
                                            (<> {student.user_data.email} </>)
                                        }
                                    </td>
                                    <td>
                                        {editMode && student.id === selectedStudentId ?
                                            (< input type="contactNumber" name="contactNumber" value={contactNumber} onChange={onTextChange(setContactNumber)} placeholder={student.user_data.phone_number} />)
                                            :
                                            (<> {student.user_data.phone_number} </>)
                                        }
                                    </td>
                                    <td>{student.user_data.is_active ? 'Active' : 'Deactivated'}</td>
                                    <td>
                                        {!student.user_data.is_active ? (<></>) :
                                            editMode && student.id === selectedStudentId ?
                                                (<AiOutlineSave className="icon" onClick={() => handleEdit(student.id)} />)

                                                :
                                                (<AiOutlineEdit className="icon" onClick={() => toggleEdit(student)} />)
                                        }
                                    </td>
                                    <td>
                                        {((editMode && student.id === selectedStudentId) || !student.user_data.is_active) ?
                                            (<></>)
                                            :
                                            (<>
                                                <AiOutlineDelete className="icon" onClick={() => setDeletePopupStudentId(student.id)} />
                                                {deletePopupStudentId === student.id && (
                                                    <div className="deletePopup flex">
                                                        <p className="msg">Are you sure to DEACTIVATE Student?</p>
                                                        <div className="buttons flex">
                                                            <button className="btn flex" onClick={() => handleDelete(student.id)}>Yes</button>
                                                            <button className="btn flex" onClick={closeDeletePopup}>Cancel</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>)
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}


            </div >

        </>
    )
};

export default StudentList;
