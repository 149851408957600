import React from 'react';

import { RiDeleteBin5Line } from 'react-icons/ri'

import './Video.css';


const VideoPreview = ({ videos, setVideos, videoFileNames }) => {

    const onDelete = (video) => (event) => {
        setVideos(videos.filter((e) => e !== video));
    }
    return (
        <div className="previewVideosContainer flex" value={videos} >
            {videos &&
                videos.map((video, Idx) => {
                    return (
                        <div key={video} className="previewVideos flex">
                            <button className="delete-btn" onClick={onDelete(video)}> <RiDeleteBin5Line className="icon" /> </button>
                            {videoFileNames.length > 0 ? (
                                <p>{Idx + 1} {videoFileNames[Idx].name}</p>
                            ) : (
                                <p>{Idx + 1}</p>
                            )}

                        </div>
                    );
                })}
        </div>
    )
}

export default VideoPreview;
