import React, { useState, useEffect } from 'react';
import { durationStringToSeconds } from '../../../services/DateService';

import { GoDotFill } from "react-icons/go";
import './Video.css';
import ProgressBars from '../../Common/Tailwind/ProgressBars/ProgressBars';

const ChaptersTable = ({ segments, playerRef }) => {

    const handleChapterClick = (startTime) => {
        if (playerRef && playerRef.current && playerRef.current.player_) {
            const startTimeInSeconds = durationStringToSeconds(startTime);
            if (startTimeInSeconds > playerRef.current.duration()) {
                return;
            }
            playerRef.current.player_.currentTime(durationStringToSeconds(startTime));
            playerRef.current.player_.play(); // Optionally play the video after seeking
        }
    };

    return (
        <div className="w-full overflow-x-auto scrollbar-hide">
            <ProgressBars steps={segments} handleStepClick={handleChapterClick} />
        </div >
    );
};

export default ChaptersTable;
