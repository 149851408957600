import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import '../../Components/Batches/batches.css'

import { TEACHER_USER_TYPE } from '../../services/UserService';
import BatchView from '../../Components/Batches/BatchView';
import { TeacherNavItems } from './TeacherNavItems';

const TeacherTimetable = () => {
    return (
        <>
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            <BatchView user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </>
    )
}

export default TeacherTimetable;
