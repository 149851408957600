import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';


import './Test.css'

import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle, AiFillDelete, AiFillExclamationCircle, AiFillPlusCircle, AiOutlineDelete, AiOutlineExclamationCircle, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import AutoTestSubjectCreate from './TestSubject/AutoTestSubjectCreate';
import AutoTestSectionsCreate from './TestSubject/AutoTestSectionsCreate';
import { MdAddCircleOutline } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { securedFetchSubjects } from '../../services/SyllabusService';


const AutoTestCreate = ({ sectionsData, setSectionsData, subjectsData, setSubjectsData, isEntireSyllabus, subjects, setSubjects, selectedSubjects, selectedMainTopics, selectedTopics }) => {

    const [allowedSubjects, setAllowedSubjects] = useState([]);
    const [activeSubjectId, setActiveSubjectId] = useState(subjectsData.length === 0 ? null : subjectsData[0].subject_id);
    const [isTestSubjectCreateOpen, setTestSubjectCreateOpen] = useState(false);
    const navigate = useNavigate();

    const tabsRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (subjects.length === 0) {
                const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
                if (subjectsResponse === null) {
                    return;
                }
                setSubjects(subjectsResponse.data);
            }
        })();
    }, []);

    useEffect(() => {
        const includedSubjectIds = getIncludedSubjectIds();
        const usedSubjectIds = subjectsData.map(data => data.subject_id);
        setAllowedSubjects(subjects.filter(subject => (includedSubjectIds.includes(subject.id) && !usedSubjectIds.includes(subject.id))));
    }, [subjects, selectedSubjects, selectedMainTopics, selectedTopics, subjectsData, isEntireSyllabus]);

    const getAllowedSubjectData = (data) => {
        return getIncludedSubjectIds().includes(data.subject_id);
    }

    const getIncludedSubjectIds = () => {
        if (isEntireSyllabus) {
            return subjects.map(subject => subject.id);
        }
        if (selectedSubjects.length === 0 && selectedTopics.length === 0 && selectedMainTopics.length === 0) {
            return subjects.map(subject => subject.id);
        }
        if (selectedTopics.length > 0 || selectedMainTopics.length > 0) {
            return [...selectedTopics, ...selectedMainTopics].map(topic => topic.subject_id);
        }
        return selectedSubjects.map(subject => subject.id);
    }

    const handleTabClick = (e, subjectId) => {
        e.preventDefault();
        setActiveSubjectId(subjectsData.find(data => data.subject_id === subjectId)?.subject_id || null);
    }

    const onAddSubject = (subject) => {
        setSubjectsData(prevSubjectsData => [...prevSubjectsData, { subject_id: subject.id, valid: true }])
        setActiveSubjectId(subject.id);
    }

    const onDeleteSubject = (subjectId) => {

        setSubjectsData(prevSubjectsData => prevSubjectsData.filter(data => data.subject_id !== subjectId));
        setSectionsData(prevSectionsData => prevSectionsData.filter(data => data.subject_id !== subjectId));
        if (activeSubjectId === subjectId) {
            setActiveSubjectId(null);
        }
    }

    const scrollTabs = (e, direction) => {
        e.preventDefault();
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const renderSubject = () => {
        if (activeSubjectId === null) {
            return (
                <div className="testSubjectCreate flex">
                    <h3 className="subjectTitle">Add Subject</h3>
                </div>
            );
        }
        return (
            <div className="testSubjectCreate flex">
                <AutoTestSectionsCreate activeSubjectId={activeSubjectId} sectionsData={sectionsData} setSectionsData={setSectionsData} />
            </div>
        )
    }

    return (
        <div className="testEdit">
            <div className="testSubjectCreateTabsContainer flex">
                <button className="left-btn" onClick={(e) => scrollTabs(e, 'left')}>
                    <AiOutlineLeft className="icon" />
                </button>
                <div className="testSubjectTabs flex" draggable ref={tabsRef}>
                    {subjectsData.map((subjectData) => (
                        <button
                            className={(activeSubjectId !== null && activeSubjectId === subjectData.subject_id) ? "tabItem selected" : "tabItem"}
                            key={subjectData.subject_id}
                            onClick={(e) => handleTabClick(e, subjectData.subject_id)}
                        >
                            {subjects.find(subject => subject.id === subjectData.subject_id)?.subject_name}
                            {!getAllowedSubjectData(subjectData) && (<AiFillExclamationCircle
                                className="icon invalid-subject"
                                data-tooltip-id="tooltip"
                                data-tooltip-content={!selectedSubjects.map(subject => subject.id).includes(subjectData.subject_id) ? "Subject not selected" : "No topic chosen"}
                                data-tooltip-place="right"
                            />)}

                            <AiOutlineDelete
                                className="icon delete-subject"
                                onClick={(e) => {
                                    e.stopPropagation(); // Stop click event from bubbling up to the parent button
                                    onDeleteSubject(subjectData.subject_id);
                                }}
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Delete the subject"
                                data-tooltip-place="right"
                            />

                        </button>
                    ))}

                    {(subjectsData.length < 3) && (
                        <>
                            <button className={activeSubjectId === null ? "tabItem selected" : "tabItem"}
                                onClick={(e) => (
                                    e.preventDefault(),
                                    setTestSubjectCreateOpen(true)
                                )} >
                                <MdAddCircleOutline
                                    className="icon add-subject"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content="Add the subject"
                                    data-tooltip-place="right" />
                            </button>
                            <AutoTestSubjectCreate
                                allowedSubjects={allowedSubjects}
                                isOpen={isTestSubjectCreateOpen}
                                onRequestClose={() => setTestSubjectCreateOpen(false)}
                                onAddSubject={onAddSubject} />
                        </>)
                    }

                    <Tooltip id="tooltip" />
                </div >

                <button className="right-btn" onClick={(e) => scrollTabs(e, 'right')}>
                    <AiOutlineRight className="icon" />
                </button>
            </div>

            <div className="testSubjectCreateContentsContainer flex">
                {renderSubject()}
            </div>




        </div>


    )

    // return <h>Hello</h>
}

export default AutoTestCreate;
