import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import './NTA.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';
import { TEST_CREATE_TYPE_PRACTICE } from '../../../services/TestService';

const NTASectionInfo = ({ activeSection, questionsForSection, paragraphsForSection, user = STUDENT_USER_TYPE }) => {


    const {
        testCreateType
    } = useNTATest();

    const [subType, setSubType] = useState('single choice');
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [numberOfParagraphs, setNumberOfParagraphs] = useState(0);

    useEffect(() => {
        setSubType(getQuestionSubtype());
        setNumberOfQuestions(getNumberOfQuestions());
        setNumberOfParagraphs(paragraphsForSection.length);
    }, [questionsForSection, paragraphsForSection])

    const getQuestionSubtype = () => {
        if (questionsForSection.length > 0) {
            return questionsForSection[0].question.question_subtype;
        }
        if (paragraphsForSection.length > 0) {
            return paragraphsForSection[0].paragraph.questions[0].question.question_subtype;
        }
        return null;
    }

    const getNumberOfQuestions = () => {
        if (paragraphsForSection.length > 0) {
            const questionCount = paragraphsForSection.map(testParagraph => testParagraph.paragraph.questions.length);
            return questionCount.reduce((a, v) => a + v, 0);
        }
        if (questionsForSection.length > 0) {
            return questionsForSection.length;
        }
        return 0;
    }

    return (
        <div className="NTASectionInfo roboto-medium flex">
            <div className="NTASectionQuestionType flex">
                <span><strong>Question Type:</strong></span>
                {testCreateType === TEST_CREATE_TYPE_PRACTICE ? <span>Mixed(Practice)</span> : <span>{subType}</span>}
            </div>

            <div className="NTASectionBriefMarkingScheme flex">
                <span>Full Marks: <span className="NTA-marks-correct roboto-bold">{activeSection.full_marks}</span></span>
                <span>Partial Marks: <span className="NTA-marks-partial roboto-bold">{activeSection.partial_marks}</span></span>
                <span>Negative Marks: <span className="NTA-marks-wrong roboto-bold">{activeSection.negative_marks} </span></span>
            </div>
        </div>
    );
}


export default NTASectionInfo;
