import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import TestPage from '../../Components/Test/TestPage';
import SingleTestPage from '../../Components/Test/SingleTestPage';
import { useParams } from 'react-router-dom';

const AdminTest = () => {
    const { testId } = useParams();
    return (
        <>
            <SingleTestPage id={parseInt(testId)} user={ADMIN_USER_TYPE} />
        </>
    )
}

export default AdminTest;