import React, { useRef, useState, useEffect } from 'react';

import './StudentAnalytics.css';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import TopicScoresTreemap from './TopicScoresTreemap';

const physicsTopicScores = {
    type: "node",
    name: "Physics",
    value: 0,
    children: [
        {
            type: "node",
            name: "Mechanics",
            value: 0,
            children: [
                { type: "leaf", name: "Kinematics", value: 90 },
                { type: "leaf", name: "Dynamics", value: 50 },
                { type: "leaf", name: "Statics", value: 10 },
            ],
        },
        {
            type: "node",
            name: "Thermodynamics",
            value: 0,
            children: [
                { type: "leaf", name: "Heat Transfer", value: 78 },
                { type: "leaf", name: "Laws of Thermodynamics", value: 5 },
                { type: "leaf", name: "Entropy", value: 76 },
            ],
        },
        {
            type: "node",
            name: "Electromagnetism",
            value: 0,
            children: [
                { type: "leaf", name: "Electrostatics", value: 18 },
                { type: "leaf", name: "Magnetism", value: 80 },
                { type: "leaf", name: "Maxwell's Equations", value: 83 },
            ],
        },
        {
            type: "node",
            name: "Optics",
            value: 0,
            children: [
                { type: "leaf", name: "Geometric Optics", value: 72 },
                { type: "leaf", name: "Wave Optics", value: 68 },
                { type: "leaf", name: "Optical Instruments", value: 25 },
            ],
        },
        {
            type: "node",
            name: "Modern Physics",
            value: 0,
            children: [
                { type: "leaf", name: "Quantum Mechanics", value: 63 },
                { type: "leaf", name: "Relativity", value: 67 },
                { type: "leaf", name: "Nuclear Physics", value: 64 },
            ],
        },
    ],
};

const mathTopicScores = {
    type: "node",
    name: "Mathematics",
    value: 0,
    children: [
        {
            type: "node",
            name: "Algebra",
            value: 0,
            children: [
                { type: "leaf", name: "Linear Algebra", value: 90 },
                { type: "leaf", name: "Abstract Algebra", value: 88 },
                { type: "leaf", name: "Polynomials", value: 95 },
            ],
        },
        {
            type: "node",
            name: "Calculus",
            value: 0,
            children: [
                { type: "leaf", name: "Differential Calculus", value: 87 },
                { type: "leaf", name: "Integral Calculus", value: 83 },
                { type: "leaf", name: "Multivariable Calculus", value: 86 },
            ],
        },
        {
            type: "node",
            name: "Geometry",
            value: 0,
            children: [
                { type: "leaf", name: "Euclidean Geometry", value: 80 },
                { type: "leaf", name: "Analytic Geometry", value: 76 },
                { type: "leaf", name: "Non-Euclidean Geometry", value: 77 },
            ],
        },
        {
            type: "node",
            name: "Statistics",
            value: 0,
            children: [
                { type: "leaf", name: "Probability", value: 72 },
                { type: "leaf", name: "Inferential Statistics", value: 68 },
                { type: "leaf", name: "Regression Analysis", value: 71 },
            ],
        },
        {
            type: "node",
            name: "Number Theory",
            value: 0,
            children: [
                { type: "leaf", name: "Prime Numbers", value: 77 },
                { type: "leaf", name: "Cryptography", value: 30 },
                { type: "leaf", name: "Diophantine Equations", value: 56 },
            ],
        },
    ],
};

const chemistryTopicScores = {
    type: "node",
    name: "Chemistry",
    value: 0,
    children: [
        {
            type: "node",
            name: "Organic Chemistry",
            value: 0,
            children: [
                { type: "leaf", name: "Hydrocarbons", value: 92 },
                { type: "leaf", name: "Alcohols", value: 88 },
                { type: "leaf", name: "Amines", value: 90 },
            ],
        },
        {
            type: "node",
            name: "Inorganic Chemistry",
            value: 0,
            children: [
                { type: "leaf", name: "Coordination Compounds", value: 60 },
                { type: "leaf", name: "Metallurgy", value: 68 },
                { type: "leaf", name: "P-Block Elements", value: 62 },
            ],
        },
        {
            type: "node",
            name: "Physical Chemistry",
            value: 0,
            children: [
                { type: "leaf", name: "Thermodynamics", value: 78 },
                { type: "leaf", name: "Electrochemistry", value: 85 },
                { type: "leaf", name: "Chemical Kinetics", value: 76 },
            ],
        },
        {
            type: "node",
            name: "Analytical Chemistry",
            value: 0,
            children: [
                { type: "leaf", name: "Qualitative Analysis", value: 72 },
                { type: "leaf", name: "Quantitative Analysis", value: 78 },
                { type: "leaf", name: "Chromatography", value: 77 },
            ],
        },
        {
            type: "node",
            name: "Biochemistry",
            value: 0,
            children: [
                { type: "leaf", name: "Proteins", value: 5 },
                { type: "leaf", name: "Carbohydrates", value: 62 },
                { type: "leaf", name: "Enzymes", value: 90 },
            ],
        },
    ],
};

const subjectScoresArray = [
    { "subject_name": "Physics", "subject_topics_score": physicsTopicScores },
    { "subject_name": "Mathematics", "subject_topics_score": mathTopicScores },
    { "subject_name": "Chemistry", "subject_topics_score": chemistryTopicScores }
];

const StudentAnalyticsbyTopcis = ({ user = STUDENT_USER_TYPE }) => {

    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const subjectColors = {
        physics: '#003366',
        chemistry: '#4CAF50',
        mathematics: '#800000'
    };

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: window.innerHeight * 0.8 // Set height to 80% of viewport height
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className="analytics-chart-container">

            {subjectScoresArray.map((subjectData, index) => (
                <div key={index}
                    className="analytics-chart-section"
                    ref={containerRef}>
                    <h3 className="analytics-chart-section-title">{subjectData.subject_name}</h3>

                    <TopicScoresTreemap data={subjectData.subject_topics_score}
                        width={dimensions.width}
                        height={dimensions.height}
                        themeColor={subjectColors[subjectData.subject_name.toLowerCase()]} />

                </div>
            ))}

        </div>
    );
};

export default StudentAnalyticsbyTopcis;