import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';
import Syllabus from '../../Components/Syllabus/Syllabus';

const StudentSyllabus = () => {
    return (
        <>
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Syllabus"
                description="Master entire syllabus with JEETA"
            /> */}
            <Syllabus user={STUDENT_USER_TYPE} />
            <Footer user={STUDENT_USER_TYPE} />
        </>
    )
}

export default StudentSyllabus;