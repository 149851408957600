import React from 'react'

import bkgImage from '../../Assets/Analytics.png'

import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import Analytics from '../../Components/Analysis/Analytics';

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';

const AdminAnalytics = () => {
    return (
        <>
            <Navbar navItems={AdminNavItems} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Analytics"
                description="Unlock the Power of Data for Personalized Coaching!"
            /> */}
            <Analytics />
            <Footer />
        </>
    )
};

export default AdminAnalytics;