import React, { useEffect } from "react";

import './dashboard.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import { STUDENT_USER_TYPE } from "../../services/UserService";
import SlotCard from "../Slots/SlotCard/SlotCard";
import LoadingPage from "../Common/LoadingPage";
import DashboardLectureInfo from "./DashboardLectureInfo";
import StudentQuestionRecommendation from "../Recommendation/StudentQuestionRecommendation";

const StudentDashboardItems = ({ selectedTab, user = STUDENT_USER_TYPE }) => {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    if (selectedTab === null) {
        return;
    }

    return (
        <>
            {selectedTab === 'Lectures' && (
                <DashboardLectureInfo user={user} />
            )}

            {selectedTab === 'JEETA Quest' && (
                <StudentQuestionRecommendation user={user} />
            )}
        </>

    )
}

export default StudentDashboardItems