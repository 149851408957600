import React, { useState } from 'react';

import withEnablePrivacy from './withEnablePrivacy';
import { AuthContext } from './Components/Authentication/AuthContext';
import JeetaApp from './JeetaApp';
import ZoomApp from './ZoomApp';
import styles from './index.css';
import Config from './routes/Home/Config';

const App = () => {

    const [userType, setUserType] = useState(null);
    const [config, setConfig] = useState(null);


    return (
        <AuthContext.Provider value={{ userType, setUserType, config, setConfig }}>
            <div className="App">
                <Config />
                <JeetaApp />
                <ZoomApp />
            </div>
        </AuthContext.Provider>
    )
}

export default withEnablePrivacy(App);