import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import moment from "moment";

import './profile.css'

import PINCode from "../Common/PINCode";
import Feedback from "../Feedback/Feedback";

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, hasAdminRoleInfo, hasBatchInfo, hasSubjectInfo, securedFetchUserById, securedFetchUsers } from "../../services/UserService";
import { navigateCallbackOptions, securedLogoutUser } from "../../services/AuthenticationService";
import { securedFetchSlots } from "../../services/BatchService";
import { securedFetchNextSlot } from "../../services/TimetableService";

import { PiHandWaving } from "react-icons/pi";
import { BiMessageRoundedError } from "react-icons/bi";

import { Transition } from '@headlessui/react'
import { PaperAirplaneIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'


const ProfilePopup = ({ profileItem, user = ADMIN_USER_TYPE, onRequestClose = null, isOpen = false }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [nextClassInfo, setNextClassInfo] = useState({});
    const [openFeedback, setOpenFeedback] = useState(false);
    const [show, setShow] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (userInfo || !isOpen) return;

            const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
            if (fetchedUserInfo === null) {
                return;
            }
            setUserInfo(fetchedUserInfo);
            const endTimeForToday = moment().format('YYYY-MM-DD 23:59:59');
            const fetchedNextClass = await securedFetchNextSlot({ end_time: endTimeForToday }, navigateCallbackOptions(navigate));
            if (fetchedNextClass === null) {
                return;
            }
            setNextClassInfo(fetchedNextClass);
            setOpenFeedback(false);
        })();
    }, [isOpen]);

    const onLogout = async () => {
        const response = await securedLogoutUser(navigateCallbackOptions(navigate));
        onRequestClose();
        if (response === null) {
            return;
        }
        navigate('/');
    }

    const onFeedbackSend = () => {
        onRequestClose();
    }

    const onRequestFeedbackClose = () => {
        setOpenFeedback(false);
    }

    return (
        <>
            {isOpen && userInfo && (
                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
                >

                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition show={show}
                        // enter="transform ease-out duration-300 transition"
                        // enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        // enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        // leave="transition ease-in duration-100"
                        // leaveFrom="opacity-100"
                        // leaveTo="opacity-0"
                        >
                            <div className="pointer-events-auto w-auto max-w-max min-w-[280px] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                                <div className="relative p-4">
                                    <div className="flex items-start">

                                        <div className="mr-3 w-0 flex-1">
                                            <div className="flex justify-center items-center gap-2">
                                                <div className="flex-shrink-0 pt-0.5">
                                                    {userInfo.user_data.photo ? (
                                                        <img
                                                            alt=""
                                                            src={userInfo.user_data.photo}
                                                            className="h-10 w-10 rounded-full"
                                                        />
                                                    ) : (
                                                        <UserCircleIcon className="h-10 w-10 text-[var(--SecondaryBkg)]" />
                                                    )}
                                                </div>
                                                <p className="text-base font-medium text-gray-900">Hi! {userInfo.user_data.first_name}</p>

                                            </div>

                                            {user === TEACHER_USER_TYPE && nextClassInfo.pin && (
                                                <div className="flex">
                                                    <span>Lecture PIN</span>
                                                    <PINCode PIN={nextClassInfo.pin} />
                                                </div>
                                            )}

                                            {/* <p className="mt-1 text-sm text-gray-500">Sent you an invite to connect.</p> */}
                                            <div className="mt-4 ml-2 flex flex-col justify-center items-center gap-2 md:gap-3">

                                                <div className="flex gap-2">
                                                    <Link to={profileItem.url} >
                                                        <button
                                                            type="button"
                                                            onClick={onRequestClose}
                                                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        >
                                                            Profile
                                                        </button>
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        onClick={onLogout}
                                                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    >
                                                        Logout
                                                    </button>
                                                </div>

                                                <button
                                                    type="button"
                                                    onClick={() => { setOpenFeedback(true) }}
                                                    className="inline-flex items-center gap-2 rounded-md bg-[var(--PrimaryBkg)] px-5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[var(--PrimaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Feedback
                                                    <PaperAirplaneIcon className="h-3.5 w-3.5" />
                                                </button>
                                            </div>

                                            <Feedback
                                                user={user}
                                                isOpen={openFeedback}
                                                onRequestClose={onRequestFeedbackClose}
                                                onFeedbackSend={onFeedbackSend}
                                            />
                                        </div>

                                    </div>

                                    <button
                                        type="button"
                                        onClick={onRequestClose}
                                        className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div >
            )}
        </>

    )
};

export default ProfilePopup;
