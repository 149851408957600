import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import './profile.css'

import 'aos/dist/aos.css'

import { ADMIN_USER_TYPE, securedUpdateUserPassword } from "../../services/UserService";
import { navigateCallbackOptions, securedLogoutUser } from "../../services/AuthenticationService";

import { AiFillCloseCircle } from 'react-icons/ai';
import { BsCloudUpload } from 'react-icons/bs'
import { RiLockPasswordLine } from 'react-icons/ri';

Modal.setAppElement('#root');

const ChangePasswordForm = ({ isOpen = false, onRequestClose = null, user = ADMIN_USER_TYPE }) => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const navigate = useNavigate();

    const onTextChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const handleSubmit = async () => {
        const data = {
            old_password: oldPassword,
            password: newPassword
        }
        const updateResponse = await securedUpdateUserPassword(data, navigateCallbackOptions(navigate));
        if (updateResponse === null) {
            return;
        }
        const logoutResponse = await securedLogoutUser(navigateCallbackOptions(navigate));
        if (logoutResponse === null) {
            return;
        }
        onRequestClose();
        navigate('/');
    };

    return (
        <>
            <Modal overlayClassName="customModal" className="profileModal" isOpen={isOpen} onRequestClose={onRequestClose}>
                <div className="register-container flex">
                    <div className="registerTitle flex">
                        <h3 data-aos="fade-down" className="title">
                            Change your password
                        </h3>
                    </div>

                    <div className="inputBox flex">

                        <div className="contactInput flex">
                            <div className="input flex">
                                <label className="inputLabel" htmlFor="oldPassword">Old Password</label>
                                <input type="oldPassword" name="oldPassword" value={oldPassword} onChange={onTextChange(setOldPassword)} placeholder='Old Password' required />
                                <RiLockPasswordLine className="icon" />
                            </div>
                        </div>


                        <div className="contactInput flex">
                            {/* <label className="title" htmlFor="EMAIL">Email</label> */}
                            <div className="input flex">
                                <label className="inputLabel" htmlFor="newPassword">New Password</label>
                                <input type="newPassword" name="newPassword" value={newPassword} onChange={onTextChange(setNewPassword)} placeholder='New Password' required />
                                <RiLockPasswordLine className="icon" />
                            </div>
                        </div>

                        <div className="contactInput flex">
                            {/* <label className="title" htmlFor="CONTACT NUMBER">Contact Number</label> */}
                            <div className="input flex">
                                <label className="inputLabel" htmlFor="confirmNewPassword">Confirm</label>
                                <input type="confirmNewPassword" name="confirmNewPassword" value={confirmNewPassword} onChange={onTextChange(setConfirmNewPassword)} placeholder='Confirm New Password' required />
                                <RiLockPasswordLine className="icon" />
                            </div>
                        </div>
                    </div>

                    <button className='btn flex' id="submit" onClick={handleSubmit} disabled={newPassword !== confirmNewPassword}>
                        SUBMIT <BsCloudUpload className="icon" />
                    </button>

                    <AiFillCloseCircle className="icon" id="close" onClick={onRequestClose} />

                </div>

            </Modal>

        </>
    )
};

export default ChangePasswordForm;
