import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./SyllabusSidebar.css";

import Logo from "../../../Assets/Syllabus.png";

import { SidebarItems } from "./SidebarItems";
import { HiBars2 } from "react-icons/hi2";
import { motion } from "framer-motion";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../../services/UserService";

const SyllabusSidebar = ({ user = STUDENT_USER_TYPE, selectedTab = null, setSelectedTab = null }) => {
    const [expanded, setExpaned] = useState(true);
    const navigate = useNavigate();

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    return (
        <>
            {/* <div className="syllabus-bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
                <HiBars2 />
            </div> */}
            <motion.div className='syllabus-sidebar'
                variants={sidebarVariants}
                animate={window.innerWidth <= 768 ? `${expanded}` : ''}
            >
                {/* logo */}
                <div className="syllabus-logo">
                    <img src={Logo} alt="logo" />
                    <span>
                        S<span>y</span>ll<span>a</span>b<span>u</span>s
                    </span>
                </div>

                <div className="syllabus-menu">
                    {SidebarItems.map((item, index) => {
                        return (
                            <div
                                className={selectedTab === item.heading ? "menuItem active" : "menuItem"}
                                key={index}
                                onClick={() => setSelectedTab(item.heading)}
                            >
                                <item.icon className="icon" />
                                <span>{item.heading}</span>
                            </div>
                        );
                    })}
                </div>
            </motion.div>
        </>
    );
};

export default SyllabusSidebar;