import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import './batches.css'

import BatchTimetableFullCalendar from './BatchTimetableFullCalendar';

import { seureAdminFetchSlotsByBatchBetween, securedFetchSlots } from '../../services/BatchService';
import { ServercError, UnauthorizedError } from '../../services/Errors';
import { getDateString, getEndDateOfNextMonth, getStartDateOfLastMonth } from '../../services/DateService';
import { getEventsListFromTimetable } from '../../services/TimetableService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import HeaderSection from "../Common/Tailwind/PageSections/HeaderSection";


const BatchView = ({ user = STUDENT_USER_TYPE }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [eventsList, setEventsList] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: searchParams.get('startDate') || null,
        endDate: searchParams.get('endDate') || null
    });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            if (!dateRange.startDate || !dateRange.endDate) {
                return;
            }
            const startTime = `${dateRange.startDate} 00:00:00`;
            const endTime = `${dateRange.endDate} 23:59:59`;
            const filter = {
                start_time: startTime,
                end_time: endTime
            }
            const response = await securedFetchSlots(filter, navigateCallbackOptions(navigate));
            if (response !== null) {
                setEventsList(getEventsListFromTimetable(response.data));
            }
        })();
    }, [dateRange]);

    useEffect(() => {
        (async () => {
            const searchStartDate = searchParams.get('startDate');
            const searchEndDate = searchParams.get('endDate');
            if (dateRange.startDate === searchStartDate && dateRange.endDate === searchEndDate) {
                return;
            }
            if (searchStartDate !== dateRange.startDate || searchEndDate !== dateRange.endDate) {
                setDateRange({
                    startDate: searchStartDate,
                    endDate: searchEndDate
                });
            }
        })();
    }, [location]);

    return (

        <div className="page">

            <div className="batchView flex">

                {/* header & title */}
                <div className="batches-header">

                    {user === STUDENT_USER_TYPE ? (
                        <HeaderSection header={"Engage and Learn through Classroom Interaction with JEETA!"} />
                    ) : (
                        <HeaderSection header={"Transform Classrooms via Dynamic Interaction with JEETA!"} />
                    )}


                    {/* <div className="title"> */}
                    {/* <h1>Engage and Motivate Yourself with JEETA!</h1> */}

                    {/* {user === STUDENT_USER_TYPE ? (
                            <h2>Engage and Learn through Classroom Interaction with JEETA!</h2>
                        ) : (
                            <h2>Tranform Classrooms via Dynamic Interaction with JEETA!</h2>
                        )} */}
                    {/* </div> */}
                </div>

                <BatchTimetableFullCalendar eventsList={eventsList} user={user} dateRange={dateRange} setDateRange={setDateRange} />

            </div>
        </div>
    )
}

export default BatchView;