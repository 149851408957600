import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./DashboardSidebar.css";

import AdminLogo from "../../../Assets/AdminProfile.png";
import TeacherLogo from "../../../Assets/Teacher.png";
import StudentLogo from "../../../Assets/Student.png";

import { HiBars2 } from "react-icons/hi2";
import { motion } from "framer-motion";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../../services/UserService";
import { AdminSidebarItems } from "./AdminSidebarItems";
import { TeacherSidebarItems } from "./TeacherSidebarItems";
import { StudentSidebarItems } from "./StudentSidebarItems";

const DashboardSidebar = ({ user = ADMIN_USER_TYPE, selectedTab = null, setSelectedTab = null }) => {
    const [expanded, setExpaned] = useState(true);
    const sidebarItems = user === ADMIN_USER_TYPE ? AdminSidebarItems : user === TEACHER_USER_TYPE ? TeacherSidebarItems : StudentSidebarItems;

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    useEffect(() => {
        if (!selectedTab) {
            setSelectedTab(sidebarItems[0].heading);
        }
    }, []);

    return (
        <>
            {/* <div className="dashboard-bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
                <HiBars2 />
            </div> */}
            <motion.div className='dashboard-sidebar'
                variants={sidebarVariants}
                animate={window.innerWidth <= 768 ? `${expanded}` : ''}
            >
                {/* logo */}
                <div className="dashboard-logo">
                    {user === ADMIN_USER_TYPE && (
                        < img src={AdminLogo} alt="logo" />
                    )}
                    {user === TEACHER_USER_TYPE && (
                        < img src={TeacherLogo} alt="logo" />
                    )}
                    {user === STUDENT_USER_TYPE && (
                        < img src={StudentLogo} alt="logo" />
                    )}

                    <span>
                        D<span>a</span>sh<span>b</span>oa<span>r</span>d
                    </span>
                </div>

                <div className="dashboard-menu">
                    {sidebarItems.map((item, index) => {
                        return (
                            <div
                                className={selectedTab === item.heading ? "menuItem active" : "menuItem"}
                                key={index}
                                onClick={() => setSelectedTab(item.heading)}
                            >
                                <item.icon />
                                <span>{item.heading}</span>
                            </div>
                        );
                    })}
                </div>
            </motion.div>
        </>
    );
};

export default DashboardSidebar;