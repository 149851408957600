import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

import './questionModal.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import QuestionTypeSingleChoice from './QuestionTypes/QuestionTypeSingleChoice';
import QuestionTypeMultiChoice from './QuestionTypes/QuestionTypeMultiChoice';
import QuestionTypeParagraph from './QuestionTypes/QuestionTypeParagraph';
import QuestionTypeMatchTheColumn from './QuestionTypes/QuestionTypeMatchTheColumn';
import QuestionTypeSubjective from './QuestionTypes/QuestionTypeSubjective';
import LoadingPage from '../../Common/LoadingPage';

Modal.setAppElement('#root'); // This is important for accessibility reasons

const QuestionCreateTypeFactory = ({ selectedType, isImageBased, dType, selectedTopics, selectedTags, onRequestClose, onAddQuestion, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {

    useEffect(() => {
    }, [selectedType, isImageBased])

    const renderQuestionCreate = () => {
        switch (selectedType.id) {
            case 'multi choice':
                return <QuestionTypeMultiChoice isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'paragraph':
                return <QuestionTypeParagraph isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'match the column':
                return <QuestionTypeMatchTheColumn topics={selectedTopics} tags={selectedTags} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} />;
            case 'numerical':
                return <QuestionTypeSubjective type='numerical' tags={selectedTags} isImageBased={isImageBased} topics={selectedTopics} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'subjective':
                return <QuestionTypeSubjective isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            default:
                return <QuestionTypeSingleChoice isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
        }
    }
    const renderQuestionType = () => {
        if (!selectedType || !selectedType.id) {
            return;
        }
        return (<>{renderQuestionCreate()}</>)
    }

    return (
        <>
            {renderQuestionType(selectedType)}
        </>

    );
}



export default QuestionCreateTypeFactory;