import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './Alert.css'

import { AiFillCloseCircle, AiOutlineRight } from 'react-icons/ai';
import { ADMIN_USER_TYPE } from '../../services/UserService';


const Alert = ({ isOpen, onRequestClose, className = null, message, user = ADMIN_USER_TYPE, parentId = null }) => {
    return (
        <Modal className={className === null ? `alertModal flex` : className} isOpen={isOpen} onRequestClose={onRequestClose} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <span>{message}</span>
            <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
        </Modal >
    );
}

export default Alert;
