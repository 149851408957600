import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './NTA.css'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoMdClose } from "react-icons/io";

const NTAInstructions = ({ isOpen, onRequestClose, testData, handleInstructionAgree, infoOnly = false, parentId = null, user = ADMIN_USER_TYPE }) => {

    const [isAgreed, setIsAgreed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsAgreed(event.target.checked);
    };

    const handleProceedtoNext = () => {
        if (isAgreed) {
            handleInstructionAgree();
        } else {
            setShowPopup(true);
        }
    }

    if (testData === null) return;

    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs === 0) ? "" : ((hrs < 10 ? "0" : "") + hrs.toString() + (hrs > 1 ? " hours" : " hour"));
        const mins_str = (mins === 0) ? "" : ((mins < 10 ? "0" : "") + mins.toString() + (mins > 1 ? " minutes" : "minute"));

        return hrs_str + ((hrs > 0 && mins > 0) ? " and " : "") + mins_str;
    }


    return (
        <Modal overlayClassName="customModal" className="NTAModal flex" isOpen={isOpen} onRequestClose={onRequestClose} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <div className="NTAInstructions flex">
                <div className="title flex">
                    <h2 className="general roboto-bold">
                        INSTRUCTIONS
                    </h2>
                </div>

                <div className="main flex">
                    <h2 className="caution roboto-bold">
                        Please read the instructions carefully
                    </h2>

                    <ol className="olInstruction roboto-regular">
                        <h3 className="list-title roboto-bold">
                            General Instructions:
                        </h3>

                        <li className="olItem">
                            Total duration of <strong>{testData.test_name}</strong> is <strong>{convertDurationFormat(testData.test_duration_minutes)}</strong>.
                        </li>

                        <li className="olItem">
                            The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.
                        </li>

                        <li className="olItem">
                            The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols:

                            <table className="instruction_area">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="not_visited" title="Not Visited">1</span>
                                        </td>
                                        <td>
                                            You have not visited yet.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="not_answered" title="Not Answered">2</span>
                                        </td>
                                        <td>
                                            You have not answered the question.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="answered" title="Answered">3</span>
                                        </td>
                                        <td>
                                            You have answered the question.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="review" title="Marked for Review">4</span>
                                        </td>
                                        <td>
                                            You have NOT answered the question, but have marked the question for review.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="review_marked_considered" title="Answered & Marked for Review">5</span>
                                        </td>
                                        <td>
                                            The question(s) "Answered and Marked for Review" will be considered for evalution.
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            {/* <ol className="olInstruction-child">
                                <li className="olItem">
                                    <img src={icon1} alt="Unvisited Question" /> You have not visited the question yet.
                                </li>
                                <li className="olItem">
                                    <img src={icon2} alt="Unanswered Question" /> You have not answered the question.
                                </li>
                                <li className="olItem">
                                    <img src={icon3} alt="Answered Question" /> You have answered the question.
                                </li>
                                <li className="olItem">
                                    <img src={icon4} alt="Marked for Review" /> You have NOT answered the question, but have marked the question for review.
                                </li>
                                <li className="olItem">
                                    <img src={icon5} alt="Answered and Marked for Review" /> The question(s) "Answered and Marked for Review" will be considered for evalution.
                                </li>
                            </ol> */}
                        </li>

                        {/* <li className="olItem">
                            You can click on the "&gt;" arrow which apperes to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on "&lt;" which appears on the right side of question window.
                        </li> */}

                        {/* <li className="olItem">
                            You can click on your "Profile" image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.
                        </li> */}

                        {/* <li className="olItem">
                            You can click on <img src={iconDown} alt="Icon Down" className="inline-icon" /> to navigate to the bottom and <img src={iconUp} alt="Icon Up" className="inline-icon" /> to navigate to top of the question are, without scrolling.
                        </li> */}

                        <h3 className="list-title roboto-bold">
                            Navigating to a Question:
                        </h3>

                        <li className="olItem">
                            To answer a question, do the following:
                            <ol className="olInstruction-child" type="a">
                                <li className="olItem">
                                    Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.
                                </li>
                                <li className="olItem">
                                    Click on <strong>Save & Next</strong> to save your answer for the current question and then go to the next question.
                                </li>
                                <li className="olItem">
                                    Click on <strong>Mark for Review & Next</strong> to save your answer for the current question, mark it for review, and then go to the next question.
                                </li>
                            </ol>
                        </li>

                        <h3 className="list-title roboto-bold">
                            Answering a Question:
                        </h3>

                        <li className="olItem">
                            Procedure for answering a multiple choice type question:
                            <ol className="olInstruction-child" type="a">
                                <li className="olItem">
                                    To select you answer, click on the button of one of the options.
                                </li>
                                <li className="olItem">
                                    To deselect your chosen answer, click on the button of the chosen option again or click on the <strong>Clear Response</strong> button
                                </li>
                                <li className="olItem">
                                    To change your chosen answer, click on the button of another option
                                </li>
                                <li className="olItem">
                                    To save your answer, you MUST click on the <strong>Save & Next</strong> button
                                </li>
                                <li className="olItem">
                                    To mark the question for review, click on the <strong>Mark for Review & Next</strong> button
                                </li>
                            </ol>
                        </li>

                        <li className="olItem">
                            To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.
                        </li>

                        <h3 className="list-title roboto-bold">
                            Navigating through sections:
                        </h3>

                        <li className="olItem">
                            Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by click on the section name. The section you are currently viewing is highlighted.
                        </li>

                        <li className="olItem">
                            After click the Save & Next button on the last question for a section, you will automatically be taken to the first question of the next section.                        </li>

                        <li className="olItem">
                            You can shuffle between sections and questions anything during the examination as per your convenience only during the time stipulated.                        </li>

                        <li className="olItem">
                            Candidate can view the corresponding section summary as part of the legend that appears in every section above the question palette.
                        </li>

                    </ol>
                </div>

                <hr></hr>

                <div className="language flex">
                    Please note all questions will appear in English ONLY.
                </div>
                {
                    !infoOnly ? (
                        <>
                            <hr></hr>

                            <div className="agree-input">
                                <input type="checkbox"
                                    id="agreeCheckbox"
                                    checked={isAgreed}
                                    onChange={handleCheckboxChange}
                                /> I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall. I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include a ban from future Tests / Examinations.
                            </div>

                            <button className={`NTA-btn ${isAgreed ? 'agreed' : 'not-agreed'}`} onClick={handleProceedtoNext}>
                                I am ready to begin
                            </button>

                            {showPopup &&
                                <div className='agree-popup flex'>
                                    <button className="NTA-popup-close flex">
                                        <div>
                                            Info
                                        </div>
                                        <div >
                                            Close <IoMdClose className="icon" onClick={() => setShowPopup(false)} />
                                        </div>
                                    </button>
                                    <h3 className="NTA-popup-msg flex"> Please accept terms and conditions before proceeding.</h3>
                                    <button className='NTA-popup-OK flex' onClick={() => setShowPopup(false)}> OK </button>
                                </div>
                            }
                        </>

                    ) : (
                        <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
                    )
                }
            </div >


        </Modal >
    );
}

export default NTAInstructions;
