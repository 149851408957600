import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './footer.css'

import { AiOutlineTwitter, AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { FiSend } from 'react-icons/fi'
import { PiRobot } from 'react-icons/pi'
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService'
import { navigateCallbackOptions } from '../../services/AuthenticationService'
import { securedSubmitFeedback } from '../../services/FeedbackService'

const Footer = ({ user = ADMIN_USER_TYPE }) => {

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const navigate = useNavigate();

  const handleFeedbackSubmitClick = async () => {
    const submitResponse = await securedSubmitFeedback({ message: feedbackMessage }, navigateCallbackOptions(navigate));
    if (submitResponse === null) {
      return;
    }
    alert("Thank you for your valuable feedback!");
    setFeedbackMessage("");
  }

  return (
    <footer className='footer'>

      {/* <div className="footer-title flex" >
        <h2>Dream Big, Score Bigger!</h2>
        <span>JEETEGA by JEETA</span>
      </div> */}

      {/* <footer className="footer-feedback flex">

        <div className="logoDiv flex">
          <PiRobot className="icon" />
          <span>JEETA Values Your Feedback</span>
        </div>

        {/* <div className="footer-question" data-aos="fade-up">
          {user === ADMIN_USER_TYPE &&
            <p>How satisfied are you with integrating JEETA into your coaching?</p>
          }
          {user === TEACHER_USER_TYPE &&
            <p>Can you share any success stories or challenges you've experienced while using JEETA?</p>
          }
          {user === STUDENT_USER_TYPE &&
            <p>How has your learning experience changed since utilizing JEETA?</p>
          }
        </div> */}
      {/* 
      <div className="footer-answer flex">
        <textarea className="answer-input"
          rows="4"
          value={feedbackMessage}
          onChange={(e) => setFeedbackMessage(e.target.value)}
          placeholder='Please share your experience and any suggestions you have for improvements.' />
        <button className='btn flex' type='submit' onClick={handleFeedbackSubmitClick}>
          SEND <FiSend className="icon" />
        </button>
      </div>
    </div> */}

      <div div className="footerDiv flex" >
        <p>India's Next Learning Paradigm</p>
        <p>COPYRIGHTS RESERVED - JEETA 2024</p>
      </div >

    </footer >

  )
}

export default Footer

