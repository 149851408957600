import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import './ReadingMaterialModal.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import TopicCreate from '../../Syllabus/TopicCreate';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedFetchGrades } from '../../../services/BatchService';

import { AiFillCloseCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import TagCreate from '../../Tags/TagCreate';
import { securedFetchTagsByFilter } from '../../../services/TagService';
import LoadingPage from '../../Common/LoadingPage';
import { min } from 'moment';
import { READING_MATERIAL_UPLOAD_TYPES, securedPostReadingMaterial, securedUpdateReadingMaterial, securedUploadPdfList } from '../../../services/ReadingMaterialService';
import PdfUpload from '../../Common/PdfUpload';
import PdfPreview from '../../Common/PdfPreview';
import { securedUploadImagesList } from '../../../services/QuestionService';
import { BsCloudUpload } from 'react-icons/bs';
import Spinner from '../../Common/Tailwind/Spinner';

Modal.setAppElement('#root'); // This is important for accessibility reasons

const ReadingMaterialCreate = ({ isOpen = true, inputReadingMaterialData = { id: null }, onRequestClose = null, onAddReadingMaterial = null, user = ADMIN_USER_TYPE,
    initialSubjects = null, initialSelectedSubject = null, initialGrades = null, initialSelectedGrade = null, initialSelectedTopics = null, initialSelectedTags = null, initialSelectedType = null, allowedTypes = null, hiddenFilters = null }) => {

    const [readingMaterialName, setReadingMaterialName] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [types, setTypes] = useState(allowedTypes ? allowedTypes : READING_MATERIAL_UPLOAD_TYPES);
    const [selectedType, setSelectedType] = useState(allowedTypes ? allowedTypes[0] : READING_MATERIAL_UPLOAD_TYPES[0]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isImageBased, setImageBased] = useState(true);
    const [isTopicCreateModalOpen, setTopicCreateModalOpen] = useState(false);
    const [isTagCreateModalOpen, setTagCreateModalOpen] = useState(false);
    const [pdfs, setPdfs] = useState([]);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();


    const resetFilters = async () => {
        setPdfs([]);
        setPdfFileNames([]);
        setSelectedGrade({});
        setSelectedSubject({});
        const topicsResponse = await fetchAndSetTopics({ subject_ids: subjects.map(subject => subject.id), grades: grades.map(grade => grade.id) });
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse);
        const tagsResponse = await securedFetchTagsByFilter(null, navigateCallbackOptions(navigate));
        if (tagsResponse === null) {
            return;
        }
        setTags(tagsResponse.data);
        setSelectedTopics([]);
        setReadingMaterialName('');
        setSelectedTags([]);
    }


    const makeTopicFilter = (initialFilter) => {
        const filter = {};
        if (selectedSubject && selectedSubject.id) {
            filter['subject_ids'] = [selectedSubject.id];
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        if (selectedGrade && selectedGrade.id) {
            filter['grades'] = [selectedGrade.id];
        } else {
            filter['grades'] = grades.map(subject => subject.id);
        }
        return filter;
    }

    const makeReadingMaterialData = (pdfId) => {
        return {
            id: inputReadingMaterialData.id,
            material_name: readingMaterialName,
            material_type: selectedType.id,
            pdf_id: pdfId,
            topics: selectedTopics.map(selectedTopic => ({ topic_id: selectedTopic.id })),
            tags: selectedTags.map(selectedTag => ({ tag_id: selectedTag.id }))
        }
    }

    const isInternalDataInvalid = () => {
        return selectedTopics.length === 0 || readingMaterialName.length === 0;
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);
        const pdfResponse = await securedUploadPdfList(pdfs, true, navigateCallbackOptions(navigate));
        const pdfId = pdfResponse[0];
        const data = makeReadingMaterialData(pdfId);
        let response;
        if (inputReadingMaterialData.id === null) {
            response = await securedPostReadingMaterial(data, navigateCallbackOptions(navigate));
        } else {
            response = await securedUpdateReadingMaterial(inputReadingMaterialData.id, data, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddReadingMaterial) {
            await onAddReadingMaterial('standalone', inputReadingMaterialData.id !== null ? inputReadingMaterialData.id : response.question_id);
        }
        setIsSaving(false);
        await resetFilters();
        if (onRequestClose != null) {
            onRequestClose();
        }
    }


    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        setSubjects(subjectsResponse.data);
        return subjectsResponse.data;
    };

    const fetchAndSetTopics = async (filter = null) => {
        let topicFilter = filter;
        if (!topicFilter) {
            topicFilter = makeTopicFilter();
        }
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return null;
        }
        setTopics(topicsResponse.data);
        return topicsResponse.data;
    };

    const onTopicCreate = async (newTopicId) => {
        const topicsResponse = await fetchAndSetTopics();
        const topicToAdd = topicsResponse.find(topic => (topic.id === newTopicId));
        if (topicToAdd) {
            setSelectedTopics([...selectedTopics, topicToAdd]);
        }
    }

    const onTagCreate = async (newTagId) => {
        const tagsResponse = await securedFetchTagsByFilter(null);
        const tagsResponseData = tagsResponse.data;
        setTags(tagsResponseData);
        const tagsToAdd = tagsResponseData.find(tag => (tag.id === newTagId));
        if (tagsToAdd) {
            setSelectedTags([...selectedTags, tagsToAdd]);
        }
    }

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
        return gradesResponse.data;
    };

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            setIsLoading(true);
            let subjectsResponse = initialSubjects, gradesResponse = initialGrades;
            if (subjectsResponse && subjectsResponse.length > 0) {
                setSubjects(initialSubjects);
            } else {
                subjectsResponse = await fetchAndSetSubjects();
                if (subjectsResponse === null) {
                    return;
                }
            }
            if (allowedTypes) {
                setTypes(allowedTypes);
                setSelectedType(allowedTypes[0]);
            }
            if (gradesResponse && gradesResponse.length > 0) {
                setGrades(gradesResponse.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
            } else {
                gradesResponse = await fetchAndSetGrades();
                if (gradesResponse === null) {
                    return;
                }
            }

            const gradeIdsForTopics = initialSelectedGrade && initialSelectedGrade.id ? [initialSelectedGrade.id] : ((selectedGrade && selectedGrade.id) ? [selectedGrade.id] : gradesResponse.map(grade => grade.id));
            const subjectIdsForTopics = initialSelectedSubject && initialSelectedSubject.id ? [initialSelectedSubject.id] : ((selectedSubject && selectedSubject.id) ? [selectedSubject.id] : subjectsResponse.map(subject => subject.id));

            const topicsResponse = await fetchAndSetTopics({ subject_ids: subjectIdsForTopics, grades: gradeIdsForTopics });
            if (topicsResponse === null) {
                return;
            }
            setTopics(topicsResponse);
            const tagsResponse = await securedFetchTagsByFilter(null);
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
            if (initialSelectedSubject && initialSelectedSubject.id) {
                setSelectedSubject(initialSelectedSubject);
            }
            if (initialSelectedGrade && initialSelectedGrade.id) {
                setSelectedGrade(initialSelectedGrade);
            }
            const initialSelectedTopicIds = initialSelectedTopics && initialSelectedTopics.length > 0 ? initialSelectedTopics.map(topic => topic.id) : selectedTopics.map(topic => topic.id);
            setSelectedTopics(topicsResponse.filter(topic => initialSelectedTopicIds.includes(topic.id)));

            const initialSelectedTagIds = initialSelectedTags && initialSelectedTags.length > 0 ? initialSelectedTags.map(tag => tag.id) : selectedTags.map(tag => tag.id);
            setSelectedTags(tagsResponse.data.filter(tag => initialSelectedTagIds.includes(tag.id)));
            if (initialSelectedType && initialSelectedType.id) {
                setSelectedType(initialSelectedType);
            }
            setIsLoading(false);
        })();
    }, [isOpen]);

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            const topicFilter = makeTopicFilter();
            topicFilter["subject_ids"] = [subjectId]
            const topicsResponseData = await fetchAndSetTopics(topicFilter);
            if (topicsResponseData === null) {
                return;
            }
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
        setSelectedTopics([]);
    };

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            const topicFilter = makeTopicFilter();
            topicFilter["grades"] = [gradeId]
            const topicsResponse = await fetchAndSetTopics(topicFilter);
            if (topicsResponse === null) {
                return;
            }
            setSelectedGrade({ id: gradeId, grade: gradeId.toString() });
        }
        setSelectedTopics([]);
    };

    const onSelectedTopicToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(selectedTopicsFromSelect);
    };

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onTypeChange = (selection) => {
        setSelectedType({ id: selection.value, name: selection.label });
    };

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const isReadingMaterialDataValid = () => {
        return selectedTopics.length > 0;
    }

    const checkDisabled = (key) => {
        return hiddenFilters && hiddenFilters[key] === 'disable';
    }

    const renderReadingMaterialCreate = () => {
        if (isLoading) {
            return <Spinner />;
        }

        if (isSaving) {
            return <LoadingPage type='save' />;
        }

        return (
            <>
                <h3 className="modalTitle"> Create New Reading Material </h3>

                <form className="readingMaterialContent flex" onSubmit={onSubmit} >
                    <div className="readingMaterialTextInput">
                        <label className="title">Name</label>
                        <textarea className="text" placeholder="Enter..." onChange={(e) => setReadingMaterialName(e.target.value)} value={readingMaterialName} rows="1" required />
                    </div>

                    <div className="readingMaterialInfoInput flex">
                        {(!hiddenFilters || (hiddenFilters.subjects !== 'hide' && hiddenFilters.topics !== 'hide')) && (<SelectionDropdown className="readingMaterialSelectionDropdown" name='Subject' onSelectionChange={onSelectedSubjectChange} itemList={subjects} selectedItem={selectedSubject} nameField='subject_name' valueField='id' isDisabled={checkDisabled('subjects')} />)}
                        {(!hiddenFilters || (hiddenFilters.grades !== 'hide' && hiddenFilters.topics !== 'hide')) && (<SelectionDropdown className="readingMaterialSelectionDropdown" name='Grade' onSelectionChange={onSelectedGradeChange} itemList={grades} selectedItem={selectedGrade} nameField='grade' valueField='id' isDisabled={checkDisabled('grades')} />)}
                        {(!hiddenFilters || (hiddenFilters.types !== 'hide')) && (<SelectionDropdown className="readingMaterialSelectionDropdown" name="Type" itemList={types} selectedItem={selectedType} onSelectionChange={onTypeChange} nameField='name' valueField='id' isDisabled={checkDisabled('types')} />)}
                    </div>
                    {(!hiddenFilters || (hiddenFilters.topics !== 'hide')) && (
                        <div className="readingMaterialTopicInput flex">
                            <SelectionDropdownMultiple className="readingMaterialSelectionDropdownMultiple" name='Topics' itemList={topics} selectedItems={selectedTopics} onSelectionToggle={onSelectedTopicToggle} nameField='topic_name' valueField='id' required isSearchable={true} isDisabled={checkDisabled('topics')} />
                            <button id="add-btn" onClick={() => setTopicCreateModalOpen(true)}><MdAdd className="icon" /> </button>
                        </div>)}

                    {(!hiddenFilters || (hiddenFilters.tags !== 'hide')) && (
                        <div className="readingMaterialTagInput flex">
                            <SelectionDropdownMultiple className="readingMaterialSelectionDropdownMultiple" name='Tags' itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagToggle} nameField='tag_name' valueField='id' isSearchable={true} isDisabled={checkDisabled('tags')} />
                            <button id="add-btn" onClick={() => setTagCreateModalOpen(true)}><MdAdd className="icon" /> </button>
                        </div>)}

                    <PdfUpload title='Pdf' pdfs={pdfs} setPdfs={setPdfs} label={`pdf`} maxSize={1} setPdfFileNames={setPdfFileNames} />
                    <PdfPreview pdfs={pdfs} setImages={setPdfs} pdfFileNames={pdfFileNames} />

                    <button className='btn flex' id='submit' onClick={handleSubmit}>
                        SUBMIT <BsCloudUpload className="icon" />
                    </button>
                </form>

                <TopicCreate isOpen={isTopicCreateModalOpen} onRequestClose={() => setTopicCreateModalOpen(false)} initialSubjects={subjects} initialGrades={grades} initialSubject={selectedSubject} initialGrade={selectedGrade} onTopicCreate={onTopicCreate} user={user} />
                <TagCreate isOpen={isTagCreateModalOpen} onRequestClose={() => setTagCreateModalOpen(false)} allTags={tags} onTagCreate={onTagCreate} user={user} />

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (

        <Modal overlayClassName="customModal" className="readingMaterialModal flex" isOpen={isOpen} onRequestClose={onRequestClose} >
            {renderReadingMaterialCreate()}
        </Modal>

    );
}



export default ReadingMaterialCreate