import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE } from '../../services/UserService';
import Syllabus from '../../Components/Syllabus/Syllabus';
import { AdminNavItems } from './AdminNavItems';

const AdminSyllabus = () => {
    return (
        <>
            <Navbar navItems={AdminNavItems} user={ADMIN_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Syllabus"
                description="Master entire syllabus with JEETA"
            /> */}
            <Syllabus user={ADMIN_USER_TYPE} />
            <Footer />
        </>
    )
}

export default AdminSyllabus;