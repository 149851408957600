import { checkAuthorized, returnJsonResponse, withAdminAuth, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams, urlToFile } from "./CommonService";
import { STUDENT_USER_TYPE } from "./UserService";

export const uploadPdfList = async (pdfList, hmacHash = false) => {
    if (pdfList.length === 0) {
        return [];
    }
    const data = new FormData();
    for (const [i, pdf] of pdfList.entries()) {
        const file = await urlToFile(pdf, `pdf${i}`, 'application/pdf');
        data.append('reading_material_files', file);
    }

    const response = await fetch(`${BACKEND_URL}/pdfs/reading-material/bulk`, {
        method: 'POST',
        body: data,
    });

    await checkAuthorized(response);

    const { pdf_ids } = await response.json();
    return pdf_ids;
};

export const createReadingMaterial = async (data) => {
    const response = await fetch(`${BACKEND_URL}/reading-materials`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};


export const updateReadingMaterial = async (id, data) => {
    const response = await fetch(`${BACKEND_URL}/reading-materials/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
};

export const fetchReadingMaterials = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/reading-materials${params}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchReadingMaterialsByIds = async (ids, extraFilter = null) => {
    if (ids.length === 0) {
        return { data: [] };
    }
    let filter = { ids: ids };
    if (extraFilter) {
        filter = { ...filter, ...extraFilter };
    }
    const response = await fetch(`${BACKEND_URL}/reading-materials/bulk${createParams(filter)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchReadingMaterialById = async (id, filter) => {
    const response = await fetch(`${BACKEND_URL}/reading-materials/${id}${createParams(filter)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createReadingMaterialMarkings = async (data) => {
    const response = await fetch(`${BACKEND_URL}/reading-material-markings/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteReadingMaterialMarking = async (id) => {
    const response = await fetch(`${BACKEND_URL}/reading-material-markings/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}


export const fetchReadingMaterialMarkingsByFilter = async (filter) => {
    const response = await fetch(`${BACKEND_URL}/reading-material-markings${createParams(filter)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}


export const securedUploadPdfList = withAuth(uploadPdfList);
export const securedPostReadingMaterial = withAuth(createReadingMaterial);
export const securedUpdateReadingMaterial = withAuth(updateReadingMaterial);
export const securedFetchReadingMaterials = withAuth(fetchReadingMaterials);
export const securedFetchReadingMaterialsByIds = withAuth(fetchReadingMaterialsByIds);
export const securedFetchReadingMaterialById = withAuth(fetchReadingMaterialById);
export const securedCreateReadingMaterialMarkings = withAuth(createReadingMaterialMarkings);
export const securedDeleteReadingMaterialMarking = withAuth(deleteReadingMaterialMarking);
export const securedFetchReadingMaterialMarkingsByFilter = withAuth(fetchReadingMaterialMarkingsByFilter);



export const hasReadingMaterialCreateAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const READING_MATERIAL_UPLOAD_TYPES = [{ id: "module", name: "Module" }, { id: "book", name: "Book" }, { id: 'note', name: 'Note' }];

