import React from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade, securedFetchGrades } from '../../services/BatchService';

import './batches.css'
import { UnauthorizedError } from '../../services/Errors';
import SelectionDropdown from '../Common/SelectionDropdown';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { updateSearchParams } from '../../services/CommonService';

const GradeBatchSelection = ({ grades, selectedGrade, batches, selectedBatch, setGrades, setSelectedGrade, setBatches, setSelectedBatch, initialGradeId = null, initialBatchId = null, onSelectedBatchChange, user = ADMIN_USER_TYPE, useSearchParamter = false, replaceSearchParams = true }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };


    useEffect(() => {
        (async () => {
            if (initialGradeId === selectedGrade.id && initialBatchId === selectedBatch.id) {
                return;
            }
            const fetchedGrades = await fetchAndSetGrades();
            if (fetchedGrades === null) {
                return;
            }
            const parsedGrades = fetchedGrades.map((item) => ({ id: item.grade, grade_name: String(item.grade) }));
            setGrades(parsedGrades);
            const initialSelectedGrade = parsedGrades.find((grade) => grade.id === initialGradeId) || {};
            setSelectedGrade(initialSelectedGrade);
            if (initialSelectedGrade.id) {
                const batchesResponse = await securedFetchBatchesByFilter({ grades: [initialSelectedGrade.id] }, navigateCallbackOptions(navigate));
                if (batchesResponse === null) {
                    return;
                }
                setBatches(batchesResponse.data);
                const initialSelectedBatch = batchesResponse.data.find(batch => batch.id === initialBatchId) || {};
                if (initialSelectedBatch.id) {
                    await onSelectedBatchChange({ value: initialSelectedBatch.id, label: initialSelectedBatch.batch_name });
                }
            }
        })();
    }, [initialGradeId, initialBatchId]);

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            const batchesResponse = await securedFetchBatchesByFilter({ grades: [gradeId] }, navigateCallbackOptions(navigate));
            if (batchesResponse === null) {
                return;
            }
            setBatches(batchesResponse.data);
            setSelectedBatch({});
        } else {
            setBatches([]);
            setSelectedBatch({});
        }
        setSelectedGrade({ id: gradeId, grade_name: selection.label });
    };

    const onSelectedBatchChangeWrapper = async (selection) => {
        if (useSearchParamter) {
            updateSearchParams(setSearchParams, searchParams, { gradeId: selectedGrade.id, batchId: selection.value }, replaceSearchParams);
        }
        await onSelectedBatchChange(selection);
    }

    return (
        <div className="batchGradeSelectDropdown flex">

            {/* Grade Selection */}
            <SelectionDropdown className="gradeSelectionDropdown" name='Grade' onSelectionChange={onSelectedGradeChange} selectedItem={selectedGrade} itemList={grades} nameField='grade_name' valueField='id' />
            {/* Batch Selection */}
            <SelectionDropdown className="batchSelectionDropdown" name='Batch' onSelectionChange={onSelectedBatchChangeWrapper} selectedItem={selectedBatch} itemList={batches} nameField='batch_name' valueField='id' />

        </div>
    )
};

export default GradeBatchSelection;