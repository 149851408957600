import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';
import { useParams } from 'react-router-dom';
import NTATestView from '../../Components/Test/NTA/NTATestView';

const StudentTest = () => {
    const { testId } = useParams();
    return (
        <>
            <NTATestView id={parseInt(testId)} user={STUDENT_USER_TYPE} />
            {/* <Footer user={STUDENT_USER_TYPE} /> */}
        </>
    )
}

export default StudentTest;