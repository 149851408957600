import React from 'react';

const PINCode = ({ PIN = "000000" }) => {
    if (PIN === null) {
        return;
    }
    return (
        // <div className="PIN-container flex">
        //     <div className="number-box flex">{PIN[0]}</div>
        //     <div className="number-box flex">{PIN[1]}</div>
        //     <div className="number-box flex">{PIN[2]}</div>
        //     <div className="number-box flex">{PIN[3]}</div>
        //     <div className="number-box flex">{PIN[4]}</div>
        //     <div className="number-box flex">{PIN[5]}</div>
        // </div>

        <div className="flex justify-center items-center gap-1 m-2">
            {[0, 1, 2, 3, 4, 5].map((index) => (
                <div key={index}
                    className="w-6 h-8
                        bg-[var(--PrimaryColor)] text-white rounded
                        flex justify-center items-center
                        text-lg font-bold"
                >
                    {PIN[index]}
                </div>
            ))}
        </div>
    );
};

export default PINCode;