// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import * as d3 from 'd3';

// import './StudentAnalytics.css';

// const Treemap = ({ width, height, data, themeColor }) => {
//     const [hoveredLeaf, setHoveredLeaf] = useState(null);
//     const [clickedLeaf, setClickedLeaf] = useState(null);

//     const hierarchy = useMemo(() => {
//         return d3.hierarchy(data).sum((d) => d.value);
//     }, [data]);

//     const root = useMemo(() => {
//         const treeGenerator = d3.treemap().size([width, height]).padding(4);
//         return treeGenerator(hierarchy);
//     }, [hierarchy, width, height]);

//     const colorScale = useMemo(() => {
//         const values = root.leaves().map(leaf => leaf.data.value);
//         return d3.scaleLinear()
//             .domain([0, Math.max(...values)])
//             .range(['#FFFFFF', themeColor]); // Adjust color range as needed
//     }, [root]);

//     const handleMouseEnter = (leaf) => {
//         setHoveredLeaf(leaf);
//     };

//     const handleMouseLeave = () => {
//         setHoveredLeaf(null);
//     };

//     const handleClick = (leaf) => {
//         setClickedLeaf(leaf === clickedLeaf ? null : leaf);
//     };

//     const allShapes = root.leaves().map((leaf, i) => {
//         const color = colorScale(leaf.data.value);
//         const isHovered = leaf === hoveredLeaf;
//         const isClicked = leaf === clickedLeaf;

//         return (
//             <g
//                 key={i}
//                 onMouseEnter={() => handleMouseEnter(leaf)}
//                 onMouseLeave={handleMouseLeave}
//                 onClick={() => handleClick(leaf)}
//             >
//                 <rect
//                     x={leaf.x0}
//                     y={leaf.y0}
//                     width={leaf.x1 - leaf.x0}
//                     height={leaf.y1 - leaf.y0}
//                     stroke={isClicked ? "black" : "transparent"}
//                     strokeWidth={isClicked ? 2 : 0}
//                     fill={color}
//                     opacity={isHovered || isClicked ? 1 : (hoveredLeaf ? 0.3 : 0.8)}
//                     className="transition-all duration-200"
//                 />
//                 <text
//                     x={leaf.x0 + 3}
//                     y={leaf.y0 + 3}
//                     fontSize={12}
//                     textAnchor="start"
//                     alignmentBaseline="hanging"
//                     fill="white"
//                     className="font-bold pointer-events-none"
//                 >
//                     {leaf.data.name}
//                 </text>
//                 <text
//                     x={leaf.x0 + 3}
//                     y={leaf.y0 + 18}
//                     fontSize={12}
//                     textAnchor="start"
//                     alignmentBaseline="hanging"
//                     fill="white"
//                     className="font-light pointer-events-none"
//                 >
//                     {leaf.data.value}
//                 </text>
//             </g>
//         );
//     });

//     return (
//         <svg width={width} height={height} className="treemap-leaf">
//             {allShapes}
//         </svg>
//     );
// };

// const TopicScoresTreemap = ({ data, width, height, themeColor }) => {

//     const containerRef = useRef(null);
//     const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

//     useEffect(() => {
//         if (containerRef.current) {
//             setDimensions({
//                 width: containerRef.current.offsetWidth,
//                 height: containerRef.current.offsetHeight
//             });
//         }
//     }, []);

//     return (
//         <div ref={containerRef} className="treemap-leaf-container">
//             <Treemap
//                 width={width || dimensions.width}
//                 height={height || dimensions.height}
//                 data={data}
//                 themeColor={themeColor}
//             />
//         </div>
//     );

// };

// export default TopicScoresTreemap;
import React, { useRef, useState, useEffect } from 'react';
import Treemap from '../../Common/Treemap';
import './StudentAnalytics.css';

const TopicScoresTreemap = ({ data, width, height, themeColor }) => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight
            });
        }
    }, []);

    const valueAccessor = (d) => d.value;
    const nameAccessor = (d) => d.name;

    return (
        <div ref={containerRef} className="treemap-leaf-container">
            <Treemap
                width={width || dimensions.width}
                height={height || dimensions.height}
                data={data}
                themeColor={themeColor}
                valueAccessor={valueAccessor}
                nameAccessor={nameAccessor}
            />
        </div>
    );
};

export default TopicScoresTreemap;