import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './Feedback.css'

import { AiFillCloseCircle } from 'react-icons/ai'
import { FiSend } from 'react-icons/fi'
import { PiRobot } from 'react-icons/pi'
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService'
import { navigateCallbackOptions } from '../../services/AuthenticationService'
import { securedSubmitFeedback } from '../../services/FeedbackService'
import LoadingPage from '../Common/LoadingPage';
import { EnvelopeIcon, EnvelopeOpenIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Spinner from '../Common/Tailwind/Spinner'

const Feedback = ({ user = ADMIN_USER_TYPE, onRequestClose = null, onFeedbackSend = null, isOpen }) => {

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleFeedbackSubmitClick = async () => {
    setIsSaving(true);
    const submitResponse = await securedSubmitFeedback({ message: feedbackMessage }, navigateCallbackOptions(navigate));
    if (submitResponse === null) {
      setIsSaving(false);
      return;
    }
    setIsSubmitted(true);
    setIsSaving(false);
    // alert("Thank you for your valuable feedback!");
    await new Promise(resolve => setTimeout(resolve, 2000));
    setFeedbackMessage("");
    onFeedbackSend();
    setIsSubmitted(false);
    onRequestClose();
  }

  const renderFeedback = () => {

    if (isSaving) {
      return (
        <div className="m-auto w-full flex h-12 w-12 items-center justify-center">
          <Spinner />
        </div>);
    }
    return (
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <EnvelopeIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <DialogTitle as="h3" className="text-xl font-semibold leading-6 text-[var(--SecondaryColor)]">
            {isSubmitted ? "Thank you for your valuable feedback!" : "JEETA Values Your Feedback"}
          </DialogTitle>

          {isSubmitted ? (<></>) : (
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <formn className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-md font-medium leading-6 text-gray-900">
                    {user === ADMIN_USER_TYPE &&
                      <p>How satisfied are you with integrating JEETA into your coaching?</p>
                    }
                    {user === TEACHER_USER_TYPE &&
                      <p>Can you share any success stories<br />or<br />challenges you've experienced while using JEETA?</p>
                    }
                    {user === STUDENT_USER_TYPE &&
                      <p>How has your learning experience changed <br />since utilizing JEETA?</p>
                    }
                  </label>
                  <div className="mt-2">
                    <textarea
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      rows="5"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                      required
                      placeholder='Please share your experience and any suggestions you have for improvements.' />
                  </div>
                </div>

                <div>
                  <button
                    onClick={handleFeedbackSubmitClick}
                    className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <span>SEND</span>
                    <PaperAirplaneIcon className="h-6 w-6" />
                  </button>
                </div>
              </formn>
            </div>)}

        </div>
      </div>
    )
  }

  return (
    <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={onRequestClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            {renderFeedback()}

          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default Feedback

