import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

import './User.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import SelectionDropdownMultiple from "../Common/SelectionDropdownMultiple";
import MemberRegisterForm from "./MemberRegisterForm";

import { ADMIN_USER_TYPE, securedFetchUsers, securedUpdateUserDataById, securedDeactivateUserById } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchSubjects } from "../../services/SyllabusService";
import { UnauthorizedError } from "../../services/Errors";

import { BiFilterAlt } from 'react-icons/bi'
import { AiOutlineSave, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { MdAddCircleOutline } from "react-icons/md";
import Spinner from "../Common/Tailwind/Spinner";

const TeacherList = ({ user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [deletePopupTeacherId, setDeletePopupTeacherId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [teachers, setTeachers] = useState(null);
    const [selectedSubjectsToEdit, setSelectedSubjectsToEdit] = useState([]);
    const [selectedTeacherId, setSelectedTeacherId] = useState();
    const [isCreateUserOpen, setCreateUserOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const closeDeletePopup = () => {
        setDeletePopupTeacherId(null);
    };

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    const makeFilter = () => {
        const filter = {};
        if (selectedSubjects.length > 0) {
            filter['subject_ids'] = selectedSubjects.map(subject => (subject.id));
        }
        return filter;
    }

    const toggleEdit = async (teacher) => {
        setEditMode(!editMode);
        setSelectedTeacherId(teacher.id);
        setFullName(teacher.user_data.first_name);
        setEmail(teacher.user_data.email);
        setContactNumber(teacher.user_data.phone_number);
    }

    const handleEdit = async (teacherId) => {
        const user_data = {
            email: email,
            first_name: fullName,
            phone_number: contactNumber
        }
        const newSubjects = selectedSubjectsToEdit.map(subject => ({ subject_id: subject.id }));
        const data = {
            subjects: newSubjects,
            user_data: user_data
        };
        const response = await securedUpdateUserDataById('teacher', teacherId, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        await onSubmitFilter();
        setEditMode(!editMode);
        setSelectedTeacherId();
    }

    const onTeacherCreate = async () => {
        await onSubmitFilter();
        setCreateUserOpen(false);
    }

    const handleDelete = async (teacherId) => {
        const response = await securedDeactivateUserById('teacher', teacherId, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const newTeacherList = teachers.map(teacher => {
            if (teacher.id === teacherId) {
                return { ...teacher, user_data: { ...teacher.user_data, is_active: false } };
            }
            return teacher;
        })
        setTeachers(newTeacherList);
        closeDeletePopup();
    }

    const onSelectedSubjectToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
    };

    const onSelectedSubjectToEditChange = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjectsToEdit(selectedSubjectsFromSelect);
    };

    const onSubmitFilter = async () => {
        setIsLoading(true);
        const teachersResponse = await securedFetchUsers('teachers', makeFilter(), navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            setIsLoading(false);
            return false;
        }
        setTeachers(teachersResponse.data);
        setIsLoading(false);
        return true;
    }

    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const success = await onSubmitFilter();
            if (!success) {
                return;
            }
            const fetchedSubjects = await fetchSubjects();
            if (fetchedSubjects === null) {
                return;
            }
            const parsedSubjects = fetchedSubjects.map((item) => ({ id: item.id, subject_name: item.subject_name }))
            setSubjects(parsedSubjects);
        })();
    }, []);

    const renderHeader = () => {
        if (teachers === null) {
            return;
        }
        return (
            <div className="membersFilter flex">
                <SelectionDropdownMultiple className="subjectSelectionDropdown" name="Subjects" itemList={subjects} selectedItems={selectedSubjects} onSelectionToggle={onSelectedSubjectToggle} valueField="id" nameField="subject_name" required={true} />

                <button className="btn flex" onClick={onSubmitFilter}>Filter <BiFilterAlt className="icon" /> </button>

                <button className="create-btn flex"
                    onClick={() => setCreateUserOpen(true)}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Create New Teacher"
                    data-tooltip-place="right">
                    <MdAddCircleOutline className="icon" />
                </button>

                <MemberRegisterForm
                    isOpen={isCreateUserOpen}
                    onRequestClose={() => (setCreateUserOpen(false))}
                    onMemberCreate={onTeacherCreate}
                    type={{ id: 'teacher', name: 'Teacher' }} />

                <Tooltip id="tooltip" />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="memberList flex">
                {renderHeader()}
                <Spinner />
            </div>);
    }

    return (

        <>
            <div className="memberList flex">


                {renderHeader()}
                {teachers !== null && (
                    <div className="membersTable flex">
                        <table className="table-style">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subjects</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>

                            <tbody>
                                {teachers.map(teacher => (
                                    <tr key={teacher.id}>
                                        <td>
                                            {editMode && teacher.id === selectedTeacherId ?
                                                (<input type="fullName" name="fullName" value={fullName} onChange={onTextChange(setFullName)} placeholder={teacher.user_data.first_name} />)
                                                :
                                                (<> {teacher.user_data.first_name} </>)
                                            }
                                        </td>
                                        <td>
                                            {/* {editMode && teacher.id === selectedTeacherId ?
                                            (<SelectionDropdownMultiple className="subjectSelectionDropdown" name='' onSelectionToggle={onSelectedSubjectToEditChange} selectedItems={selectedSubjectsToEdit} itemList={subjects} nameField='subject_name' valueField='id' />)
                                            :
                                            (<>{teacher.subjects.map(subject => subject.subject.subject_name).join(', ')} </>)
                                        } */}
                                            <>{teacher.subjects.map(subject => subject.subject.subject_name).join(', ')} </>
                                        </td>
                                        <td>
                                            {editMode && teacher.id === selectedTeacherId ?
                                                (<input type="email" name="email" value={email} onChange={onTextChange(setEmail)} placeholder={teacher.user_data.email} />)
                                                :
                                                (<> {teacher.user_data.email} </>)
                                            }
                                        </td>
                                        <td>
                                            {editMode && teacher.id === selectedTeacherId ?
                                                (< input type="contactNumber" name="contactNumber" value={contactNumber} onChange={onTextChange(setContactNumber)} placeholder={teacher.user_data.phone_number} />)
                                                :
                                                (<> {teacher.user_data.phone_number} </>)
                                            }
                                        </td>
                                        <td>
                                            {teacher.user_data.is_active ? 'Active' : 'Deactivated'}
                                        </td>
                                        <td>
                                            {!teacher.user_data.is_active ? (<></>) :
                                                editMode && teacher.id === selectedTeacherId ?
                                                    (<AiOutlineSave className="icon" onClick={() => handleEdit(teacher.id)} />)

                                                    :
                                                    (<AiOutlineEdit className="icon" onClick={() => toggleEdit(teacher)} />)
                                            }
                                        </td>
                                        <td>
                                            {((editMode && teacher.id === selectedTeacherId) || !teacher.user_data.is_active) ?
                                                (<></>)
                                                :
                                                (<>
                                                    <AiOutlineDelete className="icon" onClick={() => setDeletePopupTeacherId(teacher.id)} />
                                                    {deletePopupTeacherId === teacher.id && (
                                                        <div className="deletePopup flex">
                                                            <p className="msg">Are you sure to DEACTIVATE Teacher?</p>
                                                            <div className="buttons flex">
                                                                <button className="btn flex" onClick={() => handleDelete(teacher.id)}>Yes</button>
                                                                <button className="btn flex" onClick={closeDeletePopup}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>)
                                            }
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

            </div>

        </>
    )
};

export default TeacherList;
