import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { checkUserType, securedFetchUserById } from '../../services/UserService';

const PrivateRoute = ({ children, allowedRoles }) => {
    const [isLoading, setIsLoading] = useState(true);

    const { userType, setUserType } = useAuth();


    useEffect(() => {
        (async () => {
            if (userType !== null) {
                setIsLoading(false);
                return;
            }
            const errorCallback = {
                authErrorCallback: () => {
                    return;
                }
            }
            const fetchedUserInfo = await securedFetchUserById(errorCallback);
            if (fetchedUserInfo === null) {
                setIsLoading(false);
                return;
            }
            const currentUserType = checkUserType(fetchedUserInfo);
            setUserType(currentUserType);
        })();
    }, [userType]);

    if (isLoading) {
        return;
    }
    return userType && allowedRoles.includes(userType) ? children : <Navigate to="/" />
};

export default PrivateRoute;