import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from "./UserService";

export const createTestSeries = async (data) => {
    const response = await fetch(`${BACKEND_URL}/test-series`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    const result = await response.json();
    return result;
}

export const fetchAllTestSeries = async () => {
    const response = await fetch(`${BACKEND_URL}/test-series`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    const result = await response.json();
    return result;
}

export const createTest = async (testType, data) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    const result = await response.json();
    return result;
}

export const createAutomatedTest = async (testType, data) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/generate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    const result = await response.json();
    return result;
}

export const updateTest = async (testId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deleteTestUsers = async (testId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/users/bulk${createParams(data)}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const createTestUsers = async (testId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/users/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchTests = async (testType, filter) => {
    if (containsEmptyArray(filter)) {
        return { data: [] };
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTestById = async (testType, id) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createTestSubjects = async (testId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createTestSections = async (testId, testSubjectId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTestSections = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/test-sections${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const addTestQuestions = async (type, testId, testSubjectId, testSectionId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}/test-questions/${type}/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const updateTestSubjects = async (testId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateTestSections = async (testId, testSubjectId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateTestSection = async (testId, testSubjectId, testSectionId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateTestQuestions = async (type, testId, testSubjectId, testSectionId, data) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}/test-questions/${type}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deleteTestSubject = async (testId, testSubjectId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deleteTestSection = async (testId, testSubjectId, testSectionId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deleteTestQuestion = async (type, testId, testSubjectId, testSectionId, testQuestionId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}/test-questions/${type}/${testQuestionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchTestSectionById = async (testId, testSubjectId, testSectionId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}/test-sections/${testSectionId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTestSubjectById = async (testId, testSubjectId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/test-subjects/${testSubjectId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchAttemptedTests = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/tests/student-attempted${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const startTest = async (testType, id, data) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${id}/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchRemainingTime = async (testType, id) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${id}/time-remaining`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createTestReport = async (testId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/reports`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTestReport = async (id) => {
    const response = await fetch(`${BACKEND_URL}/tests/${id}/reports`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    try {
        return await returnJsonResponse(response);
    }
    catch (err) {
        return null;
    }
}

export const createStudentTestReport = async (testType, testId) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${testId}/students/reports`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchStudentTestReport = async (testType, id) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${id}/students/reports`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    try {
        return await returnJsonResponse(response);
    }
    catch (err) {
        return null;
    }
}

export const markStudentTestAttempt = async (testId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/students`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}


export const submitStudentTestAttempt = async (testType, testId) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${testId}/students/submit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const fetchStudentTestAttempt = async (testId) => {
    const response = await fetch(`${BACKEND_URL}/tests/${testId}/students`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    try {
        return await returnJsonResponse(response);
    }
    catch (err) {
        return null;
    }
}

export const securedCreateTestSeries = withAuth(createTestSeries);
export const securedFetchAllTestSeries = withAuth(fetchAllTestSeries);
export const securedCreateTest = withAuth(createTest);
export const securedCreateAutomatedTest = withAuth(createAutomatedTest);
export const securedUpdateTest = withAuth(updateTest);
export const securedDeleteTestUsers = withAuth(deleteTestUsers);
export const securedCreateTestUsers = withAuth(createTestUsers);
export const securedFetchTests = withAuth(fetchTests);
export const securedFetchTestById = withAuth(fetchTestById);
export const securedCreateTestSections = withAuth(createTestSections);
export const securedCreateTestSubjects = withAuth(createTestSubjects);
export const securedAddTestQuestions = withAuth(addTestQuestions);
export const securedUpdateTestSubjects = withAuth(updateTestSubjects);
export const securedUpdateTestSections = withAuth(updateTestSections);
export const securedUpdateTestQuestions = withAuth(updateTestQuestions);
export const securedUpdateTestSection = withAuth(updateTestSection);
export const securedDeleteTestSubject = withAuth(deleteTestSubject);
export const securedDeleteTestSection = withAuth(deleteTestSection);
export const securedDeleteTestQuestion = withAuth(deleteTestQuestion);
export const securedFetchTestSubjectById = withAuth(fetchTestSubjectById);
export const securedFetchTestSectionById = withAuth(fetchTestSectionById);
export const securedFetchAttemptedTests = withAuth(fetchAttemptedTests);
export const securedStartTest = withAuth(startTest);
export const securedFetchRemainingTime = withAuth(fetchRemainingTime);
export const securedCreateTestReport = withAuth(createTestReport);
export const securedFetchTestReport = withAuth(fetchTestReport);

export const securedCreateStudentTestReport = withAuth(createStudentTestReport);
export const securedFetchStudentTestReport = withAuth(fetchStudentTestReport);
export const securedMarkStudentTestAttempt = withAuth(markStudentTestAttempt);
export const securedSubmitStudentTestAttempt = withAuth(submitStudentTestAttempt);
export const securedFetchStudentTestAttempt = withAuth(fetchStudentTestAttempt);



export const hasSlotEditAuthority = user => {
    return user === ADMIN_USER_TYPE;
}

export const hasSlotTestEditAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasSlotEndAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasSlotStartAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasSlotDeleteAuthority = (user) => {
    return user === ADMIN_USER_TYPE;
}
export const hasTestCreateAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasTestEditAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const canCreateTestSubject = (testType) => {
    return testType !== 'live' && testType !== 'wrap up';
}

export const canCreateTestSection = (testType) => {
    return testType !== 'live' && testType !== 'wrap up';
}

export const canShowSectionMarks = (testType) => {
    return testType !== 'live' && testType !== 'wrap up';
}

export const areTestQuestionsVisible = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const FULL_TEST_TYPES = [{ id: "phase", name: "Phase Test" }]

export const SLOT_TEST_TYPES = [{ id: "live", name: "Live Quiz" }, { id: "wrap-up", name: "Wrap Up Test" }]

export const TEST_CREATE_TYPE_BASIC = 'basic'
export const TEST_CREATE_TYPE_PRACTICE = 'practice'
export const TEST_CREATE_TYPE_VIRTUAL = 'virtual'

export const getTestTypePathBase = (type) => {
    if (type === TEST_CREATE_TYPE_BASIC) {
        return ''
    }
    return `${type}-`
}