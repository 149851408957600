import { BACKEND_URL } from "./BackendConfig";
import { ServerError, ServerErrorResponse, UnauthorizedError } from "./Errors";

export const loginWithPassword = async (type, credentials) => {
    const formData = {
        user_type: type,
        username: credentials.username,
        password: credentials.password
    }

    const response = await fetch(`${BACKEND_URL}/users/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData)
    });
    return response;
};

export const loginWithRefreshToken = async (type, refreshToken) => {

    const data = {
        refresh_token: refreshToken
    }
    const response = await fetch(`${BACKEND_URL}/users/refresh-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    return response;
};

const onServerErrorAction = (response, serverErrorMessage, showAlert = true, returnError = false) => {
    if (showAlert) {
        alert(serverErrorMessage);
    }
    if (returnError) {
        return response;
    }
}

const onUnauthorizedErrorAction = (response, unauthorizedErrorMessage, showAlert = true, returnError = false) => {
    if (showAlert) {
        alert(unauthorizedErrorMessage);
    }
    if (returnError) {
        return response;
    }
}

export const withAuth = (apiCall) => {
    return async (...args) => {
        const options = args[args.length - 1];
        const authErrorCallback = options?.authErrorCallback || null;
        const serverErrorCallback = options?.serverErrorCallback || null;
        let response = null;
        try {
            response = await apiCall(...args);
        }
        catch (err) {
            if (err instanceof ServerError) {
                if (serverErrorCallback) {
                    serverErrorCallback();
                    return response;
                }
                onServerErrorAction(response, err.message);
                return response;
            }
            if (err instanceof UnauthorizedError) {
                if (authErrorCallback) {
                    authErrorCallback();
                    return null;
                }
                onUnauthorizedErrorAction(response, err.message);
                return null;
            }
        }
        // If the response is okay, return it
        return response;
    };
};


export const checkAuthorized = async (response) => {
    let errorMessage = "";
    if (response.status === 404) {
        return response;
    }
    if (!response.ok) {
        try {
            const errorJsonResponse = await response.json();
            errorMessage += errorJsonResponse.detail;
        } catch {
            errorMessage += "No detail provided";
        }
    }
    if (response.status === 401) {
        throw new UnauthorizedError(`Unauthorized error: ${errorMessage}`);
    }
    else if (!response.ok) {
        throw new ServerError(`Server error: ${errorMessage}`);
    }
    return response;
};


export const navigateCallbackOptions = (navigateObject) => {
    return { authErrorCallback: authErrorCallbackNavigate(navigateObject) };
}

export const authErrorCallbackNavigate = (navigateObject) => async () => {
    alert('User is not authorized, please log in again');
    await securedLogoutUser(false);
    navigateObject('/');
}


export const logoutUser = async (checkAuth = true) => {
    const response = await fetch(`${BACKEND_URL}/users/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    if (!checkAuth) {
        return response;
    }
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const returnJsonResponse = async (response) => {
    if (!response.ok) {
        return response;
    }
    const result = await response.json();
    return result;
}

export const securedLogoutUser = withAuth(logoutUser);




