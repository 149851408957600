import React, { useState, useEffect } from 'react';

import './slotview.css'

import Break from '../../Assets/Break.png';
import QuestionSlider from '../Questions/QuestionView/QuestionSlider/QuestionSlider';

import { STUDENT_USER_TYPE } from '../../services/UserService';
import { areTestQuestionsVisible } from '../../services/TestService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchQuestionsByIds } from '../../services/QuestionService';
import { securedFetchStudentRecommendationQuestions } from '../../services/RecommendationService';
import { securedFetchTImetableSlotQuestionsByFilter } from '../../services/TimetableService';
import Spinner from '../Common/Tailwind/Spinner';
import MessagePage from '../Common/Tailwind/PageSections/MessagePage';


const SlotQuestions = ({ lectureData, user = STUDENT_USER_TYPE }) => {
    const [questions, setQuestions] = useState(null);
    const [questionCountByStrategy, setQuestionCountByStrategy] = useState({});
    const [selectedTab, setSelectedTab] = useState('ai');
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 860);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 860) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }

            if (window.innerWidth < 600) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        // Set the sidebar state based on the initial window width
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        (async () => {
            if (!lectureData.id) {
                return;
            }
            const slotQuestions = await securedFetchTImetableSlotQuestionsByFilter(lectureData.id, 'standalone', {}, navigateCallbackOptions(navigate));
            if (slotQuestions === null) {
                return;
            }
            const slotParagraphs = await securedFetchTImetableSlotQuestionsByFilter(lectureData.id, 'paragraph', {}, navigateCallbackOptions(navigate));
            if (slotParagraphs === null) {
                return;
            }
            const allQuestionIds = slotQuestions.data.map(question => question.question_id);
            const allParagraphIds = slotParagraphs.data.map(paragraph => paragraph.paragraph_id);
            const allQuestionData = await securedFetchQuestionsByIds('standalone', allQuestionIds, null, navigateCallbackOptions(navigate));
            if (allQuestionData === null) {
                return;
            }
            const questionDatas = allQuestionData.data.filter(question => question.question_type === 'standalone').map(question => {
                return { ...slotQuestions.data.find(recommendedQuestion => recommendedQuestion.question_id === question.id), question: question, type: 'standalone' };
            })
            const allParagraphsData = await securedFetchQuestionsByIds('paragraph', allParagraphIds, null, navigateCallbackOptions(navigate));
            if (allParagraphsData === null) {
                return;
            }
            const paragraphDatas = allParagraphsData.data.map(paragraph => {
                return { ...slotParagraphs.data.find(recommendedParagraph => recommendedParagraph.paragraph_id === paragraph.id), question: paragraph, type: 'paragraph' };
            });
            const currentQuestionCountsByStrategy = { ai: slotQuestions.data.length };
            setQuestionCountByStrategy(currentQuestionCountsByStrategy);
            setQuestions([...questionDatas, ...paragraphDatas]);
        })();
    }, [lectureData]);

    const renderQuestionSlider = () => {
        if (questions === null) {
            return <Spinner />;
        }
        const filteredQuestionDatas = questions.filter(question => question.generation_type === selectedTab);
        const filteredQuestions = filteredQuestionDatas.map(question => question.question);
        const filteredQuestionTypes = filteredQuestionDatas.map(question => question.type);
        if (filteredQuestions.length === 0) {
            return (
                // <>
                //     <MessagePage />
                // </>
                <MessagePage imgsrc={Break} title={"NO recommended questions"} message1={"There are"} message2={"from JEETA."} message3={"Review the lecture instead"} />
                // <div className='slotQuestions'>

                //     <div className="title flex">
                //         <h3>
                //             {isTabletView ? <br /> : ' '}
                //             There are no recommended questions from
                //             {isMobileView ? <br /> : ' '}
                //             JEETA.
                //         </h3>
                //         {/* <img className="breakImg" src={Break} alt="img" /> */}
                //         <h3>
                //             Review the lecture instead.
                //         </h3>
                //     </div>
                // </div>
            );
        }
        const filteredQuestionIds = filteredQuestions.map(question => question.id);
        const selectedQuestionId = filteredQuestions[0].id;

        return (
            <div className="slotQuestions">

                <div className="title flex" >
                    <h3>
                        JEETA has prepared
                        {isTabletView ? <br /> : ' '}
                        {questionCountByStrategy[selectedTab] || 0}
                        {isMobileView ? <br /> : ' '}
                        questions!
                    </h3>
                </div>

                <div className="slotQuestionSlider flex">
                    <QuestionSlider questionTypes={filteredQuestionTypes} questionId={selectedQuestionId} showAnswers={true} showImages={true} type={'standalone'} user={user} questionIds={filteredQuestionIds} questionIdIndex={0} />
                </div>
            </div>
        );
    }

    return (
        <>
            {renderQuestionSlider()}
        </>
    );
}

export default SlotQuestions;