import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { motion } from "framer-motion";

import './Syllabus.css'

import SyllabusQuestions from './SyllabusQuestions';
import SyllabusSidebar from './Sidebar/SyllabusSidebar';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import SyllabusSlots from './SyllabusSlots';
import LoadingPage from '../Common/LoadingPage';
import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { securedFetchGrades } from '../../services/BatchService';


const SyllabusFilter = ({ subjects, setSubjects, selectedSubjects, setSelectedSubjects, grades, setGrades, selectedGrades, setSelectedGrades, mainTopics, setMainTopics, selectedMainTopics, setSelectedMainTopics, topics, setTopics, selectedTopics, setSelectedTopics, user = ADMIN_USER_TYPE }) => {

    const navigate = useNavigate();

    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;

        }
        return subjectsResponse.data;
    };

    const fetchGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;

        }
        return gradesResponse.data;
    };

    const onSelectedSubjectChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedSubjects = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(newSelectedSubjects);
        const mainTopicFilter = makeTopicFilter(true);
        if (newSelectedSubjects.length > 0) {
            mainTopicFilter['subject_ids'] = newSelectedSubjects.map(subject => subject.id);
        } else {
            mainTopicFilter['subject_ids'] = subjects.map(subject => subject.id);
        }
        mainTopicFilter['topic_types'] = ['user'];
        const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
        if (mainTopicsResponse === null) {
            return;
        }
        setMainTopics(mainTopicsResponse.data);
        const updateSelectedMainTopics = selectedMainTopics.length > 0 ? selectedMainTopics.filter(mainTopic => mainTopicsResponse.data.find(mainTopicResponse => mainTopicResponse.id === mainTopic.id)) : [];
        setSelectedMainTopics(updateSelectedMainTopics);

        const subTopicFilter = makeTopicFilter();
        subTopicFilter['subject_ids'] = newSelectedSubjects.map(subject => subject.id);
        subTopicFilter['parent_ids'] = mainTopicsResponse.data.map(mainTopic => mainTopic.id);

        const subTopicsResponse = await securedFetchTopicsByFilter(subTopicFilter, navigateCallbackOptions(navigate));
        if (subTopicsResponse === null) {
            return;
        }
        setTopics(subTopicsResponse.data);
        const updateSelectedSubTopics = selectedTopics.length > 0 ? selectedTopics.filter(topic => subTopicsResponse.data.find(topicResponse => topicResponse.id === topic.id)) : [];
        setSelectedTopics(updateSelectedSubTopics);
    };

    const onSelectedGradeChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedGrades = grades.filter(grade => selectedIds.includes(grade.id));
        setSelectedGrades(newSelectedGrades);
        const mainTopicFilter = makeTopicFilter(true);
        if (newSelectedGrades.length > 0) {
            mainTopicFilter['grades'] = newSelectedGrades.map(grade => grade.id);
        } else {
            mainTopicFilter['grades'] = grades.map(grade => grade.id);
        }
        mainTopicFilter['topic_types'] = ['user'];
        const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
        if (mainTopicsResponse === null) {
            return;
        }
        setMainTopics(mainTopicsResponse.data);
        const updateSelectedMainTopics = selectedMainTopics.length > 0 ? selectedMainTopics.filter(mainTopic => mainTopicsResponse.data.find(mainTopicResponse => mainTopicResponse.id === mainTopic.id)) : [];
        setSelectedMainTopics(updateSelectedMainTopics);

        const subTopicFilter = makeTopicFilter();
        subTopicFilter['grades'] = newSelectedGrades.map(grade => grade.id);
        subTopicFilter['parent_ids'] = mainTopicsResponse.data.map(mainTopic => mainTopic.id);

        const subTopicsResponse = await securedFetchTopicsByFilter(subTopicFilter, navigateCallbackOptions(navigate));
        if (subTopicsResponse === null) {
            return;
        }
        setTopics(subTopicsResponse.data);
        const updateSelectedSubTopics = selectedTopics.length > 0 ? selectedTopics.filter(topic => subTopicsResponse.data.find(topicResponse => topicResponse.id === topic.id)) : [];
        setSelectedTopics(updateSelectedSubTopics);
    };

    const onSelectedMainTopicChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedMainTopics = mainTopics.filter(mainTopic => selectedIds.includes(mainTopic.id));
        setSelectedMainTopics(newSelectedMainTopics);
        const subTopicFilter = makeTopicFilter();
        if (newSelectedMainTopics.length > 0) {
            subTopicFilter['parent_ids'] = newSelectedMainTopics.map(mainTopic => mainTopic.id);
        } else {
            subTopicFilter['parent_ids'] = mainTopics.map(mainTopic => mainTopic.id);
        }

        subTopicFilter['topic_types'] = ['user'];
        const subTopicsResponse = await securedFetchTopicsByFilter(subTopicFilter, navigateCallbackOptions(navigate));
        if (subTopicsResponse === null) {
            return;
        }
        setTopics(subTopicsResponse.data);
        const newTopicIds = subTopicsResponse.data.map(topic => topic.id);
        setSelectedTopics(selectedTopics.filter(selectedTopic => newTopicIds.includes(selectedTopic.id)));
    };

    const onSelectedTopicChange = (selection) => {
        // const { value, checked } = event.target;
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedTopics = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(newSelectedTopics);
    };

    const makeTopicFilter = (mainType = false) => {
        const filter = {};
        if (selectedSubjects.length > 0) {
            filter['subject_ids'] = selectedSubjects.map(selectedSubject => selectedSubject.id);
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        if (selectedGrades.length > 0) {
            filter['grades'] = selectedGrades.map(selectedGrade => selectedGrade.id);
        } else {
            filter['grades'] = grades.map(grade => grade.id);
        }
        if (mainType) {
            filter['parent_ids'] = [-1];
        } else {
            if (selectedMainTopics.length > 0) {
                filter['parent_ids'] = selectedMainTopics.map(mainTopic => mainTopic.id);
            } else {
                filter['parent_ids'] = mainTopics.map(topic => topic.id);
            }
        }
        return filter;
    }

    useEffect(() => {
        (async () => {
            if (subjects.length === 0) {
                const subjectsResponse = await fetchSubjects();
                if (subjectsResponse === null) {
                    return;
                }
                setSubjects(subjectsResponse);
            }
            if (grades.length === 0) {
                const gradesResponse = await fetchGrades();
                if (gradesResponse === null) {
                    return;
                }
                setGrades(gradesResponse);
            }
            const mainTopicsResponse = await securedFetchTopicsByFilter({ parent_ids: [-1] }, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);

            const subTopicsResponse = await securedFetchTopicsByFilter({ parent_ids: mainTopicsResponse.data.map(mainTopic => mainTopic.id) }, navigateCallbackOptions(navigate));
            if (subTopicsResponse === null) {
                return;
            }
            setTopics(subTopicsResponse.data);
        })();
    }, []);


    return (
        <div className="syllabus-filter flex">

            <div className="subject-grade-filter flex">
                <div className="filter flex">
                    <label className="filter-label" id="subject">Subjects</label>
                    <SelectionDropdownMultiple className="filterDropdown" itemList={subjects} selectedItems={selectedSubjects} onSelectionToggle={onSelectedSubjectChange} valueField='id' nameField='subject_name' />
                </div>
                <div className="filter flex">
                    <label className="filter-label" id="subject">Grades</label>
                    <SelectionDropdownMultiple className="filterDropdown" itemList={grades} selectedItems={selectedGrades} onSelectionToggle={onSelectedGradeChange} valueField='id' nameField='grade' />
                </div>
            </div>
            <div className="filter flex">
                <label className="filter-label" id="main-topic">Main Topics</label>
                <SelectionDropdownMultiple className="filterDropdown" itemList={mainTopics} selectedItems={selectedMainTopics} onSelectionToggle={onSelectedMainTopicChange} valueField='id' nameField='topic_name' isSearchable={true} />
            </div>
            <div className="filter flex">
                <label className="filter-label" id="topic">Topics</label>
                <SelectionDropdownMultiple className="filterDropdown" itemList={topics} selectedItems={selectedTopics} onSelectionToggle={onSelectedTopicChange} valueField='id' nameField='topic_name' isSearchable={true} />
            </div>
        </div>
    );

};

export default SyllabusFilter;