import React from 'react'

import './navbar.css';

import { MdOutlineQuiz } from "react-icons/md";
import { FaCalendarAlt, FaUsers, FaRegListAlt } from 'react-icons/fa'
import { LuFileClock } from "react-icons/lu";
import { SiGoogleclassroom } from "react-icons/si";
import { PiChartLineUpBold } from "react-icons/pi";

const NavbarIconGenerator = ({ title = null }) => {

    if (title === null) return;

    return (
        <>
            {title === "Syllabus" && (
                <div className="flex item-center gap-1">
                    <FaRegListAlt className="h-5 w-5" />
                    <span className="hidden md:block">{title}</span>
                </div >

            )}
            {
                title === "Questions" && (
                    <div className="flex item-center gap-1">
                        <MdOutlineQuiz className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>

                )
            }
            {
                title === "Batches" && (
                    <div className="flex item-center gap-1">
                        <FaCalendarAlt className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>
                )
            }
            {
                title === "Members" && (
                    <div className="flex item-center gap-1">
                        <FaUsers className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>
                )
            }
            {
                title === "Tests" && (
                    <div className="flex item-center gap-1">
                        <LuFileClock className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>
                )
            }
            {
                title === "Live Class" && (
                    <div className="flex item-center gap-1">
                        <SiGoogleclassroom className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>
                )
            }
            {
                title === "Analytics" && (
                    <div className="flex item-center gap-1">
                        <PiChartLineUpBold className="h-5 w-5" />
                        <span className="hidden md:block">{title}</span>
                    </div>
                )
            }
        </>

    );
}

export default NavbarIconGenerator