import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';

import "./testQuestion.css";

import QuestionSlide from '../../Questions/QuestionView/QuestionSlider/QuestionSlide';
import ParagraphSlide from '../../Questions/QuestionView/QuestionSlider/ParagraphSlide';
import { useTest } from '../TestContext';
import { securedDeleteTestQuestion, securedUpdateTestQuestions, securedUpdateTestSections } from '../../../services/TestService';
import { prefetchAnswers, securedFetchQuestionById } from '../../../services/QuestionService';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { UnauthorizedError } from '../../../services/Errors';

import { RiDeleteBin5Line } from 'react-icons/ri'
import { IoInformationCircle } from "react-icons/io5";

const TestQuestionView = ({ testId, questions, paragraphs, testStatus = 'NOT STARTED', showImages = false, showAnswers = false, user = ADMIN_USER_TYPE }) => {

    const [type, setType] = useState("standalone");
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionData, setQuestionData] = useState(null);
    const [draggingQuestionIndex, setDraggingQuestionIndex] = useState(null);
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [paragraphData, setParagraphData] = useState(null);
    const [draggingParagraphIndex, setDraggingParagraphIndex] = useState(null);
    const standaloneQuestions = questions.filter(question => question.question.question_type === 'standalone');
    const {
        testSubjects,
        setTestSubjects,
        testSections,
        setTestSections,
        testQuestions,
        setTestQuestions,
        testParagraphs,
        setTestParagraphs,
    } = useTest();
    const navigate = useNavigate();

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Minimum distance (in pixels) to consider a swipe gesture
    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;
    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null on every new touch start
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () => {
        let index = (questionIndex === 0 ? standaloneQuestions.length - 1 : questionIndex - 1);

        if (type === 'paragraph') {
            setParagraphIndex(index);
            setParagraphData(paragraphs[index].paragraph);
        }
        else {
            setQuestionIndex(index);
            setQuestionData(standaloneQuestions[index].question);
        }
    }

    const next = () => {
        let index = (questionIndex === standaloneQuestions.length - 1 ? 0 : questionIndex + 1);

        if (type === 'paragraph') {
            setParagraphIndex(index);
            setParagraphData(paragraphs[index].paragraph);
        }
        else {
            setQuestionIndex(index);
            setQuestionData(standaloneQuestions[index].question);
        }
    }

    const onDeleteQuestion = async (type, testSectionId, questionToDelete) => {
        const testSubjectId = testSections.find((testSection) => testSection.id === questionToDelete.test_section_id).test_subject_id;
        const response = await securedDeleteTestQuestion(type, testSubjects[0].test_id, testSubjectId, testSectionId, questionToDelete.id, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        if (type === 'standalone') {
            const remainingQuestions = standaloneQuestions.filter(question => question.question_id !== questionToDelete.question_id);
            const remainingQuestionOrders = remainingQuestions.map((question, index) => ({ id: question.id, question_order: index }));
            const updatedOrders = await securedUpdateTestQuestions(type, testSubjects[0].test_id, testSubjectId, testSectionId, remainingQuestionOrders);
            const remainingTestQuestions = testQuestions.filter(testQuestion => testQuestion.id !== questionToDelete.id);
            remainingTestQuestions.forEach((testQuestion, index) => {
                testQuestion.question_order = remainingQuestions.findIndex((remainingQuestion => remainingQuestion.question_id === testQuestion.question_id));
            });
            setTestQuestions(remainingTestQuestions);
        }
        else if (type === 'paragraph') {
            const remainingParagraphs = paragraphs.filter(paragraph => paragraph.paragraph_id !== questionToDelete.paragraph_id);
            const remainingParagraphOrders = remainingParagraphs.map((paragraph, index) => ({ id: paragraph.id, paragraph_order: index }));
            const updatedOrders = await securedUpdateTestQuestions(type, testSubjects[0].test_id, testSubjectId, testSectionId, remainingParagraphOrders);
            const remainingTestParagraphs = testParagraphs.filter(testParagraph => testParagraph.id !== questionToDelete.id);
            remainingTestParagraphs.forEach((testParagraph, index) => {
                testParagraph.paragraph_order = remainingParagraphs.findIndex((remainingParagraph => remainingParagraph.paragraph_id === testParagraph.paragraph_id));
            });
            setTestParagraphs(remainingTestParagraphs);
        }
    }

    const handleClickId = async (clickType, index) => {
        setType(clickType);
        if (clickType === 'standalone') {
            setQuestionIndex(index);
            setQuestionData(standaloneQuestions[index].question);
        } else if (clickType === 'paragraph') {
            setParagraphIndex(index);
            setParagraphData(paragraphs[index].paragraph);
        }
    };

    const handleDragStart = (e, dragType, index) => {
        setType(dragType);

        if (dragType === 'standalone') {
            setDraggingQuestionIndex(index);
        } else if (dragType === 'paragraph') {
            setDraggingParagraphIndex(index);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e) => {
        const testQuestionToDelete = questions[draggingQuestionIndex];
        const testParagraphToDelete = paragraphs[draggingParagraphIndex];

        if (draggingQuestionIndex !== null) {
            await onDeleteQuestion('standalone', testQuestionToDelete.test_section_id, testQuestionToDelete);
        } else if (draggingParagraphIndex !== null) {
            await onDeleteQuestion('paragraph', testParagraphToDelete.test_section_id, testParagraphToDelete);
        }
        setDraggingQuestionIndex(null);
        setDraggingParagraphIndex(null);
    };

    useEffect(() => {
        (async () => {

            let startType = 'paragraph';
            if (paragraphs.length > 0) {
                setParagraphIndex(0);
                setParagraphData(paragraphs[0].paragraph);
            }
            else if (questions.length > 0) {
                setQuestionIndex(0);
                setQuestionData(questions[0].question);
                startType = 'standalone'
            }

            setType(startType);

        })();
    }, [questions, paragraphs]);

    const renderQuestionSlide = () => {
        if (!questionData || questionData.question_type === 'paragraph') {
            return <></>;
        }
        return <QuestionSlide
            testId={testId}
            questionId={questionData.id}
            inputQuestionData={questionData}
            showImages={true}
            testStatus={testStatus}
            showAnswers={true}
            user={user}
        />;
    }

    const renderParagraphSlide = () => {
        if (!paragraphData) {
            return <></>;
        }
        return <ParagraphSlide
            testId={testId}
            paragraphId={paragraphData.id}
            inputParagraphData={paragraphData}
            testStatus={testStatus}
            showImages={true}
            showAnswers={true}
            user={user} />;
    }

    return (
        <div className="testQuestionView flex" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

            <div className="questionNumberSlide flex">
                <div className="questionNumber">
                    {type === 'standalone' ?
                        <p>Question {questionIndex + 1}</p> :
                        <p>Paragraph {paragraphIndex + 1}</p>
                    }
                </div>

                {type === 'standalone' ? (
                    renderQuestionSlide()
                ) : (
                    renderParagraphSlide())}
            </div>

            <div className="questionButtons flex">

                {standaloneQuestions.length > 0 && (
                    <div className="questionIdxBtn grid">
                        {
                            standaloneQuestions.map((question, index) => (
                                <button className="idxBtn flex"
                                    id={(index === questionIndex) && type === 'standalone' ? 'clicked-btn' : 'default-btn'}
                                    draggable
                                    key={index}
                                    onClick={() => handleClickId('standalone', index)}
                                    onDragStart={(e) => handleDragStart(e, 'standalone', index)}
                                >
                                    {index + 1}
                                </button>))
                        }
                    </div>
                )}

                {paragraphs.length > 0 && (
                    <div className="paragraphIdxBtn grid">
                        {
                            paragraphs.map((paragraph, index) => (
                                <button className="idxBtn flex"
                                    id={index === paragraphIndex && type === 'paragraph' ? 'clicked-btn' : 'default-btn'}
                                    draggable
                                    key={index}
                                    onClick={() => handleClickId('paragraph', index)}
                                    onDragStart={(e) => handleDragStart(e, 'paragraph', index)}
                                >
                                    {index + 1}
                                </button>))
                        }
                    </div>
                )}

                {testStatus === 'NOT STARTED' && (
                    <div className="delete-bin flex"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}>
                        <RiDeleteBin5Line className="icon" />
                    </div>)}
            </div>


        </div >
    )
};

export default TestQuestionView;
