import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import LoadingPage from '../../../Common/LoadingPage';

import { securedDeleteTestSection } from '../../../../services/TestService';
import { ADMIN_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';
import { AiFillCloseCircle } from "react-icons/ai";

Modal.setAppElement('#root');  // This is important for accessibility reasons

const TestSectionDelete = ({ isOpen, onRequestClose, testId, testSubjectId, onDeleteSection = null, testSectionId, user = ADMIN_USER_TYPE }) => {
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const handleDelete = async () => {
        setIsSaving(true);
        const response = await securedDeleteTestSection(testId, testSubjectId, testSectionId, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onDeleteSection !== null) {
            await onDeleteSection(testSectionId);
        }
        setIsSaving(false);
        onRequestClose();
    }

    const renderTestSectionDelete = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>
                <h3 className="modalTitle">Are you sure to DELETE section?</h3>
                <div className="buttons flex">
                    <button className="btn flex" onClick={handleDelete}>Yes</button>
                    <button className="btn flex" onClick={onRequestClose}>Cancel</button>
                </div>
                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (
        <Modal overlayClassName="customModal" className="testSectionModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderTestSectionDelete()}
        </Modal>
    );
}

export default TestSectionDelete;
