import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';

import './slotview.css';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import Spinner from '../Common/Tailwind/Spinner';



const SlotLiveLectureVideo = ({ lectureData, meetingStatus, user = ADMIN_USER_TYPE }) => {

    const location = useLocation();

    const handleOpenInNewTab = () => {
        const currentPath = location.pathname;
        let newRelativePath = `${currentPath}/live-stream`
        if (lectureData.class_type === 'online') {
            newRelativePath = `/zoom${currentPath}/meeting`;
        }
        const newUrl = `${window.location.origin}${newRelativePath}`;
        window.open(newUrl, '_blank');
    };

    const startMeeting = () => {
        handleOpenInNewTab();
    };

    if (lectureData == null || meetingStatus == null) {
        return <Spinner />;
    }

    if (meetingStatus === 'waiting' && lectureData.status === 'finished') {
        return (
            <div className="slotLectureVideo">
                <div className="videoPlayer flex">
                    <p className="no-video-msg"> Video has not been uploaded yet. </p>
                </div>
            </div>);
    }
    return (
        <>
            <div className="slotLectureVideo" id="live-class">

                <div className="videoPlayer flex">
                    <p className="live-class-msg">Live Class Streaming</p>
                    <button className="btn flex"
                        id="record"
                        onClick={(e) => {
                            e.preventDefault();
                            startMeeting();
                        }}
                    >
                        Join Live Classroom
                    </button>


                    <div className="meetingSDKElement"></div>

                </div>

            </div>
        </>

    );
};

export default SlotLiveLectureVideo;
