import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import './slotview.css';

import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { hasLiveDoubtCreateAuthority, securedCreateDoubt, securedCreateDoubtClusters, securedFetchDoubtClustersByFilter, securedFetchDoubtsByFilter } from '../../services/DoubtService';
import DoubtClusterCard from '../Doubts/DoubtClusterCard';
import Spinner from '../Common/Tailwind/Spinner';
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'


const SlotLiveDoubtClusters = ({ lectureData, user = ADMIN_USER_TYPE }) => {
    const [doubtClusters, setDoubtClusters] = useState(null);
    const navigate = useNavigate();

    const fetchAndSetDoubtClusters = async () => {
        const doubtsResponse = await securedFetchDoubtClustersByFilter({ timetable_slot_ids: [lectureData.id] }, navigateCallbackOptions(navigate));
        setDoubtClusters(doubtsResponse.data);
    }

    useEffect(() => {
        (async () => {
            await fetchAndSetDoubtClusters();
        })();
    }, []);

    const handleFetchDoubtClusters = async (event) => {
        event.preventDefault();
        setDoubtClusters(null);
        await securedCreateDoubtClusters({ timetable_slot_id: lectureData.id }, navigateCallbackOptions(navigate));
        await fetchAndSetDoubtClusters();
    };

    return (
        <>
            {doubtClusters !== null ? (
                <div className="slotLiveDoubts flex flex-col justify-center">

                    <button
                        type="button"
                        onClick={handleFetchDoubtClusters}
                        className="ml-4 inline-flex justify-center gap-1.5 items-center rounded-md bg-transparent px-3 py-2 tracking-tight text-lg md:text-xl lg:text-2xl font-bold text-[var(--PrimaryColor)] shadow-sm hover:text-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                        cursor-pointer">
                        Doubt Cluster
                        <ArrowPathIcon aria-hidden="true" className="h-6 w-6" />
                    </button>

                    <div className="w-full max-w-7xl px-2 py-6 sm:py-8 lg:px-4 lg:py-10">
                        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                            <h2 className="text-base md:text-lg lg:text-xl font-semibold leading-10 tracking-tight text-[var(--SecondaryColor)]">JEETA created {doubtClusters.length} doubt cluster{doubtClusters.length > 1 ? "s" : ""}.</h2>
                            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                                {doubtClusters.map((doubtCluster, index) => (
                                    <DoubtClusterCard doubtClusterData={doubtCluster} user={user} />
                                ))}
                            </dl>
                        </div>
                    </div>

                </div>) :
                <Spinner />}
        </>
    );
}

export default SlotLiveDoubtClusters;

