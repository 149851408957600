import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import './studentTest.css'
import '../Test.css'

import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, canShowSectionMarks, fetchRemainingTime, securedAddTestQuestions, securedCreateTestSectionForTestId, securedDeleteTestQuestion, securedDeleteTestSection, securedDeleteTestSubject, securedFetchRemainingTime, securedFetchTestById, securedFetchTestSectionById, securedFetchTestSectionsByTestId, securedFetchTestSubjectById, securedMarkStudentTestAttempt, securedUpdateTestSectionOrder, securedUpdateTestSubjectOrder } from '../../../services/TestService';
import { securedFetchQuestionById, securedFetchQuestions, securedFetchQuestionsByIds } from '../../../services/QuestionService';
import { securedFetchSubjects, subjectAbbreviationMap } from '../../../services/SyllabusService';
import { UnauthorizedError } from '../../../services/Errors';
import StudentTestQuestionView from './StudentTestQuestionsView';
import TestSectionMarkingSchemeRender from '../TestSubject/TestSection/TestSectionMarkingSchemeRender';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import Spinner from '../../Common/Tailwind/Spinner';

const StudentTestView = ({ id, onTestEnd = null, user = STUDENT_USER_TYPE, refreshKey = 0, testStatus = "NOT STARTED" }) => {

    const [testSubjects, setTestSubjects] = useState([]);
    const [testSections, setTestSections] = useState([]);
    const [testQuestions, setTestQuestions] = useState([]);
    const [testParagraphs, setTestParagraphs] = useState([]);
    const [testType, setTestType] = useState(null);
    const [activeSection, setActiveSection] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const tabsRef = useRef(null);
    const navigate = useNavigate();

    const fetchAndSetTest = async () => {
        setIsLoading(true);
        const testResponse = await securedFetchTestById(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
        const remainingTimeResponse = await securedFetchRemainingTime(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
        if (remainingTimeResponse === null) {
            setIsLoading(false);
            return;
        }
        if (remainingTimeResponse.time_remaining === null) {
            clearStates();
            setIsLoading(false);
            return;
        }
        await setStatesFromResponse(testResponse, remainingTimeResponse.time_remaining < 0);
        setIsLoading(false);
    }
    useEffect(() => {
        (async () => {
            const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse.data);
            await fetchAndSetTest();
        })();
    }, [id, refreshKey]);


    useEffect(() => {
        (async () => {
            if (testStatus === 'STARTED') {
                const response = await securedMarkStudentTestAttempt(id, navigateCallbackOptions(navigate));
            }
        })();
    }, [testStatus]);

    useEffect(() => {
        if (!testSections || testSections.length === 0) {
            return;
        }
        setActiveSection(testSections[0]);
    }, [testSections]);


    const clearStates = () => {
        setTestSubjects([]);
        setTestSections([]);
        setTestQuestions([]);
        setTestParagraphs([]);
    }

    const setStatesFromResponse = async (testResponse, fetchCorrectAnswers = false) => {
        const testSubjectsFromResponse = [...testResponse.subjects];
        let testSectionsFromResponse = [];
        testSubjectsFromResponse.forEach((testSubject) => {
            testSectionsFromResponse = [...testSectionsFromResponse, ...testSubject.sections];
        })
        let testQuestionsFromResponse = [];
        let testParagraphsFromResponse = [];

        testSectionsFromResponse.forEach((testSection) => {
            testQuestionsFromResponse = [...testQuestionsFromResponse, ...testSection.test_questions];
            testParagraphsFromResponse = [...testParagraphsFromResponse, ...testSection.test_paragraphs];
        })
        await setTestQuestionsAndParagraphs(testQuestionsFromResponse, testParagraphsFromResponse, fetchCorrectAnswers);
        testResponse.subjects = null;
        testSubjectsFromResponse.forEach((testSubject) => { testSubject.sections = null });
        testSectionsFromResponse.forEach((testSection) => {
            testSection.test_questions = null;
            testSection.test_paragraphs = null;
            testSection.subject_id = testSubjectsFromResponse.find((testSubject) => testSubject.id === testSection.test_subject_id).subject_id;
        });

        testSectionsFromResponse.sort((a, b) => a.subject_id > b.subject_id);

        const subjectPositions = {};

        testSectionsFromResponse.forEach((testSection) => {
            if (!subjectPositions[testSection.subject_id]) {
                subjectPositions[testSection.subject_id] = 1;
            } else {
                subjectPositions[testSection.subject_id] += 1;
            }

            testSection.position_in_subject = subjectPositions[testSection.subject_id]; // Assign the position
        });

        setTestSubjects(testSubjectsFromResponse);
        setTestSections(testSectionsFromResponse);
        setTestType(testResponse.test_type);
    };

    const setTestQuestionsAndParagraphs = async (testQuestionsFromResponse, testParagraphsFromResponse, fetchCorrectAnswers = false) => {
        const questionIds = testQuestionsFromResponse.map((testQuestion) => (testQuestion.question_id));
        const paragraphIds = testParagraphsFromResponse.map((testParagraph) => (testParagraph.paragraph_id));
        let extraFilter = null;
        if (fetchCorrectAnswers) {
            extraFilter = { show_correct_answer: true };
        }
        const questionsResponse = await securedFetchQuestionsByIds('standalone', questionIds, extraFilter, navigateCallbackOptions(navigate));
        if (questionsResponse === null) {
            return;
        }
        const paragraphsResponse = await securedFetchQuestionsByIds('paragraph', paragraphIds, extraFilter, navigateCallbackOptions(navigate));
        if (paragraphsResponse === null) {
            return;
        }
        const questions = questionsResponse.data;
        const paragraphs = paragraphsResponse.data;
        testQuestionsFromResponse.forEach((testQuestion) => {
            const matchingQuestion = questions.find((question) => (question.id === testQuestion.question_id));
            testQuestion['question'] = matchingQuestion;
        });
        testParagraphsFromResponse.forEach((testParagraph) => {
            const matchingParagraph = paragraphs.find((paragraph) => (paragraph.id === testParagraph.paragraph_id));
            testParagraph['paragraph'] = matchingParagraph;
        });
        testQuestionsFromResponse = testQuestionsFromResponse.filter((testQuestion) => (testQuestion.question !== undefined));
        testParagraphsFromResponse = testParagraphsFromResponse.filter((testParagraph) => (testParagraph.paragraph !== undefined));
        setTestQuestions(testQuestionsFromResponse);
        setTestParagraphs(testParagraphsFromResponse);
    }

    const getTestQuestionsForSection = (testSectionId) => {
        let questionsForSection = testQuestions.filter(testQuestion => testQuestion.test_section_id === testSectionId);
        const paragraphsForSection = testParagraphs.filter(testParagraph => testParagraph.test_section_id === testSectionId);
        paragraphsForSection.forEach((testParagraph, paragraphOrder) => {
            const paragraphQuestions = testParagraph.paragraph.questions.map((paragraphQuestion, questionOrder) => ({ question_id: paragraphQuestion.question.id, test_section_id: testSectionId, question: paragraphQuestion.question, question_order: questionOrder, paragraph_order: paragraphOrder }));
            paragraphQuestions.forEach(paragraphQuestion => {
                paragraphQuestion.question['basicInfo'] = testParagraph.paragraph.paragraph_text;
                paragraphQuestion.question['basicImages'] = testParagraph.paragraph.images;
            });
            const paragraphQuestionIds = paragraphQuestions.map(paragraphQuestion => paragraphQuestion.question_id);
            questionsForSection = questionsForSection.filter(testQuestion => !paragraphQuestionIds.includes(testQuestion.question_id));
            questionsForSection = [...questionsForSection, ...paragraphQuestions];
        })
        return questionsForSection;
    }

    const getTestParagraphsForSection = (testSectionId) => {
        return testParagraphs.filter(testParagraph => testParagraph.test_section_id === testSectionId);
    }

    const handleTabClick = (testSectionId) => {
        setActiveSection(testSections.find(testSection => testSection.id === testSectionId) || null);
    };

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const getSectionName = (testSection) => {
        const subjectName = subjects.find(subject => subject.id === testSection.subject_id).subject_name;
        return `${subjectAbbreviationMap[subjectName]} SEC${testSection.position_in_subject}`;
    }

    if (isLoading) {
        return <Spinner />;
    }

    if (!activeSection) {
        return;
    }

    return (

        <div className="studentTestView">

            <div className="studentTestSectionTabsContainer flex">
                <button className="left-btn" onClick={() => scrollTabs('left')}>
                    <AiOutlineLeft className="icon" />
                </button>
                <div className="studentTestSectionTabs flex" ref={tabsRef}>
                    {testSections.map((testSection, index) => (
                        <button className={activeSection.id === testSection.id ? "tabItem selected" : "tabItem"} key={testSection.id} onClick={() => handleTabClick(testSection.id)} >
                            {getSectionName(testSection)}
                        </button>
                    ))}
                </div>
                <button className="right-btn" onClick={() => scrollTabs('right')}>
                    <AiOutlineRight className="icon" />
                </button>
            </div>

            {activeSection != null && (
                <div className="studentTestSectionContentsContainer flex">

                    {canShowSectionMarks(testType) && (
                        <TestSectionMarkingSchemeRender testSection={activeSection} questionsForSection={getTestQuestionsForSection(activeSection.id)} paragraphsForSection={getTestParagraphsForSection(activeSection.id)} />
                    )}

                    {getTestQuestionsForSection(activeSection.id).length > 0 && (
                        <StudentTestQuestionView
                            questions={getTestQuestionsForSection(activeSection.id)}
                            testStatus={testStatus}
                            testId={id}
                            showImages={true}
                            showAnswers={true}
                            user={user}
                            testType={testType}
                        />
                    )}
                </div>
            )}
        </div >
    );
};

export default StudentTestView;