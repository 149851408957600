import React from 'react'

import image from '../../Assets/Student.png'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import { StudentNavItems } from './StudentNavItems';
import { STUDENT_USER_TYPE } from '../../services/UserService';

import UserProfile from '../../Components/Profile/UserProfile';

const StudentProfile = () => {
    return (
        <>
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            <UserProfile user={STUDENT_USER_TYPE} />
            <Footer user={STUDENT_USER_TYPE} />
        </>
    )
};

export default StudentProfile;