import React, { useRef, useState, useEffect, useMemo } from 'react';
import StackedBarplot from '../../Common/StackedBarplot';

import './AnalyticsCharts.css';
import Spinner from '../../Common/Tailwind/Spinner';

const AnalyticsBarChart = ({ period, valueField, data }) => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    console.log(data);
    console.log(valueField);
    // const maxWidth = (period === '1Week' ? 700 : (period === '1Month' ? 800 : 900))

    const subjectColors = {
        'Physics': '#003366',
        'Chemistry': '#4CAF50',
        'Mathematics': '#800000'
    };

    useEffect(() => {
        const updateDimensions = () => {
            const maxWidth = (period === '1Week' ? 700 : (period === '1Month' ? 950 : 1100))
            const maxHeightRatio = (period === '1Week' ? .65 : (period === '1Month' ? .85 : 1))

            if (containerRef.current) {
                setDimensions({
                    width: (containerRef.current.offsetWidth < maxWidth) ? containerRef.current.offsetWidth : maxWidth,
                    height: window.innerHeight * maxHeightRatio // Set height to 80% of viewport height
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [period]);

    const convertData = (inputData) => {
        return {
            ...inputData,
            datasets: inputData.datasets.map(dataset => {
                console.log(dataset.label);
                return {
                    ...dataset,
                    data: dataset.data.map(d => d[valueField]),
                    // type: 'bar',
                    backgroundColor: subjectColors[dataset.label]
                }
            })
        };
    }

    const render = () => {
        const convertedData = convertData(data);
        return (
            <div className="analytics-chart-section" ref={containerRef}>
                {/* <h3 className="analytics-chart-section-title">Questions Practiced Analytics</h3> */}
                <StackedBarplot
                    width={dimensions.width}
                    height={dimensions.height}
                    data={convertedData}
                />
            </div>
        );
    }

    if (data === null) {
        return <Spinner />;
    }

    // const allSubjects = ["Mathematics", "Physics", "Chemistry"];
    // const subjectColors = ['#800000', '#003366', '#4CAF50'];
    return (
        <div className="analytics-chart-container">
            {render()}
        </div>
    )



};

export default AnalyticsBarChart;