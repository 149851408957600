import { end } from "@popperjs/core";
import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { createParams } from "./CommonService";
import moment from "moment";

export const fetchStudentAnalyticsByFilter = async (filter) => {
    const response = await fetch(`${BACKEND_URL}/analytics/students${createParams(filter)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchStudentAnalyticsByFilter = withAuth(fetchStudentAnalyticsByFilter);

export const subjectBackgroundColorMap = {
    'Physics': 'PHY',
    'Mathematics': 'MATH',
    'Chemistry': 'CHEM'
}

export const makeChartDatapoint = (rawDatapoint, keysToKeep) => {
    const result = {};
    let type = 'month';
    const startDate = moment(rawDatapoint.start_date, 'YYYY-MM-DD');
    const endDate = moment(rawDatapoint.end_date, 'YYYY-MM-DD');
    const duration = endDate.diff(startDate, 'day');
    if (duration === 0) {
        type = 'day';
    }
    if (duration === 6) {
        type = 'week';
    }
    result['date'] = startDate;
    result['type'] = type;
    keysToKeep.forEach((key) => {
        result[key] = rawDatapoint[key]
    });
    return result;
}

export const makeDateRange = (startDate, endDate) => {
    const dateRange = [];
    const duration = endDate.diff(startDate, 'd');
    for (let m = 0; m <= duration; m += 1) {
        dateRange.push(endDate.clone().subtract(m, 'd'));
    }
    return dateRange
}

const getNewKeysToKeep = (keysToKeep) => {
    const extraData = {};
    keysToKeep.forEach((key) => {
        extraData[key] = 0;
    });
    return extraData;
}

export const fillMissingDataBySubjects = (data, subjects, dateRange, keysToKeep) => {
    const filledData = [];
    dateRange.forEach(date => {
        subjects.forEach(subject => {
            const existingEntry = data.find(
                (entry => entry.date.diff(date, 'd') === 0 && entry.subject_id === subject.id)
            );

            if (existingEntry) {
                filledData.push(existingEntry);
            } else {
                filledData.push({
                    date: date,
                    subject_id: subject.id,
                    ...getNewKeysToKeep(keysToKeep)
                });
            }
        });
    });

    return filledData;
}

export const fillMissingData = (data, dateRange, keysToKeep) => {
    const filledData = [];
    dateRange.forEach(date => {
        const existingEntry = data.find(
            entry => (entry.date.diff(date, 'd') === 0)
        );

        if (existingEntry) {
            filledData.push(existingEntry);
        } else {
            filledData.push({
                date: date,
                ...getNewKeysToKeep(keysToKeep)
            });
        }
    });
    return filledData;
}

export const makeLabelsAndDatasetsBySubjects = (dateRange, data, subjects, keysToKeep) => {
    const filledData = fillMissingDataBySubjects(data, subjects, dateRange, keysToKeep);

    if (keysToKeep.length === 1) {
    }
    const datasets = subjects.map(subject => {
        return {
            label: subject.subject_name,
            data: filledData.filter(singleData => singleData.subject_id === subject.id),
        }
    });
    return {
        labels: dateRange.map(date => date.format('YYYY-MM-DD')),
        datasets
    }
}

export const makeLabelsAndDatasets = (dateRange, data, keysToKeep) => {
    const filledData = fillMissingData(data, dateRange, keysToKeep);
    return {
        series: [{
            data: filledData
        }]
    }

}