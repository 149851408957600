import { checkAuthorized, returnJsonResponse, withAdminAuth, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { createParams } from "./CommonService";
import { STUDENT_USER_TYPE } from "./UserService";

export const fetchDoubtsByFilter = async (data) => {
    const response = await fetch(`${BACKEND_URL}/doubts${createParams(data)}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createDoubt = async (data) => {
    const response = await fetch(`${BACKEND_URL}/doubts`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchDoubtClustersByFilter = async (data) => {
    const response = await fetch(`${BACKEND_URL}/doubt-clusters${createParams(data)}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const createDoubtClusters = async (data) => {
    const response = await fetch(`${BACKEND_URL}/doubt-clusters`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchDoubtsByFilter = withAuth(fetchDoubtsByFilter);
export const securedCreateDoubt = withAuth(createDoubt);
export const securedFetchDoubtClustersByFilter = withAuth(fetchDoubtClustersByFilter);
export const securedCreateDoubtClusters = withAuth(createDoubtClusters);

export const hasLiveDoubtCreateAuthority = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const hasLiveDoubtClusterAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}