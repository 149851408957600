import { useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { MdOpenInNew, MdOutlineFullscreen } from "react-icons/md";
import SlotLiveDoubts from '../../../Slots/SlotLiveDoubts';
import SlotLiveDoubtClusters from '../../../Slots/SlotLiveDoubtClusters';
import { STUDENT_USER_TYPE } from '../../../../services/UserService';
import TailwindTabs from '../Navigation/Tabs';
import SlotTestView from '../../../Slots/SlotTest/SlotTestView';
import SlotTestEdit from '../../../Slots/SlotTest/SlotTestEdit';

const Drawer = ({ isFullscreen, toggleDrawer, setToggleDrawer, handleClick, onOpenInApp, lectureData, user }) => {
    const [open, setOpen] = useState(true);

    const renderLiveQuiz = () => {
        return (user === STUDENT_USER_TYPE ?
            <SlotTestView lectureData={lectureData} user={user} testType={'live'} />
            :
            <SlotTestEdit lectureData={lectureData} user={user} testType={'live'} />
        );
    }

    const renderLiveDoubts = () => {
        return (user === STUDENT_USER_TYPE ?
            <SlotLiveDoubts lectureData={lectureData} user={user} />
            :
            <SlotLiveDoubtClusters lectureData={lectureData} user={user} />
        );
    }

    return (
        <Dialog id='slot-drawer' open={toggleDrawer} onClose={() => setToggleDrawer(false)} className="relative z-10">
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <DialogPanel
                            transition
                            className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                        >
                            <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                <div className="h-0 flex-1 overflow-y-auto">
                                    <div className="bg-[var(--PrimaryColor)] px-4 py-4 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <DialogTitle className="text-base font-semibold leading-6 text-white">Live Class</DialogTitle>
                                            <div className="ml-3 flex items-center h-7 gap-2 items-center">
                                                {!isFullscreen && (
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={handleClick}
                                                            className="relative flex items-center rounded-md bg-transparent text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Full Screen</span>
                                                            <MdOutlineFullscreen aria-hidden="true" className="h-6 w-6" />
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={onOpenInApp}
                                                            className="relative flex items-center rounded-md bg-transparent text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Open in App</span>
                                                            <MdOpenInNew aria-hidden="true" className="h-5 w-5" />
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    type="button"
                                                    onClick={() => setToggleDrawer(false)}
                                                    className="relative rounded-md bg-transparent text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                                </button>

                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <p className="text-sm text-blue-200">
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col justify-between">
                                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                            <div className="space-y-6 pb-5 pt-6">

                                                {/* <TailwindTabs position={"center"} tabs={liveClassTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} renderByTabs={getRenderByTabs()} /> */}
                                                {renderLiveDoubts()}
                                                {/* 
                                                <div>
                                                    <div className="mt-2">
                                                        <input
                                                            id="project-name"
                                                            name="project-name"
                                                            type="text"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div>

                                                    <div className="mt-2">
                                                        <textarea
                                                            id="description"
                                                            name="description"
                                                            rows={6}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            defaultValue={''}
                                                            placeholder="Remember, there are no 'silly' questions in this classroom - your curiosity is valued and asking questions is the key to deepening your understanding. Don't hesitate to speak up and ask any doubts you may have; your voice matters and we're all here to learn together."
                                                        />
                                                    </div>
                                                </div> */}
                                                {/* <div>
                                                    <h3 className="text-sm font-medium leading-6 text-gray-900">Team Members</h3>
                                                    <div className="mt-2">
                                                        <div className="flex space-x-2">
                                                            {team.map((person) => (
                                                                <a
                                                                    key={person.email}
                                                                    href={person.href}
                                                                    className="relative rounded-full hover:opacity-75"
                                                                >
                                                                    <img
                                                                        alt={person.name}
                                                                        src={person.imageUrl}
                                                                        className="inline-block h-8 w-8 rounded-full"
                                                                    />
                                                                </a>
                                                            ))}
                                                            <button
                                                                type="button"
                                                                className="relative inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                <span className="absolute -inset-2" />
                                                                <span className="sr-only">Add team member</span>
                                                                <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <fieldset>
                                                    <legend className="text-sm font-medium leading-6 text-gray-900">Privacy</legend>
                                                    <div className="mt-2 space-y-4">
                                                        <div className="relative flex items-start">
                                                            <div className="absolute flex h-6 items-center">
                                                                <input
                                                                    defaultChecked
                                                                    id="privacy-public"
                                                                    name="privacy"
                                                                    type="radio"
                                                                    aria-describedby="privacy-public-description"
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                />
                                                            </div>
                                                            <div className="pl-7 text-sm leading-6">
                                                                <label htmlFor="privacy-public" className="font-medium text-gray-900">
                                                                    Public access
                                                                </label>
                                                                <p id="privacy-public-description" className="text-gray-500">
                                                                    Everyone with the link will see this project.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="relative flex items-start">
                                                                <div className="absolute flex h-6 items-center">
                                                                    <input
                                                                        id="privacy-private-to-project"
                                                                        name="privacy"
                                                                        type="radio"
                                                                        aria-describedby="privacy-private-to-project-description"
                                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                    />
                                                                </div>
                                                                <div className="pl-7 text-sm leading-6">
                                                                    <label htmlFor="privacy-private-to-project" className="font-medium text-gray-900">
                                                                        Private to project members
                                                                    </label>
                                                                    <p id="privacy-private-to-project-description" className="text-gray-500">
                                                                        Only members of this project would be able to access.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="relative flex items-start">
                                                                <div className="absolute flex h-6 items-center">
                                                                    <input
                                                                        id="privacy-private"
                                                                        name="privacy"
                                                                        type="radio"
                                                                        aria-describedby="privacy-private-to-project-description"
                                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                    />
                                                                </div>
                                                                <div className="pl-7 text-sm leading-6">
                                                                    <label htmlFor="privacy-private" className="font-medium text-gray-900">
                                                                        Private to you
                                                                    </label>
                                                                    <p id="privacy-private-description" className="text-gray-500">
                                                                        You are the only one able to access this project.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset> */}
                                            </div>
                                            {/* <div className="pb-6 pt-4">
                                                <div className="flex text-sm">
                                                    <a
                                                        href="#"
                                                        className="group inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        <LinkIcon
                                                            aria-hidden="true"
                                                            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900"
                                                        />
                                                        <span className="ml-2">Copy link</span>
                                                    </a>
                                                </div>
                                                <div className="mt-4 flex text-sm">
                                                    <a href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                                                        <QuestionMarkCircleIcon
                                                            aria-hidden="true"
                                                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                        />
                                                        <span className="ml-2">Learn more about sharing</span>
                                                    </a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                    <button
                                        type="button"
                                        onClick={() => setOpen(false)}
                                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="ml-4 inline-flex justify-center rounded-md bg-[var(--SecondaryColor)] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save
                                    </button>
                                </div> */}
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Drawer;