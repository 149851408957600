import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import './ReadingMaterialCard.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { BiFilterAlt, BiMessageSquareAdd } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import ReadingMaterialCreate from '../ReadingMaterialCreate/ReadingMaterialCreate';
import { READING_MATERIAL_UPLOAD_TYPES, hasReadingMaterialCreateAuthority } from '../../../services/ReadingMaterialService';

const ReadingMaterialCardListFilter = ({ syllabusFilter = null, onSubmitFilter = null, hiddenFilters = null, user = ADMIN_USER_TYPE }) => {

    const [isReadingMaterialCreateModalOpen, setReadingMaterialCreateModalOpen] = useState(false);
    const [nameSubstring, setNameSubstring] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    const navigate = useNavigate();

    const onFilterClick = async () => {
        const filter = {
            ...syllabusFilter,
            selectedTags,
            selectedTypes,
            nameSubstring
        };
        if (onSubmitFilter) {
            await onSubmitFilter(filter);
        }
    }

    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!hiddenFilters || hiddenFilters.tags !== 'hide') {
                const tagFilter = user === ADMIN_USER_TYPE ? null : { is_hidden: false }
                const tagsResponse = await securedFetchTagsByFilter(tagFilter, navigateCallbackOptions(navigate));
                if (tagsResponse === null) {
                    return;
                }
                setTags(tagsResponse.data);
            }
        })();
    }, []);

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onSelectedTypeToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTypesFromSelect = READING_MATERIAL_UPLOAD_TYPES.filter(type => selectedIds.includes(type.id));
        setSelectedTypes(selectedTypesFromSelect);
    };

    return (
        <div className="readingMaterialCardListFilter flex">

            <div className="filterTitle flex">

                {/* <h3 className="title"> Dive Deep into Learning: </h3> */}

                {user === ADMIN_USER_TYPE ?
                    <span>Explore and Master {isNarrowScreen ? <br /> : ""}Your Reading Material Database</span>
                    :
                    <span>Every Theory Revision {isNarrowScreen ? <br /> : ""}Strengthens Your Foundation</span>
                }
            </div>
            <div className="filters">
                {(!hiddenFilters || hiddenFilters.tags !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name='Tags' itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagToggle} nameField='tag_name' valueField='id' isSearchable={true} />)}
                {(!hiddenFilters || hiddenFilters.types !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name=' Types' itemList={READING_MATERIAL_UPLOAD_TYPES} onSelectionToggle={onSelectedTypeToggle} selectedItems={selectedTypes} nameField='name' valueField='id' />)}
                {(!hiddenFilters || hiddenFilters.nameSubstring !== 'hide') && (<div className="searchTextInput flex">
                    <label className="title">Name</label>
                    <input type="text" value={nameSubstring} onChange={e => setNameSubstring(e.target.value)} placeholder="Name of Reading Material..." />
                </div>)}
            </div>

            <div className="btns flex">
                <button className="btn flex" id="filter" onClick={onFilterClick}>Filter <BiFilterAlt className="icon" /> </button>
                {!hasReadingMaterialCreateAuthority(user) ? (<></>) : (
                    <>
                        <span> or</span>
                        <button className="btn flex" id="create" onClick={() => setReadingMaterialCreateModalOpen(true)} >
                            Create <BiMessageSquareAdd className="icon" />
                        </button>
                    </>
                )}
            </div>
            <ReadingMaterialCreate
                isOpen={isReadingMaterialCreateModalOpen}
                onRequestClose={() => setReadingMaterialCreateModalOpen(false)}
                user={user}
                initialSubjects={syllabusFilter.subjects}
                initialSelectedSubject={syllabusFilter.selectedSubjects.length > 0 ? syllabusFilter.selectedSubjects[0] : {}}
                initialGrades={syllabusFilter.grades}
                initialSelectedGrade={syllabusFilter.selectedGrades.length > 0 ? syllabusFilter.selectedGrades[0] : {}}
                initialSelectedTopics={syllabusFilter.selectedTopics}
            />

        </div>
    );
};

export default ReadingMaterialCardListFilter;