import React, { useState } from 'react';

import './NTA.css'

import { useNTATest } from './NTATestContext';
import NTAInstructions from './NTAInstructions';
import StudentTestReport from '../TestReport/StudentTestReport';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { TbReport } from 'react-icons/tb';
import { FiRefreshCcw } from 'react-icons/fi';
import NTATestPaper from './NTATestPaper';
import NTAAnswersCountsTooltip from './NTAAnswersCountsTooltip';
import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_VIRTUAL, securedStartTest } from '../../../services/TestService';

const NTATestHeader = ({ user = STUDENT_USER_TYPE, onRefreshClick = null, setEvaluatedNow }) => {

    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);
    const [isInstructionModalOpen, setInstructionModalOpen] = useState(false);
    const [isPaperModalOpen, setPaperModalOpen] = useState(false);
    const [tooltipPopup, setTooltipPopup] = useState(null);

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        hasAgreedInstructions,
        testCreateType
    } = useNTATest();

    const onTestReportFetch = async () => {
        setTestReportModalOpen(true);
    }

    const showTestStatusInProgress = () => {
        return testStatus === 'STARTED';
    }

    const showTestStatusCompleted = () => {
        return testStatus === 'FINISHED';
    }

    const showTestStatusReady = () => {
        return testStatus === 'NOT STARTED';
    }

    const showTestReportComponent = () => {
        return testStatus === 'FINISHED';
    }

    const canRefreshTest = () => {
        return testStatus === 'NOT STARTED' && testStatus !== null && user === STUDENT_USER_TYPE && testCreateType === TEST_CREATE_TYPE_BASIC;
    }

    const onStartTest = async () => {
        const sendTestDuration = testCreateType !== TEST_CREATE_TYPE_VIRTUAL ? testData.test_duration_minutes : testData.virtual_test_duration_minutes;
        const response = await securedStartTest(testCreateType, testData.id, { test_duration_minutes: sendTestDuration });
        if (response === null) {
            return;
        }
        setTestStatus('STARTED');
        onRefreshClick();
    }

    const canStartTest = () => {
        return testStatus === 'NOT STARTED' && testStatus !== null && testCreateType !== TEST_CREATE_TYPE_BASIC;
    }

    return (
        <div className="NTATestHeader roboto-medium flex">

            {/* Test Name  */}
            <div className="test-name flex">
                {testData.test_name}
                <span className="instruction_icon" onMouseEnter={() => setTooltipPopup(true)} onMouseLeave={() => setTooltipPopup(false)} >
                    {tooltipPopup &&
                        <NTAAnswersCountsTooltip />}
                </span>
            </div>

            {/* Test Refresh */}
            {canStartTest() && (
                <div className="test-refresh roboto-bold flex" onClick={onStartTest}>
                    <p>Click to start test</p>
                    <p className="msg" id="started">START TEST<FiRefreshCcw className="icon" /> </p>
                </div>
            )}

            {canRefreshTest() && (
                <div className="test-refresh roboto-bold flex" onClick={onRefreshClick}>
                    <p>Keep clicking the refresh button</p>
                    <p className="msg" id="started">REFRESH <FiRefreshCcw className="icon" /> </p>
                </div>
            )}

            {/* Test Report */}
            {showTestReportComponent() && (
                <div className="test-report-btn roboto-medium flex" onClick={onTestReportFetch} >
                    <p className="msg" id="NTAreport">TEST REPORT <TbReport className="icon" /> </p>
                </div>
            )}

            <StudentTestReport isOpen={isTestReportModalOpen} onRequestClose={() => (setTestReportModalOpen(false))} testId={testData.id} testName={testData.test_name} studentId={testData.student_id || null} user={user} testCreateType={testCreateType} setEvaluatedNow={setEvaluatedNow} />

            {showTestStatusInProgress() ? (
                <div className="test-status roboto-bold flex">
                    <p className="msg" id="started">IN PROGRESS</p>
                </div>
            ) : showTestStatusCompleted() ? (
                <div className="test-status roboto-bold flex" >
                    <p className="msg" id="finished">COMPLETED</p>
                </div>
            ) : showTestStatusReady() ? (
                <div className="test-status roboto-bold flex" >
                    <p className="msg" id="not-started">READY</p>
                </div>
            ) : (<></>)}

            {/* <button className='btn flex' id='submit' >
                INFO <AiFillInfoCircle className="icon" />
            </button> */}

            {/* Test Instruction */}
            {testStatus === "STARTED" &&
                <div className='test-header-popup flex'>
                    <div className="test-instruction flex" onClick={() => setPaperModalOpen(true)}>
                        <span className="instruction_icon"></span>
                        <span>View Paper</span>
                    </div>
                    <NTATestPaper isOpen={isPaperModalOpen} onRequestClose={() => { setPaperModalOpen(false) }} parentId='NTA-tests-glass' />
                    <div className="test-instruction flex" onClick={() => setInstructionModalOpen(true)}>
                        <span className="instruction_icon"></span>
                        <span>View Instructions</span>
                    </div>
                    <NTAInstructions isOpen={isInstructionModalOpen} onRequestClose={() => { setInstructionModalOpen(false) }} infoOnly={true} testData={testData} parentId='NTA-tests-glass' />
                </div>
            }
        </div>
    );
}


export default NTATestHeader;
