import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import './NTA.css'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { BsCloudUpload } from 'react-icons/bs';
import { RiArrowGoBackFill } from 'react-icons/ri';

Modal.setAppElement('#root');

const NTAFullScreenWarning = ({ isOpen, onRequestClose, handleSubmitTest, handleReenterFullScreen, user = ADMIN_USER_TYPE }) => {
    return (
        <Modal overlayClassName="customModal" className="testModal flex" id="NTA-popup" isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false} >
            <h3 className="NTA-popup-msg">
                Exiting full screen mode will automatically submit your test.
                <br />
                What action would you like to take?
            </h3>

            <div className="NTA-popup-btns flex">
                <button className="btn flex" onClick={handleSubmitTest}>
                    SUBMIT TEST <BsCloudUpload className="icon" />
                </button>

                <button className="btn flex" onClick={handleReenterFullScreen}>
                    RE-ENTER TEST <RiArrowGoBackFill className="icon" />
                </button>
            </div>
        </Modal >
    );
}

export default NTAFullScreenWarning;
