import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import './app.css';
import 'katex/dist/katex.min.css';
import 'react-tooltip/dist/react-tooltip.css';

import Home from './routes/Home/Home';
import AdminDashboard from './routes/Admin/AdminDashboard';
import StudentDashboard from './routes/Student/StudentDashboard';
import TeacherDashboard from './routes/Teacher/TeacherDashboard';
import AdminProfile from './routes/Admin/AdminProfile';
import StudentProfile from './routes/Student/StudentProfile';
import TeacherProfile from './routes/Teacher/TeacherProfile';
import AdminBatchesManage from './routes/Admin/AdminBatchesManage';
import AdminUser from './routes/Admin/AdminUser';
import AdminTests from './routes/Admin/AdminTests';
import StudentTests from './routes/Student/StudentTests';
import TeacherTests from './routes/Teacher/TeacherTests';
import StudentLiveClass from './routes/Student/StudentLiveClass';
import TeacherLiveClass from './routes/Teacher/TeacherLiveClass';
import StudentQuestionsPage from './routes/Student/StudentQuestionsPage';
import AdminAnalytics from './routes/Admin/AdminAnalytics';
import StudentAnalytics from './routes/Student/StudentAnalytics';
import TeacherAnalytics from './routes/Teacher/TeacherAnalytics';
import AdminSlots from './routes/Admin/AdminSlots';
import StudentSlots from './routes/Student/StudentSlots';
import TeacherSlots from './routes/Teacher/TeacherSlots';
import StudentSyllabus from './routes/Student/StudentSyllabus';
import TeacherSyllabus from './routes/Teacher/TeacherSyllabus';
import AdminSyllabus from './routes/Admin/AdminSyllabus';
import PrivateRoute from './Components/Authentication/PrivateRoute';
import StudentTest from './routes/Student/StudentTest';
import AdminTest from './routes/Admin/AdminTest';
import StudentPracticeTest from './routes/Student/StudentPracticeTest';
import StudentVirtualTest from './routes/Student/StudentVirtualTest';
import StudentPracticeTests from './routes/Student/StudentPracticeTests';
import StudentVirtualTests from './routes/Student/StudentVirtualTests';
import StudentZoom from './routes/Student/StudentZoom';
import AdminZoom from './routes/Admin/AdminZoom';
import StudentLiveStream from './routes/Student/StudentLiveStream';

const JeetaApp = () => {

    return (
        <div className="jeeta-app" >
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}

                {/* admin */}
                <Route path="/admin/dashboard" element={<PrivateRoute allowedRoles={['admin']}><AdminDashboard /></PrivateRoute>} />
                <Route path="/admin/profile" element={<PrivateRoute allowedRoles={['admin']}><AdminProfile /></PrivateRoute>} />
                <Route path="/admin/syllabus" element={<PrivateRoute allowedRoles={['admin']}><AdminSyllabus /></PrivateRoute>} />
                <Route path="/admin/liveclass" element={<PrivateRoute allowedRoles={['admin']}><AdminBatchesManage /></PrivateRoute>} />
                <Route path="/admin/liveclass/slots/:slotId" element={<PrivateRoute allowedRoles={['admin']}><AdminSlots /></PrivateRoute>} />

                <Route path="/zoom/admin/liveclass/slots/:slotId/meeting" element={<PrivateRoute allowedRoles={['admin']}><AdminZoom /></PrivateRoute>} />
                <Route path="/admin/members" element={<PrivateRoute allowedRoles={['admin']}><AdminUser /></PrivateRoute>} />
                <Route path="/admin/tests" element={<PrivateRoute allowedRoles={['admin']}><AdminTests /></PrivateRoute>} />
                <Route path="/admin/tests/:testId" element={<PrivateRoute allowedRoles={['admin']}><AdminTest /></PrivateRoute>} />
                <Route path="/admin/analytics" element={<PrivateRoute allowedRoles={['admin']}><AdminAnalytics /></PrivateRoute>} />

                {/* student */}
                <Route path="/student/dashboard" element={<PrivateRoute allowedRoles={['student']}><StudentDashboard /></PrivateRoute>} />
                <Route path="/student/profile" element={<PrivateRoute allowedRoles={['student']}><StudentProfile /></PrivateRoute>} />
                <Route path="/student/liveclass" element={<PrivateRoute allowedRoles={['student']}><StudentLiveClass /></PrivateRoute>} />
                <Route path="/student/liveclass/slots/:slotId" element={<PrivateRoute allowedRoles={['student']}><StudentSlots /></PrivateRoute>} />
                <Route path="/zoom/student/liveclass/slots/:slotId/meeting" element={<PrivateRoute allowedRoles={['student']}><StudentZoom /></PrivateRoute>} />
                <Route path="/student/liveclass/slots/:slotId/live-stream" element={<PrivateRoute allowedRoles={['student']}><StudentLiveStream /></PrivateRoute>} />
                <Route path="/student/questions" element={<PrivateRoute allowedRoles={['student']}><StudentQuestionsPage /> </PrivateRoute>} />
                <Route path="/student/tests" element={<PrivateRoute allowedRoles={['student']}><StudentTests /></PrivateRoute>} />
                <Route path="/student/practice-tests" element={<PrivateRoute allowedRoles={['student']}><StudentPracticeTests /></PrivateRoute>} />
                <Route path="/student/virtual-tests" element={<PrivateRoute allowedRoles={['student']}><StudentVirtualTests /></PrivateRoute>} />
                <Route path="/student/tests/:testId" element={<PrivateRoute allowedRoles={['student']}><StudentTest /></PrivateRoute>} />
                <Route path="/student/practice-tests/:testId" element={<PrivateRoute allowedRoles={['student']}><StudentPracticeTest /></PrivateRoute>} />
                <Route path="/student/virtual-tests/:testId" element={<PrivateRoute allowedRoles={['student']}><StudentVirtualTest /></PrivateRoute>} />
                <Route path="/student/syllabus" element={<PrivateRoute allowedRoles={['student']}><StudentSyllabus /></PrivateRoute>} />
                <Route path="/student/analytics" element={<PrivateRoute allowedRoles={['student']}><StudentAnalytics /></PrivateRoute>} />

                {/* teacher */}
                <Route path="/teacher/dashboard" element={<PrivateRoute allowedRoles={['teacher']}><TeacherDashboard /></PrivateRoute>} />
                <Route path="/teacher/profile" element={<PrivateRoute allowedRoles={['teacher']}><TeacherProfile /></PrivateRoute>} />
                <Route path="/teacher/liveclass" element={<PrivateRoute allowedRoles={['teacher']}><TeacherLiveClass /></PrivateRoute>} />
                <Route path="/teacher/liveclass/slots/:slotId" element={<PrivateRoute allowedRoles={['teacher']}><TeacherSlots /></PrivateRoute>} />
                <Route path="/teacher/syllabus" element={<PrivateRoute allowedRoles={['teacher']}><TeacherSyllabus /></PrivateRoute>} />
                <Route path="/teacher/tests" element={<PrivateRoute allowedRoles={['teacher']}><TeacherTests /></PrivateRoute>} />
                <Route path="/teacher/analytics" element={<PrivateRoute allowedRoles={['teacher']}><TeacherAnalytics /></PrivateRoute>} />
            </Routes>
        </div>
    )
}

export default JeetaApp;