
const MessagePage = ({
    imgsrc = "https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75",
    title = "Page not found",
    message1 = "404",
    message2 = "Sorry, we couldn't find the page you're looking for",
    message3 = "Back to home" }) => {
    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
            <div className="relative isolate w-full h-full overflow-hidden flex items-center justify-center">
                <img
                    alt=""
                    src={imgsrc}
                    className="absolute -z-10 max-h-full max-w-450 object-contain opacity-40"
                />
                <div className="mx-auto max-w-7xl px-6 py-16 text-center sm:py-20 lg:px-8">
                    <p className="text-base font-semibold leading-8 text-gray-500">{message1}</p>
                    <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-700 sm:text-4xl">{title}</h1>
                    <p className="mt-4 text-base text-gray-500 sm:mt-6">{message2}</p>
                    <div className="mt-10 flex justify-center">
                        {message3 && <a href="#" className="text-sm font-semibold leading-7 text-gray-600">
                            <span aria-hidden="true">&larr;</span> {message3}
                        </a>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MessagePage;
