import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./AnalyticsSidebar.css";

import Logo from "../../../Assets/Analytics.png";

import { SidebarItems } from "./SidebarItems";
import { HiBars2 } from "react-icons/hi2";
import { motion } from "framer-motion";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../../services/UserService";


const AnalysisSidebar = ({ user = STUDENT_USER_TYPE, selectedTab = null, setSelectedTab = null }) => {
    const [expanded, setExpaned] = useState(true);
    const navigate = useNavigate();

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    return (
        <>
            {/* <div className="analysis-bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
                <HiBars2 />
            </div> */}
            <motion.div className='analytics-sidebar'
                variants={sidebarVariants}
                animate={window.innerWidth <= 768 ? `${expanded}` : ''}
            >
                {/* logo */}
                <div className="analytics-logo">
                    <img src={Logo} alt="logo" />
                    <span>
                        A<span>n</span>a<span>l</span>y<span>t</span>i<span>cs</span>
                    </span>
                </div>

                <div className="analytics-menu">
                    {SidebarItems.map((item, index) => {
                        return (
                            <div
                                className={selectedTab === item.heading ? "menuItem active" : "menuItem"}
                                key={index}
                                onClick={() => setSelectedTab(item.heading)}
                            >
                                <item.icon />
                                <span>{item.heading}</span>
                            </div>
                        );
                    })}
                </div>
            </motion.div>
        </>
    );
};

export default AnalysisSidebar;