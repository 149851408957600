import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import moment from "moment";

import './dashboard.css'

import HeaderSection from "../Common/Tailwind/PageSections/HeaderSection";
import AdminDashboardItems from "./AdminDashboardItems";
import TeacherDashboardItems from "./TeacherDashboardItems";
import StudentDashboardItems from "./StudentDashboardItems";
import DashboardSidebar from "./Sidebar/DashboardSidebar";
import LoadingPage from "../Common/LoadingPage";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchNextSlot, securedFetchTimetableSlotById } from "../../services/TimetableService";
import { securedFetchSlots } from "../../services/BatchService";
import StudentQuestionRecommendation from "../Recommendation/StudentQuestionRecommendation";
import Spinner from "../Common/Tailwind/Spinner";

const Dashboard = ({ user = ADMIN_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
            if (fetchedUserInfo === null) {
                return;
            }
            setUserInfo(fetchedUserInfo);
            setIsLoading(false);
        })();
    }, []);

    const renderDashboardContent = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <div className="dashboard-main flex">

                {userInfo && (
                    <>
                        {(user === ADMIN_USER_TYPE) ? (
                            <AdminDashboardItems selectedTab={selectedTab} />
                        ) : ((user === TEACHER_USER_TYPE) ? (
                            <TeacherDashboardItems selectedTab={selectedTab} />
                        ) : (
                            <StudentDashboardItems selectedTab={selectedTab} />
                        ))}
                    </>

                )}

            </div>
        )
    }
    return (
        <div className="page">

            <div className="dashboard flex">

                <div className="dashboard-header flex">
                    {/* <div className="title"> */}
                    {user === ADMIN_USER_TYPE && (
                        <HeaderSection header={"Boost Your Productivity and Success with JEETA!"} />
                        // <h2>Boost Your Productivity and Success with JEETA!</h2>
                    )}
                    {user === TEACHER_USER_TYPE && (
                        <HeaderSection header={"Engage, Interact and Support Your Students with JEETA!"} />
                        // <h2>Engage, Interact and Support Your Students with JEETA!</h2>
                    )}
                    {user === STUDENT_USER_TYPE && (
                        <HeaderSection header={"Make Your Dream Come True with JEETA!"} />
                        // <h2>Make Your Dream Come True with JEETA!</h2>
                    )}
                    {/* </div> */}
                </div>

                <div className="dashboard-glass">
                    {/* Sidebar */}
                    <DashboardSidebar user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    {renderDashboardContent()}

                </div>

            </div>

        </div>
    )
}

export default Dashboard