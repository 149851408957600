import React, { useState } from "react";
import "./Card.css";
import "react-circular-progressbar/dist/styles.css";

import { CircularProgressbar } from "react-circular-progressbar";
import Chart from "react-apexcharts";

// parent Card

const Card = (props, setSelectedTab = null) => {
    const [expanded, setExpanded] = useState(false);
    const Png = props.png;

    return (
        <div
            className="CompactCard"
            id={props.title}
            onClick={() => setSelectedTab(props.title)}
        >

            <div className="radialBar">
                <CircularProgressbar
                    value={props.barValue}
                    text={`${props.barValue}%`}
                />
            </div>

            <div className="cardInfo">
                <span className="value">{props.value}</span>
                <span className="title"> {props.title}</span>
            </div>

            {/* <div className="detail"> */}
            {/* <Png /> */}
            {/* <span>Total</span> */}
            {/* <span>{props.value}</span> */}
            {/* <span>{props.valueTitle}</span> */}
            {/* </div> */}
        </div>
    );
};

export default Card;