import React, { useState, useEffect } from 'react';


const ProgressBars = ({ steps, handleStepClick }) => {

    // const [clickedSteps, setClickedSteps] = useState([]);
    const [clickedStepIndex, setClickedStepIndex] = useState(0);

    const handleClick = (index) => {
        // setClickedSteps(prev => [...new Set([...prev, index])]);
        setClickedStepIndex(index);
        handleStepClick(steps[index].start_time);
    };

    return (
        <nav aria-label="Progress">
            <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                {steps.map((step, index) => {

                    return (
                        <li key={index} className="md:flex-1 min-w-[180px]">
                            <div
                                onClick={() => handleClick(index)}
                                className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2 cursor-pointer
                                    ${index === clickedStepIndex
                                        ? 'border-[var(--SecondaryColor)] hover:border-[var(--SecondaryColor)]'
                                        : 'border-gray-200 hover:border-gray-300'}`}
                                aria-current={index === clickedStepIndex ? 'step' : undefined}
                            >
                                <span className={`text-sm font-semibold
                                    ${index === clickedStepIndex
                                        ? 'text-[var(--PrimaryColor)] group-hover:text-[var(--PrimaryColor)]'
                                        : 'text-gray-500 group-hover:text-gray-700'}`}>
                                    {step.start_time.split('.')[0]}
                                </span>
                                <span className="text-sm font-medium">{step.refined_title}</span>
                            </div>
                        </li>
                    );
                })}
            </ol>
        </nav>
    )
}

export default ProgressBars;