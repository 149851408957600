import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";
import { STUDENT_USER_TYPE } from "./UserService";

export const createBatch = async (data) => {
    const response = await fetch(`${BACKEND_URL}/batches`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchBatchesByGrade = async (grade) => {
    const response = await fetch(`${BACKEND_URL}/batches?grade=${encodeURIComponent(grade)}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchBatchesByFilter = async (filter) => {
    const response = await fetch(`${BACKEND_URL}/batches${createParams(filter)}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchGrades = async () => {
    const response = await fetch(`${BACKEND_URL}/grades`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchSlots = async (filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    let urlString = `${BACKEND_URL}/timetable/slots${params}`
    const response = await fetch(urlString, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const securedCreateBatch = withAuth(createBatch);
export const securedFetchBatchesByGrade = withAuth(fetchBatchesByGrade);
export const securedFetchGrades = withAuth(fetchGrades);
export const securedFetchSlots = withAuth(fetchSlots);
export const securedFetchBatchesByFilter = withAuth(fetchBatchesByFilter);

export const hasGradeFetchAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}
