import React from 'react';

import { RiDeleteBin5Line } from 'react-icons/ri'

import './Pdf.css';


const PdfPreview = ({ pdfs, setPdfs, pdfFileNames }) => {

    const onDelete = (pdf) => (event) => {
        setPdfs(pdfs.filter((e) => e !== pdf));
    }

    return (
        <div className="previewPdfsContainer grid" value={pdfs} >
            {pdfs &&
                pdfs.map((pdf, Idx) => {
                    return (
                        <div key={pdf} className="previewPdfs flex">
                            <button className="delete-btn" onClick={onDelete(pdf)}> <RiDeleteBin5Line className="icon" /> </button>
                            {pdfFileNames.length > 0 ? (
                                <p>{Idx + 1} {pdfFileNames[Idx].name}</p>
                            ) : (
                                <p>{Idx + 1}</p>
                            )}

                        </div>
                    );
                })}
        </div>
    )
}

export default PdfPreview;
