import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from "react-dropdown-select";

import './home.css'

import Aos from 'aos';
import 'aos/dist/aos.css';

import logo from '../../Assets/FullLogo_Transparent.png'
import Student from '../../Assets/Student.png'
import Teacher from '../../Assets/Teacher.png'
import Admin from '../../Assets/Admin.png'
import Hero from '../../Components/Hero/Hero'
import LoginForm from '../../Components/Authentication/LoginForm';
import { loginWithPassword, navigateCallbackOptions } from '../../services/AuthenticationService';
import { checkUserType, securedFetchUserById } from '../../services/UserService';
import { useAuth } from '../../Components/Authentication/AuthContext';

const Home = () => {

  const profiles = [
    { value: Teacher, label: "Teacher", description: "Innovate Your Teaching with JEETA", uri: 'teacher', navigateTo: '/teacher/dashboard' },
    { value: Student, label: "Student", description: "Achieve Your Dream with JEETA", uri: 'student', navigateTo: '/student/dashboard' },
    { value: Admin, label: "Admin", description: "Transform Your Institute with JEETA", uri: 'admin', navigateTo: '/admin/dashboard' },
  ];

  const [selectedProfile, setSelectedProfile] = useState({ value: logo, label: '', description: '', uri: '', navigateTo: '' });
  const [showLogIn, setShowLogIn] = useState(false);

  const navigate = useNavigate();

  const {
    userType, setUserType,
    config, setConfig
  } = useAuth();

  const onSeclectedProfileChange = (selected) => {
    setSelectedProfile(selected[0]);
    setShowLogIn(true);
  }

  useEffect(() => {
    Aos.init({ duration: 1000 });

    (async () => {
      const errorCallback = {
        authErrorCallback: () => {
          return;
        }
      }
      const fetchedUserInfo = await securedFetchUserById(errorCallback);
      if (fetchedUserInfo === null) {
        return;
      }
      const currentUserType = checkUserType(fetchedUserInfo);
      setUserType(currentUserType)
      navigate(`/${currentUserType}/dashboard`)
    })();
  }, []);

  const handleLogin = async (event, credentials) => {
    event.preventDefault();
    if (credentials.username.length === 0 || credentials.password.length === 0) {
      return;
    }
    const response = await loginWithPassword(selectedProfile.uri, credentials);
    if (response === null) {
      return;
    }
    setUserType(selectedProfile.uri);
    const data = await response.json();
    if (response.ok) {
      navigate(selectedProfile.navigateTo);
    } else {
      alert(data.detail);
    }
  };

  const handleRegister = (event) => {
  };

  return (

    <div className="home" >

      {showLogIn ? (
        <Hero
          cName="profile-hero"
          img={selectedProfile.value}
          title={selectedProfile.label}
          description={selectedProfile.description}
        />
      ) : (
        <Hero
          cName="hero noProfile"
          img={selectedProfile.value}
          title={selectedProfile.label}
          description={selectedProfile.description}
        />
      )}

      <Select
        className="profileSelect"
        name="selectProfile"
        placeholder='Select Your Profile...'
        options={profiles}
        onChange={onSeclectedProfileChange}
        searchable={false}
      >
      </Select>

      <LoginForm handleLogin={handleLogin} handleRegister={handleRegister} showLogIn={showLogIn} />

    </div >
  )
};

export default Home;
