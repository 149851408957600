import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';

import './Video.css';

const VideoUpload = ({ title = 'Videos', videos, setVideos, label, maxSize = 1, setVideoFileNames }) => {

    const [addEnabled, setAddEnabled] = useState(true);

    useEffect(() => {
        if (maxSize && videos.length === maxSize) {
            setAddEnabled(false);
        } else {
            setAddEnabled(true);
        }
    }, [videos]);

    const onDrop = useCallback((selectedFiles) => {
        const selectedFilesArray = Array.from(selectedFiles);
        setVideos((previousPdfArray) => previousPdfArray.concat(selectedFilesArray));
        setVideoFileNames(selectedFiles);
    }, [setVideos]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.mp4', '.mkv']
        },
        multiple: false,
        maxFiles: 1,
    });

    return (
        <div className="videoInputContainer flex">

            <label className="title">{title}</label>

            <div className="videoInput flex">
                {addEnabled && (
                    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} flex`}>
                        <input {...getInputProps()} id={`${label.toLowerCase()}-pdf-upload`} name="pdfs" />
                        {isDragActive ? (
                            <span className="drag-n-drop-msg">Drop the files here ...</span>
                        ) : (
                            <span className="drag-n-drop-msg">Drag 'n' drop files here <br />
                                or <br />
                                Click to select files
                            </span>
                        )}
                    </div>
                )}

                {videos.length > 0 &&
                    (videos.length > maxSize + 1 ? (
                        <div className="upload-video-number" id="error">
                            <p className="warning-msg">
                                Can't upload more than {maxSize} pdfs! <br />
                                <span>
                                    Please delete <b>{videos.length - maxSize}</b> of them.
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="upload-pdf-number">
                            TOTAL {videos.length} VIDEO{videos.length === 1 ? '' : 'S'}
                        </div>
                    ))
                }
            </div>

        </div >
    )
}

export default VideoUpload;
