import React, { useState, useEffect } from 'react';

import './Recommendation.css'

import Break from '../../Assets/Break.png';
import LoadingPage from '../Common/LoadingPage';
import QuestionSlider from '../Questions/QuestionView/QuestionSlider/QuestionSlider';

import { STUDENT_USER_TYPE } from '../../services/UserService';
import { areTestQuestionsVisible } from '../../services/TestService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchQuestionsByIds } from '../../services/QuestionService';
import { securedFetchStudentRecommendationQuestions } from '../../services/RecommendationService';
import Spinner from '../Common/Tailwind/Spinner';
import MessagePage from '../Common/Tailwind/PageSections/MessagePage';


const StudentQuestionRecommendation = ({ user = STUDENT_USER_TYPE }) => {
    const [questions, setQuestions] = useState(null);
    const [questionCountByStrategy, setQuestionCountByStrategy] = useState({});
    const [selectedTab, setSelectedTab] = useState('weak_topic');
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 860);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 860) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }

            if (window.innerWidth < 600) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        // Set the sidebar state based on the initial window width
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        (async () => {
            const recommendedQuestions = await securedFetchStudentRecommendationQuestions('standalone', {}, navigateCallbackOptions(navigate));
            if (recommendedQuestions === null) {
                return;
            }
            const recommendedParagraphs = await securedFetchStudentRecommendationQuestions('paragraph', {}, navigateCallbackOptions(navigate));
            if (recommendedParagraphs === null) {
                return;
            }
            const allQuestionIds = recommendedQuestions.data.map(question => question.question_id);
            const allParagraphIds = recommendedParagraphs.data.map(paragraph => paragraph.paragraph_id);
            const allQuestionData = await securedFetchQuestionsByIds('standalone', allQuestionIds, null, navigateCallbackOptions(navigate));
            if (allQuestionData === null) {
                return;
            }
            const questionDatas = allQuestionData.data.filter(question => question.question_type === 'standalone').map(question => {
                return { ...recommendedQuestions.data.find(recommendedQuestion => recommendedQuestion.question_id === question.id), question: question, type: 'standalone' };
            })
            const allParagraphsData = await securedFetchQuestionsByIds('paragraph', allParagraphIds, null, navigateCallbackOptions(navigate));
            if (allParagraphsData === null) {
                return;
            }
            const paragraphDatas = allParagraphsData.data.map(paragraph => {
                return { ...recommendedParagraphs.data.find(recommendedParagraph => recommendedParagraph.paragraph_id === paragraph.id), question: paragraph, type: 'paragraph' };
            });
            const currentQuestionCountsByStrategy = {};
            recommendedQuestions.data.map(recommendedQuestion => {
                if (currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] == null) {
                    currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] = 0;
                }
                currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] += 1;
            });
            setQuestionCountByStrategy(currentQuestionCountsByStrategy);
            setQuestions([...questionDatas, ...paragraphDatas]);
        })();
    }, []);

    const renderQuestionSlider = () => {
        if (questions === null) {
            return <Spinner />;
        }
        const filteredQuestionDatas = questions.filter(question => question.selection_strategy === selectedTab);
        const filteredQuestions = filteredQuestionDatas.map(question => question.question);
        const filteredQuestionTypes = filteredQuestionDatas.map(question => question.type);
        if (filteredQuestions.length === 0) {
            return (
                <>
                    <div className="recommendation-strategy">
                        <h3>Topic Recommendation: </h3>
                        <div className="recommendation-strategy-btns">
                            <button className={`recommendation-strategy-btn ${selectedTab === "weak_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('weak_topic') }}>Weak</button>
                            <button className={`recommendation-strategy-btn ${selectedTab === "less_solved_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('less_solved_topic') }}>Less Solved</button>
                            <button className={`recommendation-strategy-btn ${selectedTab === "recently_learned_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('recently_learned_topic') }}>Recently Learned</button>
                        </div>
                    </div>

                    <MessagePage imgsrc={Break}
                        title={"NO recommended questions"}
                        message1={"Take a breather! There are"}
                        message2={"from JEETA today."}
                        message3={"Review your progress instead"} />
                </>
            );
        }
        const filteredQuestionIds = filteredQuestions.map(question => question.id)
        const selectedQuestionId = filteredQuestions[0].id

        return (
            <>
                <div className="recommendation-strategy">
                    <h3>Topic Recommendation: </h3>
                    <div className="recommendation-strategy-btns">
                        <button className={`recommendation-strategy-btn ${selectedTab === "weak_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('weak_topic') }}>Weak</button>
                        <button className={`recommendation-strategy-btn ${selectedTab === "less_solved_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('less_solved_topic') }}>Less Solved</button>
                        <button className={`recommendation-strategy-btn ${selectedTab === "recently_learned_topic" ? 'active' : ''}`} onClick={() => { setSelectedTab('recently_learned_topic') }}>Recently Learned</button>
                    </div>
                </div>

                <div className="title flex" >
                    <h3>
                        JEETA has prepared
                        {isTabletView ? <br /> : ' '}
                        {questionCountByStrategy[selectedTab] || 0}
                        {isMobileView ? <br /> : ' '}
                        new questions for you today!
                    </h3>
                </div>

                <div className="recommendedQuestionSlider flex">
                    <QuestionSlider questionTypes={filteredQuestionTypes} questionId={selectedQuestionId} showAnswers={true} showImages={true} type={'standalone'} user={user} questionIds={filteredQuestionIds} questionIdIndex={0} />
                </div>
            </>
        );
    }

    return (
        <>
            {renderQuestionSlider()}
        </>
    );
}

export default StudentQuestionRecommendation;