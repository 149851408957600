
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./StudentSelectTestPageSidebar.css";

import Logo from "../../../../Assets/Test.png";

import { StudentSelectTestPageSidebarItems } from "./StudentSelectTestPageSidebarItems";

import { motion } from "framer-motion";
import { STUDENT_USER_TYPE } from "../../../../services/UserService";

const StudentSelectTestPageSidebar = ({ user = STUDENT_USER_TYPE, selectedTab = null, setSelectedTab = null }) => {
    const [expanded, setExpaned] = useState(true);
    const SidebarItems = StudentSelectTestPageSidebarItems;
    const navigate = useNavigate();

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    return (

        <motion.div className='tests-sidebar'
            variants={sidebarVariants}
            animate={window.innerWidth <= 768 ? `${expanded}` : ''}
        >

            <div className="tests-logo">
                <img src={Logo} alt="logo" />
                <span>
                    T<span>e</span>s<span>t</span>s
                </span>
            </div>

            <div className="tests-menu">
                {SidebarItems.map((item, index) => {
                    return (
                        <div
                            className={selectedTab === item.name ? "menuItem active" : "menuItem"}
                            key={index}
                            onClick={() => navigate(item.url)}
                        >
                            <item.icon className="icon" />
                            <span>{item.heading}</span>
                        </div>
                    );
                })}
            </div>
        </motion.div>

    );

};

export default StudentSelectTestPageSidebar;