// import React from 'react';
// import { Radar } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     RadialLinearScale,
//     PointElement,
//     LineElement,
//     Filler,
//     Tooltip,
//     Legend,
// } from 'chart.js';

import ReactApexChart from "react-apexcharts";

// ChartJS.register(
//     RadialLinearScale,
//     PointElement,
//     LineElement,
//     Filler,
//     Tooltip,
//     Legend
// );

// function RadarChart({ data }) {

//     // console.log(analyticsData);


//     const options = {
//         scales: {
//             r: {
//                 pointLabels: {
//                     font: {
//                         size: 16, // Customize the font size for the labels around the radar chart
//                     }
//                 },
//                 ticks: {
//                     font: {
//                         size: 14, // Customize the font size for the ticks
//                     }
//                 }
//             }
//         },
//         plugins: {
//             tooltip: {
//                 enabled: true,
//                 backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                 borderColor: '#ffffff',
//                 borderWidth: 1,
//                 bodyColor: '#ffffff',
//                 titleColor: '#ffffff',
//                 titleMarginBottom: 10,
//                 bodySpacing: 5,
//                 mode: 'index',
//                 intersect: false,
//                 callbacks: {
//                     label: function (tooltipItem) {
//                         // You can add more logic here to customize the tooltip text
//                         return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.r} units`;
//                     }
//                 }
//             },
//             legend: {
//                 labels: {
//                     font: {
//                         size: 14, // Customize the font size for the legend labels
//                     }
//                 }
//             }
//         }
//     };

//     return (
//         <>
//             <Radar data={data} options={options} />
//         </>
//     );
// }

// export default RadarChart;


const RadarChart = ({ width, height, data }) => {
    console.log(data);
    const state = {

        series: data.datasets,
        // [{
        //     name: 'Series 1',
        //     data: [80, 50, 30, 40, 100, 20],
        // }, {
        //     name: 'Series 2',
        //     data: [20, 30, 40, 80, 20, 80],
        // }, {
        //     name: 'Series 3',
        //     data: [44, 76, 78, 13, 43, 10],
        // }],
        options: {
            chart: {
                // height: 350,
                type: 'radar',
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                }
            },
            title: {
                text: undefined
            },
            stroke: {
                width: 2
            },
            fill: {
                opacity: 0.1
            },
            markers: {
                size: 0
            },
            yaxis: {
                stepSize: 10
            },
            xaxis: {
                categories: data.labels
            },
            tooltip: {
                // theme: undefined,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                // offsetX: 40
            }
        },


    };

    return (
        <div style={{ width, height, overflowX: 'auto' }}>
            <ReactApexChart options={state.options} series={state.series} type="radar" />
        </div>
    );

}

export default RadarChart;