import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import videojs from 'video.js';
import 'videojs-mobile-ui';
import 'video.js/dist/video-js.css';
import 'videojs-markers';
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'videojs-hls-quality-selector';
import Spinner from '../../Common/Tailwind/Spinner';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'


import './Video.css';
import { durationStringToSeconds } from '../../../services/DateService';
import ChaptersTable from './ChaptersTable';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { TbLockAccess } from 'react-icons/tb';
import { securedCreateVideoAccessRequestBulk, securedFetchClassLectureVideoById, securedFetchRecordedVideoByFilter, securedFetchRecordedVideoById, securedFetchVideoAccessRequestsByFilter } from '../../../services/VideoService';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BellAlertIcon, InformationCircleIcon } from '@heroicons/react/20/solid';


const VideoViewer = ({ videoId, videoType = 'recorded_lecture', defaultApproverId = null, inputVideoData = null, startSegmentId = null, user = ADMIN_USER_TYPE, videoTitle = '' }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [videoData, setVideoData] = useState(inputVideoData);
    const [accessStatus, setAccessStatus] = useState(null);
    const navigate = useNavigate();
    const fetchMethodMap = {
        'recorded_lecture': securedFetchRecordedVideoById,
        'class_lecture': securedFetchClassLectureVideoById
    }
    const getStartTimeInSeconds = () => {
        if (startSegmentId == null || videoData == null || videoData.video == null) {
            return 0;
        }
        const matchingSegment = videoData.video.video_segments.find(segment => segment.id === parseInt(startSegmentId));
        if (!matchingSegment) {
            return 0;
        }
        const startTimeInSeconds = durationStringToSeconds(matchingSegment.start_time);
        return startTimeInSeconds;
    }

    const handleAccessRequestClick = async () => {
        const data = { video_id: videoId, auto_generated_request_message: videoTitle, video_type: videoType };
        if (defaultApproverId !== null) {
            data["video_access_request_decision_makers"] = [{ decision_maker_id: defaultApproverId }];
        }
        const accessRequest = await securedCreateVideoAccessRequestBulk({ video_access_request_creates: [data] }, navigateCallbackOptions(navigate));
        if (accessRequest === null) {
            return;
        }
        setAccessStatus('waiting');
    }

    const getUrl = (inputVideoData) => {
        if (inputVideoData == null || inputVideoData.video == null) {
            return null;
        }
        return inputVideoData.video.processed_url != null ? inputVideoData.video.processed_url : inputVideoData.video.url;
    }

    const getProcessedStatus = async (url) => {
        const response = await fetch(url);
        return response.ok;
    }

    const fetchAndSetVideoData = async () => {
        const response = await fetchMethodMap[videoType](videoId, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        if (response.status !== 404) {
            setAccessStatus('approve');
            setVideoData({ ...response, is_processed: (await getProcessedStatus(getUrl(response))) });
            return;
        }
        const accessResponse = await securedFetchVideoAccessRequestsByFilter({ video_ids: [videoId] }, navigateCallbackOptions(navigate));
        if (accessResponse === null) {
            return;
        }
        if (accessResponse.data.length === 0) {
            setAccessStatus('none');
            return;
        }
        if (accessResponse.data[0].latest_request_status !== 'approve') {
            setAccessStatus(accessResponse.data[0].latest_request_status);
            return;
        }

        setAccessStatus('approve');
        setVideoData({ ...response, is_processed: (await getProcessedStatus(getUrl(response))) });
    }

    useEffect(() => {
        (async () => {
            if (videoData || !fetchMethodMap[videoType]) {
                return;
            }
            await fetchAndSetVideoData();
        })();
    }, [videoId]);

    useEffect(() => {
        if (!videoData || !videoData.video || getUrl(videoData) == null || !videoData.is_processed) {
            return;
        }
        const url = getUrl(videoData);
        if (url == null) {
            return;
        }
        const videoType = url.split('.')[-1] === 'mp4' ? 'video/mp4' : 'application/x-mpegurl';
        playerRef.current = videojs(videoRef.current, {
            controls: true,
            fluid: true,
            preload: 'auto',
            sources: [{ src: url, type: videoType }],
            controlBar: {
                skipButtons: {
                    forward: 10,
                    backward: 10
                }
            },
            html5: {
                vhs: {
                    overrideNative: true
                }
            },
            enableSmoothSeeking: true,
            playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2, 4],
            userAction: {
                hotkeys: true
            }
        });

        playerRef.current.ready(() => {
            playerRef.current.currentTime(getStartTimeInSeconds());
            // this.play(); // Optionally, start playback automatically
        });

        const onPlay = () => {
            // console.log('play', playerRef.current.currentTime());
        };

        const onPause = () => {
            // console.log('pause', playerRef.current.currentTime());
            playerRef.current.bigPlayButton.show();
        };

        playerRef.current.on("play", onPlay);
        playerRef.current.on("pause", onPause);
        if (videoType === 'application/x-mpegurl') {
            playerRef.current.hlsQualitySelector({
                displayCurrentQuality: true
            });
        }
        // Marker and mobile UI setup omitted for brevity

        return () => {
            if (playerRef.current) {
                // console.log('unmount', playerRef.current.currentTime());
                playerRef.current.off("play", onPlay);
                playerRef.current.off("pause", onPause);
                playerRef.current.dispose();
            }
        };
    }, [videoData]);

    if (getUrl(videoData) == null && accessStatus === null) {
        return <Spinner />;
    }

    if (accessStatus === 'none') {
        return (
            <>
                <div className="bg-white px-4 pb-4 pt-1 sm:p-2 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                        </div>
                        <div className="mt-1 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                NOT Accessible
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Access required. Please submit a request for permission.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                        type="button"
                        onClick={handleAccessRequestClick}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                        Request Access
                    </button>
                </div>
                {/* 
                <button className='btn' onClick={handleAccessRequestClick} >
                    <TbLockAccess /> Request Access for Viewing
                </button> */}
            </>
        )
    }

    if (accessStatus === 'waiting') {
        return (<div className="text-center">

            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <BellAlertIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />
            </div>

            <h3 className="text-base font-semibold leading-6 pt-1 pb-4 text-[var(--PrimaryColor)]">
                Request Submitted Successfully!
                <br />
                Your access request is now pending approval.
            </h3>
        </div>)
    }

    if (videoData == null) {
        return <Spinner />;
    }

    if (!videoData.is_processed) {
        return (<div className="text-center">

            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <BellAlertIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />
            </div>

            <h3 className="text-base font-semibold leading-6 pt-1 pb-4 text-[var(--PrimaryColor)]">
                Video is being processed.
                <br />
                It will be available soon!
            </h3>
        </div>)
    }

    return (

        <div>
            <div className="mt-1 text-center sm:mt-1">

                {videoTitle &&
                    <h3 className="text-lg md:text-xl lg:text-2xl font-bold leading-6 text-[var(--PrimaryColor)]">
                        {videoTitle}
                    </h3>
                }

                <div className="mt-2">
                    <div data-vjs-player>
                        <video ref={videoRef} className="videoPlayer video-js vjs-default-skin vjs-big-play-centered w-full">
                        </video>
                    </div>
                    {
                        videoData.video.video_segments && (
                            <ChaptersTable segments={videoData.video.video_segments} playerRef={playerRef} />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default VideoViewer;
