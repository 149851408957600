import React, { useEffect, useState } from 'react';

import 'aos/dist/aos.css'

import "./Doubt.css"

import { ADMIN_USER_TYPE } from '../../services/UserService';
import { DisclosurePanel } from '@headlessui/react';


const DoubtCard = ({ doubtData, numberPrefix = "", user = ADMIN_USER_TYPE }) => {


    return (
        <>
            <DisclosurePanel as="dd" className="mt-2 pr-12">
                <p className="text-base leading-7 text-gray-600">{`${numberPrefix} ${doubtData.doubt_text}`}</p>
            </DisclosurePanel>
        </>
    );
};

export default DoubtCard;
