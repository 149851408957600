import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';

import './Image.css';

const ImageUpload = ({ title = 'Images', images, selectImages, label, maxSize = 1, setImageFileNames }) => {

    const [addEnabled, setAddEnabled] = useState(true);

    const onSelectImage = (selectImages) => (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file)
        });

        selectImages((previousImageArray) => previousImageArray.concat(imagesArray));
        event.target.value = null;
    };

    useEffect(() => {
        if (maxSize && images.length === maxSize) {
            setAddEnabled(false);
        } else {
            setAddEnabled(true);
        }
    }, [images]);

    const onDrop = useCallback((selectedFiles) => {
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        selectImages((previousImageArray) => previousImageArray.concat(imagesArray));
        setImageFileNames(selectedFiles);
    }, [selectImages]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg']
        },
        multiple: true,
        maxFiles: 10,
    });

    return (
        <div className="imageInputContainer flex">

            <label className="title">{title}</label>

            <div className="imageInput flex">
                {addEnabled && (
                    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} flex`}>
                        <input {...getInputProps()} id={`${label.toLowerCase()}-image-upload`} name="images" />
                        {isDragActive ? (
                            <span className="drag-n-drop-msg">Drop the files here ...</span>
                        ) : (
                            <span className="drag-n-drop-msg">Drag 'n' drop files here <br />
                                or <br />
                                Click to select files
                            </span>
                        )}
                    </div>
                )}

                {images.length > 0 &&
                    (images.length > maxSize + 1 ? (
                        <div className="upload-img-number" id="error">
                            <p className="warning-msg">
                                Can't upload more than {maxSize} images! <br />
                                <span>
                                    Please delete <b>{images.length - maxSize}</b> of them.
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="upload-img-number">
                            TOTAL {images.length} IMAGE{images.length === 1 ? '' : 'S'}
                        </div>
                    ))
                }
            </div>

        </div >
    )
}

export default ImageUpload;
