import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import moment from 'moment';

import './Test.css'

import bkgImg from '../../Assets/Test.png'

import SelectionDropdown from '../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import TestEdit from './TestEdit';
import TestHeader from './TestHeader';
import TestUpsert from './TestCreate/TestCreate';
import { FULL_TEST_TYPES, TEST_CREATE_TYPE_BASIC, hasTestCreateAuthority, hasTestEditAuthority, securedCreateTest, securedFetchAllTestScoringFormats, securedFetchAllTestSeries, securedFetchTestById, securedFetchTestReport, securedFetchTests, securedStartTest } from '../../services/TestService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { MdAddCircleOutline } from "react-icons/md";
import { AiOutlineEdit } from 'react-icons/ai';
import Spinner from '../Common/Tailwind/Spinner';

const SingleTestPage = ({ id, user = ADMIN_USER_TYPE }) => {
    const [testData, setTestData] = useState(null);
    const [testStatus, setTestStatus] = useState(null);
    const [isTestInfoEditOpen, setTestInfoEditOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const refreshTest = async () => {
        const testResponse = await securedFetchTestById(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
        if (testResponse === null) {
            return;
        }
        setTestData(testResponse);
    }

    useEffect(() => {
        (async () => {
            await refreshTest();
        })();
    }, [])

    if (testData === null) {
        return;
    }

    const renderTest = () => {


        return (
            <div className="tests-glass flex">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {testStatus === 'NOT STARTED' && (
                            <div className="testInfo-edit-btn flex" onClick={() => setTestInfoEditOpen(true)}>
                                <p className="msg flex">TEST INFO <AiOutlineEdit className="icon" /></p>
                            </div>
                        )}
                        <TestHeader testData={testData} testStatus={testStatus} setTestStatus={setTestStatus} user={user} />
                        <TestEdit id={id} testData={testData} testStatus={testStatus} />
                        <TestUpsert testData={testData} onTestUpsert={onTestUpsert} isOpen={isTestInfoEditOpen} onRequestClose={() => setTestInfoEditOpen(false)} />
                    </>
                )}
            </div >
        )
    }

    const onTestUpsert = async () => {
        await refreshTest();
    }

    return (
        <div className="singleTestPage">

            {renderTest()}

        </div>
    );
}

export default SingleTestPage;
