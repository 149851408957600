import React, { useEffect, useState } from "react";
import { securedFetchVideoAccessRequestsByFilter, securedUpdateVideoAccessRequestDecision } from "../../services/VideoService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import VideoViewerPopup from "../Files/Video/VideoViewerPopup";
import { ADMIN_USER_TYPE } from "../../services/UserService";
import Spinner from "../Common/Tailwind/Spinner";

const VideoRequests = ({ types = null, refresh = true, user = ADMIN_USER_TYPE }) => {

    const [accessRequests, setAccessRequests] = useState(null);
    const [isVideoViewOpen, setVideoViewOpen] = useState(false);
    const [videoViewData, setVideoViewData] = useState(null);
    const navigate = useNavigate();

    const onDecideRequest = async (decision, request) => {
        const data = { decision: decision };
        const approvedResponse = await securedUpdateVideoAccessRequestDecision(request.id, data, navigateCallbackOptions(navigate));
        if (approvedResponse === null) {
            return;
        }
        setAccessRequests(prev => prev.filter(req => req.id !== request.id));
    }

    const fetchAndSetWaitingRequests = async () => {
        const filter = { latest_request_statuses: ['waiting'], types: types };
        const accessRequestResponse = await securedFetchVideoAccessRequestsByFilter(filter, navigateCallbackOptions(navigate));
        if (accessRequestResponse === null) {
            return;
        }
        setAccessRequests(accessRequestResponse.data);
    }

    const onClickVideoRequest = (request) => {
        setVideoViewData({ title: request.auto_generated_request_message, video_id: request.video_id, video_type: request.video_type });
        setVideoViewOpen(true);
    }

    useEffect(() => {
        (async () => {
            if (!refresh) {
                return;
            }
            await fetchAndSetWaitingRequests();
        })();
    }, [refresh]);

    if (accessRequests === null) {
        return <Spinner />;
    }
    return (
        <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-4 py-3">
                            Request Message
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Requested By
                        </th>
                        <th scope="col" class="px-6 py-3">

                        </th>
                    </tr>
                </thead>
                <tbody>

                    {accessRequests.map(request => {
                        return (
                            <tr class="bg-transparent border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white cursor-pointer"
                                    onClick={() => onClickVideoRequest(request)}>
                                    {request.auto_generated_request_message}
                                </th>
                                <td class="px-6 py-3">
                                    {request.requester.first_name}
                                </td>
                                <td class="px-6 py-3">
                                    <span className="isolate inline-flex rounded-md shadow-sm">
                                        <button
                                            type="button"
                                            className="relative inline-flex items-center rounded-l-md
                                            bg-green-700 hover:bg-green-800 px-5 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
                                            dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800
                                            focus:z-10"
                                            onClick={() => onDecideRequest('approve', request)}
                                        >
                                            Approve
                                        </button>

                                        <button
                                            type="button"
                                            className="relative -ml-px inline-flex items-center rounded-r-md 
                                            bg-red-700 hover:bg-red-800 px-5 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
                                            dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800
                                            focus:z-10"
                                            onClick={() => onDecideRequest('reject', request)}
                                        >
                                            Deny
                                        </button>
                                    </span>
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
            <VideoViewerPopup isOpen={isVideoViewOpen} onRequestClose={() => setVideoViewOpen(false)} videoType={videoViewData?.video_type || null} videoId={videoViewData?.video_id || null} user={user} />
        </div>
    )
}

export default VideoRequests;