import React, { useEffect, useState } from "react";

import './dashboard.css'

import Break from '../../Assets/Break.png';
import { STUDENT_USER_TYPE } from "../../services/UserService";
import SlotCard from "../Slots/SlotCard/SlotCard";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { securedFetchSlots } from "../../services/BatchService";
import { securedFetchNextSlot } from "../../services/TimetableService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import Spinner from "../Common/Tailwind/Spinner";
import MessagePage from "../Common/Tailwind/PageSections/MessagePage";

const DashboardLectureInfo = ({ user = STUDENT_USER_TYPE }) => {

    const [nextClassInfo, setNextClassInfo] = useState({});
    const [allClasses, setAllClasses] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const timetableFilter = {
                start_time: moment().startOf('day').format(),
                end_time: moment().endOf('day').format()
            };
            const fetchedAllClassesForToday = await securedFetchSlots(timetableFilter, navigateCallbackOptions(navigate));
            if (fetchedAllClassesForToday === null) {
                setIsLoading(false);
                return;
            }
            setAllClasses(fetchedAllClassesForToday.data);
            const fetchedNextClassInfo = await securedFetchNextSlot({ end_time: timetableFilter.end_time }, navigateCallbackOptions(navigate));
            if (fetchedNextClassInfo === null) {
                setIsLoading(false);
                return;
            }
            setNextClassInfo(fetchedNextClassInfo);
            setIsLoading(false);
        })();
    }, [])

    const renderSingleLecture = (lecture) => {

        let finished = nextClassInfo.start_time > lecture.start_time;
        let current = nextClassInfo.id === lecture.id;

        return (

            <SlotCard slotData={lecture} user={user} slotStatus={finished ? "FINISHED" : current ? "CURRENT" : ""} />
        )
    }

    if (allClasses === null || isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {
                allClasses.length === 0 ? (
                    <MessagePage imgsrc={Break} title={"NO lecture"} message1={"You have"} message2={"today!"} message3={"Review your progress instead"} />

                ) : (
                    <>
                        <div className="title flex" >
                            <h3>
                                You have
                                {' '}
                                {allClasses.length}
                                {' '}
                                {allClasses.length > 1 ? 'lectures' : 'lecture'}
                                {' '}
                                today!
                            </h3>
                        </div>
                        <div className="dashboard-cardList">
                            {allClasses.map((lecture) => renderSingleLecture(lecture))}
                        </div>
                    </>
                )
            }


        </>

    )
}

export default DashboardLectureInfo