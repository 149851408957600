import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";
import { getTestTypePathBase } from "./TestService";

export const createTestStudentQuestionAction = async (testType, testId, data) => {
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${testId}/student-question-actions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchTestStudentQuestionActionsByFilter = async (testType, filter) => {
    if (containsEmptyArray(filter)) {
        return { data: [] };
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/student-question-actions${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteTestStudentQuestionActionsByFilter = async (testType, testId, filter) => {
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/${getTestTypePathBase(testType)}tests/${testId}/student-question-actions${params}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}


export const securedCreateTestQuestionAction = withAuth(createTestStudentQuestionAction);
export const securedFetchTestStudentQuestionActionsByFilter = withAuth(fetchTestStudentQuestionActionsByFilter);
export const securedDeleteTestStudentQuestionActionsByFilter = withAuth(deleteTestStudentQuestionActionsByFilter);

