import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';
import StudentTestPage from '../../Components/Test/StudentTest/StudentTestPage';
import StudentSelectTestPage from '../../Components/Test/StudentTest/StudentSelectTestPage';

const StudentVirtualTests = () => {
    return (
        <>
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            <StudentSelectTestPage user={STUDENT_USER_TYPE} pathTab={'Virtual'} />
            <Footer user={STUDENT_USER_TYPE} />
        </>
    )
}

export default StudentVirtualTests;