import { RiLogoutCircleRLine, RiHome4Line, RiFileChartLine } from "react-icons/ri";
import { BsQuestionCircleFill, BsQuestionSquare } from "react-icons/bs";
import { PiBookOpenText } from "react-icons/pi";
import { SiGoogleclassroom } from "react-icons/si";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlineQuiz, MdOutlineVideoCameraFront, MdVideoLibrary } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { FaList } from "react-icons/fa";
import { icons } from "react-icons/lib";



// Sidebar Items
export const SidebarItems = [
    // {
    //     icon: RiHome4Line,
    //     heading: "Dashboard",
    // },
    // {
    //     icon: FaList,
    //     heading: 'Topics'
    // },
    {
        icon: MdOutlineQuiz,
        heading: 'Questions'
    },

    {
        icon: MdOutlineVideoCameraFront,
        heading: 'Lectures'
    },
    // {
    //     icon: BsQuestionSquare,
    //     heading: 'Doubts'
    // },
    {
        icon: PiBookOpenText,
        heading: 'Readings'
    },
    {
        icon: MdVideoLibrary,
        heading: 'Videos'
    },
    // {
    //     icon: HiOutlineClipboardList,
    //     heading: "Quizzes",
    // },
    // {
    //     icon: UilPackage,
    //     heading: 'Products'
    // },


    // {
    //     icon: FiUsers,
    //     heading: "Personal",
    // },
];
