import { LuFileClock } from "react-icons/lu";
import { SiGoogleclassroom } from "react-icons/si";
import { IoMdNotifications } from "react-icons/io";
import { MdRocketLaunch } from "react-icons/md";

// Sidebar Items
export const AdminSidebarItems = [

    // {
    //     icon: IoMdNotifications,
    //     heading: 'Notice'
    // },

    {
        icon: SiGoogleclassroom,
        heading: 'Lectures'
    }

    // {
    //     icon: LuFileClock,
    //     heading: 'Tests'
    // },
];
