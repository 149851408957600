import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import './ReadingMaterialView.css'

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import PINCode from "../../Common/PINCode";
import ReadingMaterialPdfViewer from './ReadingMaterialPdfViewer';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useEffect } from 'react';
import { getHmacUrl } from '../../../services/CommonService';


Modal.setAppElement('#root'); // This is important for accessibility reasons

const ReadingMaterialView = ({ isOpen, onRequestClose, readingMaterialData, user = STUDENT_USER_TYPE }) => {
    const navigate = useNavigate();

    useEffect(() => {

        if (!isOpen || !readingMaterialData) {
            return;
        }
        (async () => {
            readingMaterialData.pdf.url = await getHmacUrl(readingMaterialData.pdf.url, navigate);
        })();
    }, [isOpen]);
    if (readingMaterialData === null) {
        return;
    }
    return (
        <Modal overlayClassName="customModal" className="reading-material-view" isOpen={isOpen} onRequestClose={onRequestClose} >
            <ReadingMaterialPdfViewer readingMaterialData={readingMaterialData} user={user} />
            <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
        </Modal>
    )

}

export default ReadingMaterialView;