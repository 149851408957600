import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import videojs from 'video.js';
import 'videojs-mobile-ui';
import 'video.js/dist/video-js.css';
import "videojs-markers";
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'videojs-hls-quality-selector';


import './zoom.css'
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { securedFetchSlotStatus, securedFetchTimetableSlotById } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchZoomMeetingHostCredentialsById, securedfetchZoomMeetingRegistrantCredentialsById } from '../../services/ZoomMeetingService';
import Drawer from '../Common/Tailwind/Drawers/Drawer';
import Spinner from '../Common/Tailwind/Spinner';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const ZoomIframeView = ({ slotId, user = ADMIN_USER_TYPE }) => {

    const [lectureData, setLectureData] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [toggleDrawer, setToggleDrawer] = useState(true);
    const [zoomUserData, setZoomUserData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    const getSrcUrl = () => {
        if (lectureData.zoom_meeting_id == null) {
            alert('No Live Class exists for this slot!');
            navigate(-1);
        }
        return `/zoom/${user}/liveclass/slots/${slotId}/meeting/${lectureData.zoom_meeting_id}`;
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement != null);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleClick = () => {
        const element = document.body;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    const onLoad = async (iframeLocation) => {
        if (iframeLocation !== `${window.origin}${getSrcUrl()}`) {
            closeTab();
        }
    };

    const onOpenInApp = () => {
        if (zoomUserData == null) {
            return;
        }
        const url = user === ADMIN_USER_TYPE ? zoomUserData.start_url : zoomUserData.join_url;
        window.open(url, '_blank');
        closeTab();
    }

    useEffect(() => {
        (async () => {
            const response = await securedFetchTimetableSlotById(slotId, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
            const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (slotStatus.status === 404) {
                alert("This slot's status was not found!");
                navigate(-1);
                return;
            }
            if (slotStatus.status !== 'finished' && response.zoom_meeting_id != null) {
                let userDataResponse = null;
                if (user === ADMIN_USER_TYPE) {
                    userDataResponse = await securedFetchZoomMeetingHostCredentialsById(response.zoom_meeting_id, navigateCallbackOptions(navigate));
                } else {
                    userDataResponse = await securedfetchZoomMeetingRegistrantCredentialsById(response.zoom_meeting_id, navigateCallbackOptions(navigate))
                }
                if (userDataResponse != null) {
                    setZoomUserData(userDataResponse);
                }
            }
            setLectureData({ ...response, status: slotStatus.status });
        })();
    }, [slotId, navigate]);


    if (lectureData === null) {
        return <Spinner />;
    }
    return (
        <div id='zoom-iframe-total'>
            {!toggleDrawer && (
                <div className="drawer-toggle-button" onClick={() => setToggleDrawer(true)}>
                    <ChevronLeftIcon className="h-10 w-10 text-[var(--PrimaryColor)] z-10" />
                </div>)}

            <Drawer isFullscreen={isFullscreen} toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} handleClick={handleClick} onOpenInApp={onOpenInApp} lectureData={lectureData} user={user} />

            {lectureData ? (
                <div className="zoom-iframe-container flex">
                    <iframe
                        className="zoom-iframe"
                        src={getSrcUrl()}
                        onLoad={async (event) => await onLoad(event.target.contentWindow.location.href)}
                        sandbox="allow-same-origin allow-forms allow-scripts"
                        allow="microphone; camera"
                        allowFullScreen
                        style={{ border: "none" }}
                    />
                </div>
            ) : (
                <Spinner />
            )
            }

        </div >
    );
};

export default ZoomIframeView;
