import React from 'react';
import { Chart } from 'react-google-charts';

import '../testReport.css';

const ResponseStatistics = ({ data = null }) => {

    const chartData = [
        ['Response', 'Percentage'],
        ['Correct', data.correct.percentage],
        ['Incorrect', data.incorrect.percentage],
        ['No Responses', data.no_response.percentage],
    ];

    const chartOptions = {
        // title: 'Students Response',
        // is3D: true,
        chartArea: {
            width: '100%',
            height: '80%',
        },
        legend: {
            position: 'bottom', // Set the position of the legend (top, bottom, left, right)
            maxLines: 2,     // Set the maximum number of legend entries per line
            textStyle: {
                fontSize: 12,  // Set the font size for legend text
            },
            legendToggle: true, // Enable legend item toggling
        },
    };

    return (
        <div className="statistics-section flex">

            <h3 className="title">Reponse</h3>

            <Chart
                chartType="PieChart"
                data={chartData}
                options={chartOptions}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default ResponseStatistics;