import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { createParams } from "./CommonService";
import { BACKEND_URL } from "./BackendConfig";

export const fetchZoomMeetingById = async (id) => {
    const response = await fetch(`${BACKEND_URL}/zoom-meetings/${id}`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchZoomMeetingStatusById = async (id) => {
    const response = await fetch(`${BACKEND_URL}/zoom-meetings/${id}/status`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}


export const fetchZoomMeetingHostCredentialsById = async (id) => {
    const response = await fetch(`${BACKEND_URL}/zoom-meetings/${id}/host-credentials`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}


export const fetchZoomMeetingRegistrantCredentialsById = async (id) => {
    const response = await fetch(`${BACKEND_URL}/zoom-meetings/${id}/registrant-credentials`, { credentials: 'include' });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const securedFetchZoomMeetingById = withAuth(fetchZoomMeetingById);
export const securedFetchZoomMeetingStatusById = withAuth(fetchZoomMeetingStatusById);
export const securedFetchZoomMeetingHostCredentialsById = withAuth(fetchZoomMeetingHostCredentialsById);
export const securedfetchZoomMeetingRegistrantCredentialsById = withAuth(fetchZoomMeetingRegistrantCredentialsById);