export class UnauthorizedError extends Error {
    constructor(message) {
        super(message);
        this.name = "UnauthorizedError";
    }
}

export class ServerError extends Error {
    constructor(message) {
        super(message);
        this.name = "ServerError";
    }
}

export class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.name = "NotFoundError";
    }
}

export class ServerErrorResponse {
    constructor(message) {
        this.name = "ServerError";
    }
}

