import React, { useEffect, useState } from 'react';
import { default as ReactSelect } from "react-select";

const SelectionDropdown = ({ className = 'pInput', name, itemList, selectedItem, onSelectionChange, valueField, nameField, onAddItem = null, placeHolderText = "Select...", defaultValue = null, required = null, isSearchable = false, isDisabled = false }) => {
    const customFilterOption = (option, rawInput) => {
        const searchTerm = rawInput.toLowerCase();

        return option.label ? option.label.toLowerCase().includes(searchTerm) : true;
    }

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const handleKeyDown = async (event) => {
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (onAddItem) {
                    const newItem = {};
                    newItem[nameField] = inputValue;
                    await onAddItem(newItem);
                    setInputValue('');
                }
                break;
            default:
                break;
        }
    };

    const getSelectedItemValue = () => {
        return (selectedItem && selectedItem[valueField] && selectedItem[nameField])
            ? { value: selectedItem[valueField], label: selectedItem[nameField] }
            : ((defaultValue && defaultValue[valueField] && defaultValue[nameField])
                ? { value: defaultValue[valueField], label: defaultValue[nameField] }
                : null)
    }

    return (

        <div className={className}>
            <label className="title">{name}</label>
            {
                required ? (
                    <ReactSelect
                        className="selectionDropdown"
                        id={name}
                        name={name}
                        placeholder={placeHolderText}
                        options={itemList.map(item => ({ value: item[valueField], label: item[nameField] }))}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        onChange={onSelectionChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={getSelectedItemValue()}
                        isSearchable={isSearchable}
                        filterOption={customFilterOption}
                        required
                        isDisabled={isDisabled}
                    />
                ) : (
                    <ReactSelect
                        className="selectionDropdown"
                        id={name}
                        name={name}
                        placeholder={placeHolderText}
                        options={itemList.map(item => ({ value: item[valueField], label: item[nameField] }))}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        onChange={onSelectionChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={getSelectedItemValue()}
                        isSearchable={isSearchable}
                        filterOption={customFilterOption}

                        isDisabled={isDisabled}
                    />
                )
            }

        </div >

    );
};

export default SelectionDropdown;