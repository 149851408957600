import React, { useEffect } from 'react';
import { useAuth } from '../../Components/Authentication/AuthContext';
import { fetchCoachingConfig } from '../../services/ConfigService';

const Config = () => {
    const {
        userType, setUserType,
        config, setConfig
    } = useAuth();

    useEffect(() => {
        if (config !== null) {
            return;
        }
        (async () => {
            const coachingConfig = await fetchCoachingConfig();
            if (coachingConfig !== null) {
                setConfig(coachingConfig);
            }
        })();
    }, []);
    return (<></>)
};

export default Config;
