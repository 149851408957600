import React, { useMemo, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import zoomPlugin from 'chartjs-plugin-zoom';



// const LineChart = ({ width, height, data }) => {

//     const chartRef = useRef(null);

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             y: {
//                 type: 'linear',
//                 display: true,
//                 position: 'left',
//                 title: {
//                     display: true,
//                 },
//             },
//             y1: {
//                 type: 'linear',
//                 display: true,
//                 position: 'right',
//                 title: {
//                     display: true,
//                 },
//                 grid: {
//                     drawOnChartArea: false,
//                 },
//             },
//         },
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: false,
//             },
//             datalabels: {
//                 color: 'white',
//                 anchor: 'center',
//                 align: 'center',
//                 font: {
//                     weight: 'bold'
//                 },
//                 formatter: (value) => value || ''
//             },
//             zoom: {
//                 pan: {
//                     enabled: true,
//                     mode: 'x',
//                 },
//                 zoom: {
//                     wheel: { enabled: false },
//                     pinch: { enabled: false },
//                     mode: 'x',
//                 }
//             },
//             tooltip: {
//                 callbacks: {
//                     label: function (context) {
//                         let label = context.dataset.label || '';
//                         if (label) {
//                             label += ': ';
//                         }
//                         if (context.parsed.y !== null) {
//                             label += context.parsed.y;
//                             if (context.dataset.label === 'Accuracy') {
//                                 label += '%';
//                             }
//                         }
//                         return label;
//                     }
//                 }
//             }
//         }
//     };

//     useEffect(() => {
//         const chart = chartRef.current;
//         if (chart) {
//             const totalBars = data.labels.length;
//             const visibleBars = 30;
//             if (totalBars > visibleBars) {
//                 const scrollPercent = visibleBars / totalBars;
//                 chart.canvas.parentNode.style.height = `${height}px`;
//                 chart.canvas.parentNode.style.width = `${width}px`;
//                 chart.canvas.style.width = `${width / scrollPercent}px`;
//                 chart.canvas.style.height = `${height}px`;
//             }
//         }
//     }, [data.labels.length, width, height]);

//     console.log(data);

//     return (
//         <div style={{ width, height, overflowX: 'auto' }}>
//             <Chart ref={chartRef} data={data} options={options} />
//         </div>
//     );
// };

// export default LineChart;


// import { Square3Stack3DIcon } from "@heroicons/react/24/outline";

// If you're using Next.js please use the dynamic import for react-apexcharts and remove the import from the top for the react-apexcharts
// import dynamic from "next/dynamic";
// const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });
const CustomLineChart = ({ width, height, data }) => {

    console.log(data);

    const chartConfig = {
        type: "line",
        // height: 240,
        series: data.datasets,
        // [
        //     {
        //         name: "Sales",
        //         data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
        //     },
        //     {
        //         name: "Profit",
        //         data: [0, 10, 30, 30, 50, 35, 20, 23, 50],
        //     },
        // ],
        options: {
            chart: {
                scrollable: true,
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: true,
                    resetIcon: {
                        offsetX: -10,
                        offsetY: 0,
                        fillColor: '#fff',
                        strokeColor: '#37474F'
                    },
                    selection: {
                        background: '#90CAF9',
                        border: '#0D47A1'
                    }
                },
            },
            title: {
                show: undefined,
            },
            dataLabels: {
                enabled: false,
            },
            // colors: ["#020617"],
            stroke: {
                lineCap: "round",
                curve: "smooth",
            },
            // markers: {
            //     size: 0,
            // },
            xaxis: {
                // axisTicks: {
                //     show: false,
                // },
                // axisBorder: {
                //     show: false,
                // },
                // labels: {
                //     style: {
                //         colors: "#616161",
                //         fontSize: "12px",
                //         fontFamily: "inherit",
                //         fontWeight: 400,
                //     },
                // },
                categories: data.labels,
            },
            yaxis: [{
                // labels: {
                //     style: {
                //         colors: "#616161",
                //         fontSize: "12px",
                //         fontFamily: "inherit",
                //         fontWeight: 400,
                //     },
                // },
                title: {
                    text: 'Counts',
                },

            }, {
                opposite: true,
                title: {
                    text: 'Percentage (%)'
                }
            }],
            // grid: {
            //     show: true,
            //     borderColor: "#dddddd",
            //     strokeDashArray: 5,
            //     xaxis: {
            //         lines: {
            //             show: true,
            //         },
            //     },
            //     padding: {
            //         top: 5,
            //         right: 20,
            //     },
            // },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                // theme: "dark",
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                // offsetX: 40
            }
        },
    };

    // return (<></>);
    return (
        <div style={{ width, height, overflowX: 'auto' }}>
            <Chart {...chartConfig} />
        </div>

    );
}


export default CustomLineChart;