import React from "react";

const TailwindButton = ({ text = "", iconComponent = null, bgColor = 'blue', textColor = 'white', ...props }) => {
    return (
        // <button type="button" {...props} className={`btn flex justify-center items-center gap-1 text-${textColor} bg-${bgColor}-700 hover:bg-${bgColor}-800 focus:ring-4 focus:ring-${bgColor}-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-${bgColor}-600 dark:hover:bg-${bgColor}-700 focus:outline-none dark:focus:ring-${bgColor}-800`}>
        <button type="button" {...props} className={`btn flex text-sm`}>
            {text}
            {iconComponent && React.createElement(iconComponent, { className: "w-5 h-5 text-white me-2" })}
        </button>);
}

export default TailwindButton;