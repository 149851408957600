import React, { useEffect, useState } from "react";

import './hero.css'

const Hero = (props) => {

  const [img, setImg] = useState(0);

  // Update the key when the image source changes
  useEffect(() => {
    setImg(prevImg => prevImg + 1);
  }, [props.img]); // Dependency on image source

  return (
    <div className={props.cName}>

      <img
        key={img}
        className="hero-img"
        src={props.img}
        alt="hero-img" />

      <div className="hero-text flex">
        <h1 className="hero-title">{props.title}</h1>
        <h2 className="hero-description">{props.description}</h2>
      </div>

    </div>
  )
}

export default Hero