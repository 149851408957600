import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import './NTA.css'

import TestRemainingTime from '../TestRemainingTime';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import NTAAnswersCountsTooltip from './NTAAnswersCountsTooltip';
import NTASectionInfo from './NTASectionInfo';
import { useNTATest } from './NTATestContext';
import Alert from '../../Common/Alert';

const NTASectionTabs = ({ handleTabClick, user = STUDENT_USER_TYPE, refreshKey = 0, onRefreshClick = null }) => {

    const [activePopupId, setActivePopupId] = useState(null);
    const containerRef = useRef(null);
    const eachTabRefs = useRef({});
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const tabsRef = useRef(null);

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        getSectionName,
        testCreateType
    } = useNTATest();

    const togglePopup = (testSectionId) => {
        if (activePopupId === testSectionId) {
            return;
        }
        setActivePopupId(testSectionId);
    }

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (activePopupId === null) {
            return;
        }
        const targetRef = eachTabRefs.current[activePopupId];
        if (targetRef) {
            const rect = targetRef.getBoundingClientRect();
            setPosition({ top: Math.max(0, rect.top), left: Math.max(rect.left, 0) });
        }
    }, [activePopupId]);

    return (
        <>
            {activePopupId !== null && (
                <NTAAnswersCountsTooltip answerData={null} sectionId={activePopupId} sectionName={getSectionName(testSections.find(testSection => testSection.id === activePopupId))} style={{
                    position: 'absolute',
                    top: position.top,
                    left: position.left,
                }} />)}

            <div className="NTASectionTabsandInfo roboto-medium flex">
                <div className="NTASectionTabsHeaderandContainer roboto-medium flex">

                    <div className="NTASectionTabsHeader flex">
                        <h3>Sections </h3>

                        <div className="NTATimer flex">
                            {testStatus === "STARTED" && <b>Time Left: </b>}
                            <TestRemainingTime id={testData.id} testData={testData} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} timerType="NTA" testCreateType={testCreateType} />
                        </div>
                    </div>


                    {(testData !== null && testSections !== null && (testSections.length === 0 || activeSection !== null)) ?
                        (<div className="NTASectionTabsContainer flex">

                            <button className="left-btn" onClick={() => scrollTabs('left')}>
                                <AiOutlineLeft className="icon" />
                            </button>

                            <div className="NTASectionTabs flex" ref={tabsRef}>
                                {testSections.map((testSection, index) => (
                                    <button className={activeSection.id === testSection.id ? "tabItem selected roboto-bold" : "tabItem"} key={testSection.id} ref={(el) => (eachTabRefs.current[testSection.id] = el)} >
                                        <span onClick={() => handleTabClick(testSection.id)}>{getSectionName(testSection)}</span>
                                        <span className="instruction_icon" onMouseEnter={() => togglePopup(testSection.id)} onMouseLeave={() => togglePopup(null)}>
                                            {activePopupId === testSection.id &&
                                                <NTAAnswersCountsTooltip answerData={null} sectionId={testSection.id} sectionName={getSectionName(testSection)} />}
                                        </span>
                                    </button>
                                ))}
                            </div>

                            <button className="right-btn" onClick={() => scrollTabs('right')}>
                                <AiOutlineRight className="icon" />
                            </button>

                        </div>) : (
                            <div className="NTASectionTabsContainer flex">
                                {testStatus === "NOT STARTED" ? (
                                    <p className="not-started-msg roboto-bold">Test has not started yet.</p>
                                ) : (
                                    <p className="not-started-msg roboto-bold">There are no sections to show in this test.</p>
                                )}
                            </div>
                        )}
                </div>

                {testData !== null && testSections !== null && activeSection !== null &&

                    <NTASectionInfo
                        activeSection={activeSection}
                        user={user}
                        questionsForSection={testQuestions.filter(testQuestion => testQuestion.test_section_id === activeSection.id)}
                        paragraphsForSection={testParagraphs.filter(testParagraph => testParagraph.test_section_id === activeSection.id)}
                    />
                }
                <div className="NTATestLanguage"></div>

            </div>
        </>
    );
}


export default NTASectionTabs;
