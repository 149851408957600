import React, { useRef, useState, useEffect } from 'react';

// import './AnalyticsCharts.css';
import CustomLineChart from "../../Common/LineChart";
import Spinner from '../../Common/Tailwind/Spinner';
const AnalyticsLineChart = ({ period, valueField, data }) => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            const maxWidth = (period === '1Week' ? 700 : (period === '1Month' ? 950 : 1100))
            const maxHeightRatio = (period === '1Week' ? .65 : (period === '1Month' ? .85 : 1))

            if (containerRef.current) {
                setDimensions({
                    width: (containerRef.current.offsetWidth < maxWidth) ? containerRef.current.offsetWidth : maxWidth,
                    height: window.innerHeight * maxHeightRatio // Set height to 80% of viewport height
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [period]);


    const convertData = (inputData) => {
        return {
            ...inputData,
            datasets: inputData.datasets.map(dataset => {
                return {
                    ...dataset,
                    data: dataset.data.map(d => d[(dataset.name === 'Accuracy' ? 'accuracy' : 'numberOfTests')]),
                    // backgroundColor: subjectColors[dataset.label],
                    type: (dataset.name === 'Accuracy' ? 'line' : 'bar'),
                    // borderWidth: (dataset.name === 'Accuracy' ? null : 2)

                    // yAxisID: (dataset.label === 'Accuracy' ? 'y' : 'y1'),
                    // fill: !(dataset.label === 'Accuracy')
                }
            })
        };
    }

    const render = () => {
        console.log(data);
        const convertedData = convertData(data);
        console.log(convertedData);
        return (
            <div className="analytics-chart-section" ref={containerRef}>
                <CustomLineChart
                    width={dimensions.width}
                    height={dimensions.height}
                    data={convertedData}
                // xKey="date"
                // yKey="accuracy"
                // yAxisLabel="Accuracy (%)"
                // tooltipFormat={(d) => `Date: ${d.date}, Accuracy: ${d.accuracy}%, Tests: ${d.numberOfTests}`}

                // data={data}
                // xKey="date"
                // yKey="accuracy"
                // yAxisLabel="Accuracy (%)"
                // tooltipFormat={(d) => `Date: ${d.date}, Accuracy: ${d.accuracy}%, Tests: ${d.numberOfTests}`}
                />
            </div>
        );
    }

    if (data === null) {
        return <Spinner />;
    }

    return (
        <div className="analytics-chart-container">
            {render()}
        </div>
    );
}

export default AnalyticsLineChart;