import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';
import { Tooltip } from 'react-tooltip'

import "./QuestionSlider.css";

import { hasQuestionDeleteAuthority, hasQuestionEditAuthority, hasQuestionMarkFeature, hasQuestionResponses, prefetchAnswers, securedDeleteQuestionById, securedFetchQuestionById, securedFetchSolutionByQuestionId } from '../../../../services/QuestionService';
import { UnauthorizedError } from '../../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';
import { getHmacUrl, replaceImagesInObject, updateArray } from '../../../../services/CommonService';
import { hasQuestionMetadata, securedCreateBulkStudentQuestionResponses, securedFetchQuestionMetadatasByFilter, securedFetchStudentQuestionResponsesByFilter, securedUpdateQuestionMetadatas } from '../../../../services/UserQuestionService';

import { FaRegCheckCircle } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im"
import { BsCheckSquare } from 'react-icons/bs'
import { BiShow, BiHide } from 'react-icons/bi'
import { RiArrowGoBackFill, RiDeleteBin5Line, RiEdit2Fill } from 'react-icons/ri'
import { MdOutlineRadioButtonUnchecked, MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";
import QuestionEdit from '../../QuestionCreate/QuestionEdit';
import { IoMdStar, IoMdStarOutline } from 'react-icons/io';
import QuestionSlide from './QuestionSlide';
import ForwardTimer from '../../../Common/ForwardTimer';

const ParagraphSlide = ({ paragraphId, inputParagraphData = null, showImages = false, testId = null, showAnswers = false, user = ADMIN_USER_TYPE, showQuestions = true, onParagraphDelete = null }) => {

    const options = ['A', 'B', 'C', 'D'];
    const [paragraphData, setParagraphData] = useState(null);
    const [solutions, setSolutions] = useState([]);
    const [isEditParagraphOpen, setEditParagraphOpen] = useState(false);
    const [timePassed, setTimePassed] = useState(0);

    const navigate = useNavigate();

    const handleEditParagraphClick = async () => {
        setEditParagraphOpen(true);
    }

    const onEditParagraph = async () => {
        let paragraph = await fetchQuestion(paragraphId, { show_correct_answer: prefetchAnswers(user) });
        setParagraphData(paragraph);
    }

    const fetchQuestion = async (questionId, filter) => {
        let question = await securedFetchQuestionById('paragraph', questionId, filter, navigateCallbackOptions(navigate));
        if (hasQuestionMetadata(user)) {
            const questionMetadata = await securedFetchQuestionMetadatasByFilter(user, 'paragraph', { paragraph_ids: [questionId] }, navigateCallbackOptions(navigate));
            if (questionMetadata === null) {
                return;
            }
            let metadata = {};

            if (questionMetadata.data.length > 0) {
                metadata = questionMetadata.data[0];
            }
            question = { ...question, metadata: metadata };
        }
        return question;
    };

    const handleMarkStar = async (event) => {
        event.stopPropagation();
        const newIsStarMarked = paragraphData.metadata.is_star_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'paragraph', { is_star_marked: newIsStarMarked, paragraph_id: paragraphData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = paragraphData.metadata ? { ...paragraphData.metadata, is_star_marked: newIsStarMarked } : null;
        setParagraphData({ ...paragraphData, metadata: newMetadata });
    }

    const handleMarkForReview = async (event) => {
        event.stopPropagation();
        const newIsMarkedForReview = paragraphData.metadata.is_review_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'paragraph', { is_review_marked: newIsMarkedForReview, paragraph_id: paragraphData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = paragraphData.metadata ? { ...paragraphData.metadata, is_review_marked: newIsMarkedForReview } : null;
        setParagraphData({ ...paragraphData, metadata: newMetadata });
    }

    const renderParagraphQuestion = (question, questionIndex) => {
        return (
            <QuestionSlide questionId={question.id} inputQuestionData={question} testId={testId} user={user} testStatus={null} showImages={showImages} showAnswers={showAnswers} />
        )
    };

    const makeParagraphDataForEdit = () => {
        const paragraphDataCopy = { ...paragraphData };
        paragraphDataCopy.questions.forEach((question, index) => {
            if (!solutions || solutions.length < index + 1) {
                return;
            }
            paragraphDataCopy.questions[index].question['solution'] = solutions[index];
        });
        return paragraphDataCopy;
    }



    const renderQuestionMetadata = () => {
        if (testId !== null || !hasQuestionMarkFeature(user)) {
            return;
        }
        return (
            <div className="question-saved" >
                <>
                    <button className="saved-btn" id="star-btn" onClick={handleMarkStar}>
                        {paragraphData.metadata && paragraphData.metadata.is_star_marked ? (<IoMdStar className="icon" />) : (<IoMdStarOutline className="icon" />)}
                    </button>

                    <button className="saved-btn" id="review-btn" onClick={handleMarkForReview}>
                        {paragraphData.metadata && paragraphData.metadata.is_review_marked ? (<MdPlaylistAddCheck className="icon" />) : (<MdPlaylistAdd className="icon" />)}
                    </button>
                </>
            </div>);
    }

    const showId = () => {
        if (paragraphData.topics.length === 0) return '';

        return `${paragraphData.topics[0].topic.subject.subject_name[0]}${paragraphData.id}`;
    };

    const showDifficulty = () => {
        if (paragraphData.paragraph_difficulty === null) return '';

        return paragraphData.paragraph_difficulty.toUpperCase();
    };

    useEffect(() => {
        (async () => {
            let paragraph = inputParagraphData;
            if (!paragraph) {
                paragraph = await fetchQuestion(paragraphId, { show_correct_answer: prefetchAnswers(user) });
            }
            setParagraphData(paragraph);
            const solutionResponses = await Promise.all(paragraph.questions.map(async (paragraphQuestion) => {
                const solutionResponse = await securedFetchSolutionByQuestionId(paragraphQuestion.question_id, navigateCallbackOptions(navigate));
                if (solutionResponse === null || solutionResponse.status === 404) {
                    return null;
                }
                return solutionResponse;
            }));
            setSolutions(solutionResponses);
        })();
    }, [paragraphId]);

    if (!paragraphData) {
        return <p>Loading...</p>;  // Or some other loading indicator
    }



    return (
        <div className="paragraph-slide flex">
            <div className="question-info flex">
                {paragraphData.topics.length > 0 && (
                    <span className="question-id" id={paragraphData.topics[0].topic.subject.subject_name}> {showId()} </span>
                )}
                {!testId && (
                    <>
                        {!prefetchAnswers(user) && (<ForwardTimer timeVar={timePassed} setTimeVar={setTimePassed} />)}
                        {/* Displaying Question ID */}


                        {paragraphData.paragraph_difficulty && (
                            <span className="question-difficulty" id={paragraphData.paragraph_difficulty}>
                                {showDifficulty()}
                            </span>
                        )}

                        {renderQuestionMetadata()}

                        {/* Displaying Answer Marks */}
                        {hasQuestionResponses(user) && (
                            <div className="answer-marks flex">
                                {paragraphData.number_of_questions_total && paragraphData.number_of_questions_correct > 0 && (
                                    <>
                                        <div className="mark flex" id="correct"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={`Correctly Answered: ${paragraphData.number_of_questions_correct}`}
                                            data-tooltip-place="right">
                                            <FaRegCheckCircle className="icon" />
                                            <p>{paragraphData.number_of_questions_correct}</p>
                                        </div>
                                        <div className="mark flex" id="incorrect"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={`Incorrectly Answered: ${paragraphData.number_of_questions_incorrect}`}
                                            data-tooltip-place="right">
                                            <ImCancelCircle className="icon" />
                                            <p>{paragraphData.number_of_questions_incorrect}</p>
                                        </div>
                                        <div className="mark flex" id="unanswered"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={`Unanswered: ${paragraphData.number_of_questions_total - paragraphData.number_of_questions_incorrect - paragraphData.number_of_questions_correct}`}
                                            data-tooltip-place="right">
                                            <MdOutlineRadioButtonUnchecked className="icon" />
                                            <p>{paragraphData.number_of_questions_total - paragraphData.number_of_questions_incorrect - paragraphData.number_of_questions_correct}</p>
                                        </div>
                                    </>
                                )}
                                <Tooltip id="tooltip" />
                            </div>
                        )}
                        {testId === null && hasQuestionEditAuthority(user) && (
                            <button className="edit-btn" onClick={async (event) => {
                                event.stopPropagation();
                                await handleEditParagraphClick();
                            }}>
                                <RiEdit2Fill className="icon" />
                            </button>
                        )}
                    </>

                )}
            </div>

            {/* Render paragraph text */}
            {paragraphData.paragraph_text && (
                <div className="paragraph-text flex">
                    <Latex>
                        {paragraphData.paragraph_text}
                    </Latex>
                </div>
            )}

            {/* Render paragraph images */}
            {(showImages && paragraphData.images.length > 0) && (
                <div className="paragraph-images flex">
                    {paragraphData.images.map(img => (
                        <img key={img.image.id} src={img.image.url} alt="Paragraph related" draggable={false} />
                    ))}
                </div>
            )}
            {
                paragraphData.questions.map((question, index) => renderParagraphQuestion(question.question, index))
            }


            <QuestionEdit inputQuestionData={makeParagraphDataForEdit()} isOpen={isEditParagraphOpen} onRequestClose={() => setEditParagraphOpen(false)} user={user} onEditQuestion={onEditParagraph} />
        </div >
    );
};

export default ParagraphSlide;
