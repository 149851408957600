import React from 'react';

const TailwindTabs = ({ position = "end", tabs, selectedTab, setSelectedTab, renderByTabs }) => {
    const classNames = (classes) => {
        return classes.join(' ')
    }


    return (
        <div class="flex flex-col w-full">
            <nav aria-label="Tabs" className={`flex justify-${position} space-x-2 pb-4`}>
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        aria-current={selectedTab === tab ? 'page' : undefined}
                        className={classNames([
                            tab === selectedTab ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-500 hover:text-gray-700',
                            'rounded-md px-3 py-2 text-sm font-medium cursor-pointer']
                        )}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </div>
                ))}
            </nav>
            {renderByTabs[selectedTab]()}
        </div >
    )
}

export default TailwindTabs;