import { useNavigate } from 'react-router-dom';

import './SlotCard.css'

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import { hasSlotPinViewAuthority } from '../../../services/TimetableService';
import PINCode from "../../Common/PINCode";
import { createParams } from '../../../services/CommonService';


const SlotCard = ({ slotData, slotStatus, showDates = false, user = STUDENT_USER_TYPE }) => {

    const navigate = useNavigate();

    const goToSlotId = (slotId) => {
        let pathParams = { selectedTab: 'Video' };
        if (slotData.matching_segment_id) {
            pathParams['segmentId'] = slotData.matching_segment_id;
        }
        navigate(`/${user}/liveclass/slots/${slotId}${createParams(pathParams)}`);
    }

    const slotMatchingSegmentRefinedTitle = (slotData.matching_segment_refined_title == null ? null : slotData.matching_segment_refined_title);

    return (
        <div className={`slot-card ${slotData.subject.subject_name}`} onClick={() => goToSlotId(slotData.id)}>

            {/* background */}
            <div className={`bkg-cover ${slotStatus}`}></div>

            {slotData.lecture_hall && (<h3 className="classroom"> {slotData.lecture_hall.lecture_hall_name}</h3>)}
            <h2 className={`subject ${slotData.subject.subject_name}`}>{slotData.subject.subject_name}</h2>
            <h3 className="time">
                {slotData.start_time.split('T')[1].slice(0, 5)}
                -
                {slotData.end_time.split('T')[1].slice(0, 5)}
            </h3>
            {showDates &&
                <span className="date">
                    {slotData.start_time.split('T')[0].slice(8, 10)}
                    /
                    {slotData.start_time.split('T')[0].slice(5, 7)}
                    /
                    {slotData.start_time.split('T')[0].slice(2, 4)}
                </span>
            }
            {slotMatchingSegmentRefinedTitle !== null &&
                <span className="matchingSegment">{slotMatchingSegmentRefinedTitle}</span>
            }
            {
                hasSlotPinViewAuthority(user) && slotData.pin && slotStatus !== "FINISHED" && (
                    <div className="LecturePIN flex">
                        <PINCode PIN={slotData.pin} />
                    </div>
                )
            }
        </div >
    )

}

export default SlotCard;