import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import '../questions.css'

import LoadingPage from '../../Common/LoadingPage';
import QuestionCard from './QuestionCard';
import QuestionCardListFilter from './QuestionCardListFilter';
import { QUESTION_STANDALONE_TYPES, securedFetchQuestions } from '../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiOutlineLeft, AiOutlineRight, AiFillCloseCircle } from 'react-icons/ai'
import { hasQuestionMetadata, securedFetchQuestionMetadatasByFilter } from '../../../services/UserQuestionService';
import Spinner from '../../Common/Tailwind/Spinner';

Modal.setAppElement('#root');

const QuestionCardList = ({
    handleQuestionCardClick, isModal = false, isOpen = false, syllabusFilter = null, searchFilter = null, setSearchFilter, onQuestionsChange = null,
    onRequestClose = null, user = ADMIN_USER_TYPE, includeTestQuestions = false, page = 1, setPage, testId = null, allowedTypes = null, hiddenFilters = null }) => {

    const [currentShowQuestions, setCurrentShowQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [lastPageFetched, setLastPageFetched] = useState(page);
    const [totalQuestionCount, setTotalQuestionCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const pageSize = 12;
    const navigate = useNavigate();

    const makeFilter = (currentPage, externalFilter = null) => {
        const currentSearchFilter = externalFilter ? externalFilter : searchFilter;
        const filter = { skip: (currentPage - 1) * pageSize, limit: pageSize * 4 }
        if (!currentSearchFilter) {
            return filter;
        }
        if (currentSearchFilter.selectedTopics.length > 0) {
            filter['topic_ids'] = currentSearchFilter.selectedTopics.map((item) => (item.id));
        }
        else if (currentSearchFilter.selectedSubjects.length > 0 || currentSearchFilter.selectedGrades.length > 0) {
            filter['topic_ids'] = currentSearchFilter.topics.map((item) => (item.id));
        }
        if (currentSearchFilter.selectedTags.length > 0) {
            filter['tag_ids'] = currentSearchFilter.selectedTags.map((item) => (item.id));
        }
        if (currentSearchFilter.selectedDifficulties && currentSearchFilter.selectedDifficulties.length > 0) {
            filter['difficulties'] = currentSearchFilter.selectedDifficulties.map((item) => (item.id));
        }
        if (currentSearchFilter.selectedType && QUESTION_STANDALONE_TYPES.includes(currentSearchFilter.selectedType.id) && currentSearchFilter.selectedType.value != null) {
            filter['subtypes'] = [currentSearchFilter.selectedType.value];
        }
        filter['include_test_questions'] = includeTestQuestions;
        if (currentSearchFilter.queryString) {
            filter['query_string'] = currentSearchFilter.queryString;
        }
        if (currentSearchFilter.showUnattemptedOnly) {
            filter['include_attempted'] = !currentSearchFilter.showUnattemptedOnly;
        }
        if (currentSearchFilter.showStarredOnly) {
            filter['include_only_star_marked'] = currentSearchFilter.showStarredOnly;
        }
        if (currentSearchFilter.showMarkedForReviewOnly) {
            filter['include_only_review_marked'] = currentSearchFilter.showMarkedForReviewOnly;
        }
        if (currentSearchFilter.showIncorrectOnly) {
            filter['include_only_incorrect'] = currentSearchFilter.showIncorrectOnly;
        }
        if (currentSearchFilter.questionId) {
            filter['ids'] = [parseInt(currentSearchFilter.questionId)];
        }
        if (user === ADMIN_USER_TYPE) {
            filter['sort_by_time_order'] = 'desc';
        }
        return filter;
    }

    const getQuestionType = (typeId) => {
        if (QUESTION_STANDALONE_TYPES.includes(typeId)) {
            return 'standalone';
        }
        return typeId;
    }

    const getQuestionIdsFilter = (typeId, ids) => {
        if (QUESTION_STANDALONE_TYPES.includes(typeId)) {
            return { question_ids: ids };
        }
        return { paragraph_ids: ids };
    }

    const getIdField = (type) => {
        if (type === 'paragraph') {
            return 'paragraph_id';
        }
        return 'question_id';
    }

    const fetchQuestionData = async (page, filter) => {
        setLoading(true);
        setCurrentShowQuestions([]);
        const currentFilter = makeFilter(page, filter);
        const response = await securedFetchQuestions(getQuestionType(filter.selectedType.id), currentFilter, navigateCallbackOptions(navigate));
        if (response === null) {
            setLoading(false);
            return null;
        }
        let questionsData = response.data;
        if (hasQuestionMetadata(user)) {
            const questionsMetadataResponse = await securedFetchQuestionMetadatasByFilter(user, getQuestionType(filter.selectedType.id), getQuestionIdsFilter(filter.selectedType.id, questionsData.map(questionData => questionData.id)), navigateCallbackOptions(navigate));
            if (questionsMetadataResponse !== null) {
                questionsData = questionsData.map(questionData => ({ ...questionData, metadata: (questionsMetadataResponse.data.find(metadata => metadata[getIdField(filter.selectedType.id)] === questionData.id) || {}) }));
            }
        }
        setFilteredQuestions(questionsData);
        setCurrentShowQuestions(questionsData.slice(0, pageSize));
        setLastPageFetched(page);
        if (onQuestionsChange) {
            onQuestionsChange(questionsData.slice(0, pageSize));
        }
        setTotalQuestionCount(response.count);
        setLoading(false);

        return response.data;
    }

    const fetchData = async (hasFilterChanged = false, externalFilter = null) => {
        let currentPage = page;
        if (hasFilterChanged) {
            setPage(1);
            currentPage = 1;
        }
        const currentSearchFilter = externalFilter ? externalFilter : searchFilter;
        return await fetchQuestionData(currentPage, currentSearchFilter);
    };


    const renderQuestionCard = (questionData) => {
        let type = "standalone";

        if (questionData.paragraph_text !== undefined) {
            type = "paragraph";
        }
        return <QuestionCard questionData={{ ...questionData, type: type }} handleQuestionCardClick={async () => await handleQuestionCardClick(questionData)} onQuestionDelete={() => fetchData()} testId={testId} user={user} />;
    }

    const renderQuestionCards = () => {
        return (
            <>
                {
                    currentShowQuestions.map(questionData => (
                        renderQuestionCard(questionData, handleQuestionCardClick, currentShowQuestions.map(question => question.id))
                    ))

                    // <WithBorderGrid data={currentShowQuestions} handleCardClick={handleQuestionCardClick} user={user} testId={testId} />
                }
            </>
        )
    }

    const onPageChange = async (changeUnit) => {
        if (changeUnit === 0) {
            return;
        }
        setLoading(true);
        setCurrentShowQuestions([]);
        const numberOfPages = Math.ceil(totalQuestionCount / pageSize);
        if (page + changeUnit < 1 || page + changeUnit > numberOfPages) {
            setLoading(false);
            return;
        }
        const currentQuestionIndexStart = (page + changeUnit - lastPageFetched) * pageSize;
        const currentQuestionIndexEnd = currentQuestionIndexStart + pageSize;
        if (currentQuestionIndexStart >= 0 && currentQuestionIndexEnd <= filteredQuestions.length) {
            setCurrentShowQuestions(filteredQuestions.slice(currentQuestionIndexStart, currentQuestionIndexEnd));
            if (onQuestionsChange) {
                onQuestionsChange(filteredQuestions.slice(currentQuestionIndexStart, currentQuestionIndexEnd));
            }
            setPage(page + changeUnit);
            setLoading(false);
            return;
        }

        setPage(page + changeUnit);
        await fetchQuestionData(page + changeUnit, searchFilter);
        setLoading(false);
    }

    const onSubmitFilter = async (conservePage = true, externalFilter = null) => {
        const result = await fetchData(!conservePage, externalFilter);
        return result !== null;
    }

    const displayPageButtons = () => {
        const numberOfButtonsAfterPage = Math.max(0, Math.ceil((totalQuestionCount - page * 12) / 12));
        const startIndex = Math.max(page - 5, 1);
        const endIndex = Math.min(startIndex + 10, page + numberOfButtonsAfterPage);

        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (

            <button button key={startIndex + index} className={startIndex + index === page ? "page-btns current-page-btn" : "page-btns not-current-page-btn"} onClick={() => onPageChange(startIndex + index - page)} >
                {startIndex + index}
            </button>

        ));
    }

    const renderCardList = () => {
        return (
            <>
                <QuestionCardListFilter syllabusFilter={syllabusFilter} searchFilter={searchFilter} testId={testId} setSearchFilter={setSearchFilter} onSubmitFilter={onSubmitFilter} user={user} allowedTypes={allowedTypes} hiddenFilters={hiddenFilters} />

                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="questionCardList flex">

                            <div className="questionCards">
                                {currentShowQuestions && renderQuestionCards()}
                            </div>

                            <div className="change-page-btns flex">
                                <button className="left-btn flex" onClick={async () => await onPageChange(-1)} hidden={page === 1}>
                                    <AiOutlineLeft className="icon" />
                                </button>

                                {displayPageButtons(totalQuestionCount)}

                                <button className="right-btn flex" onClick={async () => await onPageChange(1)}>
                                    <AiOutlineRight className="icon" />
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    return (
        <>

            {
                isModal ? (
                    <Modal overlayClassName="customModal" className="questionCardListModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>
                        {renderCardList()}
                        <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
                    </Modal>
                ) : (
                    renderCardList()
                )
            }
        </>
    );
};

export default QuestionCardList;