import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';

import "../TestQuestion/testQuestion.css";

import QuestionSlide from '../../Questions/QuestionView/QuestionSlider/QuestionSlide';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { checkResponseCorrectness, securedFetchStudentQuestionResponsesByFilter } from '../../../services/UserQuestionService';
import { securedFetchTestStudentQuestionActionsByFilter } from '../../../services/TestStudentAction';
import { TEST_CREATE_TYPE_BASIC } from '../../../services/TestService';

const StudentTestQuestionView = ({ questions, testId, testStatus = null, showImages = false, showAnswers = false, user = STUDENT_USER_TYPE, testType = null }) => {

    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionData, setQuestionData] = useState(null);

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const [questionStyleById, setQuestionStyleById] = useState([]);

    const navigate = useNavigate();
    // Minimum distance (in pixels) to consider a swipe gesture
    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null on every new touch start
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () => {
        let index = (questionIndex === 0 ? questions.length - 1 : questionIndex - 1);

        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    }

    const next = () => {
        let index = (questionIndex === questions.length - 1 ? 0 : questionIndex + 1);

        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    }

    const getQuestionStatus = (id) => {
        const findQuestionStatus = questionStyleById.find(questionStatus => questionStatus.question_id === id);
        if (testStatus === 'STARTED') {
            if (findQuestionStatus) {
                return 'saved-response';
            }
            return 'unmarked-response';
        }
        if (!findQuestionStatus) {
            return 'no-response';
        }
        return findQuestionStatus.status;
    }

    const fetchAndSetResponses = async () => {
        if (testStatus === 'NOT STARTED' || testStatus === null) {
            return;
        }
        const studentResponses = await securedFetchTestStudentQuestionActionsByFilter(TEST_CREATE_TYPE_BASIC, {
            test_ids: [testId], question_ids: questions.map(question => question.question_id), latest_action_only: true
        }, navigateCallbackOptions(navigate));
        if (studentResponses === null || studentResponses.data.length === 0) {
            return;
        }
        if (testStatus === 'STARTED') {
            setQuestionStyleById(studentResponses.data.filter(studentResponse => studentResponse.student_question_response_id != null).map(studentResponse => ({ question_id: studentResponse.question_id, status: 'SAVED' })));
            return;
        }
        if (!studentResponses.data.find(data => data.student_question_response && data.student_question_response.total_correct !== null)) {
            return;
        }
        setQuestionStyleById(studentResponses.data.map(studentResponse => {
            const checkCorrect = studentResponse.student_question_response ? checkResponseCorrectness(studentResponse.student_question_response, true) : null;
            const status = checkCorrect === null ? '' : checkCorrect ? 'correct-response' : 'incorrect-response';
            return { question_id: studentResponse.question_id, status: status };
        }));
    }

    const onTestQuestionResponseChange = (questionId, event) => {
        const previousResponse = questionStyleById.find(questionStatus => questionStatus.question_id === questionId);
        if ((previousResponse && event === 'SAVE') || (!previousResponse && event === 'UNCHECK')) {
            return;
        }
        if (event === 'SAVE') {
            setQuestionStyleById([...questionStyleById, { question_id: questionId, status: 'SAVE' }]);
            return;
        }
        setQuestionStyleById(questionStyleById.filter(questionStatus => questionStatus.question_id !== questionId));
    }

    useEffect(() => {
        (async () => {
            if (questions.length > 0) {
                setQuestionIndex(0);
                setQuestionData(questions[0].question);
                await fetchAndSetResponses();
            }
        })();
    }, [questions, testStatus]);

    const renderQuestionSlide = () => {

        if (!questionData) {
            return <></>;
        }

        return <QuestionSlide
            basicInfo={questionData.basicInfo ? questionData.basicInfo : null}
            basicImages={questionData.basicImages ? questionData.basicImages : null}
            questionId={questionData.id}
            inputQuestionData={questionData}
            showImages={true}
            showAnswers={true}
            user={user}
            testId={testId}
            testStatus={testStatus}
            testType={testType}
            onTestQuestionResponseChange={onTestQuestionResponseChange}
        />;
    }

    const handleClickId = async (index) => {
        setQuestionIndex(index);
        setQuestionData(questions[index].question);
    };

    return (
        <div className="testQuestionView flex" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

            <div className="questionNumberSlide flex">
                <div className="questionNumber">
                    <p>Question {questionIndex + 1} {questions[questionIndex] && questions[questionIndex].paragraph_order != null ? `(P${questions[questionIndex].paragraph_order + 1} Q${questions[questionIndex].question_order + 1})` : ''}</p>
                </div>

                {renderQuestionSlide()}
            </div>

            <div className="questionButtons flex">
                <div className="questionIdxBtn grid">
                    {
                        questions.map((question, index) => (
                            <button className={`idxBtn ${getQuestionStatus(question.question_id)} flex`}
                                id={(index === questionIndex) ? 'clicked-btn' : 'default-btn'}
                                draggable
                                key={index}
                                onClick={() => handleClickId(index)}
                            >
                                {index + 1}
                            </button>))
                    }
                </div>
            </div>

        </div >
    )
};

export default StudentTestQuestionView;