
const HeaderSection = ({ header = null }) => {

    return (
        <div className="bg-transparent max-w-full px-1 py-1 sm:py-1 lg:px-1">
            <div className="mx-auto text-center">
                <h2 className="text-xl lg:text-2xl font-semibold tracking-tight text-white">{header}</h2>
            </div>
        </div>
    )

}

export default HeaderSection;